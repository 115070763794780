import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    phoneNumber: "",
    referSuccessToEmail: false,
    referSuccessToNumber: false,
    emailError: "",
    phoneNoError: "",
    emailLoader: false,
    phoneNoLoader: false,
};

export const referAndEarnSlice = createSlice({
    name: "refer",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        setReferSuccessToEmail: (state, action) => {
            state.referSuccessToEmail = action.payload;
        },
        setReferSuccessToNumber: (state, action) => {
            state.referSuccessToNumber = action.payload;
        },
        setEmailError: (state, action) => {
            state.emailError = action.payload;
        },
        setPhoneNoLoader: (state, action) => {
            state.phoneNoLoader = action.payload;
        },
        setEmailLoader: (state, action) => {
            state.emailLoader = action.payload;
        },
        setPhoneNoError: (state, action) => {
            state.phoneNoError = action.payload;
        }
    }
});

export const {
    setEmail,
    setPhoneNumber,
    setReferSuccessToEmail,
    setReferSuccessToNumber,
    setEmailError,
    setEmailLoader,
    setPhoneNoLoader,
    setPhoneNoError
} = referAndEarnSlice.actions;

export default referAndEarnSlice.reducer;