import React from 'react'
// import banner_img_mobile from "../../assets/images/png/redeemcoinsimagemobile.png";
// import banner_img_desktop from "../../assets/images/png/redeemcoinsimagedesktop.png";
import banner_img_mobile from "../../assets/images/web/homepagebanners/redeemcoinsimagemobile.webp";
import banner_img_desktop from "../../assets/images/web/homepagebanners/redeemcoinsimagedesktop.webp";
import { useNavigate } from 'react-router-dom';
import LazyImage from '../common/LazyImage';

const HowtoRedeemCoins = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="container">
                <div className="promotion_banner-main">
                    <div className="promotion-body">
                        <div className="promotion_banner" onClick={() => navigate('/howtogaincoins')}>
                            <span className="promotion_banner_mobile"><LazyImage src={banner_img_mobile} placeholder={banner_img_mobile} alt="" /></span>
                            <span className="promotion_banner_desktop"><LazyImage src={banner_img_desktop} placeholder={banner_img_desktop} alt="" /></span>
                            {/* <img className="promotion_banner_mobile" src={banner_img_mobile} alt="promotion_banner" />
                            <img className="promotion_banner_desktop" src={banner_img_desktop} alt="promotion_banner" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HowtoRedeemCoins