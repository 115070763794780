
import homeIconBlu from '../../assets/images/svg/footer_icons_mobile/homeIconBlu.svg';
import homeIconYlw from '../../assets/images/svg/footer_icons_mobile/homeIconYlw.svg';
import newsIconBlu from '../../assets/images/svg/footer_icons_mobile/newsIconBlu.svg';
import newsIconYlw from '../../assets/images/svg/footer_icons_mobile/newsIconYlw.svg';
import newsIconWhite from '../../assets/images/svg/footer_icons_mobile/newsIconWht.svg';
import gameIconBlu from '../../assets/images/svg/footer_icons_mobile/gameIconBlu.svg';
import gameIconYlw from '../../assets/images/svg/footer_icons_mobile/gameIconYlw.svg';
import gameIconWhite from '../../assets/images/svg/footer_icons_mobile/gameIconWht.svg';
import scoreIconBlu from '../../assets/images/svg/footer_icons_mobile/scoreIconBlu.svg';
import scoreIconYlw from '../../assets/images/svg/footer_icons_mobile/scoreIconYlw.svg';
import scoreIconWhite from '../../assets/images/svg/footer_icons_mobile/scoresIconWht.svg';
import storeIconBlu from '../../assets/images/svg/footer_icons_mobile/storeIconBlu.svg';
import storeIconYlw from '../../assets/images/svg/footer_icons_mobile/storeIconYlw.svg';
import storeIconWhite from '../../assets/images/svg/footer_icons_mobile/StoreIconWht.svg';
import highlightIconYlw from '../../assets/images/svg/footer_icons_mobile/highlights_yellow.png';
import highlightIconWhite from '../../assets/images/svg/footer_icons_mobile/starIconWht.svg';


export const footer_Data = [
    // {
    //     id: 1,
    //     title: "Home",
    //     link: "/home",
    //     image1: homeIconYlw,
    //     image2: homeIconBlu,
    //     value: "home"
    // },
    {
        id: 3,
        title: "Games",
        link: "/games",
        image1: gameIconYlw,
        image2: gameIconWhite,
        value: "games"
    },
    {
        id: 2,
        title: "News",
        link: "/news",
        image1: newsIconYlw,
        image2: newsIconWhite,
        value: "news"
    },
    {
        id: 1,
        title: "Highlights",
        link: "/viewallhighlights",
        image1: highlightIconYlw,
        image2: highlightIconWhite,
        value: "viewallhighlights"
    },
    {
        id: 4,
        title: "Scores",
        link: "/scores",
        image1: scoreIconYlw,
        image2: scoreIconWhite,
        value: "scores"
    },
    {
        id: 5,
        title: "Store",
        link: "/coinsmeanprizes",
        image1: storeIconYlw,
        image2: storeIconWhite,
        value: "store"
    }
];