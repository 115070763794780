import React, { useEffect, useState } from "react";
import { DotsContentLoader } from "../common/Loader";
import { useSelector } from "react-redux";
import moment from "moment";

function calculateTeamScoreSums(matches, teamId) {
  let home = 0;
  let away = 0;

  matches.forEach((match) => {
    if (match.homeTeamId === teamId) {
      home += parseInt(match.homeTeamScore);
      away += parseInt(match.awayTeamScore);
    }
    if (match.awayTeamId === teamId) {
      home += parseInt(match.awayTeamScore);
      away += parseInt(match.homeTeamScore);
    }
  });

  return [home, away];
}

const getResult = (teamId, rowData) => {
  if (teamId == rowData?.homeTeamId && rowData?.winner === "home") {
    return "W";
  } else if (teamId == rowData?.awayTeamId && rowData?.winner === "away") {
    return "W";
  } else if (teamId == rowData?.awayTeamId && rowData?.winner === "home") {
    return "L";
  } else if (teamId == rowData?.homeTeamId && rowData?.winner === "away") {
    return "L";
  } else {
    return "D";
  }
};

export const HomePreviewForm = ({ data, logo, handleTeamType, isLoading }) => {
  const [overGoals, setOverGoals] = useState("");
  const [bothTeamScored, setBothTeamScored] = useState("");
  const lineupsData = useSelector((state) => state.lineups);

  useEffect(() => {
    // console.log("homepage------------: ", data);
    const sumValues = [];
    data.forEach((obj) => {
      const sum = +obj.homeTeamScore + parseInt(obj.awayTeamScore);
      if (sum > 2.5) {
        sumValues.push(sum);
      }
    });
    setOverGoals(sumValues.length);

    const bothTeamValue = data?.filter(
      (item) => item.homeTeamScore > 0 && item.awayTeamScore > 0
    );
    setBothTeamScored(bothTeamValue.length);
  }, [data]);

  return (
    <React.Fragment>
      <div className="preview_form">
        <div className="form_head">
          <div className="form-head-previewimg">
            <img width={"24px"} height={"22px"} src={logo && logo} alt="" />
          </div>
          <h4>FORM</h4>
          <div className="form-head-select">
            <select onChange={(e) => handleTeamType(e, "home")}>
              <option value="all">All</option>
              <option value="home">Home</option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <DotsContentLoader />
        ) : (
          <React.Fragment>
            {data?.map((preview, ind) => {
              return (
                <div className="form_body" key={ind}>
                  <div className="date_details">
                    {moment(preview.kickoff).format("L")}
                  </div>
                  <div className="team_name">
                    <div className="home_team_name">{preview.homeTeamAbbr}</div>
                    <div className="team_scores">
                      <div>{preview.homeTeamScore}</div>
                      <div className="HAscores">-</div>
                      <div>{preview.awayTeamScore}</div>
                    </div>
                    <div className="away_team_name">
                      <div>{preview.awayTeamAbbr}</div>
                    </div>
                  </div>
                  <div className="team_status">
                    <div
                      className={`${getResult(
                        lineupsData?.topLiveScoreBanner?.homeTeamId,
                        preview
                      ) === "W"
                        ? "win"
                        : getResult(
                          lineupsData?.topLiveScoreBanner?.homeTeamId,
                          preview
                        ) === "L"
                          ? "loss"
                          : "draw"
                        }`}
                    >
                      {getResult(
                        lineupsData?.topLiveScoreBanner?.homeTeamId,
                        preview
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>
      <div className="game_goals_details">
        <div className="game_score">
          <div>Goals Scored (Conceded)</div>
          <div>
            {
              calculateTeamScoreSums(
                data,
                lineupsData?.topLiveScoreBanner?.homeTeamId
              )[0]
            }
            /
            {
              calculateTeamScoreSums(
                data,
                lineupsData?.topLiveScoreBanner?.homeTeamId
              )[1]
            }
          </div>
        </div>
        <div className="game_score">
          <div>Games Over 2.5 Goals</div>
          <div>
            {overGoals}/{data.length}
          </div>
        </div>
        <div className="game_score">
          <div>Both Teams Scored</div>
          <div>
            {bothTeamScored}/{data.length}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const AwayPreviewForm = ({ data, logo, handleTeamType, isLoading }) => {
  const [overGoals, setOverGoals] = useState("");
  const [bothTeamScored, setBothTeamScored] = useState("");
  const lineupsData = useSelector((state) => state.lineups);

  useEffect(() => {
    // console.log("awaypage============: ", data);
    const sumValues = [];
    data.forEach((obj) => {
      const sum = +obj.homeTeamScore + parseInt(obj.awayTeamScore);
      if (sum > 2.5) {
        sumValues.push(sum);
      }
    });
    setOverGoals(sumValues?.length);

    const bothTeamValue = data?.filter(
      (item) => item?.homeTeamScore > 0 && item?.awayTeamScore > 0
    );
    setBothTeamScored(bothTeamValue?.length);
  }, [data]);

  return (
    <React.Fragment>
      <div className="preview_form">
        <div className="form_head">
          <div className="form-head-previewimg">
            <img width={"24px"} height={"22px"} src={logo && logo} alt="" />
          </div>
          <h4>FORM</h4>
          <div className="form-head-select">
            <select onChange={(e) => handleTeamType(e, "away")}>
              <option value="all">All</option>
              <option value="away">Away</option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <DotsContentLoader />
        ) : (
          <React.Fragment>
            {data?.map((preview, ind) => {
              return (
                <div className="form_body" key={ind}>
                  <div className="date_details">
                    {moment(preview.kickoff).format("L")}
                  </div>
                  <div className="team_name">
                    <div className="home_team_name">{preview.homeTeamAbbr}</div>
                    <div className="team_scores">
                      <div>{preview.homeTeamScore}</div>
                      <div className="HAscores">-</div>
                      <div>{preview.awayTeamScore}</div>
                    </div>
                    <div className="away_team_name">
                      <div>{preview.awayTeamAbbr}</div>
                    </div>
                  </div>
                  <div className="team_status">
                    <div
                      className={`${getResult(
                        lineupsData?.topLiveScoreBanner?.awayTeamId,
                        preview
                      ) === "W"
                        ? "win"
                        : getResult(
                          lineupsData?.topLiveScoreBanner?.awayTeamId,
                          preview
                        ) === "L"
                          ? "loss"
                          : "draw"
                        }`}
                    >
                      {getResult(
                        lineupsData?.topLiveScoreBanner?.awayTeamId,
                        preview
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>
      <div className="game_goals_details">
        <div className="game_score">
          <div>Goals Scored (Conceded)</div>
          <div>
            {
              calculateTeamScoreSums(
                data,
                lineupsData?.topLiveScoreBanner?.awayTeamId
              )[0]
            }
            /
            {
              calculateTeamScoreSums(
                data,
                lineupsData?.topLiveScoreBanner?.awayTeamId
              )[1]
            }
          </div>
        </div>
        <div className="game_score">
          <div>Games Over 2.5 Goals</div>
          <div>
            {overGoals}/{data.length}
          </div>
        </div>
        <div className="game_score">
          <div>Both Teams Scored</div>
          <div>
            {bothTeamScored}/{data.length}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
