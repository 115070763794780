import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setNewsSearch, setNewsSearchData } from "./newsSlice";

const NewsSearch = () => {
    const [combinedNews, setCombinedNews] = useState([]);
    const dispatch = useDispatch();
    const {
        newsData,
        isLoading,
        newsSearchData,
        newsSearch
    } = useSelector((state) => state.news);

    const handleNewsSearch = (e) => {
        dispatch(setNewsSearch(e.target.value));
    }

    useEffect(() => {
        const combinedData = newsData?.categories?.reduce((acc, category) => {
            return acc.concat(category?.data);
        }, []);

        if (combinedData) {
            const uniqueIds = {};
            const filteredData = combinedData.filter(data => {
                if (!uniqueIds[data.newsID]) {
                    uniqueIds[data.newsID] = true;
                    return true;
                }
                return false;
            });
            setCombinedNews(filteredData);
        }
    }, [newsData]);

    useEffect(() => {
        getSearchResults(combinedNews, newsSearch)
    }, [newsSearch, combinedNews]);

    const getSearchResults = (data, key) => {
        const data1 = filterSearchData(data, key)
        dispatch(setNewsSearchData(data1));
    };

    const filterSearchData = (data, letterToFilter) => {
        return data
            .map(details => ({
                ...details,
                relatesTo: searchDataByLetter(details, letterToFilter)
            }))
            .filter(details => details.relatesTo.length > 0);
    };

    const searchDataByLetter = (details, letterToFilter) => {
        if (details === null || letterToFilter === null) {
            return []
        }
        return details.relatesTo.filter(match =>
            match?.toLowerCase()?.includes(letterToFilter?.toLowerCase()) ||
            details?.newsTitle?.toLowerCase()?.includes(letterToFilter?.toLowerCase())
        );
    };

    return (
        <React.Fragment>
            <div className='news_search_input'>
                <input
                    type={"text"}
                    className="search_input"
                    name='confimPassword'
                    placeholder="Search all news"
                    onChange={(e) => handleNewsSearch(e)}
                    value={newsSearch}
                />
                <span className='pointer'><FiSearch /></span>
            </div>
        </React.Fragment>
    )
};
export default NewsSearch;