import React, { useState, useEffect } from "react";
import Accordion from "../common/Accordion";
import GainCoinsModal from "./GainCoinsModal";
// import howToGainCoinsImageDesktop from "../../assets/images/png/howtogaincoins/howtogaincoinsDesktop.png";
// import howToGainCoinsImageMobile from "../../assets/images/png/howtogaincoins/howtogaincoinsmobile.png";
import howToGainCoinsImageMobile from "../../assets/images/web/howtogaincoins/howtogaincoinsmobile.webp";
import howToGainCoinsImageDesktop from "../../assets/images/web/howtogaincoins/howtogaincoinsDesktop.webp";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getReferredByUser } from "../signIn/signInApi";
import { AiFillCaretRight } from "react-icons/ai";
import Footer from "../common/Footer";
import { getFromLocalStorage } from "../../services/helper/Index";
import { coins } from "../../config/appConfig";

function HowToGainCoins() {
  const navigate = useNavigate();
  const [coinsModal, setCoinsmodal] = useState(false);
  const { userDetails, referredUser } = useSelector((state) => state.signIn);
  const dispatch = useDispatch();
  const token = getFromLocalStorage("gk");
  const staticDatas = useSelector((state) => state?.staticData);
  const howToGainCoinsDataInfo1 =
    staticDatas?.staticData &&
    staticDatas?.staticData?.howToGainCoins[0]?.info1;
  const howToGainCoinsInfo2 =
    staticDatas?.staticData &&
    staticDatas?.staticData?.howToGainCoins[0]?.info2;
  const howToGainCoinsDataInfo3 =
    staticDatas?.staticData &&
    staticDatas?.staticData?.howToGainCoins[0]?.info3;
  const [isAccordionOneActive, setisAccordionOneActive] = useState(false);
  const [isAccordionTwoActive, setisAccordionTwoActive] = useState(false);
  const { isSignInSuccess } = useSelector((state) => state.signIn);

  const navigateToProfile = (profileLink) => {
    const token = getFromLocalStorage("gk");
    if (token && isSignInSuccess) {
      navigate(profileLink);
    } else {
      navigate("/signin");
    }
  };
  useEffect(() => {
    {
      token && dispatch(getReferredByUser());
    }
  }, [dispatch]);
  return (
    <React.Fragment>
      <Footer />
      {<GainCoinsModal open={coinsModal} close={() => setCoinsmodal(false)} />}
      <div className="gain_coins_wrapper">
        <div className="container">
          <div className="gain_coin_container">
            <div className="gain_coins_head">
              <div className="gain_coins_title title_for_mobile">
                How To Gain Coins
              </div>
              {referredUser?.username ? (
                <div className="gain_coins_ref">
                  Ref - {referredUser?.username}
                </div>
              ) : null}
            </div>
            <div className="gain_coin_bannerHeader">
              <div className="gain_coin_banner">
                <div className="gain_coin_banner_image_MB">
                  <img
                    className="howToGainCoinsImageMobile"
                    alt="banner"
                    // height={350}
                    src={howToGainCoinsImageMobile}
                  />
                </div>
                <div className="gain_coin_banner_image_DSK">
                  <img
                    className="howToGainCoinsImageDesktop"
                    alt="banner"
                    // height={350}
                    src={howToGainCoinsImageDesktop}
                  />
                </div>
              </div>
            </div>
            {/* Ways to Gain Coins */}
            <div className="HowTogain_Accordion">
              {/* <Accordion title={""} content={<AccOneContent />} />
               */}
              <div
                className={
                  isAccordionOneActive
                    ? "howToGainCoins_content collapsed"
                    : "howToGainCoins_content"
                }
              >
                <div
                  className="howToGainCoins_contentHeader"
                  onClick={() => setisAccordionOneActive(!isAccordionOneActive)}
                >
                  How to Gain Coins{" "}
                </div>
                <div className="howToGainCoins_contentBody">
                  <div className="howToGainCoins_contentSection">
                    <div className="content">
                      <p>
                        Coins are at the heart of all of the games available to
                        you on our Play Big Website.
                      </p>
                      <p>
                        Although the website is play with coins to use and we
                        give you ample coins to play our games for the first few
                        weeks, you will need to collect more coins to continue
                        playing in relatively simple ways.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  isAccordionTwoActive
                    ? "howToGainCoins_content collapsed"
                    : "howToGainCoins_content"
                }
              >
                <div
                  className="howToGainCoins_contentHeader"
                  onClick={() => setisAccordionTwoActive(!isAccordionTwoActive)}
                >
                  Ways to Gain Coins{" "}
                </div>
                <div className="howToGainCoins_contentBody">
                  <div className="howToGainCoins_contentSection">
                    <div className="content">
                      <div className="title">FREQUENCY</div>
                      <p>
                        You’ll be rewarded by logging in frequently in any
                        calendar week. Here’s what you can earn:
                      </p>
                      <div
                        className="howToGainCoinsDataInfo"
                        dangerouslySetInnerHTML={{
                          __html: howToGainCoinsDataInfo1,
                        }}
                      ></div>
                      <div
                        className="howToGainCoinsDataInfo"
                        dangerouslySetInnerHTML={{
                          __html: howToGainCoinsInfo2,
                        }}
                      ></div>
                      <div
                        className="howToGainCoinsDataInfo"
                        dangerouslySetInnerHTML={{
                          __html: howToGainCoinsDataInfo3,
                        }}
                      ></div>
                      <p>
                        To confirm how many days you have logged in during this
                        game week,{" "}
                        <span
                          className="pointer"
                          onClick={() => setCoinsmodal(true)}
                        >
                          <u>CLICK HERE</u>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="howToGainCoins_contentSection">
                    <div className="content">
                      <div className="title">Completion of Profile Details</div>
                      <p>
                        Add your personal details and we will reward you with{" "}
                        {coins?.completeProfile} Coins! You can do this by
                        visiting your profile{" "}
                        <span
                          class="pointer"
                          onClick={() => navigateToProfile("/personal-details")}
                        >
                          <u>HERE</u>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="howToGainCoins_contentSection">
                    <div className="content">
                      <div className="title">Friend Referrals</div>
                      <p>
                        You will receive {coins?.completeProfile} coins for
                        every player you refer (with a maximum of{" "}
                        {coins?.maxReferCoinsPerWeek} referrals per week).
                      </p>
                      <p>
                        Adding friends is also a great way to compete to see who
                        has the greatest football knowledge. So, you’ll not only
                        win coins – you'll win the respect of your invited
                        friends!
                      </p>
                      {/* <ul>
                        <li>
                          <span>
                            <AiFillCaretRight />
                          </span>
                          <div>
                            {" "}
                            You'll get kudos from your friends for introducing
                            them to our great games where you can compete
                            against them
                          </div>
                        </li>
                        <li>
                          <span>
                            <AiFillCaretRight />
                          </span>{" "}
                          You win when they win! If one of the friends that you
                          have referred goes on to win one of our top first
                          placed prizes then you will win up to 10% of the prize
                          amount that they win
                        </li>
                        <li>
                          <span>
                            <AiFillCaretRight />
                          </span>{" "}
                          You will gain an extra {coins?.referToFriend} Coin for each player you
                          refer (max {coins?.maxReferCoinsPerWeek} persons per week).
                        </li>
                      </ul> */}
                      <p>
                        <span>
                          {" "}
                          <Link to="/referandearn">REFER FRIENDS HERE </Link>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="howToGainCoins_contentSection">
                    <div className="content">
                      <div className="title">Playing Games</div>
                      <p>
                        There are lots of ways to win coins when playing games!
                        Each game has a unique payout structure, so make sure
                        you check out the rules for each game to see how many
                        coins you can win. Aside from the individual game
                        winnings, there are weekly leaderboards for all games
                        where additional coins can be won.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default HowToGainCoins;
