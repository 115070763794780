import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";

const initialState = {
  isLoading: false,
  upcomingData: [],
  liveData: [],
};

export const liveUpcomingSlice = createSlice({
  name: "liveUpcoming",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUpcomingData(state, action) {
      state.upcomingData = action.payload;
    },
    setLiveData(state, action) {
      state.liveData = action.payload;
    },
  },
});

export const { setIsLoading, setUpcomingData, setLiveData } =
  liveUpcomingSlice.actions;

export const getLiveUpcoming = (type, page, limit) => (dispatch) => {
  dispatch(setIsLoading(true));
  dispatch(setUpcomingData([]));
  dispatch(setLiveData([]));
  let url = endpoints.homeLiveUpcoming;
  let homeLiveUpcomingBaseUrl;
  if (type?.toLowerCase() === "upcoming" && page && limit) {
    homeLiveUpcomingBaseUrl = url + `?type=${type}&page=${page}&limit=${limit}`;
  } else if (type?.toLowerCase() === "live") {
    homeLiveUpcomingBaseUrl = url + `?type=${type}`;
  }
  if (homeLiveUpcomingBaseUrl) {
    getRequest(homeLiveUpcomingBaseUrl)
      .then((res) => {
        if (res?.status === 200 && res?.data?.data) {
          if (type?.toLowerCase() === "upcoming") {
            dispatch(setUpcomingData(res?.data?.data));
          } else if (type?.toLowerCase() === "live") {
            dispatch(setLiveData(res?.data?.data));
          }
        }
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        console.log(`Error in getting ${type} Matches`);
        dispatch(setIsLoading(false));
      });
  }
};

export default liveUpcomingSlice.reducer;
