import React from "react";
import { useNavigate } from "react-router";
import { IoChevronBackCircle } from "react-icons/io5";
import backArrow from "../../assets/images/png/back_arrow.png";

function BackButton() {
  const navigate = useNavigate();
  const goBack = () => {
    if (
      window.location.href.indexOf("code=") > -1 &&
      window.location.href.indexOf("mobile=") > -1
    ) {
      window.location.href = "/";
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="back-button pointer" onClick={goBack}>
      <div className="backButton_Icon">
        {/* <IoChevronBackCircle /> */}
        <img src={backArrow} alt="" />
      </div>
      {/* <div className="backButton_Text">BACK</div> */}
    </div>
  );
}

export default BackButton;
