import React from "react";
import PromotionTopBanner from "./PromotionTopBanner";
// import becomeVipBannerMobile from "../../assets/images/png/promotions/becomeVipBannar_mobile.png";
// import becomeVipBannerWeb from "../../assets/images/png/promotions/becomeVipBanner_web.png";
import becomeVipBannerMobile from "../../assets/images/web/promotions/becomeVipBannar_mobile.webp";
import becomeVipBannerWeb from "../../assets/images/web/promotions/becomeVipBanner_web.webp";
import Footer from "../common/Footer";

const BecomeVip = () => {
    return (
        <React.Fragment>
            <Footer />
            <div className="container">
                <PromotionTopBanner
                    title={"Become VIP"}
                    mobileBanner={becomeVipBannerMobile}
                    webBanner={becomeVipBannerWeb}
                />
                <div className="promotion_detail">
                    <div className="detail_contain">
                        <h4>Become VIP</h4>
                        <ol>
                            <li>Playbig has the best sports betting VIP program. They are very generous with their VIPs, giving out free bets, special promotions, physical gifts, tickets to major sporting events and more.</li>
                            <li>The Playbig VIP program is by invitation only. Typically, users get an email from a Playbig representative if they “make the cut.” </li>
                            <li>Above, you'll see an example of a Playbig VIP sports betting bonuses. For March Madness, Playbig is offering three 25% profit boosts to VIP members. Profit boosts are pretty simple - you're profit increases by the “boost” amount. In other words, with a 25% profit boost, +100 odds goes to +125 (or +200 to +250 odds). Profit boosts make it much easier to beat the viP and find Positive EV wagers.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BecomeVip;