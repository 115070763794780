import { endpoints } from "../../api/ApiEndPoints";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../../services/apiCaller/index";
import { cleanObject } from "../../services/helper/Index";
import { setUserDetails } from "../signIn/signSlice";
import {
  setAccoladesDetails,
  setAllAvatarImages,
  setErrorWhileSaving,
  setIsErrorWhileSaving,
  setIsUsernameAvailable,
  setSuccessMessage,
  setUserNameAvailbleMessage,
  setUserNameUpdate,
  setIsProfileAvtarSuccess,
  setDetailsUpdateModal,
  setFormToShowInModal,
} from "./myProfileSlice";
import {
  setEmailUpdateOtpSent,
  setEmailUpdateOtpSentLoading,
  setEmailErrorMessage,
  setEmailUpdateOtpVerify,
  setEmailUpdateOtpVerifyLoading,
  setEmailOtpErrorMessage,
  setPhoneUpdateGenerateOtp,
  setPhoneUpdateGenerateOtpLoading,
  setPhoneErrorMessage,
  setPhoneUpdateVerifyOtp,
  setPhoneUpdateVerifyOtpLoading,
  setPhoneOtpErrormessage,
} from "./myProfileSlice";
import { getuserDetails } from "../home/homeApi";

export const isUserNameChanged = (latestuserName, getState) => {
  const { signIn } = getState();
  const oldUsername = signIn?.userDetails?.username;
  if (oldUsername === latestuserName) {
    return false;
  } else {
    return true;
  }
};
export const checkEmptyObj = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const isEighteenPlus = (dob) => {
  const [day, month, year] = dob.split("-");
  const birthDate = new Date(`${year}-${month}-${day}`);
  const diffInMs = Date.now() - birthDate.getTime();
  const ageInYears = diffInMs / 1000 / 60 / 60 / 24 / 365;
  return ageInYears >= 18;
};

function convertDateFormat(dateString) {
  const [day, month, year] = dateString.split("-");
  const newDateString = `${month}-${day}-${year}`;
  return newDateString;
}

export const validateUserName = (username) => (dispatch) => {
  let uservalidateUrl = endpoints.userNameValidate;
  let body = {
    username: username,
  };

  postRequest(uservalidateUrl, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setIsUsernameAvailable(true));
        dispatch(setUserNameAvailbleMessage("Username is available"));
      } else {
        dispatch(setIsUsernameAvailable(false));
        dispatch(setIsErrorWhileSaving(true));
        dispatch(setUserNameAvailbleMessage("Sorry username is taken"));
      }
    })
    .catch((err) => {});
};
export const updateUserName = (username) => (dispatch) => {
  let userUpdateUrl = endpoints.updateUser;
  let body = {
    username: username,
  };

  putRequest(userUpdateUrl, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setIsUsernameAvailable(true));
        dispatch(setUserNameAvailbleMessage(res.data.message));
        dispatch(setUserDetails(res?.data?.updatedData));
        dispatch(setUserNameUpdate(true));
      } else {
        const result = res.response;
        dispatch(setIsUsernameAvailable(false));
        dispatch(setUserNameAvailbleMessage(result.data.message));
      }
    })
    .catch((err) => {
      const errorResponse = err.response;
      dispatch(setIsUsernameAvailable(false));
      dispatch(setUserNameAvailbleMessage(errorResponse.data.message));
    });
};
export const updateUserDetails =
  (userDetails, setIsInputEnable) => (dispatch, getState) => {
    let userUpdateUrl = endpoints.updateUser;
    const isChanged = isUserNameChanged(userDetails.username, getState);
    if (!isChanged) {
      var payload = {
        ...userDetails,
        address: checkEmptyObj(userDetails.address),
        dob: convertDateFormat(userDetails.dob),
        username: "",
      };
    } else {
      payload = {
        ...userDetails,
        dob: convertDateFormat(userDetails.dob),
      };
    }
    putRequest(userUpdateUrl, cleanObject(payload))
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUserDetails(res?.data?.updatedData));
          dispatch(setIsErrorWhileSaving(false));
          dispatch(setErrorWhileSaving(""));
          dispatch(setSuccessMessage(res.data.message));
          dispatch(setDetailsUpdateModal(true));
          setIsInputEnable(true);
        } else {
          const result = res.response;
          dispatch(setIsErrorWhileSaving(true));
          dispatch(setErrorWhileSaving(result.data.message));
          dispatch(setSuccessMessage(""));
        }
      })
      .catch((err) => {
        const errorResponse = err.response;
        console.log("errorResponse: ", errorResponse);
        dispatch(setSuccessMessage(""));
        dispatch(setIsErrorWhileSaving(true));
        dispatch(setErrorWhileSaving(errorResponse.data.message));
      });
  };
export const getAllAvatarImages = () => (dispatch) => {
  let allAvatarUrl = endpoints.avatarList;
  getRequest(allAvatarUrl, true)
    .then((res) => {
      dispatch(setAllAvatarImages(res.data));
    })
    .catch((err) => {});
};
export const updateAvatar = (avatarUrl) => (dispatch) => {
  dispatch(setIsProfileAvtarSuccess(false));
  let userUpdateUrl = endpoints.updateUser;
  let body = {
    avatarPath: avatarUrl,
  };

  putRequest(userUpdateUrl, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setUserDetails(res?.data?.updatedData));
        dispatch(setIsProfileAvtarSuccess(true));
      } else {
        dispatch(setIsProfileAvtarSuccess(false));
      }
    })
    .catch((err) => {});
};

export const accoladesUser = () => (dispatch) => {
  const accoladesUrl = endpoints.accolades;
  getRequest(accoladesUrl, true)
    .then((res) => {
      if (res?.status === 200) {
        dispatch(setAccoladesDetails(res.data));
      }
    })
    .catch((err) => {});
};

export const emailUpdateGenerateOtp = (email) => (dispatch) => {
  let emailUpdateGenerateOtpURL = endpoints.emailUpdateGenerateOtp;
  let body = {
    email: email,
  };
  dispatch(setEmailUpdateOtpSent(false));
  dispatch(setEmailUpdateOtpSentLoading(true));
  putRequest(emailUpdateGenerateOtpURL, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setEmailUpdateOtpSentLoading(false));
        dispatch(setEmailUpdateOtpSent(true));
        dispatch(setFormToShowInModal("emailOtp"));
      } else {
        dispatch(setEmailUpdateOtpSentLoading(false));
        dispatch(setEmailErrorMessage(res?.response?.data?.message));
      }
    })
    .catch((err) => {
      dispatch(setEmailUpdateOtpSentLoading(false));
      dispatch(setEmailErrorMessage("otp sent failed"));
    });
};

export const emailUpdateVerifyOtp = (code) => (dispatch) => {
  let emailUpdateVerifyOtpUrl = endpoints.emailUpdateVerifyOtp;
  const stringCode = code.join("");
  const otp = parseInt(stringCode);
  let body = {
    code: otp,
  };

  dispatch(setEmailUpdateOtpVerifyLoading(true));
  dispatch(setEmailUpdateOtpVerify(false));
  dispatch(setEmailOtpErrorMessage(""));
  postRequest(emailUpdateVerifyOtpUrl, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setEmailUpdateOtpVerifyLoading(false));
        dispatch(setEmailUpdateOtpVerify(true));
        dispatch(setFormToShowInModal("coingainmessage"));
        dispatch(getuserDetails());
      } else {
        dispatch(setEmailUpdateOtpVerifyLoading(false));
        dispatch(setEmailOtpErrorMessage(res?.response?.data?.message));
      }
    })
    .catch((err) => {
      dispatch(setEmailUpdateOtpVerifyLoading(false));
      dispatch(setEmailOtpErrorMessage("Otp verify fail"));
    });
};

export const phoneUpdateGenerateOtp =
  (phonenumber, Code) => (dispatch) => {
    let phoneUpdateGenerateOtpURL = endpoints.phoneUpdateGenerateOtp;
    let body = {
      countryCode: Code,
      mobile: phonenumber,
    };
    dispatch(setPhoneUpdateGenerateOtp(false));
    dispatch(setPhoneUpdateGenerateOtpLoading(true));
    putRequest(phoneUpdateGenerateOtpURL, cleanObject({ ...body }))
      .then((res) => {
        if (res.status === 200) {
          dispatch(setPhoneUpdateGenerateOtpLoading(false));
          dispatch(setPhoneUpdateGenerateOtp(true));
          dispatch(setFormToShowInModal("phoneOtp"));
        } else {
          dispatch(setPhoneUpdateGenerateOtpLoading(false));
          dispatch(setPhoneErrorMessage(res?.response?.data?.message));
          // dispatch(setIsProfileAvtarSuccess(false))
        }
      })
      .catch((err) => {
        dispatch(setPhoneUpdateGenerateOtpLoading(false));
        dispatch(setPhoneErrorMessage("otp sent failed"));
      });
  };

export const phoneUpdateVerifyOtp = (code) => (dispatch) => {
  let phoneUpdateVerifyOtp = endpoints.phoneUpdateVerifyOtp;
  const stringCode = code.join("");
  const otp = parseInt(stringCode);
  let body = {
    code: otp,
  };

  dispatch(setPhoneUpdateVerifyOtpLoading(true));
  dispatch(setPhoneUpdateVerifyOtp(false));
  dispatch(setPhoneOtpErrormessage(""));
  postRequest(phoneUpdateVerifyOtp, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setPhoneUpdateVerifyOtpLoading(false));
        dispatch(setPhoneUpdateVerifyOtp(true));
        dispatch(setFormToShowInModal("coingainmessage"));
        dispatch(getuserDetails());
      } else {
        dispatch(setPhoneUpdateVerifyOtpLoading(false));
        dispatch(setPhoneOtpErrormessage(res?.response?.data?.message));
      }
    })
    .catch((err) => {
      dispatch(setPhoneUpdateVerifyOtpLoading(false));
      dispatch(setPhoneOtpErrormessage("Otp verify fail"));
    });
};
