import { endpoints } from "../../api/ApiEndPoints";
import { putRequest } from "../../services/apiCaller";
import { cleanObject } from "../../services/helper/Index";
import { setUserDetails } from "../signIn/signSlice";
import { setLoader, setIsApiCall, setSuccessMsg, setError } from "./SettingSlice";

export const updateUserInfo = (sms, push, email, offer) => (dispatch) => {
    const url = endpoints?.changeUserInfo
    const payload = {
        isAllowSmsNotification: sms,
        isAllowPushNotification: push,
        isAllowEmailNotification: email,
        isAllowOfferNotification: offer
    };

    const headers = {
        authorization: "5664564537546hvrytf56rfhgv"
    };

    dispatch(setLoader(true));
    dispatch(setIsApiCall(true));
    putRequest(url, cleanObject(payload), headers).then((res) => {
        if (res?.status === 200) {
            dispatch(setUserDetails(res?.data?.updatedData))
            dispatch(setLoader(false));
            dispatch(setIsApiCall(false));
            dispatch(setError(true));
            dispatch(setSuccessMsg("Preferences Updated"));
        } else {
            dispatch(setLoader(false));
            dispatch(setIsApiCall(false));
            dispatch(setError(false))
        }
    });
}