import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSideMenuOpened: false
};

export const headerSlice = createSlice({
    name: "sign",
    initialState,
    reducers: {
        setIsSideMenuOpened: (state, action) => {
            state.isSideMenuOpened = action.payload;
        },
    }
});

export const {
    setIsSideMenuOpened,
} = headerSlice.actions;


export default headerSlice.reducer;
