import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changePassword, validatePin } from "./changePasswordApi";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
    setChangePinSuccess,
    setPasswordErrorMessage,
    setPasswordSuccessMessage
} from "./changePasswordSlice";
import { ButtonLoader } from "../common/Loader";
import Footer from "../common/Footer";

function ChangePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passwordDetails, setPasswordDetails] = useState({
        oldpin: "",
        newpin: "",
        confirmpin: "",
    })
    const [oldPinError, setOldPinError] = useState("");
    const [newPinError, setNewPinError] = useState("");
    const [confirmPinError, setConfirmPinError] = useState("");
    const [validateError, setValidateError] = useState("");

    const { passwordSuccessMessage, passwordErrorMessage, changePinSuccess, loader } = useSelector((state) => state.changePassword);
    const { userDetails } = useSelector((state) => state.signIn);

    const inputHandler = (e) => {
        const name = e.target.name
        const value = e.target.value
        setPasswordDetails({ ...passwordDetails, [name]: value })
        dispatch(setPasswordSuccessMessage(""))
        if (name === "oldpin") {
            setOldPinError("");
            dispatch(setPasswordErrorMessage(""));
        }
        if (name === "newpin") {
            setNewPinError("");
        }
        if (name === "confirmpin") {
            setConfirmPinError("");
            setValidateError("");
        }
    }

    useEffect(() => {
        dispatch(setPasswordSuccessMessage(""));
        dispatch(setPasswordErrorMessage(""));
    }, [])

    useEffect(() => {
        if (changePinSuccess) {
            setPasswordDetails({
                oldpin: "",
                newpin: "",
                confirmpin: "",
            })
            dispatch(setChangePinSuccess(false))
        }
    }, [changePinSuccess]);

    const handleChangePassword = (passwordDetails, e) => {
        e.preventDefault()
        if (passwordDetails.oldpin.trim() === "" && passwordDetails.newpin.trim() === "" && passwordDetails.confirmpin.trim() === "") {
            setOldPinError("Please Enter Current Password");
            setNewPinError("Please Enter New Password");
            setConfirmPinError("Please Enter Confirm Password");
        } else if (passwordDetails.oldpin.trim() === "") {
            setOldPinError("Please Enter Current Password");
        } else if (passwordDetails.newpin.trim() === "") {
            setNewPinError("Please Enter New Password");
        } else if (passwordDetails.oldpin.length < 6) {
            setOldPinError("Password must have 6 alphanumeric characters");
        } else if (passwordDetails.newpin.length < 6) {
            setNewPinError("Password must have 6 alphanumeric characters");
        } else if (!validatePin(passwordDetails.newpin)) {
            setNewPinError("Password should have at least 1 lowercase letter, 1 uppercase letter, 1 number.");
        } else if (passwordDetails.newpin === userDetails.username) {
            setNewPinError("Can't have the same password as your username");
        } else if (passwordDetails.confirmpin.trim() === "") {
            setConfirmPinError("Please Enter Confirm Password");
        } else if (passwordDetails.newpin !== passwordDetails.confirmpin) {
            dispatch(setPasswordErrorMessage(""));
            setValidateError("New Pin and Confirm Pin should be same");
        } else {
            setOldPinError("");
            setNewPinError("");
            setValidateError("");
            dispatch(changePassword(passwordDetails.oldpin, passwordDetails.newpin))
        }
    };

    return (
        <React.Fragment >
            <Footer />
            <div className="container">
                <div className="password-content">
                    <div className="title">Change Pin / Password</div>
                    <div className="edit-password-title">Edit PIN/PASSWORD</div>
                    <hr className="hr-line" />
                    <div className="update-password-title">Update your PIN/PASSWORD below</div>
                    <form onSubmit={(e) => handleChangePassword(passwordDetails, e)} className="form-group">
                        <div className="input-container">
                            <div className="input-type">Current PIN/PASSWORD</div>
                            <input
                                type="text"
                                placeholder="Abc111"
                                name="oldpin"
                                className="input-box"
                                value={passwordDetails.oldpin}
                                onChange={(e) => inputHandler(e)}
                                // minLength={6}
                                maxLength={15}
                            />
                            <div className='error-msg error-msg-position'>
                                {oldPinError}
                                {passwordErrorMessage && passwordErrorMessage}
                            </div>
                            <div className="input-type">New PIN/PASSWORD</div>
                            <input
                                type="text"
                                placeholder="Abc222"
                                className="input-box"
                                name="newpin"
                                value={passwordDetails.newpin}
                                onChange={(e) => inputHandler(e)}
                                // minLength={6}
                                maxLength={15}
                            />
                            <div className='error-msg error-msg-position'>
                                {newPinError}
                            </div>
                            <div className="input-type">Confirm PIN/PASSWORD</div>
                            <input
                                type="text"
                                placeholder="Abc222"
                                className="input-box"
                                name="confirmpin"
                                value={passwordDetails.confirmpin}
                                onChange={(e) => inputHandler(e)}
                                // minLength={6}
                                maxLength={15}
                            />
                            <div className='error-msg error-msg-position'>
                                {confirmPinError}
                                {validateError}
                            </div>
                        </div>
                        <div className="validation-msg">
                            <div>Your PIN/PASSWORD : </div>
                            <div className="pin_contents">
                                <div className="pin_content">
                                    <span><BsCheck className="check_icon" /></span>
                                    <p>Must have at least 6 alphanumeric characters</p>
                                </div>
                                <div className="pin_content">
                                    <span><BsCheck className="check_icon" /></span>
                                    <p>One of which will need to be numeric</p>
                                </div>
                                <div className="pin_content">
                                    <span><BsCheck className="check_icon" /></span>
                                    <p>Letters will need to be a combination of upper and lower case (at least one of each)</p>
                                </div>
                            </div>
                        </div>
                        <div className="btn-container">
                            <button className="button update-btn" type="submit">
                                {loader ? <ButtonLoader /> : "CHANGE PIN/PASSWORD"}
                            </button>
                            <button className="button back-btn" onClick={() => (dispatch(setPasswordSuccessMessage("")), navigate("/settings"))}>
                                BACK
                            </button>
                        </div>
                        <div className="success-msg">
                            &emsp; {passwordSuccessMessage && passwordSuccessMessage}
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ChangePassword