import React, { useEffect } from "react";
import SuperBanner from "../superBanner/SuperBanner";
import LiveUpcoming from "../LiveUpcoming/LiveUpcoming";
import HitSixGame from "../hitsix/HitSixGame";
import PredictMatch from "../PredictMatch/PredictMatch";
import VirtualCTT from "../virtualCtt/VirtualCTT";
import { useDispatch, useSelector } from "react-redux";
import { HomePageLoader } from "../common/Loader";
import { setCompletedMatchesCount, setSocketMatchesData } from "./homeSlice";
import Highlights from "../highlights/Highlights";
import Groups from "../groups/Groups";
import StoreBanner from "../store/StoreBanner";
import GoingViral from "../goingViral/GoingViral";
import Footer from "../common/Footer";
import PromotionBanner from "../PromotionBanner/PromotionBanner";
import HowtoRedeemCoins from "../howtoredeemcoins/HowtoRedeemCoins";

function Home() {
  const { isBannerLoading } = useSelector((state) => state.superBanner);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {isBannerLoading ? (
        <HomePageLoader />
      ) : (
        <React.Fragment>
          {isBannerLoading ? (
            <HomePageLoader />
          ) : (
            <React.Fragment>
              <Footer title={""} />
              <SuperBanner />
              <HowtoRedeemCoins />
              <LiveUpcoming />
              <Highlights />
              {/* <PromotionBanner /> */}
              <PredictMatch />
              <StoreBanner />
              {/* <Groups />
              <GoingViral /> */}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Home;
