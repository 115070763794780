import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import FaqAccordion from './FaqAccordion';
import { getFromLocalStorage } from '../../services/helper/Index';
import Footer from '../common/Footer';

function Faq() {
    const staticDatas = useSelector((state) => state.staticData);
    const token = getFromLocalStorage("gk")
    const { isSignInSuccess } = useSelector(state => state.signIn)
    const faqResponseAfterLogin = staticDatas?.staticData?.playbigsportsfaqs;
    const faqsBeforeLogin = staticDatas?.staticData?.playbigsportsfaqsBeforeLogin;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <React.Fragment>
            <Footer />
            <div className="faq_wrapper">
                <div className="container">
                    <div className="faq_container">
                        <div className="faq_head">
                            <div className="title brand_secondary" >FAQ's</div>
                        </div>
                        <div className="faq_Accordion">
                            {
                                token && isSignInSuccess ?
                                    faqResponseAfterLogin && faqResponseAfterLogin.map((obj, ind) => {
                                        return (
                                            <div className="faq_Accordion_Content" key={ind}>
                                                <FaqAccordion title={obj?.question} content={obj.answers} />
                                            </div>
                                        );
                                    })

                                    :
                                    faqsBeforeLogin && faqsBeforeLogin.map((obj, ind) => {
                                        return (
                                            <div className="faq_Accordion_Content" key={ind}>
                                                <FaqAccordion title={obj?.question} content={obj.answers} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default Faq