import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { setIsSideMenuOpened } from "./headerSlice";
import { useSelector, useDispatch } from "react-redux";
import pickSixIcon from "../../assets/images/png/sidemenuIcons/pick6.png";
import MpIcon from "../../assets/images/png/sidemenuIcons/mp_icon.png";
import gamesIcon from "../../assets/images/png/sidemenuIcons/gamesIcon.png"
import VcttIcon from "../../assets/images/svg/sideMenuicons/ball.svg";
import penality_Shootout from "../../assets/images/png/sidemenuIcons/penalty_shootut.png";
import { getFromLocalStorage } from '../../services/helper/Index';

export default function SideMenuAccordion(props) {
    const [sidemenuAccordion, setSidemenuAccordion] = useState(true);
    const { isSideMenuOpened } = useSelector((state) => state.header);
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const navigate = useNavigate()
    const dispatch = useDispatch();



    const toggleSideMenu = () => {
        dispatch(setIsSideMenuOpened(!isSideMenuOpened));
    };


    const navigateToGame = (GameLink) => {
        const token = getFromLocalStorage("gk");
        if (token && isSignInSuccess) {
            navigate(GameLink);
        } else {
            navigate("/signin");
        }
    }
    return (
        <li className='sidmenu_games_list'>
            <div
                className={sidemenuAccordion ? "collapsed" : "collapse"}
                onClick={() => setSidemenuAccordion(!sidemenuAccordion)}
            >
                <div className="sidemenu-accordion sidemen-games">
                    <div className="left">
                        <div className="icon_container">
                            <img alt="icon" src={gamesIcon} />
                        </div>
                        <div className="list_title">{"Games"}</div>
                    </div>
                    <div className="right">
                        <MdOutlineKeyboardArrowUp size={25}/>
                    </div>
                </div>

                <ol className="accordion_content_box">
                    {<a onClick={() => { navigateToGame("/game/pick6"); toggleSideMenu() }}>
                        <li className='games_list'>
                            <div className="left">
                                {/* <div className="icon_container">
                                </div> */}
                                <div className="icon_container">
                                    <img alt="icon" src={pickSixIcon} />
                                </div>
                                <div className="list_title">Pick 6</div>
                            </div>
                        </li>
                    </a>}
                    {<a onClick={() => { navigateToGame("/game/matchpredictor"); toggleSideMenu() }} >
                        <li className='games_list'>
                            <div className="left">
                                {/* <div className="icon_container">
                                </div> */}
                                <div className="icon_container">
                                    <img alt="icon" src={MpIcon} />
                                </div>
                                <div className="list_title">Match Predictor</div>
                            </div>
                        </li>
                    </a>}
                    {<a onClick={() => { navigateToGame("/game/penaltyshootout"); toggleSideMenu() }} >
                        <li className='games_list' >
                            <div className="left">
                                {/* <div className="icon_container">
                                </div> */}
                                <div className="icon_container">
                                    <img alt="icon" src={penality_Shootout} />
                                </div>
                                <div className="list_title">Virtual Penalty Shootout</div>
                            </div>
                        </li>
                    </a>}
                    {/* {<a onClick={() => { navigateToGame("/game/vctt/"); toggleSideMenu() }}>
                        <li className='games_list'>
                            <div className="left">
                                <div className="icon_container">
                                </div>
                                <div className="icon_container">
                                    <img alt="icon" src={VcttIcon} />
                                </div>
                                <div className="list_title">Virtual Catch The Trend</div>
                            </div>
                        </li>
                    </a>}
                     */}
                </ol>
            </div>
        </li>
    )
}

// export default function SideMenuAccordion(props) {
//     const [sidemenuAccordion, setSidemenuAccordion] = useState(true);
//     const { isSideMenuOpened } = useSelector((state) => state.header);
//     const dispatch = useDispatch();
//     const toggleSideMenu = () => {
//         dispatch(setIsSideMenuOpened(!isSideMenuOpened));
//       };

//     return (
//         <li>
//             <div
//                 className={sidemenuAccordion ? "collapsed" : ""}
//                 onClick={() => setSidemenuAccordion(!sidemenuAccordion)}
//             >
//                 <div className="sidemenu-accordion">
//                     <div className="left">
//                         <div className="icon_container">
//                             <img alt="icon" src={require('../../assets/images/png/sidemenuIcons/' + props.src)} />
//                         </div>
//                         <div className="list_title">{props.title}</div>
//                     </div>
//                     <div className="right">
//                         <MdOutlineArrowForwardIos />
//                     </div>
//                 </div>

//                 <div className="accordion_content_box">
//                     {props?.gameData?.links?.map((link, index) => {
//                         return (
//                             <Link key={index} to={link.link}><div className="accordion_content" onClick={() => toggleSideMenu()}>{link?.name}</div></Link>
//                         )
//                     })}
//                 </div>
//             </div>
//         </li>
//     )
// }