import React, { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { setToCookie } from "../../services/helper/Index";
import { useDispatch } from "react-redux";

const SelectDropdown = ({
  countryIds,
  setCountryCode,
  countryCode,
  getMSISDNSuffixvalues,
  fromPage,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    const code = option?.toString();
    if (fromPage === "signup" || fromPage === "signin") {
      setToCookie("regionCode", code);
    }
    setCountryCode(code);
    if (fromPage === "profile") {
      dispatch(setCountryCode(code))
    }
    setIsDropdownOpen(false);
    getMSISDNSuffixvalues(code);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef?.current &&
      !dropdownRef?.current?.contains(event?.target)
    ) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown_wrapper">
      <button
        type="button"
        className="dropdown_button"
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <span>{countryCode ? countryCode : 254}</span>
        <span className="select-dropdown-icon">
          <BiChevronDown />
        </span>
      </button>
      {isDropdownOpen && (
        <div className="custom-dropdown">
          {countryIds.map((option) => (
            <div
              key={option}
              className={`dropdown-option`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default SelectDropdown;
