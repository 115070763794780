import { endpoints } from "../../api/ApiEndPoints";
import { putRequest } from "../../services/apiCaller";
import { setFeedbackSubmitSuccess, setFeedbackErrorMessage, setFeedbackSubmitLoading, setfeedbackError, setFeedbackPhoneEmailErrorMsg } from "./gamesFeedbackSlice";

export const submitGamefeedBack = (title, description, type, value) => (dispatch) => {
    let gameFeedbackUrl = endpoints.gameFeedback;
    let body =  type === "email" ?  {
        email: value,
        title: title,
        description: description
    } :  {
        mobile: value,
        title: title,
        description: description
    };
    dispatch(setFeedbackSubmitLoading(true))
    dispatch(setfeedbackError(false))
    dispatch(setFeedbackErrorMessage(""));
    putRequest(gameFeedbackUrl, { ...body })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setFeedbackSubmitSuccess(true));
                dispatch(setFeedbackSubmitLoading(false))
            }
            else {
                dispatch(setFeedbackSubmitLoading(false))
                dispatch(setfeedbackError(true))
                dispatch(setFeedbackErrorMessage(res.data.message));
            }
        })
        .catch((err) => {
            dispatch(setFeedbackSubmitLoading(false))
            dispatch(setfeedbackError(true))
            dispatch(setFeedbackErrorMessage("error in feedback submission"));
        })
}