import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    feedbackSubmitSuccess : false,
    feedbackError : false,
    feedbacksSubmitLoading : false,
    feedBackErrorMessage: "",
    feedbackPhoneEmailErrorMsg: ""
};

export const gamesfeedbackSlice = createSlice({
    name: "gamefeedback",
    initialState,
    reducers: {
        setFeedbackSubmitSuccess: (state, action) => {
            state.feedbackSubmitSuccess = action.payload;
        },
        setfeedbackError: (state, action) => {
            state.feedbackError = action.payload;
        },
        setFeedbackSubmitLoading: (state, action) => {
            state.feedbacksSubmitLoading = action.payload;
        },
        setFeedbackErrorMessage: (state, action) => {
            state.feedBackErrorMessage = action.payload;
        },
        setFeedbackPhoneEmailErrorMsg: (state, action) => {
            state.feedbackPhoneEmailErrorMsg = action.payload;
        }
    }
});

export const {
    setFeedbackSubmitSuccess,
    setfeedbackError,
    setFeedbackSubmitLoading,
    setFeedbackErrorMessage,
    setFeedbackPhoneEmailErrorMsg
} = gamesfeedbackSlice.actions;

export default gamesfeedbackSlice.reducer;