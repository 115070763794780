import { endpoints } from "../../api/ApiEndPoints";
import { postRequest } from "../../services/apiCaller";
import { cleanObject } from "../../services/helper/Index";
import {
    setEmailLoader,
    setPhoneNoLoader,
    setReferSuccessToEmail,
    setReferSuccessToNumber,
    setEmailError,
    setPhoneNoError
} from "./referAndEarnSlice";

export const referUserToEmail = (email) => (dispatch) => {
    let regUrl = endpoints.refer;
    const payload = {
        email: [`${email}`]
    }
    dispatch(setEmailLoader(true));
    postRequest(regUrl, payload)
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setReferSuccessToEmail(true));
                dispatch(setEmailError(""));
                dispatch(setEmailLoader(false));
            } else {
                dispatch(setReferSuccessToEmail(false));
                dispatch(setEmailError(res?.response?.data?.message));
                dispatch(setEmailLoader(false));
            }
        })
        .catch((err) => {
            dispatch(setReferSuccessToEmail(false));
            dispatch(setEmailError(err?.response?.data?.message));
            dispatch(setEmailLoader(false));
        })
};

export const referUserToNumber = (number,code) => (dispatch) => {
    let regUrl = endpoints.referToNumber;
    const payload = {
        mobile: [`${number}`],
        countryCode: code
    }
    dispatch(setPhoneNoLoader(true));
    postRequest(regUrl, cleanObject({ ...payload }))
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setReferSuccessToNumber(true));
                dispatch(setPhoneNoError(""));
                dispatch(setPhoneNoLoader(false));
            } else {
                dispatch(setReferSuccessToNumber(false));
                dispatch(setPhoneNoError(res?.response?.data?.message));
                dispatch(setPhoneNoLoader(false));
            }
        })
        .catch((err) => {
            dispatch(setReferSuccessToNumber(false));
            dispatch(setPhoneNoError(err?.response?.data?.message));
            dispatch(setPhoneNoLoader(false));
        })
};