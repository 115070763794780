export default (dateString) => {
  if (dateString) {
    const eventDate = new Date(dateString);
    const todayDate = new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(todayDate.getDate() + 1);
    let returnObj = {
      date: "",
      time:
        addZero(eventDate.getHours()) + ":" + addZero(eventDate.getMinutes()),
    };
    if (
      todayDate.getDate() === eventDate.getDate() &&
      todayDate.getMonth() === eventDate.getMonth() &&
      todayDate.getMonth() === eventDate.getMonth()
    ) {
      returnObj.date = "Today";
    } else if (
      tomorrowDate.getDate() === eventDate.getDate() &&
      tomorrowDate.getMonth() === eventDate.getMonth() &&
      tomorrowDate.getMonth() === eventDate.getMonth()
    ) {
      returnObj.date = "Tomorrow";
    } else {
      let ed =
        addZero(eventDate.getDate()) +
        "/" +
        addZero(eventDate.getMonth() + 1) +
        "/" +
        addZero(eventDate.getFullYear());
      returnObj.date = ed;
    }
    return returnObj;
  }
};

export const filterCompetitionsByTeamLetter = (data, letterToFilter) => {
  return data
    .map(competition => ({
      ...competition,
      competition_matches: filterMatchesByTeamLetter(competition, letterToFilter)
    }))
    .filter(competition => competition.competition_matches.length > 0);
};
const filterMatchesByTeamLetter = (competition, letterToFilter) => {
  if(competition === null || letterToFilter === null){
    return []
  }
  return competition.competition_matches.filter(match =>
      match?.homeTeamName?.toLowerCase()?.includes(letterToFilter?.toLowerCase()) ||
      match?.awayTeamName?.toLowerCase()?.includes(letterToFilter?.toLowerCase()) || 
      match?.cCategory?.toLowerCase()?.includes(letterToFilter?.toLowerCase()) || 
      match?.cname?.toLowerCase()?.includes(letterToFilter?.toLowerCase())
  );
};

export const getUTCTimeDiff = (dt) => {
  let d = new Date(dt);
   return d.getTimezoneOffset() * -1 + 1;
 };

 export function groupByCompetition(data) {
   return data.reduce((result, item) => {
     const existingItem = result.find(group => group.cid === item.cid);
 
     if (existingItem) {
       existingItem.competition_matches.push(item);
     } else {
       result.push({
         cid: item.cid,
         cCategory: item.cCategory,
         cname: item.cname,
         cLogo:item.cLogo,
         competition_matches: [item]
       });
     }
 
     return result;
   }, []);
 }

 const addZero = (num) => {
  if (num < 10 && ("" + num).length < 2) {
    return "0" + num;
  }
  return num;
};
 export const getEventDate = (getDate) => {
   const monthNames = [
     "Jan",
     "Feb",
     "Mar",
     "Apr",
     "May",
     "Jun",
     "Jul",
     "Aug",
     "Sep",
     "Oct",
     "Nov",
     "Dec",
   ];
   const eventDate = new Date(getDate);
   const date = addZero(eventDate.getDate());
   const month = monthNames[eventDate.getMonth()];
   const time =
     addZero(eventDate.getHours()) + ":" + addZero(eventDate.getMinutes());
   let formatBetDate = time;
   return formatBetDate;
 };

const filterLiveMatches = (competition) => {
  return competition.competition_matches.filter(match =>
    match.status_str === "live"
  );
};

export const filterLiveMatchesInCompetitions = (data) => {
  return data
    .map(competition => ({
      ...competition,
      competition_matches: filterLiveMatches(competition)
    }))
    .filter(competition => competition.competition_matches.length > 0);
};

export const formatScoresKickOffDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [year, month, day].join('-');
}
