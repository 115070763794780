import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "../settings/SettingApi";
import { ButtonLoader } from "../common/Loader";
import { setError } from "../settings/SettingSlice";
import Footer from "../common/Footer";

function ContactPreference() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { userDetails } = useSelector(state => state.signIn);
    const { loader, isApiCall, error, successMsg } = useSelector(state => state.setting);
    const [emailNotification, setEmailNotification] = useState(false);
    const [offerNotification, setOfferNotification] = useState(false);

    const handleChange = (e) => {
        dispatch(setError(false));
        if (e.name === "email") {
            setEmailNotification(e.checked);
        };
        if (e.name === "offer") {
            setOfferNotification(e.checked);
        };
    };

    useEffect(() => {
        setEmailNotification(userDetails?.isAllowEmailNotification);
        setOfferNotification(userDetails?.isAllowOfferNotification);
    }, [userDetails]);

    const handleUpdate = () => {
        if (!isApiCall) {
            dispatch(updateUserInfo(
                "",
                "",
                emailNotification,
                offerNotification
            ));
        }
    };

    useEffect(() => {
        dispatch(setError(false));
    }, [])

    return (
        <React.Fragment>
            <Footer />
            <div className="container">
                <div className="contact-content">
                    <div className="title">Edit Contact Preferences</div>
                    <div>
                        <div className="heading">Contact Preferences</div>
                        <hr className="hr-line" />
                        <div className="offer">
                            <p className="offer-info">I would like to receive round reminders from Pick 6 via email alongside offers and communications from Play Big.</p>
                            <input
                                type="checkbox"
                                name="email"
                                checked={emailNotification}
                                onChange={(e) => handleChange(e.target)}
                            />
                        </div>
                        <div className="offer">
                            <p className="offer-info">I allow my customer profile to be used by Play Big to Provide me with relevant offers and communications.</p>
                            <input
                                type="checkbox"
                                name="offer"
                                checked={offerNotification}
                                onChange={(e) => handleChange(e.target)}
                            />
                        </div>
                        {error && <div className="success-msg">&emsp; {successMsg}</div>}
                        <div className="btn-container">
                            <button className="button update-btn" onClick={handleUpdate}>
                                {loader ? <ButtonLoader /> : "UPDATE PREFERENCE"}
                            </button>
                            <button className="button back-btn" onClick={() => (dispatch(setError(false)), navigate("/settings"))}>
                                BACK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ContactPreference