import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateUserInfo } from "./SettingApi";
import Footer from "../common/Footer";
import icon from "../../assets/images/png/RightArrow.png"

function Settings() {
  const data = [
    { title: "Edit Contact Preferences" },
    { title: "Change pin / password" }
  ];

  const { userDetails } = useSelector(state => state.signIn);
  const { isApiCall } = useSelector(state => state.setting);
  const dispatch = useDispatch();

  const handleSmg = (e) => {
    if (!isApiCall) {
      dispatch(updateUserInfo(
        e.checked,
        "",
        "",
        "",
      ));
    }
  }

  const handlePush = (e) => {
    if (!isApiCall) {
      dispatch(updateUserInfo(
        "",
        e.checked,
        "",
        ""
      ));
    }
  }

  return (
    <React.Fragment>
      <Footer />
      <div className="container">
        <div className="my_profile_container setting_container">
          <div className="accolades">
            <div className="title">Settings</div>
            {data.map((item, ind) => {
              return (
                <div className="board_box" key={ind}>
                  <Link to={ind === 0 ? "/contact-preference" : "/change-password"}>
                    <div className="bg_flash">
                      <div className="bg_dark">
                        <span>{item.title}</span>
                        {/* <span className="icon"></span> */}
                        <img src={icon} alt="" />
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
            <div className="notification">
              <span>Allow SMS Notifications </span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="smg"
                  checked={userDetails?.isAllowSmsNotification}
                  onChange={(e) => handleSmg(e.target)}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="notification">
              <span>Allow Push Notifications</span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="push"
                  checked={userDetails?.isAllowPushNotification}
                  onChange={(e) => handlePush(e.target)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Settings;
