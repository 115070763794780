import React from 'react'
import Games from '../common/HitSix'
// import Coin_Web from "../../assets/images/png/coin.png"
import Coin_Web from "../../assets/images/web/coin.webp"
// import pick_six_mobile from "../../assets/images/png/matchpredictor/pick6.png";
import pick_six_mobile from "../../assets/images/web/homepagebanners/pick6.webp";
import { displayCoins, getLocalString } from '../../services/helper/Index';
import { useNavigate } from 'react-router';
import LazyImage from '../common/LazyImage';
const PickSixBanner = (props) => {
    const { pickSixData, pickSixBannerText, navigateToGame } = props;
    const jackpotPrize = !!pickSixData?.jackpotPrize ? pickSixData?.jackpotPrize : 0;
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Games >
                <div className="match_predictor_card">
                    <div className="top-section">
                        <div className='entry_button'>
                            <button>
                                <div>
                                    ENTRY = {pickSixData?.betAmount !== 0 ? displayCoins(pickSixData?.betAmount) : "FREE PLAY"}
                                </div>
                                <div className='coins_img'>
                                    {pickSixData?.betAmount !== 0 ? <LazyImage src={Coin_Web} placeholder={Coin_Web} alt="" /> : ""}
                                    {/* {pickSixData?.betAmount !== 0 ? <img src={Coin_Web} alt='coin' /> : ""} */}
                                </div>
                            </button>
                        </div>
                        <div className="middle-section">
                            <div className='game_title'>
                                <div className='mp_logo'>
                                    <LazyImage src={pick_six_mobile} placeholder={pick_six_mobile} alt="" />
                                    {/* <img src={pick_six_mobile} alt="picksix_logo " /> */}
                                </div>
                            </div>
                            <div className='game_content'>
                                <div className="left">
                                    <div dangerouslySetInnerHTML={{ __html: pickSixBannerText?.bannerText }}></div>
                                </div>
                                <div className="right">
                                    <p className='prize_detail'>WIN</p>
                                    <div className='prize_amount'>
                                        <div className='prizes_amount_coin_img'>
                                            <LazyImage src={Coin_Web} placeholder={Coin_Web} alt="" />
                                            {/* <img src={Coin_Web} alt='coin' /> */}
                                        </div>
                                        <div className='prize_amount_coins'> {displayCoins(jackpotPrize)}</div>
                                    </div>
                                </div>
                                <div className="bottom-section">
                                    <button className="btn btnMedium" onClick={() => navigateToGame("/game/pick6")}>{"Play"}</button>
                                    {/* <p><a className='coins_means_prize_txt' onClick={() => navigate("/coinsmeanprizes")} >Coins Mean Prizes</a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Games>
        </React.Fragment>
    )
}

export default PickSixBanner