import React from "react";

export const ButtonLoader = () => {
  return (
    <React.Fragment>
      <div className="SB-loader">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <circle cx="0" cy="50">
            <animate
              attributeName="r"
              values="0;24;0"
              dur="1.4s"
              repeatCount="indefinite"
            ></animate>
          </circle>
          <circle cx="60" cy="50">
            <animate
              attributeName="r"
              values="0;26;0"
              dur="1.4s"
              begin="0.4s"
              repeatCount="indefinite"
            ></animate>
          </circle>
          <circle cx="130" cy="50">
            <animate
              attributeName="r"
              values="0;24;0"
              dur="1.4s"
              begin="0.8s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </div>
    </React.Fragment>
  );
};

export const ContentLoader = () => {
  return (
    <React.Fragment>
      <div className="SB-loader">
        <div className="loaderSVGIcon" title="2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            style={{ enableBackground: "new 0 0 50 50" }}
            xmlSpace="preserve"
          >
            <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              ></animateTransform>
            </path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

export const HomePageLoader = () => {
  return (
    <React.Fragment>
      <div className="skeleton_Wrapper">
        <div className="container">
          <div className="card mobile_menubar">
            <div className="card loading">
              <div className="image  mobile_menubar"></div>
            </div>
          </div>
          <div className="card super_banner">
            <ul className="cards">
              <li className="card1">
                <div className="card loading">
                  <div className="image super_banner"></div>
                </div>
              </li>
              <li className="card2">
                <div className="card loading">
                  <div className="image super_banner"></div>
                </div>
              </li>
              <li className="card3">
                <div className="card loading">
                  <div className="image super_banner"></div>
                </div>
              </li>
            </ul>

          </div>
          <div className="card reedem_coins">
            <div className="card loading">
              <div className="image reedem_coins"></div>
            </div>
          </div>
          <div className="card upcoming_and_live">
            <div className="card loading">
              <div className="image upcoming_and_live"></div>
            </div>
          </div>
          <div className="card games_banner">
            <ul className="cards">
              <li className="card1">
                <div className="card loading">
                  <div className="image games_banner"></div>
                </div>
              </li>
              <li className="card2">
                <div className="card loading">
                  <div className="image games_banner"></div>
                </div>
              </li>
              <li className="card3">
                <div className="card loading">
                  <div className="image games_banner"></div>
                </div>
              </li>
            </ul>
          </div>
          <div className="card store_banner">
            <div className="card loading">
              <div className="image store_banner"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const DotsContentLoader = () => {
  return (
    <React.Fragment>
      <div className="SB-loader">
        <div className="loaderSVGIcon" title="2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="31"
            height="31"
            viewBox="0 0 31 31"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_47598"
                  data-name="Rectangle 47598"
                  width="31"
                  height="31"
                  transform="translate(0 0)"
                  fill="none"
                />
              </clipPath>
              <clipPath id="clip-path-3">
                <rect
                  id="Rectangle_47586"
                  data-name="Rectangle 47586"
                  width="4.928"
                  height="4.928"
                  fill="none"
                />
              </clipPath>
              <clipPath id="clip-path-4">
                <rect
                  id="Rectangle_47587"
                  data-name="Rectangle 47587"
                  width="4.929"
                  height="4.928"
                  fill="#fef8e9"
                />
              </clipPath>
              <clipPath id="clip-path-5">
                <rect
                  id="Rectangle_47588"
                  data-name="Rectangle 47588"
                  width="4.926"
                  height="4.927"
                  fill="#fdf1d3"
                />
              </clipPath>
              <clipPath id="clip-path-6">
                <rect
                  id="Rectangle_47589"
                  data-name="Rectangle 47589"
                  width="4.927"
                  height="4.928"
                  fill="#fad67b"
                />
              </clipPath>
              <clipPath id="clip-path-7">
                <rect
                  id="Rectangle_47590"
                  data-name="Rectangle 47590"
                  width="4.928"
                  height="4.928"
                  fill="#fad67b"
                />
              </clipPath>
              <clipPath id="clip-path-8">
                <rect
                  id="Rectangle_47591"
                  data-name="Rectangle 47591"
                  width="4.927"
                  height="4.927"
                  fill="#fad67b"
                />
              </clipPath>
              <clipPath id="clip-path-9">
                <rect
                  id="Rectangle_47592"
                  data-name="Rectangle 47592"
                  width="4.926"
                  height="4.928"
                  fill="#fad67b"
                />
              </clipPath>
              <clipPath id="clip-path-10">
                <rect
                  id="Rectangle_47593"
                  data-name="Rectangle 47593"
                  width="4.926"
                  height="4.928"
                  fill="#f9cf65"
                />
              </clipPath>
              <clipPath id="clip-path-11">
                <rect
                  id="Rectangle_47594"
                  data-name="Rectangle 47594"
                  width="4.927"
                  height="4.927"
                  fill="none"
                />
              </clipPath>
              <clipPath id="clip-path-12">
                <rect
                  id="Rectangle_47595"
                  data-name="Rectangle 47595"
                  width="4.928"
                  height="4.928"
                  fill="#f8c84f"
                />
              </clipPath>
            </defs>
            <g id="loader" transform="translate(0 0)">
              <g
                id="Group_118426"
                data-name="Group 118426"
                transform="translate(0 0)"
                clip-path="url(#clip-path)"
              >
                <g
                  id="Group_118425"
                  data-name="Group 118425"
                  transform="translate(0 0)"
                >
                  <g
                    id="Group_118424"
                    data-name="Group 118424"
                    clip-path="url(#clip-path)"
                  >
                    <g
                      id="Group_118393"
                      data-name="Group 118393"
                      transform="translate(19.304 1.723)"
                      opacity="0"
                    >
                      <g id="Group_118392" data-name="Group 118392">
                        <g
                          id="Group_118391"
                          data-name="Group 118391"
                          clip-path="url(#clip-path-3)"
                        >
                          <path
                            id="Path_64540"
                            data-name="Path 64540"
                            d="M59.114,9.988A2.464,2.464,0,1,1,61.25,8.755a2.464,2.464,0,0,1-2.136,1.232"
                            transform="translate(-56.652 -5.06)"
                            fill="#047de7"
                          />
                        </g>
                      </g>
                    </g>
                    <path
                      id="Path_64541"
                      data-name="Path 64541"
                      d="M40.235,4.927A2.463,2.463,0,1,1,42.7,2.463a2.466,2.466,0,0,1-2.463,2.464"
                      transform="translate(-24.901 -0.001)"
                      fill="#f6ba23"
                    />
                    <g
                      id="Group_118396"
                      data-name="Group 118396"
                      transform="translate(24.015 6.434)"
                      opacity="0.1"
                    >
                      <g id="Group_118395" data-name="Group 118395">
                        <g
                          id="Group_118394"
                          data-name="Group 118394"
                          clip-path="url(#clip-path-4)"
                        >
                          <path
                            id="Path_64542"
                            data-name="Path 64542"
                            d="M72.943,23.813a2.474,2.474,0,0,1-2.136-1.232,2.462,2.462,0,1,1,2.136,1.232"
                            transform="translate(-70.477 -18.885)"
                            fill="#fef8e9"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118399"
                      data-name="Group 118399"
                      transform="translate(25.741 12.87)"
                      opacity="0.2"
                    >
                      <g id="Group_118398" data-name="Group 118398">
                        <g
                          id="Group_118397"
                          data-name="Group 118397"
                          clip-path="url(#clip-path-5)"
                        >
                          <path
                            id="Path_64543"
                            data-name="Path 64543"
                            d="M78,42.7a2.463,2.463,0,1,1,2.464-2.463A2.466,2.466,0,0,1,78,42.7Z"
                            transform="translate(-75.543 -37.771)"
                            fill="#fdf1d3"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118402"
                      data-name="Group 118402"
                      transform="translate(24.014 19.304)"
                      opacity="0.3"
                    >
                      <g id="Group_118401" data-name="Group 118401">
                        <g
                          id="Group_118400"
                          data-name="Group 118400"
                          clip-path="url(#clip-path-6)"
                        >
                          <path
                            id="Path_64544"
                            data-name="Path 64544"
                            d="M72.938,61.581a2.464,2.464,0,1,1,2.136-1.232,2.463,2.463,0,0,1-2.136,1.232"
                            transform="translate(-70.476 -56.653)"
                            fill="#fad67b"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118405"
                      data-name="Group 118405"
                      transform="translate(19.305 24.014)"
                      opacity="0.4"
                    >
                      <g id="Group_118404" data-name="Group 118404">
                        <g
                          id="Group_118403"
                          data-name="Group 118403"
                          clip-path="url(#clip-path-7)"
                        >
                          <path
                            id="Path_64545"
                            data-name="Path 64545"
                            d="M59.122,75.406a2.464,2.464,0,1,1,2.13-3.7,2.464,2.464,0,0,1-2.13,3.7"
                            transform="translate(-56.656 -70.478)"
                            fill="#fad67b"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118408"
                      data-name="Group 118408"
                      transform="translate(12.87 25.739)"
                      opacity="0.5"
                    >
                      <g id="Group_118407" data-name="Group 118407">
                        <g
                          id="Group_118406"
                          data-name="Group 118406"
                          clip-path="url(#clip-path-8)"
                        >
                          <path
                            id="Path_64546"
                            data-name="Path 64546"
                            d="M40.234,80.466A2.464,2.464,0,1,1,42.7,78a2.466,2.466,0,0,1-2.463,2.464"
                            transform="translate(-37.771 -75.539)"
                            fill="#fad67b"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118411"
                      data-name="Group 118411"
                      transform="translate(6.436 24.014)"
                      opacity="0.58"
                    >
                      <g id="Group_118410" data-name="Group 118410">
                        <g
                          id="Group_118409"
                          data-name="Group 118409"
                          clip-path="url(#clip-path-9)"
                        >
                          <path
                            id="Path_64547"
                            data-name="Path 64547"
                            d="M21.347,75.405a2.464,2.464,0,1,1,2.136-1.233,2.458,2.458,0,0,1-2.136,1.233"
                            transform="translate(-18.887 -70.477)"
                            fill="#fad67b"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118414"
                      data-name="Group 118414"
                      transform="translate(1.725 19.303)"
                      opacity="0.65"
                    >
                      <g id="Group_118413" data-name="Group 118413">
                        <g
                          id="Group_118412"
                          data-name="Group 118412"
                          clip-path="url(#clip-path-10)"
                        >
                          <path
                            id="Path_64548"
                            data-name="Path 64548"
                            d="M7.528,61.58a2.465,2.465,0,1,1,1.229-.331,2.473,2.473,0,0,1-1.229.331"
                            transform="translate(-5.062 -56.652)"
                            fill="#f9cf65"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118417"
                      data-name="Group 118417"
                      transform="translate(0 12.869)"
                      opacity="0.72"
                    >
                      <g id="Group_118416" data-name="Group 118416">
                        <g
                          id="Group_118415"
                          data-name="Group 118415"
                          clip-path="url(#clip-path-11)"
                        >
                          <path
                            id="Path_64549"
                            data-name="Path 64549"
                            d="M2.465,42.7a2.463,2.463,0,1,1,2.463-2.464A2.467,2.467,0,0,1,2.465,42.7"
                            transform="translate(-0.001 -37.769)"
                            fill="#f9cf65"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118420"
                      data-name="Group 118420"
                      transform="translate(1.725 6.434)"
                      opacity="0.8"
                    >
                      <g id="Group_118419" data-name="Group 118419">
                        <g
                          id="Group_118418"
                          data-name="Group 118418"
                          clip-path="url(#clip-path-12)"
                        >
                          <path
                            id="Path_64550"
                            data-name="Path 64550"
                            d="M7.523,23.811a2.464,2.464,0,1,1,2.136-1.232,2.459,2.459,0,0,1-2.136,1.232"
                            transform="translate(-5.063 -18.883)"
                            fill="#f8c84f"
                          />
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_118423"
                      data-name="Group 118423"
                      transform="translate(6.436 1.723)"
                      opacity="0.88"
                    >
                      <g id="Group_118422" data-name="Group 118422">
                        <g
                          id="Group_118421"
                          data-name="Group 118421"
                          clip-path="url(#clip-path-3)"
                        >
                          <path
                            id="Path_64551"
                            data-name="Path 64551"
                            d="M21.353,9.987a2.464,2.464,0,1,1,2.131-3.7,2.464,2.464,0,0,1-2.131,3.7"
                            transform="translate(-18.888 -5.059)"
                            fill="#f7c139"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};
