import { endpoints } from "../../api/ApiEndPoints";
import {
    setPasswordSuccessMessage,
    setPasswordErrorMessage,
    setChangePinSuccess,
    setLoader
} from "./changePasswordSlice";
import { putRequest } from "../../services/apiCaller";

const pinRegX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;


export const validatePin = (pin) => {
    if (pinRegX.test(pin)) return true
    return false
}

export const changePassword = (oldpin, newpin) => (dispatch) => {
    let changePasswordUrl = endpoints.changepassword;
    let body = {
        currentPassword: oldpin,
        newPassword: newpin
    };
    dispatch(setLoader(true));
    putRequest(changePasswordUrl, { ...body })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setPasswordSuccessMessage(res.data.message));
                dispatch(setPasswordErrorMessage(""));
                dispatch(setChangePinSuccess(true));
                dispatch(setLoader(false));
            }
            else {
                dispatch(setPasswordErrorMessage(res.response.data.message));
                dispatch(setPasswordSuccessMessage(""));
                dispatch(setChangePinSuccess(false));
                dispatch(setLoader(false));
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            dispatch(setPasswordSuccessMessage(""));
            dispatch(setPasswordErrorMessage(errorResponse.data.message));
            dispatch(setChangePinSuccess(false));
            dispatch(setLoader(false));
        });
}