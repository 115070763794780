import Cookies from "js-cookie";

export const setToLocalStorage = (key, Data) => {
  let localStorageValue = btoa(Data);
  localStorage.setItem(key, localStorageValue);
};

export const getFromLocalStorage = (key) => {
  let getLocalStorageValue = "";
  let v = localStorage.getItem(key);
  if (!v) {
    v = "";
  }
  getLocalStorageValue = atob(v);
  return getLocalStorageValue;
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

let regexp = /android|iphone|kindle|ipad/i;
export const isMobileDevice = regexp.test(navigator.userAgent);

export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const dateOrdinal = (d) =>
  d +
  (31 === d || 21 === d || 1 === d
    ? "st"
    : 22 === d || 2 === d
      ? "nd"
      : 23 === d || 3 === d
        ? "rd"
        : "th");

export const getLocalString = (value) => {
  return value.toLocaleString();
};

export function getObject(objName, arr) {
  for (let i = 0; i < arr.length; i++) {
    if (objName in arr[i]) {
      return arr[i][objName];
    }
  }
  return null;
}

export const getFromCookie = (key) => {
  let value;
  if (typeof Cookies !== "undefined") {
    value = Cookies.get(key);
  }
  return value;
};

export const setToCookie = (key, payload) => {
  Cookies.set(key, payload);
};

export const removeFromCookie = (key) => {
  Cookies.remove(key);
};

export const isMobile = () => {
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(navigator.userAgent);
  return isMobileDevice;
};

export const displayCoins = (num) => {
  if (num < 1000) {
    return num;
  } else if (num > 1000 && num < 1000000) {
    const coins = num / 1000 + "K";
    return coins;
  } else if (num > 1000000 && num < 1000000000) {
    const coins = num / 1000000 + "M";
    return coins;
  } else {
    const coins = num / 1000000000 + "B";
    return coins;
  }
};
