import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: "",
    coinsMeanPrizesData: "",
    cmpModalClose: false,
    cmpModalOpen: false,
    prizeClaimed: {},
    loader: false,
};

export const coinsMeanPrizesSlice = createSlice ({
    name: "coinsMeanPrizes",
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setCoinsMeanPrizesData: (state, action) => {
            state.coinsMeanPrizesData = action.payload;
        },
        setCmpModalClose: (state, action) => {
            state.cmpModalClose = action.payload;
        },
        setCmpModalOpen: (state, action) => {
            state.cmpModalOpen = action.payload;
        },
        setPrizeClaimed: (state,action) => {
            state.prizeClaimed = action.payload;
        },
        setLoader: (state,action) => {
            state.loader = action.payload;
        }
    }
});

export const {
    setCoinsMeanPrizesData,
    setError,
    setCmpModalClose,
    setCmpModalOpen,
    setPrizeClaimed,
    setLoader,
} = coinsMeanPrizesSlice.actions;

export default coinsMeanPrizesSlice.reducer;