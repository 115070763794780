import React, { useEffect, useRef } from "react"
import Modal from "react-modal";
import close from "../../assets/images/svg/close_icon.svg"
// import { AiOutlineCloseCircle } from "react-icons/ai"
Modal.setAppElement("#root");

function ModalBox({ children, dOpen, dClose, isClosable, small, isPopUpClassName, inviteFriend, type }) {
       const modalRef = useRef(null);
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);
    let customStyles = {};
    if (isMobileDevice) {
        customStyles = {
            content: {
                top: '20%',
                left: '2%',
                right: '2%',
                bottom: 'auto',
                borderRadius: "15px",
                // overflow: "hidden",
                // marginRight: '-50%',
                //transform: 'translate(-50%, -50%)',
                padding: "0px",
            },
        };
    } else {
        customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: "30px",
                // overflow: "hidden",
                // marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: "0px",
            },
        };
    }


    function handleClickOutside(event) {
        if (!isClosable) return
        if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
            dClose()
        }
    }
    useEffect(() => {
        if (!dOpen && !isClosable) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [modalRef]);

    function handleCose() {
        if (isClosable) dClose()
    }

    useEffect(() => {
        if (type == "non-scrollable") {
            if (dOpen) {
                document.body.classList.add('body-no-scroll');
            } else {
                document.body.classList.remove('body-no-scroll');
            }
        }
    }, [dOpen]);

    return (
        <Modal
            isOpen={dOpen}
            style={customStyles}
            onRequestClose={handleCose}
        >
            <div className={inviteFriend ? "modal_box_container invite_friend" : small ? "modal_box_container_small" : isPopUpClassName ? "modal_box_container cmp-popUp" : "modal_box_container_link"} ref={modalRef}>
                {!isClosable ?
                    <div className="modal_header">
                        <div className={inviteFriend ? "close_button invite_friend" : small ? "small_box_close_button" : "close_button"} onClick={dClose}>
                            {/* <AiOutlineCloseCircle /> */}
                            <img src={close} alt="close" />
                        </div>
                    </div> : null}
                {children}
            </div>
        </Modal>
    )
}

export default ModalBox;
