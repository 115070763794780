import React, { useRef, useState } from "react";
import ModalBox from "../common/ModalBox";
import whatsapp from "../../assets/images/social/watsapp.svg";
import facebook from "../../assets/images/social/facebook.svg";
import twitter from "../../assets/images/social/twitter.png";
import instagram from "../../assets/images/png/instagram.png";
import copy from "../../assets/images/svg/copy.svg";
import { useSelector } from "react-redux";
import {
  Instagram_Link,
  Facebook_Link,
  Twitter_Link,
  whatsapp_Link,
} from "../../config/appConfig";

function ShareModal(props) {
  const DOMAIN = process.env.REACT_APP_DOMAIN;
  const { userDetails } = useSelector((state) => state.signIn);
  const copyBtnRef = useRef(null);
  const referalUrl = `${DOMAIN}signup?code=${userDetails?.invitationCode}`;

  const [isCopied, setisCopied] = useState(false);

  const handleCopyCode = () => {
    if (!userDetails) return;
    navigator.clipboard.writeText(referalUrl);
    setisCopied(true);
    // copyBtnRef.current.innerText = "Copied";
    setTimeout(() => setisCopied(false), 2000);
  };

  const shareData = {
    title: "Cricctt - Referal",
    text: "Refer and Earn",
    url: referalUrl,
  };

  const handleShare = async () => {
    await navigator.share(shareData);
  };

  return (
    <ModalBox
      dOpen={props.open !== ""}
      dClose={props.close}
      isClosable={props.closable}
    >
      <div className="shareModal_wrapper">
        <div className="shareModal_content">
          <div className="referral_link">
            <div className="link_section">
              <div className="truncate_link truncate">{referalUrl}</div>
            </div>
            <div
              className="copyButton pointer"
              ref={copyBtnRef}
              onClick={handleCopyCode}
            >
              {isCopied ? (
                <div className="copy_text">Copied</div>
              ) : (
                <img src={copy} alt="copy" />
              )}
            </div>
          </div>

          <div className="referral_content">
            Choose where you would like to share your referral links
          </div>
          <div className="social_links_icon">
            <div className="icon_Container">
              <a
                href={`https://web.whatsapp.com/send?text=${DOMAIN}signup?code=${userDetails?.invitationCode}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsapp} alt="icon" />
              </a>
            </div>
            <div className="icon_Container">
              <a
                href={`https://facebook.com/sharer.php?u=${DOMAIN}signup?code=${userDetails?.invitationCode}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="icon" />
              </a>
            </div>
            <div className="icon_Container">
              <a
                href={`https://twitter.com/intent/tweet?url=${DOMAIN}signup?code=${userDetails?.invitationCode}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="icon" />
              </a>
            </div>
            <div className="icon_Container">
              <a
                href={`https://www.instagram.com/intent/tweet?url=${DOMAIN}signup?code=${userDetails?.invitationCode}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="icon" />
              </a>
            </div>
          </div>
          <div className="btn_share">
            <button className="btn btnLarge" onClick={() => handleShare()}>
              SHARE
            </button>
          </div>
        </div>
      </div>
    </ModalBox>
  );
}

export default ShareModal;
