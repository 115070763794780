import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import signUpBg from "../../assets/images/png/signupbg1.png";
import BackButton from "../common/BackButton";
import { ButtonLoader } from "../common/Loader";
import Coin from "../../assets/images/png/coin.png";
import { emailVerification, mobileVerification } from "./SignUpApi";
import {
  MSISDNAcceptableDigits,
  MSISDNAcceptableDigitsWithZero,
  countryIds,
  MSISDNSuffixValues,
  MSISDNZeroEnabled,
} from "../../config/appConfig";
import {
  setEmail,
  setEmailError,
  setErrorMessage,
  setPhoneNumber,
  setPhoneError,
} from "./SignUpSlice";
import {
  getFromCookie,
  setToCookie,
  setToLocalStorage,
} from "../../services/helper/Index";
import { BiChevronDown } from "react-icons/bi";
import LOGO from "../../assets/images/png/PlayBig_Logo.png";
import SelectDropdown from "../common/selectDropdown";
import { coins } from "../../config/appConfig";
import { setIsSideMenuOpened } from "../header/headerSlice";

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const {
    email,
    errorMessage,
    emailError,
    loader,
    phoneNumber,
    phoneError,
    mobileLoader,
  } = useSelector((state) => state.signup);

  const [isPhoneActive, setisPhoneActive] = useState(true);
  const [countryCodeError, setCountryCodeError] = useState(false);
  //const [MSISDNZeroEnabled, setMSISDNZeroEnabled] = useState(false);
  const [msisdnFirstSuffixValues, setMSISDNFirstSuffixValues] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [numberLength, setNumberLength] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const btagEmail = queryParameters.get("email");
  const mobileNumber = queryParameters.get("mobile");

  const handleActiveTab = (type) => {
    if (type === "phone") {
      setisPhoneActive(true);
    } else if (type === "email") {
      setisPhoneActive(false);
    }
    dispatch(setEmailError(false));
    dispatch(setPhoneError(false));
    setCountryCodeError(false);
    setCountryCode(countryIds[0]);
    if (btagEmail) {
      dispatch(setEmail(btagEmail));
      dispatch(setPhoneNumber(""));
    } else {
      dispatch(setPhoneNumber(mobileNumber));
      dispatch(setEmail(""));
    }
  };

  const onChangeText = (e) => {
    dispatch(setEmailError(false));
    dispatch(setEmail(e.target.value));
  };

  const handleNumber = (e) => {
    // if (e.target.value.charAt(0) === "0") {
    //   setMSISDNZeroEnabled(true);
    // } else {
    //   setMSISDNZeroEnabled(false);
    // }

    dispatch(setPhoneError(false));
    setCountryCodeError(false);
    const re = /[0-9]+/g;
    if (
      re.test(e.nativeEvent.data) ||
      e.nativeEvent.inputType === "deleteContentBackward" ||
      e.nativeEvent.inputType === "deleteContentForward"
    ) {
      dispatch(setPhoneNumber(e.target.value));
    }
  };

  const handleCountryCode = (e) => {
    dispatch(setPhoneError(false));
    setCountryCodeError(false);
    if (e.target.value[0] !== "+") {
      setCountryCode("+" + e.target.value);
    } else {
      setCountryCode(e.target.value);
    }
  };

  function avoidCharacter(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.persist();
      e.preventDefault();
    }
    if (e.key == "Enter") {
      handleSubmitPhone(e);
    }
  }

  useEffect(() => {
    if (phoneNumber?.charAt(0) == "0") {
      setNumberLength(true);
    } else {
      setNumberLength(false);
    }
  }, [phoneNumber])

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    if (email === "") {
      dispatch(setErrorMessage("Enter Email"));
      dispatch(setEmailError(true));
      dispatch(setPhoneError(false));
    } else if (!regExp.test(email)) {
      dispatch(setErrorMessage("Invalid Email"));
      dispatch(setEmailError(true));
      dispatch(setPhoneError(false));
    } else {
      dispatch(setEmailError(false));
      dispatch(setPhoneError(false));
      dispatch(emailVerification(email, navigate));
    }
  };

  const handleSubmitPhone = (e) => {
    let isLengthValid;
    let isValidPrefix;
    if (phoneNumber) {
      isLengthValid =
        MSISDNZeroEnabled && phoneNumber?.charAt(0) === "0"
          ? phoneNumber.length === MSISDNAcceptableDigitsWithZero
          : phoneNumber.length === MSISDNAcceptableDigits;

      let phnNum =
        phoneNumber?.charAt(0) === "0" ? phoneNumber?.slice(1) : phoneNumber;
      isValidPrefix = msisdnFirstSuffixValues?.some((prefix) =>
        phnNum?.startsWith(prefix)
      );
    }

    e.preventDefault();
    if (phoneNumber === "") {
      dispatch(setErrorMessage("Enter Phone Number"));
      dispatch(setPhoneError(true));
      dispatch(setEmailError(false));
    } else if (countryCode === "+") {
      dispatch(setErrorMessage("Plese Enter Country Code"));
      dispatch(setPhoneError(false));
      dispatch(setEmailError(false));
      setCountryCodeError(true);
    } else {
      if (isLengthValid && isValidPrefix) {
        dispatch(setPhoneError(false));
        dispatch(setEmailError(false));
        setCountryCodeError(false);
        dispatch(mobileVerification(+phoneNumber, countryCode, navigate));
      } else if (MSISDNZeroEnabled && isLengthValid && isValidPrefix) {
        dispatch(setPhoneError(false));
        dispatch(setEmailError(false));
        setCountryCodeError(false);
        dispatch(mobileVerification(+phoneNumber, countryCode, navigate));
      } else {
        dispatch(setErrorMessage("Invalid Phone number"));
        dispatch(setPhoneError(true));
        dispatch(setEmailError(false));
      }
    }
  };

  const handleChange = (event) => {
    setToCookie("regionCode", event.target.value);
    setCountryCode(event.target.value);
    getMSISDNSuffixvalues(event.target.value);
    dispatch(setPhoneNumber(""));
    dispatch(setPhoneError(false));
    setCountryCodeError(false);
  };

  function getMSISDNSuffixvalues(cntryId) {
    let msisdnSuffixObject = MSISDNSuffixValues[cntryId];
    setMSISDNFirstSuffixValues(msisdnSuffixObject);
  }

  useEffect(() => {
    dispatch(setIsSideMenuOpened(false));
    let countryCode = getFromCookie("regionCode");
    setCountryCode(countryCode);
    getMSISDNSuffixvalues(countryCode);
    dispatch(setEmailError(false));
    dispatch(setPhoneError(false));
    setCountryCodeError(false);
    dispatch(setEmail(""));
    dispatch(setPhoneNumber(""));
    if (countryCode === "" || !countryCode || countryCode === undefined) {
      setToCookie("regionCode", countryIds[0]);
      setCountryCode(countryIds[0]);
      getMSISDNSuffixvalues(countryIds[0]);
    }
  }, []);

  useEffect(() => {
    dispatch(setPhoneError(false));
    dispatch(setEmailError(false));
    setCountryCodeError(false);
  }, [countryCode]);

  useEffect(() => {
    if (code) {
      setToLocalStorage("ref", code);
    }
    if (btagEmail) {
      dispatch(setEmail(btagEmail));
      dispatch(setPhoneNumber(""));
      setisPhoneActive(!isPhoneActive);
    } else if (mobileNumber) {
      dispatch(setEmail(""));
      dispatch(setPhoneNumber(mobileNumber));
      setisPhoneActive(isPhoneActive);
    }
  }, [code, btagEmail, mobileNumber]);

  return (
    <React.Fragment>
      <div className="signup_wrapper" role={"none"}>
        <BackButton />
        <div className="container signup_container">
          <div>
            <Link to={"/"}>
              <img src={LOGO} alt="logo" className="signup_logo" />
            </Link>
            <div className="sign_title">SIGN UP</div>
            <p>And get an instant Welcome Bonus!</p>
            <span className="coin-gain-text">
              Receive {coins?.signUp} <img src={Coin} alt="Coins" width={12} /> NOW!
            </span>
          </div>
          <form className="signup_box" onSubmit={handleSubmitEmail}>
            <div className="signup_content">
              <div className="signup_option">
                <div
                  className={
                    isPhoneActive ? "signup_phone active" : "signup_phone"
                  }
                  onClick={() => handleActiveTab("phone")}
                >
                  Phone Number
                </div>
                <div
                  className={
                    !isPhoneActive ? "signup_email active" : "signup_email"
                  }
                  onClick={() => handleActiveTab("email")}
                >
                  Email
                </div>
              </div>

              {isPhoneActive ? (
                <div className="form_group">
                  <div className="phone_group  phone-group-dropdown">
                    <SelectDropdown
                      countryIds={countryIds}
                      setCountryCode={setCountryCode}
                      countryCode={countryCode}
                      getMSISDNSuffixvalues={getMSISDNSuffixvalues}
                      fromPage={"signup"}
                    />
                    {/* <input
                                            type={"tel"}
                                            value={countryCode}
                                            maxLength={3}
                                            className='country_input'
                                            onChange={(e) => handleCountryCode(e)}
                                            onKeyPress={(e) => avoidCharacter(e)}
                                        /> */}

                    {/* <select className="country_input" onChange={handleChange}>
                      {countryIds &&
                        countryIds?.map((item, index) => {
                          return (
                            <option
                              value={item}
                              key={index}
                              selected={+item === +countryCode}
                            >
                              {item}
                            </option>
                          );
                        })}
                    </select>
                    <span className="select-dropdown-icon">
                      <BiChevronDown />
                    </span> */}
                    <input
                      type={"tel"}
                      placeholder={"Enter Phone Number"}
                      onKeyPress={(e) => avoidCharacter(e)}
                      onChange={(e) => handleNumber(e)}
                      maxLength={numberLength ? 10 : 9}
                      value={phoneNumber}
                      className="number_input"
                    />
                  </div>
                  {countryCodeError && (
                    <div className="error_message">{errorMessage}</div>
                  )}
                  {phoneError && (
                    <div className="error_message">{errorMessage}</div>
                  )}
                  <button
                    onClick={handleSubmitPhone}
                    type="button"
                    className="btn"
                  >
                    {mobileLoader ? <ButtonLoader /> : "SIGN UP"}
                  </button>
                </div>
              ) : (
                <div className="form_group email_group">
                  <input
                    className="number_input"
                    placeholder={"Enter Email"}
                    type={"text"}
                    onChange={(e) => onChangeText(e)}
                    value={email}
                  />
                  {emailError && (
                    <div className="error_message">{errorMessage}</div>
                  )}
                  <button type="submit" className="btn">
                    {loader ? <ButtonLoader /> : "SIGN UP"}
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="signUpBackground">
            <img src={signUpBg} alt={"bg"} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Signup;
