import React, { useEffect } from "react";
import logo from "../../assets/images/png/AFCLeopards.png";
import { useDispatch, useSelector } from "react-redux";
import { getPointsTable } from "./cl_PointsTableSlice";
import { useParams } from "react-router-dom";

const LiveTable = () => {
    const params = useParams()
    const dispatch = useDispatch();
    const { pointsTable } = useSelector((state) => state.pointsTable);
    useEffect(() => {
        dispatch(getPointsTable(params?.cid));
    }, [])

    const pointTableData = pointsTable[0]?.point_table;

    const hasPromotionData = pointTableData?.some(table => table.tables.some(team => team.promotion.type !== '' || team.promotion.name !== ''));

    return (
        <React.Fragment>
            {pointTableData?.length > 0 && hasPromotionData && (
                <div className="live_table_wrapper">
                    <div className="live_table_heading">LIVE TABLE</div>
                    <div className="table_container">
                        {pointTableData?.map((table, tableIndex) => (
                            <table className="table" key={`table_${tableIndex}`}>
                                <thead>
                                    {table?.groupname && (
                                        <tr>
                                            <th colSpan="11" className="groupname">
                                                {table?.groupname}
                                            </th>
                                        </tr>
                                    )}
                                    <div>{table?.name && table?.name}</div>

                                    <tr className="head_block">
                                        <th className="space-2">Pos</th>
                                        <th className="team_header">Team</th>
                                        <th className="space-2 right_border">MP</th>
                                        <th className="space-2 right_border">W</th>
                                        <th className="space-2 right_border">D</th>
                                        <th className="space-2 right_border">L</th>
                                        <th className="space-2 right_border">GF</th>
                                        <th className="space-2 right_border">GA</th>
                                        <th className="space-2">GD</th>
                                        <th className="space-2">PTS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {table?.tables?.map((team, teamIndex) => (
                                        <tr className="body_block" key={`team_${tableIndex}_${teamIndex}`}>
                                            <td className="space-2">{teamIndex + 1}</td>
                                            <td className="team_name space-2">
                                                <img width={16} height={16} src={team?.logo} alt="" />
                                                <span className="tname">{team.tname}</span>
                                            </td>
                                            <td className="space-2">{team.playedtotal}</td>
                                            <td className="space-2">{team.wintotal}</td>
                                            <td className="space-2">{team.drawtotal}</td>
                                            <td className="space-2">{team.losstotal}</td>
                                            <td className="space-2">{team.goalsfortotal}</td>
                                            <td className="space-2">{team.goalsagainsttotal}</td>
                                            <td className="space-2">{team.goalsfortotal - team.goalsagainsttotal}</td>
                                            <td className="space-2">{team.pointstotal}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ))}
                    </div>
                </div>
            )}

        </React.Fragment>
    )
}
export default LiveTable;