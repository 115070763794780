import React, { useEffect } from 'react'
// import TACMobile from "../../assets/images/png/terms_and_condition_mobile.png";
// import TACDesktop from "../../assets/images/png/terms_and_condition.png";
import TACMobile from "../../assets/images/web/termsandconditions/terms_and_condition_mobile.webp";
import TACDesktop from "../../assets/images/web/termsandconditions/terms_and_condition_desktop.webp";
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../../services/helper/Index';
import Footer from '../common/Footer';
// import TermsAndConditionsDAta from "../config/termsAndConditions.json"

function TermsAndConditions() {
    const staticDatas = useSelector((state) => state?.staticData);
    const termsAndConditionsData = staticDatas?.staticData && staticDatas?.staticData?.termsAndConditions[0]?.termsAndConditions;
    const termsAndConditionsDataBeforeLogin = staticDatas?.staticData && staticDatas?.staticData?.termsAndConditionsBeforeLogin[0]?.termsAndConditions
    const token = getFromLocalStorage("gk");
    const { isSignInSuccess } = useSelector(state => state.signIn);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <div className="gain_coins_wrapper">
                <Footer />
                <div className="container">
                    <div className="gain_coin_container">
                        <div className='gain_coins_head'>
                            <div className='gain_coins_title'>Terms and Conditions</div>
                        </div>
                        <div className="gain_coin_banner terms_banner_web">
                            <img alt='banner' src={TACDesktop}></img>
                        </div>
                        <div className="gain_coin_banner terms_banner_mobile">
                            <img alt='banner' src={TACMobile}></img>
                        </div>
                        {/* <div className="gain_coin_bannerHeader">
                            <div className="gain_coin_banner_text">
                                <div className="gain_coinBanner_textHeader">
                                    <h2>Terms And</h2>
                                    <h1>Conditions</h1>
                                </div>
                            </div>
                        </div> */}
                        {token && isSignInSuccess ?
                            <div className="terms_and_conditions_content" dangerouslySetInnerHTML={{ __html: termsAndConditionsData }}></div>
                            :
                            <div className="terms_and_conditions_content" dangerouslySetInnerHTML={{ __html: termsAndConditionsDataBeforeLogin }}></div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TermsAndConditions