import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarLogo from '../../assets/images/png/calender.png'
import likeLogo from "../../assets/images/png/Likes.png";
import { getMatches, getSearchResults, setMatchesData } from "./cl_MatchesSlice";
import { setSelectedDate } from "./cl_MatchesSlice";
import { useSelector, useDispatch } from 'react-redux';
import { filterLiveMatchesInCompetitions } from "../../helper/cl_formatDate";
import { setToLocalStorage } from "../../services/helper/Index";

const SearchBar = ({ searchText, onChangeText }) => {
    const datepickerRef = useRef(null);
    const dispatch = useDispatch();
    const [activeDate, setActiveDate] = useState("");
    const [dateDetails, setDateDetails] = useState([]);
    const [todayTab, setTodayTab] = useState(false);
    const [isToday, setIsToday] = useState("")
    const todayCalendarSelection = new Date(isToday);
    const isValidDate = !isNaN(todayCalendarSelection);
    const calendarDefaultSelection = isValidDate ? todayCalendarSelection : null
    const [selectedCalendarDate, setSelectedCalendarDate] = useState("");
    const [calendarDate, setCalenderDate] = useState("");
    const [isLive, setIsLive] = useState(false);
    const { matchesData, selectedDate, isLoading, searchResults } = useSelector(state => state.matches);

    useEffect(() => {
        setSelectedCalendarDate(!isNaN(todayCalendarSelection) ? todayCalendarSelection : null)
    }, [isToday])

    function handleClickDatepickerIcon() {
        const datepickerElement = datepickerRef.current;
        datepickerElement.setFocus(true);
    };


    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 4);


    useEffect(() => {
        setToLocalStorage("dt", activeDate)
    }, [activeDate]);

    useEffect(() => {
        const date = new Date();
        const todaysDate = formatDate(date)
        const formatedActiveDate = formatDate(activeDate)
        todaysDate === formatedActiveDate ?
            setTodayTab(true) : setTodayTab(false)

    }, [activeDate]);

    const handleDateChange = (date) => {
        const calendarPicker = formatDate(date)
        setSelectedCalendarDate(date)
        setActiveDate(calendarPicker)
        setCalenderDate(calendarPicker)
    };

    const handleTodayTab = () => {
        var currentDate = new Date();
        const lastTwoDays = [];
        const upcomingDays = [];

        for (let i = 2; i >= 1; i--) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            lastTwoDays.push(date.toDateString());
        }

        for (let i = 0; i <= 2; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            upcomingDays.push(date.toDateString());
        }
        setSelectedCalendarDate(new Date(isToday))
        setActiveDate(upcomingDays[0]);
        setIsToday(upcomingDays[0]);
        const allDates = [...lastTwoDays, ...upcomingDays];
        setDateDetails(allDates);
    }

    useEffect(() => {
        const todaysDate = new Date();
        const currentDateOriginalFormat = todaysDate.toString().split(' ').slice(0, 4).join(' ');
        const date = formatDate(activeDate)
        var currentDate;
        if (calendarDate) {
            currentDate = new Date(date);
        } else {
            currentDate = new Date();
        }
        const lastTwoDays = [];
        const upcomingDays = [];

        for (let i = 2; i >= 1; i--) {
            const date = new Date(currentDate);
            const myDate = formatDate(date)
            date.setDate(currentDate.getDate() - i);
            lastTwoDays.push(date.toDateString());
        }

        for (let i = 0; i <= 2; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            upcomingDays.push(date.toDateString());
        }
        setActiveDate(upcomingDays[0]);
        const currentDateYearFormat = formatDate(currentDateOriginalFormat)
        if (calendarDate === currentDateYearFormat) {
            setIsToday(currentDateOriginalFormat);
        }
        if (!calendarDate) {
            setIsToday(upcomingDays[0]);
        }

        const allDates = [...lastTwoDays, ...upcomingDays];
        setDateDetails(allDates);
    }, [calendarDate]);

    const handleLive = () => {
        const liveData = filterLiveMatchesInCompetitions(matchesData);
        dispatch(setMatchesData(liveData));
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    useEffect(() => {
        const formatedDate = formatDate(activeDate);
        if (formatedDate !== "NaN-NaN-NaN") {
            dispatch(getMatches(formatedDate, searchText));
        };
    }, [activeDate, searchText]);

    const handleDate = (date) => {
        const calendarDateSelection = new Date(date)
        setSelectedCalendarDate(calendarDateSelection)
        const dateFormat = formatDate(date)
        // dispatch(getMatches(dateFormat, searchText))
        setActiveDate(dateFormat)
    };
    const handleLiveState = () => {
        setIsLive(!isLive);

    }
    useEffect(() => {
        if (isLive) {
            const liveData = filterLiveMatchesInCompetitions(matchesData);
            dispatch(setMatchesData(liveData));
        } else {
            const formatedDate = formatDate(activeDate);
            if (formatedDate !== "NaN-NaN-NaN") {
                dispatch(getMatches(formatedDate, searchText));
            };
        }
    }, [isLive])

    return (
        <React.Fragment>
            <div className="container_score">
                <div className="score_wrapper">
                    <h4 className="heading_text">Scores, Fixtures & Results</h4>
                    <div className="score_date_wrapper">
                        <div className="score_block">
                            <div className='form_input'>
                                <input
                                    type={"text"}
                                    className="form_input"
                                    name='confimPassword'
                                    onChange={(e) => onChangeText(e)}
                                    value={searchText}
                                />
                                <span className='pointer'><FiSearch /></span>
                            </div>
                            {!todayTab ?
                                <div className="live_text"
                                    onClick={() => handleTodayTab()}
                                >
                                    Today
                                </div>
                                :
                                <div className="live_text"
                                    onClick={() => handleLiveState()}
                                    style={{ backgroundColor: isLive ? '#2152C4' : '#183468' }}
                                >
                                    <div></div>
                                    Live
                                </div>
                            }

                            <div className='date_picker_container'>
                                {/* <DatePicker
                                    className="date_input"
                                    onChange={(date) => handleDateChange(date)}
                                    ref={datepickerRef}
                                    selected={selectedCalendarDate}
                                /> */}
                                <DatePicker
                                    className="date_input"
                                    selected={selectedCalendarDate}
                                    onChange={(date) => handleDateChange(date)}
                                    ref={datepickerRef}
                                    maxDate={maxDate}
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                />
                                <span
                                    role={"none"} onClick={() => handleClickDatepickerIcon()}
                                >
                                    <img src={calendarLogo} alt="calender" height={20} width={20} />
                                </span>
                            </div>

                        </div>
                        <div className="date_wrapper">
                            {
                                dateDetails.map((date, ind) => {
                                    return (
                                        <div key={ind} onClick={() => handleDate(date)} className={`date_block pointer ${activeDate === date ? "isActive" : activeDate === formatDate(date) ? "isActive" : ""}`}>
                                            <div>{date === isToday ? "Today" : date.split(" ")[0]}</div>
                                            <div className="date_month">{date.split(" ")[2]} {date.split(" ")[1]}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SearchBar;