import { Link } from "react-router-dom";
import React from "react";
import logo from "../../assets/images/png/logo.png";
import gameLogo from "../../assets/images/png/PlayBig_Logo.png";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../../services/helper/Index";
import {
  Instagram_Link,
  Facebook_Link,
  Twitter_Link,
  Telegram_Link,
} from "../../config/appConfig";
import LazyImage from "./LazyImage";

function FooterLinks() {
  const { isSignInSuccess } = useSelector((state) => state.signIn);
  const token = getFromLocalStorage("gk");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="footerlinks_container">
      <div className="container">
        <div className="content_section">
          <Link to="/">
            <div>
              <LazyImage 
              src={gameLogo} 
              placeholder={gameLogo}
              alt="" />
              {/* <img src={gameLogo} alt="cricctt logo" className="logo" /> */}
            </div>
          </Link>
          {/* <div className="content">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </div> */}
        </div>
        <div className="links_section">
          <div>
            <div className="title">Support</div>
            <Link to={"/privacy-policy"}>
              <p>Privacy Policy</p>
            </Link>
            <Link to={"/terms-and-conditions"}>
              <p>Terms and Conditions</p>
            </Link>
            {/* <Link to="/cookiespolicy"><p>Cookies Policy</p></Link>  */}
            <Link to={"/faqs"}>
              <p>FAQ's</p>
            </Link>
            <Link to="/gamesfeedback">
              <p>Contact Us</p>
            </Link>
          </div>
          <div>
            <div className="title">Follow Us</div>
            <a
              href={Facebook_Link}
              target={"_blank"}
              rel="noreferrer"
            >
              <p>Facebook</p>
            </a>
            <a
              href={Twitter_Link}
              target={"_blank"}
              rel="noreferrer"
            >
              <p>Twitter</p>
            </a>
            <a
              href={Instagram_Link}
              target={"_blank"}
              rel="noreferrer"
            >
              <p>Instagram</p>
            </a>
            <a
              href={Telegram_Link}
              target={"_blank"}
              rel="noreferrer"
            >
              <p>Telegram</p>
            </a>
          </div>
        </div>
        <div className="copyright">Copyright © {currentYear} | NE GROUP</div>
      </div>
    </div>
  );
}

export default FooterLinks;
