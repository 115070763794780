import io from "socket.io-client";
let socket = null;

export const connect = () => {
  let socketURL = process.env.REACT_APP_SCORES_END_POINT;
  console.log("connect");
  socket = io(socketURL, {
    transports: ["websocket"],
    path: "/socket.io",
  });

  socket.on("join-competition", (res) => {
    console.log("join-competition-res: ", res);
  });
  socket.on("leave-competition", (res) => {
    console.log("leave-competition-res: ", res);
  });

  socket.on("leave-match", (res) => {
    console.log("leave-match-res: ", res);
  });

  socket.on("competition-feed", function (object) {
    if (object) {
      //Scores Fixture page
      brodcastEvent("competition-fixtures", object);  //  {mid: 32525, cid: 565, time: 56}
    }
  });



  /* Fixture details page groups */
  socket.on("event", function (object) {
    if (object) {
      //Fixture - Details page
      brodcastEvent("fixture-details", object);
    }
  });

  socket.on("commentory", function (object) {
    if (object) {
      //Fixture - Commentary page
      brodcastEvent("fixture-commentary", object);
    }
  });

  socket.on("lineups", function (object) {
    if (object) {
      //Fixture - LineUp page
      brodcastEvent("fixture-lineups", object);
    }
  });

  socket.on("match_info", function (object) {
    if (object) {
      //Fixture - MatchInfo 
      brodcastEvent("fixture-matchInfo", object);
    }
  });

  socket.on("match_projection", function (object) {
    if (object) {
      //Fixture - MatchProjection 
      brodcastEvent("fixture-matchprojection", object);
    }
  });

  socket.on("statistics", function (object) {
    if (object) {
      //Fixture - MatchProjection 
      brodcastEvent("fixture-statistics", object);
    }
  });

  socket.on("statistics_period1", function (object) {
    if (object) {
      //Fixture - MatchProjection half time
      brodcastEvent("fixture-statisticsp1", object);
    }
  });

  socket.on("statistics_period2", function (object) {
    if (object) {
      //Fixture - MatchProjection full time
      brodcastEvent("fixture-statisticsp2", object);
    }
  });

  socket.on("connect_error", function (err) {
    console.error(`connect_error due to ${err.message}`);
  });
};
connect();


export const subscribeCompetitionInfo = () => {
  if (socket) {
    const roomId = "competition-room";
    try {
      socket.emit("join-competition", roomId); // identifier, roomID
    } catch (err) {
      console.error(err.toString());
    }
  }
};
export const unSubscribeCompetitionInfo = () => {
  if (socket) {
    const roomId = "competition-room";
    try {
      socket.emit("leave-competition", roomId); // identifier, roomID
    } catch (err) {
      console.error(err.toString());
    }
  }
};


export const subscribeFixtureDetailsInfo = (matchId) => {
  if (socket) {
    try {
      socket.emit("join-match", matchId); // identifier, roomID
    } catch (err) {
      console.error(err.toString());
    }
  }
};

// export const unSubscribeCompetitionInfo = () => {
//   if (socket) {
//     const roomId = "competition-room";
//     try {
//       socket.emit("leave-competition", roomId); // identifier, roomID
//     } catch (err) {
//       console.error(err.toString());
//     }
//   }
// };

export const unSubscribeMatchInfo = (matchId) => {
  if (socket) {
    try {
      socket.emit("leave-match", matchId); // identifier, roomID
    } catch (err) {
      console.error(err.toString());
    }
  }
};

const brodcastEvent = (eventName, data) => {
  // console.log("eventName===",data);
  const event = new CustomEvent(eventName, { detail: { data } });
  window.dispatchEvent(event);
};

export const competitionEvents = () => {
  window.addEventListener(
    "competition-feed",
    (e) => {
      subscribeCompetitionInfo();
    },
    false
  );
};
export default connect;
