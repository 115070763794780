import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../noData";
import { setIsViewAllNews } from "./newsSlice";
import moment from "moment";
import { noNewsDataMessage } from "../../config/appConfig";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import dummyNews from "../../assets/images/png/fixtures/placeholder-img.jpg"

const NewsStories = ({ newsData }) => {
    const { isLoading, isActiveTab, isViewAllNews } = useSelector((state) => state.news);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleViewMore = () => {
        dispatch(setIsViewAllNews(!isViewAllNews));
    };

    // function capitalizeFirstLetter(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // };
    return (
        <React.Fragment>
            <div className="news_stories_wrapper">
                {
                    newsData?.map((news,index) => {
                        return (
                            news?.categoryName === isActiveTab &&
                            <React.Fragment key={index}>
                                <h4>{news?.name}</h4>
                                <div className="stories_container">
                                    {news?.data &&
                                        news?.data?.length > 0 &&
                                        (!isViewAllNews ? news?.data?.slice(0, 6) : news?.data)?.map((item, ind) => {
                                            return (
                                                <div className="stories_block pointer" key={ind} onClick={() => navigate(`article/${item.newsID}`)}>
                                                    <div className="image_container">
                                                        <img src={item?.newsImages?.mainImage}
                                                        alt=""
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = dummyNews;
                                                          }}
                                                        />
                                                    </div>
                                                    <div className="title_block">
                                                        <div className="news_title">{item?.newsTitle}</div>
                                                        <div className="published_date">
                                                            {
                                                                moment(item?.newsPublished).format("ll") == moment(new Date).format("ll") ?
                                                                    <>{moment(item?.newsPublished).startOf(new Date()).fromNow()}</>
                                                                    :
                                                                    <>{moment(item?.newsPublished).format("ll")}</>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {news?.data?.length === 0 && <NoData message={noNewsDataMessage} />}
                                {
                                    !isViewAllNews && news?.data?.length > 6 &&
                                    <div className="view_more_button" onClick={handleViewMore}>
                                        View more
                                        <span>
                                            <MdOutlineKeyboardArrowRight className="viewall_btn" />
                                        </span>
                                    </div>
                                }
                            </React.Fragment>
                        )

                    })
                }
            </div>
        </React.Fragment>
    )
}
export default NewsStories;