import React, { useEffect, useRef } from "react";
import SignIn from "./components/signIn/SignIn";
import HomeLayout from "./layouts/HomeLayout";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Signup from "./components/signup/Signup";
import OTPScreen from "./components/otpScreen/OTPScreen";
import MyProfile from "./components/myProfile/MyProfile";
import Home from "./components/home/Home";
import HowToGainCoins from "./components/howToGainCoins/HowToGainCoins";
import ReferAndEarn from "./components/refer&earn/ReferAndEarn";
// import BecomeAVip from "./components/becomeAvip/BecomeAVip";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/termsAndCondition/TermsAndConditions";
import Settings from "./components/settings/Settings";
import ContactPreference from "./components/contactPreference/ContactPreference";
import ChangePassword from "./components/changePassword/ChangePassword";
import PlayersReffered from "./components/refer&earn/PlayersReffered";
import Favourites from "./components/refer&earn/Favourites";
import Favourited from "./components/refer&earn/Favourited";
// import Prizes from "./components/prizes/Prizes";
import RecoverLayout from "./layouts/RecoverLayout";
import Details from "./components/recover/Details";
import Pin from "./components/recover/Pin";
// import UserId from "./components/recover/UserId";
import PasswordScreen from "./components/signup/PasswordScreen";
import Faq from "./components/faq/Faq";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "./services/helper/Index";
import {
  setIsSignInSuccess,
  setUserDetails,
} from "./components/signIn/signSlice";
import { useDispatch, useSelector } from "react-redux";
import GamesFeedback from "./components/gamesFeedback/GamesFeedback";
import {
  getleaderBoardDetailsAfterLogin,
  getleaderBoardDetailsBeforeLogin,
  getuserDetails,
} from "./components/home/homeApi";
import HitSix from "./components/hitsix/HitSix";
import MatchPredictorgame from "./components/PredictMatch/MatchPredictorgame";
import VcttGame from "./components/virtualCtt/VcttGame";
import ResetPin from "./components/recover/ResetPin";
import UpdatePin from "./components/recover/UpdatePin";
import PinSet from "./components/recover/PinSet";
import CoinsMeanPrizes from "./components/coinsMeanPrizes/CoinsMeanPrizes";
import { gethomeBannerImages } from "./components/superBanner/superBannerApi";
import SignupSuccess from "./components/signup/SignupSuccess";
import {
  getTermAndConditionAfterLogin,
  getTermAndConditionBeforeLogin,
} from "./components/termsAndCondition/termsAndConditionApi";
import RecoverOtpScreen from "./components/recover/RecoverOtpScreen";
// import { getCoinsMeanPrizesData, getCoinsMeanPrizesDataBeforeLogin } from "./components/coinsMeanPrizes/coinsMeanPrizesApi";
import { homeStaticData, staticData } from "./components/AppApi";
import News from "./components/news/News";
import PenaltyShootout from "./components/PenaltyShootout/PenaltyShootout";
import PickSix from "./components/PickSix/PickSix";
import MatchPredictor from "./components/PredictMatch/MatchPredictor";
import NewsArticle from "./components/news/NewsArticle";
import Fixture from "./components/fixturesResult/Fixture";
import ScoreDetails from "./components/scoreDetails/Details";
import GameScreen from "./components/gameScreen/gameScreen";
import Scores from "./components/Scores/Scores";
import ViewAllHighlights from "./components/highlights/ViewAllHighlights";
import Promotion from "./components/promotionSreen/Promotion";
import WelcomeBonus from "./components/promotionSreen/welcomeBonus";
import BecomeVip from "./components/promotionSreen/BecomeVIP";
import PlayAndWin from "./components/promotionSreen/PlayAndWin";
import Lineups from "./components/scoreDetails/Lineups";
import Preview from "./components/scoreDetails/Preview";
import VideoLoad from "./components/common/VideoLoad";
import Commentary from "./components/scoreDetails/Commentary";
import TopPlayerAndRating from "./components/scoreDetails/TopPlayerAndRating";
import LiveScore from "./components/scoreDetails/LiveScore";

function App() {
  const dispatch = useDispatch();
  const token = getFromLocalStorage("gk");
  const { isSignInSuccess, userDetails } = useSelector((state) => state.signIn);
  const userDetailsRef = useRef(userDetails);

  useEffect(() => {
    if (userDetails) {
      userDetailsRef.current = userDetails;
    }
  }, [userDetails]);

  useEffect(() => {
    if (token) {
      dispatch(setIsSignInSuccess(true));
      dispatch(getuserDetails());
    } else {
      dispatch(setIsSignInSuccess(false));
    }
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(staticData());
    dispatch(homeStaticData());
    window.scrollTo(0, 0);
    window.addEventListener(
      "balanceUpdate",
      (e) => {
        // Create a copy of the original object
        const updatedObject = { ...userDetailsRef?.current };
        // Update the 'coin' property with a new value
        updatedObject.coin = e?.detail?.balance;
        dispatch(setUserDetails(updatedObject));
      },
      false
    );
  }, []);

  useEffect(() => {
    dispatch(gethomeBannerImages());
    if (!token && !isSignInSuccess) {
      dispatch(getleaderBoardDetailsBeforeLogin());
    } else {
      dispatch(getleaderBoardDetailsAfterLogin());
    }
  }, [isSignInSuccess, dispatch]);

  window.addEventListener("message", receiveMessage, false);

  function receiveMessage(e) {
    var getToken = getFromLocalStorage("gk");
    if (
      getToken &&
      e?.data?.action === "b2bresize" &&
      e?.data?.hasOwnProperty("scrollHeight")
    ) {
      document.getElementById("gameScreen_iframe_id").style.height =
        e.data.scrollHeight + "px";
    }
    if (e?.data?.action === "scrolltotop") {
      scrollToTop();
    }
  }

  function scrollToTop() {
    window?.scrollTo({ top: 0, behavior: "smooth" });
    document.body.scrollTop = 0; // For Safari
    //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeLayout element={<Home />} />} />
          <Route path="/home" element={<HomeLayout element={<Home />} />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp" element={<OTPScreen />} />
          <Route path="/set-password" element={<PasswordScreen />} />
          <Route path="/signup-success" element={<SignupSuccess />} />
          <Route
            path="/personal-details"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MyProfile />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/howtogaincoins"
            element={
              // token || isSignInSuccess ? (
              <HomeLayout element={<HowToGainCoins />} />
              // ) : (
              //   <Navigate to={"/"} />
              // )
            }
          />
          <Route
            path="/referandearn"
            element={
              // token || isSignInSuccess ? (
              <HomeLayout element={<ReferAndEarn />} />
              // ) : (
              //   <Navigate to={"/"} />
              // )
            }
          />
          <Route path="/news" element={<HomeLayout element={<News />} />} />
          <Route
            path="/news/article/:newsId"
            element={
              // token || isSignInSuccess ? (
              <HomeLayout element={<NewsArticle />} />
              // ) : (
              //   <Navigate to={"/"} />
              // )
            }
          />
          {/* <Route path="/become-vip" element={<HomeLayout element={<BecomeAVip />} />} /> */}
          <Route
            path="/privacy-policy"
            element={<HomeLayout element={<PrivacyPolicy />} />}
          />
          <Route
            path="/terms-and-conditions"
            element={<HomeLayout element={<TermsAndConditions />} />}
          />
          <Route
            path="/settings"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<Settings />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/contact-preference"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<ContactPreference />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/change-password"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<ChangePassword />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/playersReferred"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<PlayersReffered />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/favourited"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<Favourited />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/favourites"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<Favourites />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          {/* <Route path="/prizes" element={<HomeLayout element={<Prizes />} />} /> */}
          <Route path="/recover-details" element={<Details />} />
          <Route path="/recover-pin" element={<Pin />} />
          <Route path="/recover-pin/otpVerify" element={<RecoverOtpScreen />} />
          <Route path="/recover-pin/confirmation" element={<ResetPin />} />
          <Route
            path="/recover-pin/setPin"
            element={
              <RecoverLayout element={<UpdatePin title="Recover Password" />} />
            }
          />
          <Route
            path="/recover-pin/success"
            element={<RecoverLayout element={<PinSet />} />}
          />
          {/* <Route path="/recover-userid" element={<HomeLayout element={<RecoverLayout title="Recover User ID" element={<UserId />} />} />} /> */}
          <Route path="/faqs" element={<HomeLayout element={<Faq />} />} />
          <Route
            path="/gamesfeedback"
            element={<HomeLayout element={<GamesFeedback />} />}
          />
          <Route
            path="/game/pick6"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<PickSix />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/game/penaltyshootout"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<PenaltyShootout />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/game/hit6/:tab"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<HitSix />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/game/matchpredictor"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MatchPredictor />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/game/mpCric/"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MatchPredictorgame />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/game/mpCric/:tab"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MatchPredictorgame />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/game/vctt"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<VcttGame />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route
            path="/coinsmeanprizes"
            element={<HomeLayout element={<CoinsMeanPrizes />} />}
          />
          <Route
            path="/scores"
            element={<HomeLayout element={<Fixture />} />}
          />
          <Route
            path="/scores/details/:cid/:matchId"
            element={
              <HomeLayout element={<LiveScore screen={<ScoreDetails />} />} />
            }
          />
          <Route
            path="/scores/lineups/:cid/:matchId"
            element={
              <HomeLayout element={<LiveScore screen={<Lineups />} />} />
            }
          />
          <Route
            path="/scores/preview/:cid/:matchId"
            element={
              <HomeLayout element={<LiveScore screen={<Preview />} />} />
            }
          />
          <Route
            path="/scores/commentary/:cid/:matchId"
            element={
              <HomeLayout element={<LiveScore screen={<Commentary />} />} />
            }
          />
          <Route
            path="/scores/top-player/:cid/:matchId"
            element={<HomeLayout element={<TopPlayerAndRating />} />}
          />
          <Route
            path="/games"
            element={<HomeLayout element={<GameScreen />} />}
          />
          {/* <Route path="/scores" element={<HomeLayout element={<Scores />} />} /> */}
          <Route
            path="/viewallhighlights"
            element={<HomeLayout element={<ViewAllHighlights />} />}
          />
          <Route
            path="/promotion"
            element={<HomeLayout element={<Promotion />} />}
          />
          <Route
            path="/promotion/welcome-bonus"
            element={<HomeLayout element={<WelcomeBonus />} />}
          />
          <Route
            path="/promotion/become-vip"
            element={<HomeLayout element={<BecomeVip />} />}
          />
          <Route
            path="/promotion/play-win"
            element={<HomeLayout element={<PlayAndWin />} />}
          />
          <Route
            path="/video/:videoId"
            element={<HomeLayout element={<VideoLoad />} />}
          />
          {/* <Route path="/cookiespolicy" element={<CookiesPolicy />} />
          <Route path="/contact" element={<ContactUs />} /> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
