import React, { useEffect, useRef, useState } from "react";
import BackButton from "../common/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { getHighlights, setViewAllHighlightsData } from "./cl_HighlightsSlice";
import {
  noDataMessage,
  pageNumber,
  viewAllHighlightsLimit,
} from "../../config/appConfig";
import video_icon_dsk from "../../assets/images/png/highlights/Playbuttondsk.png";
import video_icon_mb from "../../assets/images/png/highlights/Playbuttonmb.png";
import Footer from "../common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { ButtonLoader, DotsContentLoader } from "../common/Loader";
import NoData from "../noData";
import moment from "moment";
import dummyHighlight from "../../assets/images/png/fixtures/highlightPlaceholder.jpg"

const ViewAllHighlights = () => {
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(2);
  const [filteredAllHighlightsData, setFilteredAllHighlightsData] = useState([]);
  const { viewAllHighlightsData, totalRecords, isLoading } = useSelector(
    (state) => state.highlights
  );
  let length;
  length = viewAllHighlightsData?.length;

  const dispatch = useDispatch();
  const isEffectExecutedRef = useRef(false);
  useEffect(() => {
    if (!isEffectExecutedRef.current) {
      dispatch(setViewAllHighlightsData([]));
      dispatch(
        getHighlights(
          "viewallhighlights",
          "highlights",
          pageNumber,
          viewAllHighlightsLimit
        )
      );
      window.scrollTo(0, 0);
      isEffectExecutedRef.current = true;
    }
    return;
  }, []);

  useEffect(() => {
    if (viewAllHighlightsData) {
      const filterDate = new Date();
      const filteredData = viewAllHighlightsData.filter(item => new Date(item.date) < filterDate);
      filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setFilteredAllHighlightsData(filteredData);
    }
  }, [viewAllHighlightsData]);

  const handleViewAllHighlightsShowMore = (type) => {
    if (type === "inc") {
      setPageNum((num) => num + 1);
      dispatch(
        getHighlights(
          "viewallhighlights",
          "highlights",
          pageNum,
          viewAllHighlightsLimit
        )
      );
    } else {
      setPageNum(2);
      dispatch(setViewAllHighlightsData([]));
      dispatch(
        getHighlights(
          "viewallhighlights",
          "highlights",
          pageNumber,
          viewAllHighlightsLimit
        )
      );
    }
    if (type === "dec") window?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dateFormat = (date) => {
    const filterDate = moment(date).format("ll");
    const updatedDate = filterDate.split(" ")
    return updatedDate;
  }

  return (
    <React.Fragment>
      <div className="main-viewAllHighlights-page">
        {/* <div className="container">
          <div className="content-header">
            <div className="header_recover">
              <div className="container">
                <BackButton />
              </div>
              <div className="title">Highlights</div>
            </div>
          </div>
        </div> */}
        <div className="top_Footer">
          <Footer title={"viewallhighlights"} />
        </div>
        <div className="container">
          {isLoading && <DotsContentLoader />}
          {filteredAllHighlightsData && filteredAllHighlightsData?.length > 0 && (
            <div className="main-viewAllHighlights-content">
              <div className="content-body">
                <div className="viewall-highlights-videos">
                  <ul>
                    {filteredAllHighlightsData?.map((ele, index) => {
                      return (
                        <li className="card" key={index}>
                          <Link to={`/video/${ele?._id}`}>
                            <div className="card-body">
                              <img src={ele?.thumbnail}
                                alt="image"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = dummyHighlight;
                                }}
                              />
                              <div className="card-content">
                                <div className="video-icon">
                                  <div className="video-icon-mobile">
                                    <img
                                      src={video_icon_mb}
                                      alt="video-image"
                                    />
                                  </div>
                                  <div className="video-icon-desktop">
                                    <img
                                      src={video_icon_dsk}
                                      alt="video-image"
                                    />
                                  </div>
                                </div>
                                <div className="card-txt">{ele?.title}</div>
                                <div className="card_date">{dateFormat(ele?.date)[1].split(",")[0]} {dateFormat(ele?.date)[0]} {dateFormat(ele?.date)[2]}</div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="content-footer">
                <div className="showMore-container">
                  {isLoading ? (
                    <ButtonLoader />
                  ) : length > 0 ? (
                    <button
                      className="btn btnLarge showMore-button"
                      onClick={() =>
                        handleViewAllHighlightsShowMore(
                          length < totalRecords ? "inc" : "dec"
                        )
                      }
                    >
                      <span>
                        {length < totalRecords ? "Show More" : "Show Less"}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}

          {!isLoading && filteredAllHighlightsData?.length === 0 && (
            <NoData message={noDataMessage} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewAllHighlights;
