import React, { useEffect, useState } from "react";
import ModalBox from "../common/ModalBox";
import { BiTrashAlt } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import {
  setEmail,
  setEmailError,
  setReferSuccessToEmail,
} from "./referAndEarnSlice";
import { useDispatch, useSelector } from "react-redux";
import { referUserToEmail } from "./referAndEarnApi";
import { ButtonLoader } from "../common/Loader";

function InviteFriendsToEmail(props) {
  const { userDetails } = useSelector((state) => state.signIn);
  const [inviteCount, setInviteCount] = useState(1);
  const [count, setCount] = useState(1);
  const [invitedlist, setInvitedlist] = useState([]);
  const [id, setId] = useState(0);
  const dispatch = useDispatch();
  const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const { referSuccessToEmail, emailError, emailLoader } = useSelector(
    (state) => state.refer
  );

  useEffect(() => {
    const date = new Date().getTime();
    if (count === inviteCount) {
      setInvitedlist([
        ...invitedlist,
        { id: date, email: "", isInvited: false },
      ]);
      setCount(count + 1);
      dispatch(setEmailError(""));
    }
  }, [inviteCount, count, invitedlist]);

  const handleEdit = (id, email) => {
    dispatch(setEmail(email));
    dispatch(setEmailError(""));
    const tempData = [...invitedlist];
    const index = invitedlist.findIndex((ele) => ele.id === id);
    tempData[index] = {
      email,
      id: tempData[index]?.id,
      isInvited: tempData[index]?.isInvited,
    };
    setInvitedlist(tempData);
  };

  const handleInvite = (index, e) => {
    e.preventDefault();
    dispatch(setEmailError(""));
    const filterData = invitedlist.filter((ele) => ele.id === index);
    if (filterData[0].email === "") {
      dispatch(setEmailError("Please Enter Email"));
    } else if (!regExp.test(filterData[0].email)) {
      dispatch(setEmailError("Invalid Email"));
    } else {
      setId(index);
      dispatch(setEmailError(""));
      dispatch(referUserToEmail(filterData[0].email));
      dispatch(setReferSuccessToEmail(false));
    }
  };

  useEffect(() => {
    if (referSuccessToEmail) {
      const tempData = [...invitedlist];
      const index = invitedlist.findIndex((ele) => ele.id === id);
      tempData[index] = {
        email: tempData[index]?.email,
        id: tempData[index]?.id,
        isInvited: true,
      };
      setInvitedlist(tempData);
      dispatch(setReferSuccessToEmail(false));
      dispatch(setEmail(""));
      dispatch(setEmailError(""));
    }
  }, [referSuccessToEmail, id]);

  const handleDelete = (id) => {
    dispatch(setEmailError(""));
    setInviteCount((prev) => (prev === 1 ? 1 : prev - 1));
    setCount((prev) => (prev === 1 ? 1 : prev - 1));
    if (invitedlist.length > 1) {
      const filteredData = invitedlist.filter((ele) => ele.id !== id);
      setInvitedlist(filteredData);
    }
  };

  const handleAddMore = () => {
    dispatch(setEmail(""));
    setInviteCount((prev) => prev + 1);
  };

  return (
    <ModalBox
      dOpen={props.open !== ""}
      dClose={props.close}
      isClosable={props.closable}
      inviteFriend={true}
    >
      <div className="invite_friends_model">
        <div className="title">Invite Friends</div>

        <div className="form_group">
          {/* <div className="content">
            {userDetails && userDetails?.username} has invited you to play Pick 6 a great new Football game
            where you can play and win big for free
          </div> */}
          {invitedlist?.map((ele, ind) => {
            return (
              <form
                className="form_group_inline"
                key={ind}
                onSubmit={(e) => handleInvite(ele.id, e)}
              >
                <input
                  type="text"
                  className="form_input"
                  value={ele.email}
                  onChange={(e) => handleEdit(ele.id, e.target.value)}
                  placeholder={"Enter email address"}
                />
                {ele.isInvited ? (
                  <button className="btn_invite invited" type="button">
                    Invited
                  </button>
                ) : (
                  <button className="btn_invite invite" type="submit">
                    {emailLoader && ele.id === id ? <ButtonLoader /> : "Invite"}
                  </button>
                )}
                {invitedlist.length !== 1 && (
                  <span>
                    {!ele.isInvited && (
                      <BiTrashAlt
                        className="icon_delete"
                        onClick={() => handleDelete(ele.id)}
                      />
                    )}
                  </span>
                )}
              </form>
            );
          })}
          {/* <div className="form_group_inline">
                        <input type="text" className="form_input" placeholder="Enter email address" />
                        <button className="btn btn_invite btn-primary">Invite</button>
                        <BiTrashAlt className="icon_delete" />
                    </div> */}
          <div className="error_message">{emailError}</div>
        </div>
        <div className="add_more">
          <span className="icon_add">
            <FaPlus onClick={() => handleAddMore()} />
          </span>
          Add more friends
        </div>
      </div>
    </ModalBox>
  );
}

export default InviteFriendsToEmail;
