import React, { useEffect, useState } from "react"

function Timer(props) {
    const [time, setTime] = useState(props.time || 120);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (time === 0) {
            props.reset()
        }
    }, [time, props])

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return (
        <>
            {
                time > 0 ?
                    <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                    : "00:00"
            }
        </>
    );
}

export default Timer