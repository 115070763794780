import box from "../../assets/images/png/promotions/box.png";
import crown from "../../assets/images/png/promotions/crown.png";
import game from "../../assets/images/png/promotions/game.png";
// import penaltyShootoutMobile from "../../assets/images/png/promotions/penaltyShootout.png";
// import penaltyShootoutWeb from "../../assets/images/png/promotions/psWeb.png";
// import mpWeb from "../../assets/images/png/promotions/mpWeb.png";
// import mpMobile from "../../assets/images/png/promotions/mp.png";
// import pick6Mobile from "../../assets/images/png/promotions/pick6.png";
// import pick6Web from "../../assets/images/png/promotions/pick6Web.png";
import penaltyShootoutMobile from "../../assets/images/web/promotions/vps_logo.webp";
import penaltyShootoutWeb from "../../assets/images/web/promotions/psWeb.webp";
import mpWeb from "../../assets/images/web/promotions/mpWeb.webp";
import mpMobile from "../../assets/images/web/promotions/mp.webp";
import pick6Mobile from "../../assets/images/web/promotions/pick6.webp";
import pick6Web from "../../assets/images/web/promotions/pick6Web.webp";

export const data = [
    {
        "logo": box,
        "heading": "Welcome Bonus",
        "description": "Join to win with Playbig Get up to 250,000 Bonus",
        "link": "welcome-bonus"
    },
    // {
    //     "logo": crown,
    //     "heading": "Become VIP",
    //     "description": "The Experience You Deserve",
    //     "link": "become-vip"
    // },
    // {
    //     "logo": game,
    //     "heading": "Play and Win",
    //     "description": "Play to play version of the hugely popular.",
    //     "link": "play-win"
    // }
]
export const gameBanner = [
    {
        "gameBannerMobile": pick6Mobile,
        "gameBannerWeb": pick6Web,
        "link": "/game/pick6"
    },
    {
        "gameBannerMobile": mpMobile,
        "gameBannerWeb": mpWeb,
        "link": "/game/matchpredictor"
    },
    {
        "gameBannerMobile": penaltyShootoutMobile,
        "gameBannerWeb": penaltyShootoutWeb,
        "link": "/game/penaltyshootout"
    },
]