import React, { useCallback, useEffect, useState } from 'react'
// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { getFromLocalStorage } from '../../services/helper/Index';
import { useSelector } from 'react-redux';

function VcttGame() {
    const [accessToken, setAccessToken] = useState("");
    // const [gameTab, setGameTab] = useState("");
    // const { userDetails } = useSelector(state => state.signIn)

    // const { tab } = useParams()
    const { userDetails } = useSelector(state => state.signIn)


    const [isDetailsAvailable, setIsDetailsAvailable] = useState(false)

    var platformId = process.env.REACT_APP_GAME_PLATFORM_ID;
    var operatorId = process.env.REACT_APP_GAME_OPERATOR_ID;
    var brand = process.env.REACT_APP_GAME_BRAND;

    const memoizedGetFromLocalStorage = useCallback(getFromLocalStorage, []);


    useEffect(() => {
        const token = memoizedGetFromLocalStorage("gk");
        setAccessToken(token);
    }, [memoizedGetFromLocalStorage]);


    useEffect(() => {
        if (Object.keys(userDetails).length > 0) {
            setIsDetailsAvailable(true)
        } else {
            setIsDetailsAvailable(false)
        }
    }, [userDetails])


    // useEffect(() => {
    //     if (tab === undefined) {
    //         setGameTab("")
    //     } else {
    //         setGameTab(tab)
    //     }
    // }, [tab,accessToken, userDetails]);



    if (!accessToken) return null;
    return (
        <React.Fragment>
            {isDetailsAvailable ? <iframe
                className="gameScreen_frame"
                id="gameScreen_iframe_id"
                title="hit-six-game"
                src={`https://dev-games-rgs.sportsit-tech.net/games/virtualCatchTheTrend/index.html#/?token=${accessToken}&playerId=${userDetails?.id}&playerName=${userDetails.username}&platformId=${platformId}&operatorId=${operatorId}&brand=${brand}&currencyCode=INR&languageCode=en&region=IN`}
                sandbox="allow-same-origin allow-scripts"
            >
            </iframe>
                :
                null}
        </React.Fragment>
    )
}

export default VcttGame