import React from 'react'
import gameIcon from "../../assets/images/png/games.png"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../../services/helper/Index';
import PenaltyShootoutBanner from '../PenaltyShootout/PenaltyShootoutBanner';
import PickSixBanner from '../PickSix/PickSixBanner';
import MatchPredictorBanner from './MatchPredictorBanner';
import LazyImage from '../common/LazyImage';

function PredictMatch() {

    const token = getFromLocalStorage("gk");
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const { mpData, mpBannerText, pickSixData, pickSixBannerText, penaltyShootoutData, penaltyShootoutBannerText } = useSelector((state) => state.home);

    const navigate = useNavigate();

    const navigateToGame = (GameLink) => {
        if (token && isSignInSuccess) {
            navigate(GameLink);
        } else {
            navigate("/signin");
        }
    };


    return (
        <div className="container">
            <div className="super_card_title">
                <LazyImage src={gameIcon} placeholder={gameIcon} alt="" />
                {/* <img src={gameIcon} alt="gameIcon" /> */}
                <p> {"Games"}</p>
            </div>
            <div className='game_card_main'>
                <MatchPredictorBanner mpData={mpData} mpBannerText={mpBannerText} navigateToGame={navigateToGame} />
                <PickSixBanner pickSixData={pickSixData} pickSixBannerText={pickSixBannerText} navigateToGame={navigateToGame} />
                <PenaltyShootoutBanner penaltyShootoutData={penaltyShootoutData} penaltyShootoutBannerText={penaltyShootoutBannerText} navigateToGame={navigateToGame} />
            </div>
            {/* {mpData && mpData?.players && <LeaderBoard leaderBoardData={mpData.players} navigateToLDB={"/game/mpcric/ldb"} />}
            {mpData && mpData?.prevGameWinners && mpData?.prevGameWinners.length > 0 && <Winner WinnersData={mpData.prevGameWinners} navigateToResult={"/game/mpcric/result"} />} */}
        </div>
    )
}

export default PredictMatch;