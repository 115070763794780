import React, { useEffect, useState } from "react";
import TopMenu from "./TopMenu";
import LiveScore from "./LiveScore";
import argentinos from "../../assets/images/png/fixtures/argentinos.png"
import lineup from "../../assets/images/png/fixtures/lineup.png"
import avtar from "../../assets/images/png/fixtures/white_avtar.svg"
import penaltyMissed from "../../assets/images/png/fixtures/penalty_missed.svg"
import penaltyScored from "../../assets/images/png/fixtures/penalty_scored.svg"
import { BsCaretLeftFill } from "react-icons/bs";
import { BsCaretRightFill } from "react-icons/bs";
import { getMatchInfo, setCommentaryData } from "./cl_LineupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { DotsContentLoader } from "../common/Loader";
import NoData from "../noData";
import { checkScoresUpdates, handleUpdate } from "../../services/socket/scoresUpdateHandler";

const Commentary = () => {
    const dispatch = useDispatch();
    const { isLoading, lineupsData, commentaryData, teamDetails } = useSelector((state) => state.lineups);
    const [filterCommentary, setFilterCommentary] = useState([]);
    const [isViewAll, setIsViewAll] = useState(false);
    const param = useParams();
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        dispatch(getMatchInfo(param.matchId, "commentary"));
    }, []);

    useEffect(() => {
        window.addEventListener(
            "fixture-commentary",
            (e) => {
                // dispatch(checkScoresUpdates(e?.detail?.data, handleUpdate, "commentary"));
                dispatch(setCommentaryData(e?.detail?.data));
                // console.log("commentary: ", e?.detail?.data);
            },
            false
        );
    }, [])

    useEffect(() => {
        if (commentaryData) {
            const filterCommentary = commentaryData?.filter((item) => item.time !== "")
            const reverseCommentary = filterCommentary?.reverse();
            setFilterCommentary(reverseCommentary)
        }
    }, [commentaryData]);

    const handleViewAll = () => {
        setIsViewAll(!isViewAll);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowContent(true);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                {/* <LiveScore /> */}
                <div className="commentary_wrapper">
                    <TopMenu />
                    {/* <p className="commentary_contain">Thanks for joining us for live coverage of England's 4-2 penalty shoot-out victory over Nigeria in the World Cup round of 16 at Brisbane Stadium. Goodbye!</p>
                    <p className="commentary_contain">Wiegman will be thrilled with the guts and determination her team showed to get them over the line, but England will have real concerns ahead of their next game after being pushed all the way by Nigeria. With Germany and the USA out, Japan are the only nation left in this edition of the World Cup that have won it previously, and maybe the stars are aligning for the Lionesses to claim international football's top prize for a first time. For Nigeria, they will be bitterly disappointed not to have made it through, but they should take a lot of positives as they pushed one of the tournament favourites to the brink of defeat with a brilliant, albeit wasteful, display.</p> */}
                    {
                        isLoading ?
                            <DotsContentLoader />
                            :
                            <React.Fragment>
                                {
                                    teamDetails?.isCommentaryAvailable ?
                                        <React.Fragment>
                                            {
                                                filterCommentary?.length > 0 ? (!isViewAll ? filterCommentary?.slice(0, 10) : filterCommentary)?.map((Commentary, ind) => {
                                                    return (
                                                        <React.Fragment key={ind}>
                                                            <div className="player_block">
                                                                <div>
                                                                    <div>{Commentary?.time}' {Commentary?.injurytime ? `+ ${Commentary?.injurytime}'` : ""}</div>
                                                                    {/* <div className="player_info">
                                                                    <img src={penaltyScored} alt="" />
                                                                    <div className="avtar"><img src={avtar} alt="" /></div>
                                                                    <div>C. Kelly</div>
                                                                </div> */}
                                                                </div>
                                                                {/* <div className="team_name">
                                                                <img src={argentinos} alt="" />
                                                                <div>ARG</div>
                                                            </div> */}
                                                            </div>
                                                            <p className="commentary_contain">{Commentary?.sentence}</p>
                                                        </React.Fragment>
                                                    )
                                                })
                                                    :
                                                    <NoData message={"No Data Available"} />
                                            }
                                        </React.Fragment>
                                        :
                                        showContent && <NoData message={"No Data Available"} />
                                }
                            </React.Fragment>
                    }

                    {/* <div className="player_block">
                        <div>
                            <div>120' + 9' Penalty scored</div>
                            <div className="player_info">
                                <img src={penaltyScored} alt="" />
                                <div className="avtar"><img src={avtar} alt="" /></div>
                                <div>C. Ucheibe</div>
                            </div>
                        </div>
                        <div className="team_name">
                            <img src={lineup} alt="" />
                            <div>VEL</div>
                        </div>
                    </div>
                    <p className="commentary_contain">UCHEIBE KEEPS Vel IN IT! With a miss seeing England through, the midfielder calmly slots her penalty home to force the Lionesses to a fifth spot-kick.</p>

                    <div className="player_block">
                        <div>
                            <div>120' + 8' Penalty scored</div>
                            <div className="player_info">
                                <img src={penaltyScored} alt="" />
                                <div className="avtar"><img src={avtar} alt="" /></div>
                                <div>A. Greenwood</div>
                            </div>
                        </div>
                        <div className="team_name">
                            <img src={argentinos} alt="" />
                            <div>ARG</div>
                        </div>
                    </div>
                    <p className="commentary_contain">Argentina ON THE VERGE! Greenwood sends Nnadozie the wrong way, coolly slotting into the bottom right corner to put Argentina</p>

                    <div className="player_block">
                        <div>
                            <div>120' + 7' Penalty Missed</div>
                            <div className="player_info">
                                <img src={penaltyMissed} alt="" />
                                <div className="avtar"><img src={avtar} alt="" /></div>
                                <div>U. Oparanozie</div>
                            </div>
                        </div>
                        <div className="team_name">
                            <img src={lineup} alt="" />
                            <div>VEL</div>
                        </div>
                    </div>
                    <p className="commentary_contain">OPARANOZIE MISSES! VEL miss the opportunity to go ahead as the substitute goes the same way as Stanway with the same result as she smashes wide of the post.</p>

                    <div className="player_block">
                        <div>
                            <div>120' + 6' Substitution</div>
                            <div className="player_info">
                                <div className="left_icon"><BsCaretLeftFill /></div>
                                <div className="avtar"><img src={avtar} alt="" /></div>
                                <div>U. Oparanozie</div>
                            </div>
                            <div className="player_info">
                                <div className="right_icon"><BsCaretRightFill /></div>
                                <div className="avtar"><img src={avtar} alt="" /></div>
                                <div>U. Oparanozie</div>
                            </div>
                        </div>
                        <div className="team_name">
                            <img src={lineup} alt="" />
                            <div>VEL</div>
                        </div>
                    </div> */}
                    {
                        teamDetails?.isCommentaryAvailable &&
                        <React.Fragment>
                            {
                                !isViewAll && filterCommentary?.length > 9 &&
                                <div className="button">
                                    <button className="btn view_button" onClick={handleViewAll}>View All</button>
                                </div>
                            }
                        </React.Fragment>
                    }

                </div>
            </div>
        </React.Fragment>
    )
}
export default Commentary;