import React, { useEffect, useState } from "react";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { useDispatch, useSelector } from "react-redux";
import { setIsActiveTab, setIsViewAllNews } from "./newsSlice";

const NewsTabs = ({ categories }) => {
  const dispatch = useDispatch();
  const { isLoading, isActiveTab } = useSelector((state) => state.news);

  useEffect(() => {
    if (categories) {
      dispatch(setIsActiveTab(categories?.[0]?.categoryName));
    }
  }, [categories]);

  const handleTab = (name) => {
    dispatch(setIsActiveTab(name));
    dispatch(setIsViewAllNews(false));
  };

//   function capitalizeFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   }
  return (
    <React.Fragment>
      <div className="news_menu">
        <Splide
          options={{
            type: "false",
            omitEnd: true,
            autoWidth: false,
            pagination: false,
            arrows: true,
            drag: false,
            gap: "10",
          }}
        >
          {categories?.map((item, ind) => (
            <SplideSlide key={ind}>
              <div
                onClick={() => handleTab(item?.categoryName)}
                className={`pointer ${
                  item?.categoryName == isActiveTab && "active"
                }`}
              >
                {(item?.name)}
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </React.Fragment>
  );
};
export default NewsTabs;
