import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Twitter from "../../assets/images/png/Twitter.svg";
import Facebook from "../../assets/images/png/facebook.png";
import Instagram from "../../assets/images/png/instagram.png";
import Telegram from "../../assets/images/png/telegram.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { submitGamefeedBack } from "./gamesFeedbackApi";
import { ButtonLoader } from "../common/Loader";
import submitIcon from "../../assets/images/png/submitIcon.png";
import {
  setFeedbackSubmitSuccess,
  setfeedbackError,
  setFeedbackErrorMessage,
  setFeedbackPhoneEmailErrorMsg,
} from "./gamesFeedbackSlice";
import FeedbackSubmit from "./FeedbackSubmit";
import { checkIsPhone, validateEmail, validateNum } from "../signIn/signInApi";
import Footer from "../common/Footer";
import {
  Instagram_Link,
  Facebook_Link,
  Twitter_Link,
  Telegram_Link,
  MSISDNZeroEnabled,
  MSISDNAcceptableDigitsWithZero,
  MSISDNAcceptableDigits,
} from "../../config/appConfig";
import ModalBox from "../common/ModalBox";

function GamesFeedback(props) {
  const { userDetails } = useSelector((state) => state.signIn);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      setDescriptionError(false);
      dispatch(setFeedbackSubmitSuccess(false));
      dispatch(setfeedbackError(false));
      dispatch(setFeedbackErrorMessage(""));
    };
  }, []);
  const { feedbackPhoneEmailErrorMsg } = useSelector(
    (state) => state.gameFeedbackType
  );
  const [feedbackType, setFeedbackType] = useState("A Technical issue");
  const dispatch = useDispatch();
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [type, setType] = useState("")
  const navigate = useNavigate();
  const handleDescriptionChange = (e) => {
    setDescriptionError(false);
    dispatch(setFeedbackSubmitSuccess(false));
    dispatch(setfeedbackError(false));
    dispatch(setFeedbackErrorMessage(""));
    setFeedbackDescription(e.target.value);
  };

  function close(type) {
    dispatch(setFeedbackSubmitSuccess(false));
    setType(type)
  }

  useEffect(() => {
    if (type === "continue" && props.feedbackSubmitSuccess === false) {
      navigate("/")
    }
  }, [props.feedbackSubmitSuccess]);

  const submitGamefeedBackOnclick = () => {
    if (feedbackDescription?.length === 0 && phoneNumber.trim() === "") {
      dispatch(
        setFeedbackPhoneEmailErrorMsg("Email / Phone number is Required")
      );
      setDescriptionError(true);
    } else if (feedbackDescription?.length === 0) {
      setDescriptionError(true);
    } else if (phoneNumber.trim() === "") {
      dispatch(
        setFeedbackPhoneEmailErrorMsg("Email / Phone number is Required")
      );
    } else if (feedbackDescription && phoneNumber) {
      if (checkIsPhone(+phoneNumber)) {
        if (validateNum(+phoneNumber)) {
          dispatch(setFeedbackPhoneEmailErrorMsg("Invalid Phone number"));
        } else {
          dispatch(setFeedbackPhoneEmailErrorMsg(""));
          const countryCode = userDetails && userDetails?.countryCode;
          let isLengthValid =
            MSISDNZeroEnabled && phoneNumber?.charAt(0) === "0"
              ? phoneNumber?.length === MSISDNAcceptableDigitsWithZero
              : phoneNumber?.length === MSISDNAcceptableDigits;

          let phnNum =
            phoneNumber?.charAt(0) === "0" ? phoneNumber.slice(1) : phoneNumber;

          const mobileNoWithCountryCode = countryCode === undefined ? phnNum :
            countryCode + phnNum;
          if (isLengthValid) {
            dispatch(
              submitGamefeedBack(
                feedbackType,
                feedbackDescription,
                "phone",
                +mobileNoWithCountryCode
              )
            );
          } else {
            dispatch(setFeedbackPhoneEmailErrorMsg("Invalid Phone number"));
          }
        }
      } else if (!validateEmail(phoneNumber)) {
        dispatch(setFeedbackPhoneEmailErrorMsg("Invalid Email"));
      } else {
        dispatch(setFeedbackPhoneEmailErrorMsg(""));
        dispatch(
          submitGamefeedBack(
            feedbackType,
            feedbackDescription,
            "email",
            phoneNumber
          )
        );
      }
    }
  };

  useEffect(() => {
    if (props?.feedbackSubmitSuccess) {
      setFeedbackType("A Technical issue");
      setFeedbackDescription("");
      setPhoneNumber("")
    }
    if (props?.feedbackSubmitSuccess) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
  }, [props?.feedbackSubmitSuccess]);

  const handleNumberChange = (e) => {
    dispatch(setFeedbackPhoneEmailErrorMsg(""));
    setPhoneNumber(e.target.value);
  };

  return (
    <React.Fragment>
      <Footer />
      <div className="games_Feedback_wrapper">
        <div className="container">
          <div className="games_Feedback_container">
            <div className="games_Feedback_head">
              <div className="title pagetitle brand_secondary">
                Games Feedback
              </div>
            </div>
            <div className="content">
              <p>
                Your thoughts and feedback are incredibly important to us! So,
                good or bad, please let us know! Please provide your comments in
                the form below.{" "}
              </p>
              <p>
                If you need customer support for any issues, please contact us
                on one of the methods below. But please read the
                <Link to="/faqs">
                  &nbsp;<span>FAQ’s </span>
                </Link>
                first as many queries can be quickly answered there.
              </p>
              <p>
                If you would like to contact us, you can email.
                {/* <a href="mailto:support@digibinge.com" className="direct-link">
                  <u>support@digibinge.com</u>
                </a> */}
              </p>
            </div>
            <div className="feedback-content">
              <div className="feedback-content-left">
                <div className="mainbox_content">
                  <a
                    href={Twitter_Link}
                    target={"_blank"}
                    rel="noreferrer"
                    className="hyper_link"
                  >
                    <div className="box_content">
                      <img src={Twitter} alt={"social Icon"} />
                      <div className="social_title">@PLAYBIG_SPORTS</div>
                    </div>
                  </a>
                  <a
                    href={Facebook_Link}
                    target={"_blank"}
                    rel="noreferrer"
                    className="hyper_link"
                  >
                    <div className="box_content">
                      {" "}
                      <img src={Facebook} alt={"social Icon"} />
                      <div className="social_title">MESSAGE US ON FACEBOOK</div>
                    </div>
                  </a>
                  <a
                    href={Telegram_Link}
                    target={"_blank"}
                    rel="noreferrer"
                    className="hyper_link"
                  >
                    <div className="box_content">
                      {" "}
                      <img src={Telegram} alt={"social Icon"} />
                      <div className="social_title">@PLAYBIG_SPORTS</div>
                    </div>
                  </a>
                  <a
                    href={Instagram_Link}
                    target={"_blank"}
                    rel="noreferrer"
                    className="hyper_link"
                  >
                    <div className="box_content">
                      {" "}
                      <img src={Instagram} alt={"social Icon"} />
                      <div className="social_title">
                        MESSAGE US ON INSTAGRAM
                      </div>
                    </div>
                  </a>
                </div>
                <div className="mainbox_content">
                  <div className="games_Accordion">
                    <div className="games_Accordion_head">
                      What would you like to tell us about today ?
                    </div>
                    <div className="games_Accordion_Content">
                      <select
                        className="feedback_dropdown"
                        id="gamefeedback"
                        onChange={(e) => setFeedbackType(e.target.value)}
                        value={feedbackType}
                      >
                        <option value="A Technical issue">
                          A Technical issue
                        </option>
                        <option value="Some thing That I like">
                          Some thing That I like
                        </option>
                        <option value="A Suggested Improvement">
                          A Suggested Improvement
                        </option>
                        <option value="A Comment On Our Games">
                          A Comment On Our Games
                        </option>
                        <option value="Something Else">Something Else </option>
                      </select>
                    </div>
                  </div>
                  <div className="feedback_container">
                    <div className="feedback_header">
                      Please enter your phone number or email{" "}
                    </div>
                    <input
                      className="feedback_phone_input"
                      rows={10}
                      value={phoneNumber}
                      onChange={(e) => handleNumberChange(e)}
                    />
                    {feedbackPhoneEmailErrorMsg && (
                      <div className="errormessage">
                        {feedbackPhoneEmailErrorMsg}
                      </div>
                    )}
                  </div>
                  <div className="feedback_container">
                    <div className="feedback_header feedback_text">
                      Please give us your feedback{" "}
                    </div>
                    <div className="feedback_input-body">
                      <textarea
                        className="feedback_input"
                        rows={10}
                        value={feedbackDescription}
                        onChange={(e) => handleDescriptionChange(e)}
                      ></textarea>
                    </div>
                  </div>
                  {descriptionError ? (
                    <div className="errormessage">
                      Please add some description for your feedback
                    </div>
                  ) : props?.feedbackError ? (
                    <div className="errormessage">
                      {props?.feedBackErrorMessage}
                    </div>
                  ) : null}

                  <ModalBox dOpen={props?.feedbackSubmitSuccess} dClose={() => close()} isClosable={false}>
                    <div className="games_Feedback_popup">
                      <div className='popup_content'>
                        <img className="submit_icon" src={submitIcon} alt={"img"} />
                        <div className="title">
                          Thank you for your feedback
                        </div>
                        <div className="content">
                          Your comments has been successfully <br />submitted
                        </div>
                        <div className='btn-wrapper'>
                          {/* <Link to="/"> */}
                          <button className='btn btnMedium' onClick={() => close("continue")}>
                            CONTINUE
                          </button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </ModalBox>

                  <div className="btn-container">
                    <button
                      className="btn btnLarge feedback-submit-btn"
                      onClick={() => submitGamefeedBackOnclick()}
                    >
                      {props?.feedbacksSubmitLoading ? (
                        <ButtonLoader />
                      ) : (
                        "SUBMIT FEEDBACK"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    feedbackSubmitSuccess: state.gameFeedbackType.feedbackSubmitSuccess,
    feedbackError: state.gameFeedbackType.feedbackError,
    feedbacksSubmitLoading: state.gameFeedbackType.feedbacksSubmitLoading,
    feedBackErrorMessage: state.gameFeedbackType.feedBackErrorMessage,
  };
}
export default connect(mapStateToProps)(GamesFeedback);
