import React, { useState, useEffect } from "react";
import InviteFriendsToEmail from "./InviteFriendsToEmail";
// import ReferandearnBannerMobile from "../../assets/images/png/referandearn/refer_earn_mobile.png";
// import ReferandearnBannerWeb from "../../assets/images/png/referandearn/refer_earn_web.png";
// import referNEarnDesktop from "../../assets/images/png/telegram_link_img.png";
import ReferandearnBannerMobile from "../../assets/images/web/referandearn/refer_earn_mobile.webp";
import ReferandearnBannerWeb from "../../assets/images/web/referandearn/refer_earn_web.webp";
import referNEarnDesktop from "../../assets/images/web/referandearn/telegram_link_img.webp";
import ShareModal from "./ShareModal";
import InviteFriendsToNumber from "./InviteFriendsToNumber";
import Footer from "../common/Footer";
import { coins } from "../../config/appConfig";

function ReferAndEarn() {
  const [inviteType, setInviteType] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isAccordionActive1, setisAccordionActive1] = useState(false);
  const [isAccordionActive2, setisAccordionActive2] = useState(false);
  const [isAccordionActive3, setisAccordionActive3] = useState(false);
  function inviteHandler(type) {
    setInviteType(type);
  }

  useEffect(() => {
    if (
      inviteType?.toLowerCase() === "mobile" ||
      inviteType?.toLowerCase() === "email"
    ) {
      document.body.classList.add("SB-modal-open");
    } else if (isShareModalOpen) {
      document.body.classList.add("SB-modal-open");
    } else {
      document.body.classList.remove("SB-modal-open");
    }
  }, [inviteType, isShareModalOpen]);

  return (
    <React.Fragment>
      <Footer />
      {inviteType === "email" && (
        <InviteFriendsToEmail
          open={inviteType}
          close={() => setInviteType("")}
          closable={false}
        />
      )}
      {inviteType === "mobile" && (
        <InviteFriendsToNumber
          open={inviteType}
          close={() => setInviteType("")}
          closable={false}
        />
      )}
      {isShareModalOpen && (
        <ShareModal
          open={isShareModalOpen}
          close={() => setIsShareModalOpen(false)}
          closable={false}
        />
      )}
      <div className="gain_coins_wrapper">
        <div className="container">
          <div className="gain_coin_container">
            <div className="gain_coins_head">
              <div className="gain_coins_title">Refer and Earn</div>
            </div>
            <div className="referAndEarnbannerHeader">
              <div className="gain_coin_banner_desktop">
                <img alt="banner" src={ReferandearnBannerWeb}></img>
              </div>
              <div className="gain_coin_banner_mobile">
                <img alt="banner" src={ReferandearnBannerMobile}></img>
              </div>
            </div>
            <div className="referAndEarnContent">
              <div className="content_box">
                <div className="faq_Accordion_Content">
                  <div
                    className={
                      isAccordionActive1
                        ? "cric-accordion "
                        : "cric-accordion collapsed"
                    }
                  >
                    <div
                      className="cric-Accordion-header"
                      onClick={() => setisAccordionActive1(!isAccordionActive1)}
                    >
                      <div className="cric-sidePanelList-item-content">
                        <div className="content_title">
                          Our Refer and Earn programme has loads of benefits!
                        </div>
                      </div>
                    </div>
                    <div className="cric-accordion-content">
                      <div className="content_description">
                        <div className="content">
                          <p>
                            You can send your friends a unique URL so when they
                            register, they are tracked to you so you can be
                            rewarded. When your friends sign up for the first
                            time from your referral link, you will automatically
                            gain {coins?.referToFriend} additional coins for
                            every player registered (maximum of{" "}
                            {coins?.maxReferCoinsPerWeek} referrals per week).
                          </p>
                          {/* <p>Our Refer and Earn program is excellent!</p>
                          <p>
                            You can send your friends a unique URL that you can
                            then send to your friends so that when they register
                            they are tracked to you.
                          </p>
                          <p>
                            When your friends sign up for the first time
                            directly from your referral link, you will
                            automatically gain {coins?.referToFriend} additional coin per player
                            registered.
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faq_Accordion_Content">
                  <div
                    className={
                      isAccordionActive2
                        ? "cric-accordion "
                        : "cric-accordion collapsed"
                    }
                  >
                    <div
                      className="cric-Accordion-header"
                      onClick={() => setisAccordionActive2(!isAccordionActive2)}
                    >
                      <div className="cric-sidePanelList-item-content">
                        <div className="content_title">
                          How to Refer Friends
                        </div>
                      </div>
                    </div>
                    <div className="cric-accordion-content">
                      <div className="content_description">
                        <div className="content">
                          <span className="content_bold">
                            {" "}
                            <p>
                              When your friend signs up to our app (website) for the first
                              time using your referral link, you will
                              automatically gain{" "}
                              <span className="content_highlight">
                                {" "}
                                {coins?.referToFriend} Coins{" "}
                              </span>{" "}
                              for each user that plays.
                            </p>{" "}
                          </span>
                          <p>
                            There are 4 ways in which to refer a friend to the
                            game.
                          </p>
                          <ol>
                            <li>
                              1. Get your link by clicking{" "}
                              <button
                                className="link_button"
                                onClick={() => setIsShareModalOpen(true)}
                              >
                                HERE
                              </button>{" "}
                              which you can then share with friends on the
                              platform of your choice. If your friends register
                              to this link, they will be tagged to you.{" "}
                            </li>
                            <li>
                              2. You can automatically send an email to your
                              friends with your referral link by clicking{" "}
                              <button
                                className="link_button"
                                onClick={() => inviteHandler("email")}
                              >
                                HERE
                              </button>{" "}
                              and entering their email address.{" "}
                            </li>
                            <li>
                              3. You can automatically send an SMS to your
                              friends with your referral link by clicking{" "}
                              <button
                                className="link_button"
                                onClick={() => inviteHandler("mobile")}
                              >
                                HERE
                              </button>{" "}
                              and entering their mobile number.{" "}
                            </li>
                            <li>
                              4. You can share each week's selections and
                              encourage your friends to play by sharing the game
                              link that is exclusively yours{" "}
                            </li>
                          </ol>
                          <p>
                            This will generate a BTag for the player that they
                            can share with friends anywhere of their choice.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="faq_Accordion_Content">
                  <div
                    className={`cric-accordion ${
                      isAccordionActive3 ? "" : "collapsed"
                    }`}
                  >
                    <div
                      className="cric-Accordion-header"
                      onClick={() => setisAccordionActive3(!isAccordionActive3)}
                    >
                      <div className="cric-sidePanelList-item-content">
                        <div className="content_title">
                          How to Register and Login on PICK6?
                        </div>
                      </div>
                    </div>
                    <div className="cric-accordion-content">
                      <div className="content_description">
                        <div className="content">
                          <p>
                            If you are planning to sign-up on the Website, you can check out some important information below 
                          </p>
                          {/* <a href="https://telegram.me/cricctt" target={"_blank"} rel="noreferrer"><button className="telegram_refer_button"><img src={referNEarnDesktop} alt="Refer and Earn Banner" /></button></a> */}
                          <a
                            href="https://telegram.me/"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <button className="telegram_refer_button">
                              <img
                                src={referNEarnDesktop}
                                alt="Refer and Earn Banner"
                              />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReferAndEarn;
