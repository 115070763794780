import React from "react";
import vector from "../../assets/images/png/promotions/Vector.png";
import { useNavigate } from "react-router-dom";
import { data, gameBanner } from "./PromotionData";
import PromotionTopBanner from "./PromotionTopBanner";
import Footer from "../common/Footer";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../../services/helper/Index";
// import promotionBannerMobile from "../../assets/images/png/promotions/promotionBanner_mobile.png";
// import promotionBannerWeb from "../../assets/images/png/promotions/promotionBanner_web.png";
import promotionBannerMobile from "../../assets/images/web/promotions/promotion-bonus-banner-mobile.webp";
import promotionBannerWeb from "../../assets/images/web/promotions/promotion-bonus-banner-desktop.webp";

const Promotion = () => {
    const navigate = useNavigate();
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const navigateToGame = (link) => {
        const token = getFromLocalStorage("gk");
        if (token && isSignInSuccess) {
            navigate(link);
        } else {
            navigate("/signin", { state: { navigatedFrom: "Game" } });
        }
    };
    return (
        <React.Fragment>
            <Footer />
            <div className="container">
                <PromotionTopBanner
                    title={"Promotions"}
                    mobileBanner={promotionBannerMobile}
                    webBanner={promotionBannerWeb}
                />
                <div className="promotion_wrapper">
                    {
                        data && data?.map((item, ind) => {
                            return (
                                <div key={ind} className="promotion_container pointer" onClick={() => navigate(item.link)}>
                                    <div className="contain">
                                        <span><img src={item?.logo} alt={item?.heading} /></span>
                                        <div className="description">
                                            <div>{item?.heading}</div>
                                            <p>{item?.description}</p>
                                        </div>
                                    </div>
                                    <div><img src={vector} alt="vectorIcon" /></div>
                                </div>
                            )
                        })
                    }
                    <div className="game_wrapper">
                        <h4 className="game_heading">Game Promotions</h4>
                        <div className="banner_container">
                            {
                                gameBanner && gameBanner.map((banner, ind) => {
                                    return (
                                        <React.Fragment key={ind}>
                                            <div className="game_banner mobile_banner">
                                                <img className="pointer" onClick={() => navigateToGame(banner.link)} src={banner?.gameBannerMobile} alt="game_banner" />
                                            </div>
                                            <div className="game_banner web_banner">
                                                <img className="pointer" onClick={() => navigateToGame(banner.link)} src={banner?.gameBannerWeb} alt="game_banner" />
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Promotion