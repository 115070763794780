import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recoverPinResponseSuccess: "",
    recoverPinResponseError: "",
    updatePinResponseSuccess: "",
    updatePinResponseError: "",
    checkPinError: "",
    checkPinSuccess: "",
    email: "",
    otpError: false,
    otpSuccess: false,
    otpResponseMsg: "",
    errorMessage: "",
    loader: false,
};

export const recoverPinSlice = createSlice({
    name: "recoverPin",
    initialState,
    reducers: {
        setRecoverPinResponseSuccess: (state, action) => {
            state.recoverPinResponseSuccess = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setRecoverPinResponseError: (state, action) => {
            state.recoverPinResponseError = action.payload;
        },
        setUpdatePinResponseSuccess: (state, action) => {
            state.updatePinResponseSuccess = action.payload;
        },
        setUpdatePinResponseError: (state, action) => {
            state.updatePinResponseError = action.payload;
        },
        setCheckPinError: (state, action) => {
            state.checkPinError = action.payload;
        },
        setCheckPinSuccess: (state, action) => {
            state.checkPinSuccess = action.payload;
        },
        setOtpError: (state, action) => {
            state.otpError = action.payload;
        },
        setOtpSuccess: (state, action) => {
            state.otpSuccess = action.payload;
        },
        setOtpResponseMsg: (state, action) => {
            state.otpResponseMsg = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        }
    }
});

export const { setRecoverPinResponseSuccess,
    setRecoverPinResponseError,
    setUpdatePinResponseSuccess,
    setUpdatePinResponseError,
    setCheckPinError,
    setCheckPinSuccess,
    setEmail,
    setOtpError,
    setOtpSuccess,
    setOtpResponseMsg,
    setErrorMessage,
    setLoader } = recoverPinSlice.actions;

export default recoverPinSlice.reducer;