import React, { useEffect, useRef, useState } from "react";
// import userLogo from "../../assets/images/svg/profile_male.svg"
import profile from "../../assets/images/svg/profile-icon.svg";
import pen from "../../assets/images/svg/pen.svg";
import ModalBox from "../common/ModalBox";
import { BsCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import gainCoin from "../../assets/images/png/gaincoin.webp";
import myprofile_star_img1 from "../../assets/images/png/myprofile_star_img1.webp";
import myprofile_star_img2 from "../../assets/images/png/myprofile_star_img2 .webp";
import gainCoinbg1 from "../../assets/images/png/gaincoinbg1.png";
import gainCoinbg2 from "../../assets/images/png/gaincoinbg2.png";
import { useDispatch, useSelector } from "react-redux";
import {
  accoladesUser,
  getAllAvatarImages,
  isEighteenPlus,
  updateAvatar,
  updateUserDetails,
  validateUserName,
} from "./myProfileApi";
import {
  setErrorWhileSaving,
  setIsErrorWhileSaving,
  setIsProfileAvtarSuccess,
  setIsUsernameAvailable,
  setSuccessMessage,
  setUserNameAvailbleMessage,
  setDetailsUpdateModal,
  setVerificationModal,
  setFormToShowInModal,
} from "./myProfileSlice";
import { getReferredByUser } from "../signIn/signInApi";
import { dateOrdinal } from "../../services/helper/Index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarLogo from "../../assets/images/png/calender.png";
import UserNameUpdateModal from "./UserNameUpdateModal";
import ProfileUpdateModal from "./ProfileUpdateModal";
import VerificationModal from "./VerificationModal";
import Footer from "../common/Footer";
import { coins } from "../../config/appConfig";
import updateEdit from "../../assets/images/png/fixtures/editIcon.png"

function MyProfile() {
  const { userDetails, referredUser } = useSelector((state) => state.signIn);
  const {
    isUsernameAvailable,
    userNameAvailbleMessage,
    allAvatarImages,
    errorWhileSaving,
    isErrorWhileSaving,
    successMessage,
    accoladesDetails,
    isProfileAvtarSuccess,
    detailsUpdateModal,
    verificationModal,
  } = useSelector((state) => state.profile);
  const [isInputEnable, setIsInputEnable] = useState(true);
  const staticDatas = useSelector((state) => state.staticData);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [uModal, setUmodal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [dobError, setDobError] = useState("");
  const [dob, setDob] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [fullName, setFullName] = useState("");
  const dispatch = useDispatch();
  const [editIcon, setEditIcon] = useState(true);
  //const remainingBal = useSelector((state) => state?.coinsMeanPrizes?.prizeClaimed?.balance)
  const accolades =
    staticDatas?.staticData && staticDatas?.staticData?.accolades[0];
  let minUsernameLength = 3;
  let maxUsernamelength = 16;
  const [gender, setGender] = useState(userDetails?.gender);
  const profileCardHandler = () => {
    setOpen(true);
  };
  const [inputPersonalDetails, setinputPersonalDetails] = useState({});

  function close() {
    setOpen(false);
    dispatch(setIsProfileAvtarSuccess(false));
  }
  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setinputPersonalDetails({
      ...inputPersonalDetails,
      gender: event.target.value,
    });
    dispatch(setSuccessMessage(""));
    dispatch(setDetailsUpdateModal(false));
    dispatch(setErrorWhileSaving(""));
    dispatch(setUserNameAvailbleMessage(""));
  };

  const CustomInput = ({ value, onClick }) => (
    <input
    type="text"
    value={value}
    disabled={isInputEnable}
    onClick={onClick}
    placeholder="DD-MM-YYYY"
    />
    );
  const handleAdressChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "zip") {
      const re = /[0-9]+/g;
      if (
        re.test(e.nativeEvent.data) ||
        e.nativeEvent.inputType === "deleteContentBackward" ||
        e.nativeEvent.inputType === "deleteContentForward"
      ) {
        // dispatch(setPhoneNumber(e.target.value));
        setinputPersonalDetails((prevState) => ({
          ...prevState,
          address: {
            ...prevState.address,
            [name]: value,
          },
        }));
      }
    } else {
      setinputPersonalDetails((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
    dispatch(setIsErrorWhileSaving(false));
    dispatch(setSuccessMessage(""));
    dispatch(setDetailsUpdateModal(false));
    dispatch(setErrorWhileSaving(""));
    dispatch(setUserNameAvailbleMessage(""));
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setinputPersonalDetails((prevState) => ({
      ...prevState,
      fullName: value,
    }));
    setFullName(value);
    dispatch(setSuccessMessage(""));
    dispatch(setDetailsUpdateModal(false));
    dispatch(setErrorWhileSaving(""));
    dispatch(setUserNameAvailbleMessage(""));
  };

  const handleDobChange = (date) => {
    dispatch(setSuccessMessage(""));
    dispatch(setDetailsUpdateModal(false));
    dispatch(setErrorWhileSaving(""));
    dispatch(setUserNameAvailbleMessage(""));
    const value = getDate(date);
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    setDob(date);
    setinputPersonalDetails((prevState) => ({
      ...prevState,
      dob: value,
    }));
    if (value !== "") {
      if (!dateRegex.test(value)) {
        setDobError("Invalid date format (DD-MM-YYYY)");
        dispatch(setIsErrorWhileSaving(true));
      } else {
        setDobError("");
        dispatch(setIsErrorWhileSaving(false));
      }
    } else {
      setDobError("");
      dispatch(setIsErrorWhileSaving(false));
    }
  };

  const updateNewDate = (selected) => {
    const value = getDate(selected);
    setinputPersonalDetails((prevState) => ({
      ...prevState,
      dob: value,
    }));
  };

  useEffect(() => {
    var timestamp = Date.parse(userDetails?.dob);

    if (isNaN(timestamp) === false) {
      var dt = new Date(timestamp);
    } else {
      dt = null;
    }
    setDob(dt);
    const value = getDate(dt);

    setinputPersonalDetails((prevState) => ({
      ...prevState,
      address: { ...userDetails.address },
      dob: value,
      username: userDetails?.username,
      fullName: userDetails?.fullName,
    }));
    setGender(userDetails?.gender);
    setFullName(userDetails?.fullName || "");
  }, [userDetails]);

  let debounceTimer;

  const handleSearchChange = (event) => {
    dispatch(setIsUsernameAvailable(false));
    dispatch(setUserNameAvailbleMessage(""));
    setinputPersonalDetails((prevState) => ({
      ...prevState,
      username: event.target.value,
    }));
    const value = event.target.value;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setSearchQuery(value);
    }, 700);
  };

  useEffect(() => {
    if (searchQuery.trim().length === 0) {
      // setIsErrorUsername(true)
      dispatch(setIsUsernameAvailable(false));
      dispatch(setUserNameAvailbleMessage(""));
    } else if (searchQuery.length < minUsernameLength) {
      // setIsErrorUsername(true)
      dispatch(setIsUsernameAvailable(false));
      dispatch(
        setUserNameAvailbleMessage(
          "Usernname should be greater than 2 characters"
        )
      );
    }
    // else if (searchQuery.length > maxUsernamelength) {
    //     setIsErrorUsername(true)
    //     dispatch(setIsUsernameAvailable(false))
    //     dispatch(setUserNameAvailbleMessage("Usernname should be less than 16 characters"))
    // }
    else {
      // setIsErrorUsername(false)
      dispatch(validateUserName(searchQuery));
    }
  }, [searchQuery, dispatch, minUsernameLength, maxUsernamelength]);

  // useEffect(() => {
  //     if (searchQuery !== "") {
  //         dispatch(validateUserName(searchQuery))
  //     }
  // }, [searchQuery, dispatch]);

  const handleSelectImage = (icon) => {
    setSelected(icon);
    dispatch(updateAvatar(icon));
  };

  useEffect(() => {
    dispatch(getAllAvatarImages());
    dispatch(getReferredByUser());
  }, [dispatch]);

  const getDate = (UTCDate) => {
    if (UTCDate !== null && UTCDate !== "" && UTCDate !== undefined) {
      const dateObj = new Date(UTCDate);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear().toString();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    }
    return "";
  };

  const handleSave = (e, alluserDetails) => {
    e.preventDefault();
    if (alluserDetails?.dob.trim() === "") {
      setDobError("DOB is required");
      dispatch(setIsErrorWhileSaving(true));
    } else if (!isEighteenPlus(alluserDetails.dob)) {
      setDobError("DOB must be 18 or above");
      dispatch(setIsErrorWhileSaving(true));
    } else if (isErrorWhileSaving) {
      return;
    } else {
      dispatch(updateUserDetails(alluserDetails, setIsInputEnable));
      dispatch(setErrorWhileSaving(""));
      dispatch(setIsErrorWhileSaving(false));
      setEditIcon(true);
    }
  };
  
  const handleEditIcon = () => {
    setEditIcon(!editIcon);
    setIsInputEnable(!isInputEnable);
  }
  useEffect(() => {
    dispatch(accoladesUser());
    return () => {
      dispatch(setSuccessMessage(""));
      dispatch(setErrorWhileSaving(""));
      dispatch(setUserNameAvailbleMessage(""));
      setDobError("");
      dispatch(setIsErrorWhileSaving(false));
    };
  }, [dispatch]);

  const datepickerRef = useRef(null);

  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setOpen(true);
  }

  return (
    <>
      <Footer />
      {/* {open ? ( */}
        <ModalBox dOpen={open} dClose={close} isClosable={false} type={"non-scrollable"}>
          <div className="profile_icons_box profile">
            {allAvatarImages?.map((icon, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={`profice_icon_wrapper ${selected === icon ? "active" : ""
                      }`}
                    // data-borer={selected === icon.id ? "3px" : "0px"}
                    style={{
                      border: selected === icon ? "3px solid #2d5fd5" : "none",
                    }}
                    onClick={() => handleSelectImage(icon)}
                  >
                    {selected === icon ? (
                      <BsCheck className="icon_check" />
                    ) : null}
                    <img src={icon} className="profile_icon" alt={icon.name} />
                  </div>
                </>
              );
            })}
          </div>
          <div className="selectAvtar">
            {isProfileAvtarSuccess ? (
              <>
                <BsCheck className="icon_check" />
                <div>Your Avatar has been updated.</div>
              </>
            ) : (
              ""
            )}
          </div>
        </ModalBox>
      {/* ) : null} */}
      {
        <UserNameUpdateModal
          open={uModal}
          close={() => {
            setUmodal(false);
            dispatch(setIsUsernameAvailable(false));
            dispatch(setUserNameAvailbleMessage(""));
          }}
        />
      }
      {
        <ProfileUpdateModal
          open={detailsUpdateModal}
          close={() => dispatch(setDetailsUpdateModal(false))}
        />
      }
      {
        <VerificationModal
          open={verificationModal}
          close={() => dispatch(setVerificationModal(false))}
          content={"Enter Email"}
        />
      }
      <div className="container">
        <div className="my_profile_container">
          <div className="user_id">
            {referredUser?.username ? (
              <span className="badge primary">
                Ref - {referredUser?.username}
              </span>
            ) : null}
          </div>
          <div className="profle_section">
            <div className="profile_card">
              <div className="profile">
                {userDetails?.avatarPath ? (
                  <img
                    src={userDetails?.avatarPath}
                    className="pointer"
                    alt="coinIcon"
                    onClick={() => profileCardHandler()}
                  />
                ) : (
                  <img
                    src={profile}
                    alt="user profile"
                    className="pointer"
                    onClick={() => setOpen(true)}
                  />
                )}
                {/* <img src={userLogo} alt="user profile" onClick={() => setOpen(true)} /> */}
              </div>
              <div className="user_name">{userDetails?.username}</div>
              <div className="user_coins">GAME COINS: {userDetails?.coin}</div>
            </div>
            {!userDetails?.isUserNameUpdated ? (
              <div className="username_content_box">
                You have yet to choose a username. To do so click
                <button
                  onClick={() => {
                    setUmodal(true);
                  }}
                >
                  HERE
                </button>
              </div>
            ) : null}
            <div className="score_card_wrapper">
              <div className="score_card">
                <Link to="/favourited">
                  <p>
                    <span>Favourited</span> <span>{userDetails?.favourited?.length}</span>
                  </p>
                </Link>
                <div className="divider"></div>
                <Link to="/favourites">
                  <p>
                    <span>Favourites</span> <span>{userDetails?.favourites?.length}</span>
                  </p>
                </Link>
                <div className="divider"></div>
                <Link to="/playersReferred">
                  <p>
                    <span>Players Referred</span>{" "}
                    <span>{userDetails?.referred?.length}</span>
                  </p>
                </Link>
              </div>
            </div>

            {!userDetails?.emailVerified || !userDetails.mobileVerified ? (
              <div className="profile_banner">
                <div className="profile_banner_content">
                  <div className="profile_banner_coins">
                    <img alt={"icon"} src={myprofile_star_img1}></img>
                    <img alt={"icon"} src={gainCoin}></img>
                    <img alt={"icon"} src={myprofile_star_img2}></img>
                  </div>
                  <div className="title">Gain More Coins</div>
                  <div className="content">
                    Complete your contact details by adding your{" "}
                    {userDetails?.emailVerified
                      ? "phone number"
                      : "email address"}{" "}
                    to earn a further {coins?.profileGainMore} coins
                  </div>
                </div>
              </div>
            ) : null}

            <div className="accolades">
              <div className="title">Achievements</div>
              {accoladesDetails?.map((accolade, index) => {
                return (
                  <div className="board_box" key={index}>
                    <div className="bg_flash">
                      <div className="bg_dark">
                        <span>
                          {accolade.gameName === "pick6"
                            ? accolades?.pick6
                            : accolade.gameName === "matchpredictor"
                              ? accolades?.mp
                              : accolades?.vps}
                        </span>
                        {accolade.position && accolade.position > 0 ? (
                          <span>{dateOrdinal(accolade.position)}</span>
                        ) : (
                          <span>NA</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="personal_details_form_section">
              <div className="title d-flex justify-content-center">
                <span>Personal Details</span>
                <span
                  className="edit_text pointer"
                  // onClick={() => setIsInputEnable(false)}
                >
                  {" "}
                  <img className="pointer pen_icon" src={editIcon ? updateEdit : pen} alt="edit logo"
                    onClick={() => handleEditIcon()} />
                </span>
              </div>
              <div className="form_container_wrapper">
                <div className="form_container">
                  <div className="form_group">
                    <div className="form_control">
                      <label htmlFor="username">User Name</label>
                      <input
                        type="text"
                        placeholder="Username"
                        id="username"
                        name="username"
                        className="form_userDetails"
                        value={inputPersonalDetails?.username || ""}
                        maxLength={16}
                        onChange={(e) => handleSearchChange(e)}
                        disabled={
                          userDetails?.isUserNameUpdated || isInputEnable
                        }
                      />
                      {!uModal &&
                        (isUsernameAvailable ? (
                          <div className="success-msg">
                            {userNameAvailbleMessage}{" "}
                          </div>
                        ) : (
                          <div className="error-msg">
                            {userNameAvailbleMessage}{" "}
                          </div>
                        ))}
                    </div>
                    <div className="form_control ">
                      <label htmlFor="dob">Full Name</label>
                      <input
                        type="text"
                        placeholder="Full Name"
                        id="full name"
                        name="full name"
                        className="form_userDetails"
                        value={inputPersonalDetails?.fullName ? fullName : ""}
                        onChange={(e) => handleNameChange(e)}
                        disabled={isInputEnable}
                      />
                    </div>
                    {/* <div className="button_control">
                      <label htmlFor="email">Email</label>
                      <button
                        disabled={userDetails?.emailVerified || isInputEnable}
                        onClick={() => {
                          dispatch(setVerificationModal(true));
                          dispatch(setFormToShowInModal("emailInput"));
                        }}
                      >
                        {userDetails?.email || ""}
                      </button>
                    </div> */}
                    <div className="form_control">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        placeholder="Email"
                        id="email"
                        name="email"
                        value={userDetails?.email || ""}
                        readOnly
                        className="form_userDetails"
                        disabled={userDetails?.emailVerified || isInputEnable}
                        onClick={() => {
                          dispatch(setVerificationModal(true));
                          dispatch(setFormToShowInModal("emailInput"));
                        }}
                      />
                    </div>
                    {/* <div className="button_control">
                      <label htmlFor="mobile">Phone Number</label>
                      <button
                        disabled={userDetails?.mobileVerified || isInputEnable}
                        onClick={() => {
                          dispatch(setVerificationModal(true));
                          dispatch(setFormToShowInModal("phoneInput"));
                        }}
                      >
                        {userDetails?.mobile || ""}
                      </button>
                    </div> */}
                    <div className="form_control">
                      <label htmlFor="mobile">Phone Number</label>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        id="mobile"
                        name="mobile"
                        className="form_userDetails"
                        value={userDetails?.mobile || ""}
                        readOnly
                        disabled={userDetails?.mobileVerified || isInputEnable}
                        onClick={() => {
                          dispatch(setVerificationModal(true));
                          dispatch(setFormToShowInModal("phoneInput"));
                        }}
                      />
                    </div>

                    {/* <div className="form_control">
                                            <label htmlFor="mobile">Phone Number</label>
                                            <input type="number" placeholder="7876543210" id="mobile" name='mobile' value={""} onChange={(e) => handleNumberChange(e)} disabled={isInputEnable} />
                                            <div className="verify"><a href=''>Verify Phone Number</a></div>
                                        </div> */}
                    <div className="form_control ">
                      <label htmlFor="dob">
                        Date of birth (Must be over 18)
                      </label>
                      <div className="date_picker_container">
                        <DatePicker
                          selected={inputPersonalDetails.dob ? dob : null}
                          onSelect={(date) => updateNewDate(date)}
                          onChange={(date) => handleDobChange(date)}
                          placeholderText="DD-MM-YYYY"
                          disabled={isInputEnable}
                          dateFormat="dd-MM-yyyy"
                          ref={datepickerRef}
                          maxDate={new Date()}
                          showYearDropdown={true}
                          showMonthDropdown={true}
                          // customInput={<CustomInput />}
                        />
                        <span
                          role={"none"}
                          onClick={() => handleClickDatepickerIcon()}
                          onKeyDown={() => handleClickDatepickerIcon()}
                        >
                          <img
                            src={calendarLogo}
                            alt=""
                            height={20}
                            width={20}
                          />
                        </span>
                      </div>
                      {/* <input type={"date"} id="dob" name='dob' value={inputPersonalDetails?.dob ? dob : ""} onChange={(e) => handleDobChange(e)} disabled={isInputEnable} /> */}
                      <div className="error-msg error-msg-position">{dobError} </div>
                    </div>
                  </div>
                  <div className="radio_group">
                    <div className="radio_title">Gender</div>
                    <div className="item_group">
                      <div className="radio_item">
                        <input
                          type="radio"
                          name="gender"
                          id="Male"
                          value="Male"
                          checked={gender === "Male"}
                          onChange={handleGenderChange}
                          disabled={isInputEnable}
                        />
                        <label htmlFor="male">Male</label>
                      </div>
                      <div className="radio_item">
                        <input
                          type="radio"
                          name="gender"
                          id="Female"
                          value="Female"
                          checked={gender === "Female"}
                          onChange={handleGenderChange}
                          disabled={isInputEnable}
                        />
                        <label htmlFor="female">Female</label>
                      </div>
                      <div className="radio_item">
                        <input
                          type="radio"
                          name="gender"
                          id="Other"
                          value="Other"
                          checked={gender === "Other"}
                          onChange={handleGenderChange}
                          disabled={isInputEnable}
                        />
                        <label htmlFor="others">Others</label>
                      </div>
                    </div>
                  </div>
                  <div className="form_address_container">
                    <div className="address_title">
                      <span>Address</span>
                    </div>
                    <div className="form_group ">
                      <div className="form_control grow">
                        <label htmlFor="addressLine1">Address line</label>
                        <input
                          type="text"
                          placeholder="Address Line 1"
                          id="addressLine1"
                          name="addressLine1"
                          value={
                            inputPersonalDetails?.address?.addressLine1 || ""
                          }
                          onChange={(e) => handleAdressChange(e)}
                          disabled={isInputEnable}
                        />
                      </div>
                      {/* <div className="form_control">
                                                <label htmlFor="addressLine2">Address line_2</label>
                                                <input type="text" placeholder="address line 2" id="addressLine2" />
                                            </div> */}
                      <div className="form_control grow">
                        <label htmlFor="street">Street address</label>
                        <input
                          type="text"
                          placeholder="Street"
                          id="street"
                          name="streetAddress"
                          value={
                            inputPersonalDetails?.address?.streetAddress || ""
                          }
                          onChange={(e) => handleAdressChange(e)}
                          disabled={isInputEnable}
                        />
                      </div>
                      <div className="form_control">
                        <label htmlFor="state">State</label>
                        <input
                          type="text"
                          placeholder="State"
                          id="state"
                          name="state"
                          value={inputPersonalDetails?.address?.state || ""}
                          onChange={(e) => handleAdressChange(e)}
                          disabled={isInputEnable}
                        />
                      </div>
                      <div className="form_control">
                        <label htmlFor="zip">Zip</label>
                        <input
                          type="tel"
                          maxLength={6}
                          placeholder="Zip Code"
                          id="zip"
                          name="zip"
                          value={inputPersonalDetails?.address?.zip || ""}
                          onChange={(e) => handleAdressChange(e)}
                          disabled={isInputEnable}
                        />
                      </div>
                    </div>
                  </div>
                  {isErrorWhileSaving ? (
                    <div className="error-msg text-center">
                      {errorWhileSaving}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {!isErrorWhileSaving ? <div className='success-msg text-center'>{successMessage} </div> : ""} */}
                </div>
                <div className="form_button ">
                  <button
                    className={
                      isInputEnable ? "btn btnDisabled " : "btn pointer"
                    }
                    onClick={(e) => handleSave(e, inputPersonalDetails)}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProfile;
