import React from 'react'
import Games from "../common/HitSix"
import vitualCTTImg from "../../assets/images/png/vertual_ctt.png"
import vitualCTTLogo from "../../assets/images/png/Virtual_ctt.png"
import Coin from "../../assets/images/png/coin.png"
import liveIcon from "../../assets/images/png/liveIcon.png"
import { Link, useNavigate } from 'react-router-dom'
import { getFromLocalStorage } from '../../services/helper/Index'
import { useSelector } from 'react-redux'


function VirtualCTT(props) {
    const { vcttBannerText, vcttData } = props
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const navigate = useNavigate()
    const token = getFromLocalStorage("gk")

    const navigateToGame = () => {
        if (token && isSignInSuccess) {
            navigate("/game/vctt")
        } else {
            navigate("/signin")
        }
    }

    return (
        <div className="container">
            <Games title={"Virtual Catch The Trend"}>
                <div className="hit_six_card match_predictor_card">
                    <div className="top-section">
                        <img className='hidden' src={liveIcon} alt="lice ogo" />
                        {vcttData && vcttData?.betAmount ?
                            <div className='entry_button'><button>ENTRY = {vcttData?.betAmount} <img src={Coin} alt='coin' /></button></div>
                            :
                            <div className='entry_button'><button>FREE ENTRY</button></div>
                        }
                    </div>
                    <div className='game_title'>
                        <img src={vitualCTTLogo} alt="hit6 logo" />
                    </div>
                    <div className="middle-section">
                        <div className="left">
                            <div dangerouslySetInnerHTML={{ __html: vcttBannerText?.bannerText }}></div>
                            {/* <div>
                                <span>PREDICT, PLAY AND WIN!</span> <br />
                                <p className="active">
                                    Bolster your coin balance daily by playing our fun Virtual T20 game where you can climb the leaderboard by predicting the number of Ws, 4s, 6s and 0s in each over.
                                </p>
                            </div> */}
                            {/* <p>Entries by 3:00pm on Friday 24th March 2023 and T&Cs apply</p> */}
                        </div>
                        <div className="right">
                            <p><span className='prize_detail'>WIN UPTO</span><span className='prize_amount'><img src={Coin} alt='coin'></img> {vcttData?.jackpotPrize}</span>
                                <span className="vctt_banner">Every 15 minutes</span> </p>
                        </div>
                    </div>
                    <div className="bottom-section">
                        {/* <Link to={"/game/vctt"}><button className="btn btnLarge">Play Now</button></Link> */}
                        <button className="btn btnLarge" onClick={() => navigateToGame()}>Play Now</button>
                        <p><Link to="/coinsmeanprizes">Coins Mean Prizes </Link></p>
                    </div>
                </div>
            </Games>
            {/* <LeaderBoard /> */}
        </div>
    )
}

export default VirtualCTT
