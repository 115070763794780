import React, { useEffect, useState } from "react";
import BackButton from "../common/BackButton";
import TopMenu from "./TopMenu";
import { HomePreviewForm, AwayPreviewForm } from "./PreviewForm";
import lineupTeamLogo from "../../assets/images/png/fixtures/lineup.png";
import argentinosTeamLogo from "../../assets/images/png/fixtures/argentinos.png";
import whatsapp from "../../assets/images/social/watsapp.svg";
import facebook from "../../assets/images/social/facebook.svg";
import twitter from "../../assets/images/social/twitter.png";
import instagram from "../../assets/images/png/instagram.png";
import {
    Instagram_Link,
    Facebook_Link,
    Twitter_Link,
    Telegram_Link,
} from "../../config/appConfig";
import Footer from "../common/Footer";
import LiveScore from "./LiveScore";
import { useDispatch, useSelector } from "react-redux";
import { getAwayPreviewInfo, getHomePreviewInfo } from "./cl_PreviewSlice";
import { getMatchInfo } from "./cl_LineupsSlice";
import { useParams } from "react-router";
import moment from "moment";
import NoData from "../noData";
import { DotsContentLoader } from "../common/Loader";

const Preview = () => {
    const dispatch = useDispatch();
    const param = useParams();
    const [homeTeamType, setHomeTeamType] = useState("all");
    const [awayTeamType, setAwayTeamType] = useState("all");
    const { isHomeLoading, isAwayLoading, homePreviewData, awayPreviewData } = useSelector((state) => state.preview);
    const lineupsData = useSelector((state) => state.lineups);
    const [overGoals, setOverGoals] = useState("");
    const [bothTeamScored, setBothTeamScored] = useState("");
    const [showContent, setShowContent] = useState(false);

    const calculatePercentage = (specificNumber, total) => {
        const value1 = parseInt(specificNumber);
        const value2 = parseInt(total);
        const calculatedPercentage = (value1 / value2) * 100;
        return calculatedPercentage + "%"
    };

    useEffect(() => {
        const homeTeamId = lineupsData?.topLiveScoreBanner?.homeTeamId;
        if (homeTeamId) {
            dispatch(getHomePreviewInfo(homeTeamId, homeTeamType))
        }
    }, [homeTeamType, lineupsData?.topLiveScoreBanner]);

    useEffect(() => {
        const awayTeamId = lineupsData?.topLiveScoreBanner?.awayTeamId;
        if (awayTeamId) {
            dispatch(getAwayPreviewInfo(awayTeamId, awayTeamType))
        }
    }, [awayTeamType, lineupsData?.topLiveScoreBanner]);

    const handleTeamType = (e, type) => {
        if (type === "home") {
            setHomeTeamType(e.target.value);
        } else if (type === "away") {
            setAwayTeamType(e.target.value);
        }
    }
    useEffect(() => {
        dispatch(getMatchInfo(param.matchId, "headtohead"));
    }, []);

    useEffect(() => {
        const sumValues = [];
        lineupsData?.headToHeadData?.headtohead?.matches?.forEach(obj => {
            const sum = +obj.homeTeamScore + parseInt(obj.awayTeamScore);
            if (sum > 2.5) {
                sumValues.push(sum);
            }
        });
        setOverGoals(sumValues?.length)

        const bothTeamValue = lineupsData?.headToHeadData?.headtohead?.matches?.filter((item) => item.homeTeamScore > 0 && item.awayTeamScore > 0);
        setBothTeamScored(bothTeamValue?.length)
    }, [lineupsData?.headToHeadData?.headtohead?.matches]);

    const calculateTotalGoals = (matches, teamId) => {
        let totalGoals = 0;
        if (matches) {
            for (const match of matches) {
                if (match?.homeTeamId === teamId) {
                    totalGoals += parseInt(match?.homeTeamScore);
                } else if (match?.awayTeamId === teamId) {
                    totalGoals += parseInt(match?.awayTeamScore);
                }
            }
            return totalGoals;
        }
        return 0
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowContent(true);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                {/* <LiveScore /> */}
                <TopMenu />
                {
                    lineupsData?.isLoading ?
                        <DotsContentLoader />
                        :
                        <React.Fragment>
                            {
                                lineupsData?.teamDetails?.isPreviewAvailable ?
                                    <div className="score_preview_wrapper">
                                        {/* <p>Velez Sarsfield v Argentinos Juniors Live Commentr...</p> */}
                                        <HomePreviewForm
                                            data={homePreviewData}
                                            logo={lineupsData?.topLiveScoreBanner?.homeTeamLogo}
                                            handleTeamType={handleTeamType}
                                            isLoading={isHomeLoading}
                                        />
                                        <AwayPreviewForm
                                            data={awayPreviewData}
                                            logo={lineupsData?.topLiveScoreBanner?.awayTeamLogo}
                                            handleTeamType={handleTeamType}
                                            isLoading={isAwayLoading}
                                        />

                                        <div className="head_to_head">
                                            {
                                                lineupsData?.topLiveScoreBanner?.homeTeamLogo ?
                                                    <img width={"24px"} height={"22px"} src={lineupsData?.topLiveScoreBanner?.homeTeamLogo

                                                    } alt="" />
                                                    :
                                                    <h4>{lineupsData?.headToHeadData?.homeTeamName}</h4>
                                            }
                                            <h4>HEAD TO HEAD</h4>
                                            {
                                                lineupsData?.topLiveScoreBanner?.awayTeamLogo ?
                                                    <img width={"24px"} height={"22px"} src={lineupsData?.topLiveScoreBanner?.awayTeamLogo} alt="" />
                                                    :
                                                    <h4>{lineupsData?.headToHeadData?.awayTeamName}</h4>
                                            }
                                        </div>
                                        <div className="match_count">{lineupsData?.headToHeadData?.headtohead?.matches?.length} Matches</div>
                                        <div className="preview_range">
                                            <div className="range_score">
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totalhomewin}</div>
                                                <div>TOTAL WINS</div>
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totalawaywin}</div>
                                            </div>
                                            <div className="range_contain">
                                                <div className="range_value" style={{
                                                    width:
                                                        calculatePercentage(+lineupsData?.headToHeadData?.headtohead?.totalhomewin,
                                                            +lineupsData?.headToHeadData?.headtohead?.totalawaywin + +lineupsData?.headToHeadData?.headtohead?.totalhomewin)
                                                }}>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="preview_range">
                                            <div className="range_score">
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totalhomewin}</div>
                                                <div>HOME WINS</div>
                                                <div>{lineupsData?.headToHeadData?.headtohead?.matches?.length}</div>
                                            </div>
                                            <div className="range_contain">
                                                <div className="range_value" style={{
                                                    width:
                                                        calculatePercentage(lineupsData?.headToHeadData?.headtohead?.totalhomewin, lineupsData?.headToHeadData?.headtohead?.matches?.length)
                                                }}>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="preview_range">
                                            <div className="range_score">
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totalawaywin}</div>
                                                <div>AWAY WINS</div>
                                                <div>{lineupsData?.headToHeadData?.headtohead?.matches?.length}</div>
                                            </div>
                                            <div className="range_contain">
                                                <div className="range_value" style={{
                                                    width:
                                                        calculatePercentage(lineupsData?.headToHeadData?.headtohead?.totalawaywin, lineupsData?.headToHeadData?.headtohead?.matches?.length)
                                                }}>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="last_matches">
                                            <div>Last {lineupsData?.headToHeadData?.headtohead?.matches?.length} Matches</div>
                                            <div className="status_block">
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totalhomewin} <br /> WINS</div>
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totaldraw} <br /> DRAW</div>
                                                <div>{lineupsData?.headToHeadData?.headtohead?.totalawaywin} <br /> WINS</div>
                                            </div>
                                            {
                                                lineupsData?.headToHeadData?.headtohead?.matches?.map((item, ind) => {
                                                    return (
                                                        <div key={ind} className="game_details">
                                                            <div className="game_date">{moment(item.datestart).format('L')}</div>
                                                            <div className="team_data">
                                                                <div className="home_team_name">{item.homeTeamName}</div>
                                                                <div className="team_scores">
                                                                <div>{item.homeTeamScore}</div>
                                                                <div className="HAscores">-</div>
                                                                <div>{item.awayTeamScore}</div>
                                                                </div>
                                                                <div className="away_team_name">{item.awayTeamName}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="preview_range">
                                            <div className="range_score">
                                                <div>{calculateTotalGoals(lineupsData?.headToHeadData?.headtohead?.matches, lineupsData?.topLiveScoreBanner?.homeTeamId)}</div>
                                                <div>GOALS SCORED</div>
                                                <div>{calculateTotalGoals(lineupsData?.headToHeadData?.headtohead?.matches, lineupsData?.topLiveScoreBanner?.awayTeamId)}</div>
                                            </div>
                                            <div className="range_contain">
                                                <div className="range_value" style={{
                                                    width: calculatePercentage(calculateTotalGoals(lineupsData?.headToHeadData?.headtohead?.matches, lineupsData?.topLiveScoreBanner?.homeTeamId),
                                                        calculateTotalGoals(lineupsData?.headToHeadData?.headtohead?.matches, lineupsData?.topLiveScoreBanner?.homeTeamId)
                                                        +
                                                        calculateTotalGoals(lineupsData?.headToHeadData?.headtohead?.matches, lineupsData?.topLiveScoreBanner?.awayTeamId)
                                                    )
                                                }}></div>
                                            </div>
                                        </div>
                                        <div className="game_goals_details">
                                            <div className="game_score">
                                                <div>Games Over 2.5 Goals</div>
                                                <div>{overGoals}/{lineupsData?.headToHeadData?.headtohead?.matches?.length}</div>
                                            </div>
                                            <div className="game_score">
                                                <div>Both Teams Scored</div>
                                                <div>{bothTeamScored}/{lineupsData?.headToHeadData?.headtohead?.matches?.length}</div>
                                            </div>
                                        </div>
                                        {/* <div className="head_to_head">
                                <img width={"22px"} height={"22px"} src={lineupTeamLogo} alt="" />
                                <h4>TEAM COMPARISION</h4>
                                <img width={"22px"} height={"22px"} src={argentinosTeamLogo} alt="" />
                            </div>
                            <div className="preview_range">
                                <div className="range_score">
                                    <div>19</div>
                                    <div>GOALS PLAYED</div>
                                    <div>20</div>
                                </div>
                                <div className="range_contain">
                                    <div className="range_value" style={{ width: "50%" }}></div>
                                </div>
                            </div>
                            <div className="preview_range">
                                <div className="range_score">
                                    <div>18</div>
                                    <div>GOALS</div>
                                    <div>23</div>
                                </div>
                                <div className="range_contain">
                                    <div className="range_value" style={{ width: "40%" }}></div>
                                </div>
                            </div>
                            <div className="preview_range">
                                <div className="range_score">
                                    <div>60</div>
                                    <div>SHOTS IN TARGET</div>
                                    <div>94</div>
                                </div>
                                <div className="range_contain">
                                    <div className="range_value" style={{ width: "30%" }}></div>
                                </div>
                            </div>
                            <div className="preview_range">
                                <div className="range_score">
                                    <div>42.0%</div>
                                    <div>SHOTING ACCURACY</div>
                                    <div>43.33%</div>
                                </div>
                                <div className="range_contain">
                                    <div className="range_value" style={{ width: "30%" }}></div>
                                </div>
                            </div>
                            <div className="preview_range">
                                <div className="range_score">
                                    <div>12.6%</div>
                                    <div>CONVERSION RATE</div>
                                    <div>10.6%</div>
                                </div>
                                <div className="range_contain">
                                    <div className="range_value" style={{ width: "30%" }}></div>
                                </div>
                            </div>
                            <div className="preview_range">
                                <div className="range_score">
                                    <div>11</div>
                                    <div>ASSISTS</div>
                                    <div>14</div>
                                </div>
                                <div className="range_contain">
                                    <div className="range_value" style={{ width: "30%" }}></div>
                                </div>
                            </div> */}
                                        <div className="social_lineups_icon">
                                            {/* <div className="icon_Container">
                                <a href={Telegram_Link} target="_blank" rel="noreferrer">
                                    <img src={whatsapp} alt="icon" />
                                </a>
                            </div> */}
                                            <div className="icon_Container">
                                                <a href={Facebook_Link} target="_blank" rel="noreferrer">
                                                    <img src={facebook} alt="icon" />
                                                </a>
                                            </div>
                                            <div className="icon_Container">
                                                <a href={Twitter_Link} target="_blank" rel="noreferrer">
                                                    <img src={twitter} alt="icon" />
                                                </a>
                                            </div>
                                            {/* <div className="icon_Container">
                                <a href={Instagram_Link} target="_blank" rel="noreferrer">
                                    <img src={instagram} alt="icon" />
                                </a>
                            </div> */}
                                        </div>
                                    </div>
                                    :
                                    showContent && <NoData message={"No Data Available"} />
                            }
                        </React.Fragment>
                }
            </div>
        </React.Fragment >
    )
}
export default Preview;