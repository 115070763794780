import { endpoints } from "../../api/ApiEndPoints";
import {
    setRecoverPinResponseSuccess,
    setRecoverPinResponseError,
    setUpdatePinResponseSuccess,
    setUpdatePinResponseError,
    setCheckPinSuccess,
    setCheckPinError,
    setEmail,
    setOtpError,
    setOtpResponseMsg,
    setLoader,
    setOtpSuccess
} from "./recoverPinSlice";
import { postRequest, putRequest } from "../../services/apiCaller";
import { cleanObject, getFromLocalStorage, setToLocalStorage } from "../../services/helper/Index";

const pinRegX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;
const emailRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const alpharegx = /[a-zA-Z~!@#$%^&*()`'"?/.:;=]/;

export const validatePin = (pin) => {
    if (pinRegX.test(pin)) return true
    return false
}
export const validateEmail = (email) => {
    if (emailRegX.test(email)) return true
    return false
}
export const validateNum = (num) => {
    if (num.length <= 10) return true
    return false
}
export const checkIsPhone = (userId) => {
    if (alpharegx.test(userId)) {
        return false
    } else return true
}
export const recoverPin = (email, countryCode, navigate) => (dispatch) => {
    const username = validateEmail(email) ? "email" : "mobile"
    let recoverPinUrl = endpoints.recoverPin;
    let body = {
        [username]: email,
        countryCode: countryCode
    };

    postRequest(recoverPinUrl, cleanObject({ ...body }))
        .then((res) => {
            if (res.status === 200) {
                dispatch(setRecoverPinResponseSuccess(res.data.message));
                dispatch(setEmail(email));
                setToLocalStorage("rp", res?.data?.token)
                dispatch(setRecoverPinResponseError(""));
                if (username === "email") {
                    navigate("/recover-pin/confirmation");
                } else {
                    navigate("/recover-pin/otpVerify")
                }
            }
            else {
                dispatch(setRecoverPinResponseError(res?.response?.data?.message));
                dispatch(setRecoverPinResponseSuccess(""));
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            dispatch(setRecoverPinResponseSuccess(""))
            dispatch(setRecoverPinResponseError(errorResponse.data.message));
        })
}

export const setPin = (code, navigate, password) => (dispatch) => {
    let setPinUrl = endpoints.checkPin;
    let body = {
        code: code
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("rp")}` }
    postRequest(setPinUrl, { ...body }, { headers })
        .then((res) => {
            if (res.status === 200) {
                // dispatch(setCheckPinSuccess(res.data.message));
                dispatch(setCheckPinError(""));
                dispatch(updatePin(password, navigate));
            } else {
                dispatch(setCheckPinError(res?.response?.data?.message));
                dispatch(setCheckPinSuccess(""))
            }
        })
}
export const resetOtpVerification = (code, navigate) => (dispatch) => {
    const verifyOtp = endpoints.checkPin;
    const stringCode = code.join("")
    const otp = parseInt(stringCode)
    const payload = {
        code: otp
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("rp")}` }
    dispatch(setLoader(true));
    postRequest(verifyOtp, payload, { headers })
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setOtpResponseMsg(res?.response?.data?.message));
                dispatch(setOtpError(false));
                dispatch(setLoader(false));
                dispatch(setOtpSuccess(true))
                navigate("/recover-pin/setPin");
            } else if (res?.response?.status === 500) {
                dispatch(setOtpResponseMsg(res?.response?.data?.message));
                dispatch(setOtpSuccess(false));
                dispatch(setOtpError(true));
                dispatch(setLoader(false));
            } else {
                dispatch(setLoader(false));
                dispatch(setOtpResponseMsg("Session Expired!!!"));
            }
        })
        .catch((err) => {
            dispatch(setOtpSuccess(false));
            dispatch(setLoader(false));
            dispatch(setOtpResponseMsg(err?.response?.data?.message))
            dispatch(setOtpError(true));
        })
}
export const updatePin = (password, navigate) => (dispatch) => {
    let updatePinUrl = endpoints.updatePin;
    let body = {
        password: password
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("rp")}` }
    postRequest(updatePinUrl, { ...body }, { headers })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUpdatePinResponseSuccess(res.data.message));
                dispatch(setUpdatePinResponseError(""));
                navigate("/recover-pin/success");
            }
            else {
                dispatch(setUpdatePinResponseError("Session Expired!!!"));
                dispatch(setUpdatePinResponseSuccess(""));
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            dispatch(setUpdatePinResponseSuccess(""))
            dispatch(setUpdatePinResponseError(errorResponse.data.message));
        })
}