import React, { useEffect, useState } from "react";
import TopMenu from "./TopMenu";
import BackButton from "../common/BackButton";
import webMap from "../../assets/images/png/fixtures/score_map_web.png";
import webMobile from "../../assets/images/png/fixtures/score_map_mobile.png";
import matchMap from "../../assets/images/png/fixtures/match_map.png";
import subOff from "../../assets/images/png/fixtures/down_range.svg";
import yellowCard from "../../assets/images/png/fixtures/yellow_card.svg";
import awayTeamLogo from "../../assets/images/png/fixtures/away_logo.svg";
import ownGoalLogo from "../../assets/images/png/fixtures/own goal.png";
import homeTeamLogo from "../../assets/images/png/fixtures/home_logo.svg";
import penaltyMissed from "../../assets/images/png/fixtures/penalty_missed.svg";
import penaltyScored from "../../assets/images/png/fixtures/penalty_scored.svg";
import goal from "../../assets/images/png/fixtures/football.svg";
import assist from "../../assets/images/png/fixtures/assist.png";
import secondYellow from "../../assets/images/png/fixtures/second yellow.png";
import subOn from "../../assets/images/png/fixtures/up_range.svg";
import misconduct from "../../assets/images/png/fixtures/misconduct.svg";
import injury from "../../assets/images/png/fixtures/injury.svg";
import redCard from "../../assets/images/png/fixtures/red_card.svg";
import whatsapp from "../../assets/images/social/watsapp.svg";
import facebook from "../../assets/images/social/facebook.svg";
import twitter from "../../assets/images/social/twitter.png";
import instagram from "../../assets/images/png/instagram.png";
import argentinos from "../../assets/images/png/fixtures/argentinos.png";
import {
    Instagram_Link,
    Facebook_Link,
    Twitter_Link,
    Telegram_Link,
} from "../../config/appConfig";
import dummyData from "./dummy.json";
import { getMatchInfo, setLineupsData } from "./cl_LineupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { DotsContentLoader } from "../common/Loader";
import LiveScore from "./LiveScore";
import { useParams } from "react-router";
import NoData from "../noData";

const Lineups = () => {
    const dispatch = useDispatch();
    const { isLoading, lineupsData, topLiveScoreBanner, teamDetails } = useSelector((state) => state.lineups);
    const [isActiveMap, setIsActiveMap] = useState(true);
    const [homeFormattedData, setHomeFormattedData] = useState([]);
    const [awayFormattedData, setAwayFormattedData] = useState([]);
    const [showContent, setShowContent] = useState(false);
    const param = useParams();

    const keysDetails = [
        { "firstLogo": goal, "firstName": "Goal", "secondLogo": penaltyScored, "secondName": "Penalty scored" },
        { "firstLogo": ownGoalLogo, "firstName": "Own Goal", "secondLogo": penaltyMissed, "secondName": "Penalty missed" },
        { "firstLogo": assist, "firstName": "Assist", "secondLogo": yellowCard, "secondName": "Yellow card" },
        { "firstLogo": secondYellow, "firstName": "Second yellow", "secondLogo": redCard, "secondName": "Red card" },
        { "firstLogo": subOn, "firstName": "Sub on", "secondLogo": subOff, "secondName": "Sub off" },
        { "firstLogo": injury, "firstName": "Injury", "secondLogo": misconduct, "secondName": "Misconduct suspension" },
    ];

    useEffect(() => {
        dispatch(getMatchInfo(param.matchId, "lineup"));
    }, []);

    function generatePlayerGroups(teamFormation, players) {
        const formation = teamFormation ? teamFormation.split("-") : ""
        const playerGroups = [];
        let positionIndex = 1;

        if (formation && players) {
            for (const numPlayers of formation) {
                const group = [];
                for (let i = 0; i < numPlayers; i++) {
                    group.push(players[positionIndex]);
                    positionIndex++;
                }
                playerGroups.push(group);
            }
            return playerGroups;
        }
        return []
    }

    useEffect(() => {
        window.addEventListener(
            "fixture-lineups",
            (e) => {
                dispatch(setLineupsData(e?.detail?.data));
                // console.log("lineups-------: ", e?.detail?.data);
            },
            false
        );
    }, [])

    useEffect(() => {
        if (lineupsData) {
            const homeFormation = lineupsData?.home?.lineup?.formation
            const awayFormation = lineupsData?.away?.lineup?.formation
            const homePlayerData = generatePlayerGroups(homeFormation, lineupsData?.home?.lineup?.player)
            setHomeFormattedData(homePlayerData)
            const awayPlayerData = generatePlayerGroups(awayFormation, lineupsData?.away?.lineup?.player)
            setAwayFormattedData(awayPlayerData?.reverse())
        } else {
            setHomeFormattedData([])
            setAwayFormattedData([])
        }
    }, [lineupsData])

    const handleClick = (type) => {
        if (type === "velez") {
            setIsActiveMap(true);
        } else if (type === "argentions") {
            setIsActiveMap(false);
        };
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowContent(true);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                {/* <LiveScore /> */}
                <TopMenu />
                <div className="score_lineups_wrapper">
                    {/* <p>Velez Sarsfield v Argentinos Juniors Live Commentr...</p> */}
                    {
                        isLoading ?
                            <DotsContentLoader />
                            :
                            <React.Fragment>
                                {
                                    teamDetails?.isLineupsAvailable ?
                                        <React.Fragment>
                                            <div className="home_formation">
                                                <img
                                                    src={topLiveScoreBanner?.homeTeamLogo}
                                                    width={"20px"}
                                                    height={"14px"}
                                                    alt="team_logo"
                                                />
                                                {lineupsData && lineupsData?.home?.lineup?.formation}
                                            </div>
                                            <div className="match_map">
                                                <div className="players">
                                                    <div className="home_team_contain">
                                                        {
                                                            lineupsData?.home?.lineup?.player.length > 0 &&
                                                            <div className="first_player">
                                                                <div>
                                                                    <div className="home_score_count">{lineupsData?.home?.lineup?.player[0]?.shirtnumber}</div>
                                                                    <div className="team_name">{lineupsData?.home?.lineup?.player[0]?.pname}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            homeFormattedData.map((player, ind) => {
                                                                return (
                                                                    <div className="home_players" key={ind}>
                                                                        {
                                                                            player.map((item, ind) => {
                                                                                return (
                                                                                    <div key={ind}>
                                                                                        <div className="home_score_count">{item?.shirtnumber}</div>
                                                                                        <div className="team_name">{item?.pname}</div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="away_team_contain">
                                                        {
                                                            awayFormattedData.map((player, ind) => {
                                                                return (
                                                                    <div className="away_players" key={ind}>
                                                                        {
                                                                            player.map((item, ind) => {
                                                                                return (
                                                                                    <div key={ind}>
                                                                                        <div className="away_score_count">{item?.shirtnumber}</div>
                                                                                        <div className="team_name">{item?.pname}</div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            lineupsData?.away?.lineup?.player.length > 0 &&
                                                            <div className="last_player">
                                                                <div>
                                                                    <div className="away_score_count">{lineupsData?.away?.lineup?.player[0]?.shirtnumber}</div>
                                                                    <div className="team_name">{lineupsData?.away?.lineup?.player[0]?.pname}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="away_formation">
                                                {lineupsData && lineupsData?.away?.lineup?.formation}
                                                <img
                                                    src={topLiveScoreBanner?.awayTeamLogo}
                                                    width={"20px"}
                                                    height={"14px"}
                                                    alt="team_logo"
                                                /></div>
                                            <div className="lineups_button_grp">
                                                <button onClick={() => handleClick("velez")} className={isActiveMap ? "active" : ""}>{teamDetails?.homeTeamName}</button>
                                                <button onClick={() => handleClick("argentions")} className={!isActiveMap ? "active" : ""}>{teamDetails?.awayTeamName}</button>
                                            </div>

                                            <div className="score_table_wrapper">
                                                <div className="head_block">
                                                    {
                                                        isActiveMap ?
                                                            <img
                                                                src={topLiveScoreBanner?.homeTeamLogo}
                                                                width={"22px"}
                                                                height={"18px"}
                                                                alt="team_logo"
                                                            />
                                                            :
                                                            <img
                                                                src={topLiveScoreBanner?.awayTeamLogo}
                                                                width={"22px"}
                                                                height={"18px"}
                                                                alt="team_logo"
                                                            />
                                                    }
                                                    <span>Line UP</span>
                                                </div>
                                                {
                                                    isActiveMap ?
                                                        <React.Fragment>
                                                            {
                                                                lineupsData?.home?.lineup?.player.map((team, ind) => {
                                                                    return (
                                                                        <div className="body_block" key={ind}>
                                                                            <div className="left_block">
                                                                                <img
                                                                                    width={"20px"}
                                                                                    height={"20px"}
                                                                                    src={homeTeamLogo}
                                                                                    alt="team_logo"
                                                                                />
                                                                                <div className="team_score">{team.shirtnumber}</div>
                                                                                <div className="team_name">{team.pname}</div>
                                                                            </div>
                                                                            <div className="right_block">
                                                                                {
                                                                                    team?.subOn &&
                                                                                    <div className="logos">
                                                                                        <img src={subOn} alt="" />
                                                                                        <span>{team?.subOnTime && `${team?.subOnTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.subOff &&
                                                                                    <div className="logos">
                                                                                        <img src={subOff} alt="" />
                                                                                        <span>{team?.subOffTime && `${team?.subOffTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.redCard &&
                                                                                    <div className="logos">
                                                                                        <img src={redCard} alt="" />
                                                                                        <span>{team?.redCardTime && `${team?.redCardTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.yellowCard &&
                                                                                    <div className="logos">
                                                                                        <img src={yellowCard} alt="" />
                                                                                        <span>{team?.yellowCardTime && `${team?.yellowCardTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.goal &&
                                                                                    <div className="logos">
                                                                                        <img src={goal} alt="" />
                                                                                        <span>{team?.goalTime && `${team?.goalTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.injury &&
                                                                                    <div className="logos">
                                                                                        <img src={injury} alt="" />
                                                                                        <span>{team?.injuryTime && `${team?.injuryTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            {
                                                                lineupsData?.away?.lineup?.player.map((team, ind) => {
                                                                    return (
                                                                        <div className="body_block" key={ind}>
                                                                            <div className="left_block">
                                                                                <img
                                                                                    width={"20px"}
                                                                                    height={"20px"}
                                                                                    src={awayTeamLogo}
                                                                                    alt="team_logo"
                                                                                />
                                                                                <div className="team_score">{team.shirtnumber}</div>
                                                                                <div className="team_name">{team.pname}</div>
                                                                            </div>
                                                                            <div className="right_block">
                                                                                {
                                                                                    team?.subOn &&
                                                                                    <div className="logos">
                                                                                        <img src={subOn} alt="" />
                                                                                        <span>{team?.subOnTime && `${team?.subOnTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.subOff &&
                                                                                    <div className="logos">
                                                                                        <img src={subOff} alt="" />
                                                                                        <span>{team?.subOffTime && `${team?.subOffTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.redCard &&
                                                                                    <div className="logos">
                                                                                        <img src={redCard} alt="" />
                                                                                        <span>{team?.redCardTime && `${team?.redCardTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.yellowCard &&
                                                                                    <div className="logos">
                                                                                        <img src={yellowCard} alt="" />
                                                                                        <span>{team?.yellowCardTime && `${team?.yellowCardTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.goal &&
                                                                                    <div className="logos">
                                                                                        <img src={goal} alt="" />
                                                                                        <span>{team?.goalTime && `${team?.goalTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    team?.injury &&
                                                                                    <div className="logos">
                                                                                        <img src={injury} alt="" />
                                                                                        <span>{team?.injuryTime && `${team?.injuryTime}'`}</span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </React.Fragment>
                                                }
                                            </div>

                                            <div className="manager_wrapper">
                                                <div className="manager_heading">Manager</div>
                                                {
                                                    isActiveMap ?
                                                        <React.Fragment>
                                                            {
                                                                lineupsData?.home?.manager.length > 0 ?
                                                                    <React.Fragment>
                                                                        {
                                                                            lineupsData?.home?.manager.map((home, ind) => {
                                                                                return (
                                                                                    <div className="team_contain" key={ind}>
                                                                                        <img
                                                                                            width={"20px"}
                                                                                            height={"20px"}
                                                                                            src={awayTeamLogo}
                                                                                            alt="team_logo"
                                                                                        />
                                                                                        <p>{home.name}</p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <div className="team_contain">
                                                                        <div>No Manager Found</div>
                                                                    </div>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            {
                                                                lineupsData?.away?.manager.length > 0 ?
                                                                    <React.Fragment>
                                                                        {
                                                                            lineupsData?.away?.manager.map((away, ind) => {
                                                                                return (
                                                                                    <div className="team_contain" key={ind}>
                                                                                        <img
                                                                                            width={"20px"}
                                                                                            height={"20px"}
                                                                                            src={homeTeamLogo}
                                                                                            alt="team_logo"
                                                                                        />
                                                                                        <p>{away.name}</p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <div className="team_contain">
                                                                        <div>No Manager Found</div>
                                                                    </div>
                                                            }
                                                        </React.Fragment>
                                                }
                                            </div>
                                            <div className="manager_wrapper">
                                                <div className="manager_heading">Injuries and Suspensions</div>
                                                <div className="no_players">
                                                    <div>No Sidelined Players</div>
                                                </div>
                                            </div>

                                            <div className="key_wrapper">
                                                <div className="key">Key</div>
                                                {
                                                    keysDetails.map((item, ind) => {
                                                        return (
                                                            <div className="key_details" key={ind}>
                                                                <div><img src={item.firstLogo} alt="" /><span>{item.firstName}</span></div>
                                                                <div><img src={item.secondLogo} alt="" /><span>{item.secondName}</span></div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="social_lineups_icon">
                                                {/* <div className="icon_Container">
                        <a href={Telegram_Link} target="_blank" rel="noreferrer">
                            <img src={whatsapp} alt="icon" />
                        </a>
                    </div> */}
                                                <div className="icon_Container">
                                                    <a href={Facebook_Link} target="_blank" rel="noreferrer">
                                                        <img src={facebook} alt="icon" />
                                                    </a>
                                                </div>
                                                <div className="icon_Container">
                                                    <a href={Twitter_Link} target="_blank" rel="noreferrer">
                                                        <img src={twitter} alt="icon" />
                                                    </a>
                                                </div>
                                                {/* <div className="icon_Container">
                        <a href={Instagram_Link} target="_blank" rel="noreferrer">
                            <img src={instagram} alt="icon" />
                        </a>
                    </div> */}
                                            </div>
                                        </React.Fragment>
                                        :
                                        showContent && <NoData message={"No Data Available"} />
                                }
                            </React.Fragment>
                    }

                </div>
            </div>
        </React.Fragment>
    )
}
export default Lineups;