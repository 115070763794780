import React from "react";
import GamesBanner from "../common/GamesBanner";
import pick6Logo from "../../assets/images/banners/pick6GameBanner.png";
import mpLogo from "../../assets/images/banners/mpGameBanner.png";
import VPSLogo from "../../assets/images/banners/psoGameBanner.png";
import Footer from "../common/Footer";

const GameScreen = () => {
  return (
    <React.Fragment>
      <Footer title={"games"} />
      <div className="container">
        <div className="games_banner_body">
          <GamesBanner title="pick6" logo={pick6Logo} link="/game/pick6" />
          <GamesBanner
            title="match predictor"
            logo={mpLogo}
            link="/game/matchpredictor"
          />
          <GamesBanner
            title="penalty shootout"
            logo={VPSLogo}
            link="/game/penaltyshootout"
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default GameScreen;
