import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { setHomeBannerImages, setIsBannerLoading } from "./superBannerSlice";

export const gethomeBannerImages = () => (dispatch) => {
    dispatch(setIsBannerLoading(true))
    let homeBannerUrl = endpoints.beforeLogin_homePage_bannerImages;

    getRequest(homeBannerUrl, true)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setHomeBannerImages(res.data))
            }
            dispatch(setIsBannerLoading(false))
        })
        .catch((err) => {
            dispatch(setIsBannerLoading(false))
        })
};