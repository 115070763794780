import React, { useEffect, useRef, useState } from "react";
import VELlogo from "../../assets/images/png/VELLogo.png";
import ARGlogo from "../../assets/images/png/ARGLogo.png";
import BackButton from "../common/BackButton";
import Footer from "../common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMatches, setCidId, setMatchId } from "../fixturesResult/cl_MatchesSlice";
import { getMatchInfo, setMatchInfoData, setTopLiveScoreBanner } from "./cl_LineupsSlice";
import { getFromLocalStorage } from "../../services/helper/Index";
import cl_formatDate from "../../helper/cl_formatDate";
import socket, { subscribeFixtureDetailsInfo, unSubscribeCompetitionInfo, unSubscribeMatchInfo } from "../../services/socket/scores";
import TopMenu from "./TopMenu";

const LiveScore = ({ screen }) => {
    const param = useParams();
    const dispatch = useDispatch();
    const fixtureData = useSelector((state) => state.matches);
    const lineupsData = useSelector((state) => state.lineups);
    const date = getFromLocalStorage("dt");
    const lineupsDataRef = useRef(lineupsData);
    const [infoDetails, setInfoDetials] = useState({});


    useEffect(() => {
        setInfoDetials(lineupsData?.matchInfoData);
    }, [lineupsData?.matchInfoData]);



    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    const matchDate = formatDate(date);

    useEffect(() => {
        const filterFixture = fixtureData?.matchesData?.filter((live) => live.cid === param.cid);
        const filterMatch = filterFixture?.[0]?.competition_matches?.filter((match) => match.mid === param.matchId);
        if (filterMatch) {
            dispatch(setTopLiveScoreBanner(filterMatch?.[0]));
        }
    }, [fixtureData]);

    useEffect(() => {
        if (lineupsData) {
            lineupsDataRef.current = lineupsData;
        }
    }, [lineupsData])

    useEffect(() => {
        dispatch(setMatchId(param.matchId));
        dispatch(setCidId(param.cid));
        // if (fixtureData?.matchesData?.length === 0) {
        dispatch(getMatches(matchDate, ""));
        subscribeFixtureDetailsInfo(param.matchId);

        return () => unSubscribeMatchInfo(param.matchId);
        // }
    }, []);

    let kickOff = {};
    function getKickOff(fixture) {
        if (fixture?.eventKickoff) {
            kickOff = cl_formatDate(fixture?.eventKickoff);
        }
    }

    useEffect(() => {
        window.addEventListener(
            "fixture-matchInfo",
            (e) => {
                const scoresDetails = updatedScoreHomeData(e?.detail.data);

                if (scoresDetails) {
                    const filterFixture = scoresDetails?.filter((live) => live.cid == param.cid);
                    const filterMatch = filterFixture?.[0]?.competition_matches?.filter((match) => match.mid == param.matchId);

                    if (filterMatch) {
                        dispatch(setTopLiveScoreBanner(filterMatch?.[0]));
                    }
                }

                setInfoDetials((prevInfoDetails) => ({
                    ...prevInfoDetails,
                    ...(e?.detail.data && e?.detail.data.hasOwnProperty('homeTeamScore') && { homeTeamScore: e?.detail.data?.homeTeamScore }),
                    ...(e?.detail.data && e?.detail.data.hasOwnProperty('awayTeamScore') && { awayTeamScore: e?.detail.data?.awayTeamScore }),
                }));

            },
            false
        );
    }, [fixtureData?.matchesData])

    const updatedScoreHomeData = (socketData) => {
        const oldData = fixtureData.matchesData
        const newData = socketData || {}
        
        const updatedData = oldData?.map((competition) => {
            const updatedMatches = competition.competition_matches.map((match) => {
                if (match.cid == newData.cid && match.mid == newData.mid) {
                    return {
                        ...match,
                        ...newData,
                    };
                }
                return match;
            });

            return {
                ...competition,
                competition_matches: updatedMatches,
            };
        });

        return updatedData;
    }

    return (
        <React.Fragment>
            {/* <div className="back_button">
                <BackButton />
            </div> */}
            <div className="container">
                <div className="footer_containt">
                    <Footer title={"scores"} />
                </div>
                <div className="live_score_wrapper">
                    <div className="live_time">{lineupsData?.topLiveScoreBanner?.status == 3 ? lineupsData?.topLiveScoreBanner?.time + "'" : ""}</div>
                    <div className="home_team">
                        {lineupsData?.topLiveScoreBanner?.homeTeamLogo && <img src={lineupsData?.topLiveScoreBanner?.homeTeamLogo} alt="" />}
                        <div>{lineupsData?.topLiveScoreBanner?.homeTeamAbbr}</div>
                    </div>
                    {
                        lineupsData?.topLiveScoreBanner?.status == "1" ?
                            <div className="kickOff_date">
                                {getKickOff(lineupsData?.topLiveScoreBanner)}
                                <div>{kickOff?.date}</div>
                                <div>{kickOff?.time}</div>
                            </div>
                            :
                            <div className="score_line">
                                <div className="home_score">{infoDetails?.homeTeamScore}</div>
                                <div>-</div>
                                <div className="away_score">{infoDetails?.awayTeamScore}</div>
                            </div>
                    }
                    <div className="away_team">
                        {lineupsData?.topLiveScoreBanner?.awayTeamLogo && <img src={lineupsData?.topLiveScoreBanner?.awayTeamLogo} alt="" />}
                        <div>{lineupsData?.topLiveScoreBanner?.awayTeamAbbr}</div>
                        {/* <div className="team_title">L Heredia(77)</div> */}
                    </div>
                </div>
            </div>
            {/* <TopMenu /> */}
            {screen}
        </React.Fragment>
    )
}
export default LiveScore;