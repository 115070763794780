import React from 'react';
import ModalBox from '../common/ModalBox';
import UserNameSuccessDialog from './UserNameSuccessDialog';
import UserNameDialog from './UserNameDialog';
import { useSelector } from 'react-redux';

export default function UserNameUpdateModal(props) {
    const { userNameUpdate } = useSelector(state => state.profile)
    const modalToShow = userNameUpdate ? <UserNameSuccessDialog close={props.close} content={"Your Username Is Successfully Updated"} /> : <UserNameDialog />

    return (
        <ModalBox dOpen={props.open} dClose={props.close} isClosable={false} small={userNameUpdate ? true : false} type={"non-scrollable"}>
            {modalToShow}
        </ModalBox>
    )
}