import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";

const initialState = {
    isLoading: false,
    pointsTable: [],
};

export const PointsTableSlice = createSlice({
    name: "pointstable",
    initialState,
    reducers: {
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setPointsTable(state, action){
            state.pointsTable = action.payload
        },
    }
})

export const {setIsLoading, setPointsTable} = PointsTableSlice.actions

export const getPointsTable = (cid) => (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setPointsTable([]));
    let url = endpoints.pointsTable + `?cid=${cid}`
    getRequest(url, false)
    .then((res) => {
        if(res.status === 200){
            dispatch(setPointsTable(res?.data?.data))
            dispatch(setIsLoading(false))
        }
    })
    .catch(err => {
        console.log("Error in fetching Points Table");
        dispatch(setIsLoading(false));
    })
}

export default PointsTableSlice.reducer