import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { subscribeUserBalance } from "../../services/socket";
import { setUserDetails } from "../signIn/signSlice";
import { setCommonError, setHitSixBannerText, setHitSixData, setMpData, setVcttBannerText, setVcttData, setPickSixData, setPenaltyShootoutData, } from "./homeSlice";

function getObject(objName, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (objName in arr[i]) {
            return arr[i][objName];
        }
    }
    return null;
}


export const getuserDetails = () => (dispatch) => {
    let userUrl = endpoints?.userInfo;
    getRequest(userUrl, true)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUserDetails(res.data));
                subscribeUserBalance(res?.data?.id);
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};
export const getleaderBoardDetailsBeforeLogin = () => (dispatch) => {
    let beforeLoginLeaderBoardUrl = endpoints?.beforeLoginLeaderBoard;
    dispatch(setHitSixData({}))
    dispatch(setMpData({}))
    getRequest(beforeLoginLeaderBoardUrl, true)
        .then((res) => {
            if (res.status === 200) {

                const hitSixData = getObject("hit6", res.data)
                dispatch(setHitSixData(hitSixData))

                const mpData = getObject("matchpredictor", res.data)
                dispatch(setMpData(mpData));

                const penaltyShooutData = getObject("penaltyShootout", res.data)
                dispatch(setPenaltyShootoutData(penaltyShooutData));

                const pickSixData = getObject("pick6", res.data)
                dispatch(setPickSixData(pickSixData));

                const vcttData = getObject("vctt", res.data)
                dispatch(setVcttData(vcttData))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};
export const getleaderBoardDetailsAfterLogin = () => (dispatch) => {
    let beforeLoginLeaderBoardUrl = endpoints.afterLoginLeaderBoard;
    dispatch(setHitSixData({}))
    dispatch(setMpData({}))
    getRequest(beforeLoginLeaderBoardUrl, true)
        .then((res) => {
           
            if (res.status === 200) {
                const hitSixData = getObject("hit6", res.data)
                dispatch(setHitSixData(hitSixData))

                const mpData = getObject("matchpredictor", res.data)
                dispatch(setMpData(mpData));

                const penaltyShooutData = getObject("penaltyShootout", res.data)
                dispatch(setPenaltyShootoutData(penaltyShooutData));

                const pickSixData = getObject("pick6", res.data)
                dispatch(setPickSixData(pickSixData));

                const vcttData = getObject("vctt", res.data)
                dispatch(setVcttData(vcttData))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};
// export const getBannerText = () => (dispatch) => {
//     let gameTextUrl = endpoints.staticData;
//     dispatch(setHitSixData({}))
//     dispatch(setMpData({}))
//     getRequest(gameTextUrl, false)
//         .then((res) => {
//             if (res.status === 200) {
//                 const hitSixData = getObject("hit6", [res?.data?.homePageBanner[0]])
//                 dispatch(setHitSixBannerText(hitSixData))

//                 const mpData = getObject("mp", [res?.data?.homePageBanner[0]])
//                 dispatch(setMpBannerText(mpData))

//                 const vcttData = getObject("vctt", [res?.data?.homePageBanner[0]])
//                 dispatch(setVcttBannerText(vcttData))
//             }
//         })
//         .catch((err) => {
//             dispatch(setCommonError(err))
//         })
// };

