import React, { useEffect, useState } from 'react'
import BackButton from '../common/BackButton';
import facebook_img from '../../assets/images/svg/facebook_img.svg';
import twitter_img from '../../assets/images/svg/twitter_img.svg';
import copylink_img from '../../assets/images/svg/link_img.svg';
import football_img from '../../assets/images/png/newsimages/Jude_Fichado@2xdsk.webp';
import dummyHighlight from "../../assets/images/png/fixtures/placeholder-img.jpg"
import { news_Article_Data } from './Helper';
import Footer from '../common/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DotsContentLoader } from '../common/Loader';
import { getNewsData } from './newsApi';
import NoData from '../noData';
import { noNewsDataMessage } from "../../config/appConfig";

const NewsArticle = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const [newsArticle, setNewsArticle] = useState([]);

    const {
        newsData,
        isLoading
    } = useSelector((state) => state.news);

    useEffect(() => {
        dispatch(getNewsData());
    }, []);

    useEffect(() => {
        if (newsData?.categories) {
            newsData?.categories?.forEach(news => {
                const filterData = news?.data?.filter((item) => item?.newsID === param.newsId);
                if (filterData?.[0]) {
                    setNewsArticle(filterData?.[0]);
                }
            });
        }
        if (newsData?.featured) {
            const filterFeaturedData = newsData?.featured?.filter((item) => item?.newsID === param.newsId);
            if (filterFeaturedData?.[0]) {
                setNewsArticle(filterFeaturedData?.[0]);
            }
        }
    }, [newsData]);

    return (
        <React.Fragment>
            <div className='article_main_Page'>
                <div className='article_footer'>
                    {/* footer page */}
                    <Footer title={"news"} />
                </div>
                <div className='container'>
                    <div className='article_content'>
                        <div className='article_header'>
                            <BackButton />
                        </div>
                        {isLoading ?
                            <DotsContentLoader />
                            :
                            <React.Fragment>
                                {
                                    newsData && newsData?.length !== 0 ?
                                        <div className='article_body'>
                                            <p className='article-title'>{newsArticle?.newsTitle}</p>
                                            <div className='article-image'>
                                                <img src={newsArticle?.newsImages?.mainImage} 
                                                alt='football_img'
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = dummyHighlight;
                                                  }}
                                                />
                                                {/* <div className='article-links'>
                                        <div className='socialmediaIcons'>
                                            <a className='socialmedia_links'><img src={facebook_img} alt='facebook_img' /></a>
                                            <a className='socialmedia_links'><img src={twitter_img} alt='facebook_img' /></a>
                                            <a className='socialmedia_links'><img src={copylink_img} alt='facebook_img' /></a>
                                            <div className='tags'><span className='tags_list'><a>Football Kenya</a></span></div>
                                        </div>
                                        <div className='article_published'>
                                            <span className='article_date'>28/06/23</span>
                                            <span className='article_time'>8.15</span>
                                        </div>
                                    </div> */}
                                            </div>
                                            <div
                                                className="artical_newsText"
                                                dangerouslySetInnerHTML={{
                                                    __html: newsArticle?.newsText,
                                                }}
                                            ></div>
                                            {/* <p>All summer long, GOAL's Mark Doyle will be handing out grades for the biggest transfers from around the world</p>
                                        <p>For some football fans, the off-season is the part of the calendar that they look forward to the most, as that can only mean one thing: It's time for transfers! Over the next weeks and months, the biggest names will be linked with the biggest clubs, with speculation set to go into overdrive.</p>
                                        <p>We all know that some transfers turn out well for all parties involved, but there are plenty where at least one of the clubs, or even the player, are left wondering what might have been had they made a different decision while at the negotiating table.</p>
                                        <p>is here, then, to ensure you know who did the best out of every massive deal before the ink is even dry on the contracts. Throughout the summer window, we will be grading every done deal as it happens, letting you track the big winners - and losers - of transfer season.</p> */}
                                            {/* <div className='article_tags'>
                                    <div className='tags_title'>
                                        TAGS
                                    </div>
                                    <div className='tags'>
                                        <span className='tags_list'><a>Football Kenya</a></span>
                                        <span className='tags_list'><a>Premier league</a></span>
                                        <span className='tags_list'><a>Serie A</a></span>
                                        <span className='tags_list'><a>Transfers News</a></span>
                                        <span className='tags_list'><a>Champions League</a></span>
                                        <span className='tags_list'><a>World News</a></span>
                                        <span className='tags_list'><a>Other Sport</a></span>
                                    </div>
                                </div> */}
                                            {/* <div className='related_article'>
                                    <div className='related_article_title'>
                                        RELATED ARTICLES
                                    </div>
                                    <div className='related_article_content'>
                                        {news_Article_Data && news_Article_Data?.map((data) => {
                                            return (
                                                <div className='related_article_card' key={data?.id}>
                                                    <div className='card_image'>
                                                        <img src={data?.image1} alt='image1' />
                                                    </div>
                                                    <div className='card_body'>
                                                        <p>{data?.title}</p>
                                                        <div className='card_info'>
                                                            <div className='tags'>
                                                                <span className="tags_list"><a>{data?.tags}</a></span>
                                                            </div>
                                                            <span className='article_published_time'>
                                                                {data?.time}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div> */}
                                        </div>
                                        :
                                        <React.Fragment>
                                            {!isLoading && newsData?.length === 0 && <NoData message={noNewsDataMessage} />}
                                        </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NewsArticle