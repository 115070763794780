import { endpoints } from "../../api/ApiEndPoints";
import {
  setIsSignInSuccess,
  setSignErrorMessage,
  setSignInSuccessMessage,
  setUserDetails,
  setSignInLoading,
  setReferredUser,
} from "./signSlice";
import {
  cleanObject,
  removeFromLocalStorage,
  setToLocalStorage,
} from "../../services/helper/Index";
import { putRequest, getRequest } from "../../services/apiCaller";
// import { gethomeBannerImages } from "../superBanner/superBannerApi";
import { setHomeBannerImages } from "../superBanner/superBannerSlice";
import { subscribeUserBalance } from "../../services/socket";

const emailRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const numRegX = /^[6-9]{1}[0-9]{9}$/;
const alpharegx = /[a-zA-Z~!@#$%^&*()`'"?/.:;=]/;

export const validateEmail = (email) => {
  if (emailRegX.test(email)) return true;
  return false;
};

export const checkIsPhone = (userId) => {
  if (alpharegx.test(userId)) {
    return false;
  } else return true;
};

export const validateNum = (num) => {
  if (num.length <= 10) return true;
  return false;
};

export const getuserSignin =
  (email, countryCode, password, navigate, navigated) => (dispatch) => {
    const username = validateEmail(email) ? "email" : "mobile";
    // let countryCode = username === "mobile" ? +countryCode : 0;
    let loginUrl = endpoints?.login;
    let body = {
      [username]: email,
      countryCode: "" + countryCode,
      password: password,
    };
    dispatch(setSignInLoading(true));
    putRequest(loginUrl, cleanObject({ ...body }))
      .then((res) => {
        if (res.status === 200) {
          dispatch(setSignInLoading(false));
          dispatch(setIsSignInSuccess(true));
          dispatch(setHomeBannerImages([]));
          dispatch(setSignInSuccessMessage(res.data.message));
          dispatch(setUserDetails(res?.data?.user));
          setToLocalStorage("gk", res?.data?.token);
          dispatch(setSignErrorMessage(""));
          if (navigated === "Game") {
            navigate("/promotion");
          } else {
            navigate("/");
          }
          removeFromLocalStorage("ak");
          subscribeUserBalance(res?.data?.user?.id);
        } else {
          dispatch(setSignInLoading(false));
          dispatch(setIsSignInSuccess(false));
          dispatch(setSignErrorMessage(res.response.data.message));
          dispatch(setSignInSuccessMessage(""));
        }
      })
      .catch((err) => {
        dispatch(setSignInLoading(false));
        const errorResponse = err.response;
        dispatch(setIsSignInSuccess(false));
        dispatch(setSignErrorMessage(errorResponse.data.message));
        dispatch(setSignInSuccessMessage(""));
      });
  };

export const getReferredByUser = () => (dispatch) => {
  let referredByUserURL = endpoints.referredByUser;
  getRequest(referredByUserURL, true)
    .then((res) => {
      if (res?.status === 200) {
        dispatch(setReferredUser(res.data.invitedByPlayer));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
