import React, { useEffect } from "react";
import Accordion from "../common/Accordion";
// import PPMobile from "../../assets/images/png/privacypolicy/PrivacyPolicy_Banner_Mobile.png";
// import PPDesktop from "../../assets/images/web/PrivacyPolicy_Banner_Mobile.webp";
import PPMobile from "../../assets/images/web/privacypolicy/PrivacyPolicy_Banner_Mobile.webp";
import PPDesktop from "../../assets/images/web/privacypolicy/PrivacyPolicy_Banner_Desktop.webp";
import Footer from "../common/Footer";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function AccOneContent() {
    return (
      <div className="accordion_content">
        <div className="pp_accordion_sectionOne">
          <div className="content">
            <p>
            We never lose sight of the fact that your personal information is your personal information. Your privacy is important both to you and to us and we make the following promise to you:
            </p>
            <p>
            We are committed to protecting your privacy. We believe in using your personal information to make things simpler and better for you. We will always keep your personal information safe. We'll be clear and open with you about why we collect your personal information and how we use it. Where you have choices or rights, we'll explain them to you and respect your wishes.
            </p>
          </div>
        </div>
      </div>
    );
  }

  function AccTwoContent() {
    return (
      <div className="accordion_content">
        <div className="pp_accordion_sectionOne">
          <div className="content">
            <p>
            If you have any concerns about how we handle your personal information, you can contact our Data Protection Officer at: { " " }
            <a href='mailto:support@digibinge.com' class='direct-link'><u>support@digibinge.com</u></a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  function AccThirdContent() {
    return (
      <div className="accordion_content">
        <div className="pp_accordion_sectionOne">
          <div className="content">
            <div className="sub-title">Information we get from you.</div>
            <p>
            We collect personal information about you whenever you use our services. Some of it is information you give us directly when, for example, you register for an account or play one of our games
            </p>
          </div>
        </div>
        <div className="pp_accordion_sectionOne">
          <div className="content">
            <div className="sub-title">
            Information we get from your device.
            </div>
            <p>
            We collect information from the devices you use to receive our content, products and services. This includes but is not limited to the following: your IP address (a number that identifies a specific device on the internet and is required for your device to communicate with websites), hardware model, operating system and version, software, preferred language, serial numbers, device motion information, mobile network information and location data.
            </p>
            <p>
            We also collect server logs, which include information such as dates and times of access, the app (website) features or pages you view, app (website) crashes and other system activity, and the third-party site or service you were using immediately before visiting our site.
            </p>
            <p>
            Every iOS device is assigned an Identifier for Advertisers (IDFA) which enables app (website) owners and advertisers to track campaigns and deliver personalised advertising. If you are an iOS user and have opted in to tracking your IDFA (Identifier for Advertisers) is used to deliver you personalized advertising that is relevant to you (based on information we have about you, including browsing history, transactional information, demographic information and behavioral information, predictive information we create about you in each case in relation to our services and advertising and information about what other people with similar interests, demographics and behaviors are looking at) and is used for attribution and analytics purposes to tell us when and how you have interacted with advertisements, including those that have been placed on a third party site or app, and so we can assess the effectiveness of our campaigns. You can opt out of this at any time by visiting your mobile settings (by going to Settings&gt;Privacy&gt; Advertising and turning on Limit Ad Tracking).
            </p>
          </div>
        </div>
      </div>
    );
  }

  function AccFourthContent() {
    return (
      <div className="accordion_content">
        <div className="pp_accordion_sectionOne">
          <div className="content">
            <p>
            As you would expect, we use your personal information to provide you with our products and services, respond to queries, contact you about games played and provide you with the best possible level of customer service. We use technical information about your device; which includes, but is not limited to, operating system, browser version and location. As well as ensuring we can abide by our legal and regulatory requirements, this helps to present you with the correct version of our website or app, to keep it functioning securely and correctly and to check that users are using our services in licensed countries.
            </p>
            <p>
            Like most organizations, to provide our products and services we share your information with external organizations working on our behalf.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="pp_gain_coins_wrapper">
        <Footer />
        <div className="container">
          <div className="pp_gain_coin_container">
            <div className="gain_coins_head">
              <div className="gain_coins_title">Privacy Policy</div>
            </div>
            <div className="gain_coin_bannerHeader">
              <img
                className="pp_gain_coin_banner_desktop"
                alt="banner"
                src={PPDesktop}
              ></img>
              <img
                className="pp_gain_coin_banner_mobile"
                alt="banner"
                src={PPMobile}
              ></img>
            </div>
            <div className="HowTogain_Accordion">
              <div className="bg_flash">
                <Accordion
                  title={"Our Privacy Promise"}
                  content={<AccOneContent />}
                />
                <Accordion
                  title={"Our Data Protection Officer"}
                  content={<AccTwoContent />}
                />
                <Accordion
                  title={"Collecting information about you"}
                  content={<AccThirdContent />}
                />
                <Accordion
                  title={"Providing our products and services"}
                  content={<AccFourthContent />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
