import React from 'react';
import ModalBox from '../common/ModalBox';
import UserNameSuccessDialog from './UserNameSuccessDialog';

export default function ProfileUpdateModal(props) {
    return (
        <ModalBox dOpen={props.open} dClose={props.close} isClosable={true} small={true} type={"non-scrollable"}>
            <UserNameSuccessDialog close={props.close} content={"Your details are successfully updated"} />
        </ModalBox>
    )
}