import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUsernameAvailable: false,
    userNameAvailbleMessage: "",
    allAvatarImages: [],
    isErrorWhileSaving: false,
    errorWhileSaving: "",
    successMessage: "",
    accoladesDetails: [],
    isProfileAvtarSuccess: false,
    userNameUpdate: false,
    detailsUpdateModal: false,
    verificationModal: false,
    formToShowInModal: "",
    emailUpdateOtpSent: false,
    emailUpdateOtpSentLoading: false,
    emailErrorMessage: "",
    emailUpdateOtpVerify: false,
    emailUpdateOtpVerifyLoading: false,
    emailOtpErrorMessage: "",
    phoneUpdateGenerateOtp: false,
    phoneUpdateGenerateOtpLoading: false,
    phoneErrorMessage: "",
    phoneUpdateVerifyOtp: false,
    phoneUpdateVerifyOtpLoading: false,
    phoneOtpErrormessage: "",
    resendOTP: false,
    profileCountryCode: ""
};

export const profilSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setIsUsernameAvailable: (state, action) => {
            state.isUsernameAvailable = action.payload;
        },
        setUserNameAvailbleMessage: (state, action) => {
            state.userNameAvailbleMessage = action.payload;
        },
        setAllAvatarImages: (state, action) => {
            state.allAvatarImages = action.payload;
        },
        setIsErrorWhileSaving: (state, action) => {
            state.isErrorWhileSaving = action.payload;
        },
        setErrorWhileSaving: (state, action) => {
            state.errorWhileSaving = action.payload;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setAccoladesDetails: (state, action) => {
            state.accoladesDetails = action.payload;
        },
        setIsProfileAvtarSuccess: (state, action) => {
            state.isProfileAvtarSuccess = action.payload;
        },
        setUserNameUpdate: (state, action) => {
            state.userNameUpdate = action.payload;
        },
        setDetailsUpdateModal: (state, action) => {
            state.detailsUpdateModal = action.payload;
        },
        setVerificationModal: (state, action) => {
            state.verificationModal = action.payload
        },
        setFormToShowInModal: (state, action) => {
            state.formToShowInModal = action.payload
        },
        setEmailUpdateOtpSent: (state, action) => {
            state.emailUpdateOtpSent = action.payload
        },
        setEmailUpdateOtpSentLoading: (state, action) => {
            state.emailUpdateOtpSentLoading = action.payload
        },
        setEmailErrorMessage: (state, action) => {
            state.emailErrorMessage = action.payload
        },
        setEmailUpdateOtpVerify: (state, action) => {
            state.emailUpdateOtpVerify = action.payload
        },
        setEmailUpdateOtpVerifyLoading: (state, action) => {
            state.emailUpdateOtpVerifyLoading = action.payload
        },
        setEmailOtpErrorMessage: (state, action) => {
            state.emailOtpErrorMessage = action.payload
        },
        setPhoneUpdateGenerateOtp: (state, action) => {
            state.phoneUpdateGenerateOtp = action.payload
        },
        setPhoneUpdateGenerateOtpLoading: (state, action) => {
            state.phoneUpdateGenerateOtpLoading = action.payload
        },
        setPhoneErrorMessage: (state, action) => {
            state.phoneErrorMessage = action.payload
        },
        setPhoneUpdateVerifyOtp: (state, action) => {
            state.phoneUpdateVerifyOtp = action.payload
        },
        setPhoneUpdateVerifyOtpLoading: (state, action) => {
            state.phoneUpdateVerifyOtpLoading = action.payload
        },
        setPhoneOtpErrormessage: (state, action) => {
            state.phoneOtpErrormessage = action.payload
        },
        setResendOTP: (state, action) => {
            state.resendOTP = action.payload
        },
        SetProfileCountryCode: (state, action) => {
            state.profileCountryCode = action.payload
        },
    }
});

export const {
    setIsUsernameAvailable,
    setUserNameAvailbleMessage,
    setAllAvatarImages,
    setIsErrorWhileSaving,
    setErrorWhileSaving,
    setSuccessMessage,
    setAccoladesDetails,
    setIsProfileAvtarSuccess,
    setUserNameUpdate,
    setDetailsUpdateModal,
    setVerificationModal,
    setFormToShowInModal,
    setEmailUpdateOtpSent,
    setEmailErrorMessage,
    setEmailUpdateOtpSentLoading,
    setEmailUpdateOtpVerify,
    setEmailUpdateOtpVerifyLoading,
    setEmailOtpErrorMessage,
    setPhoneUpdateGenerateOtp,
    setPhoneUpdateGenerateOtpLoading,
    setPhoneErrorMessage,
    setPhoneUpdateVerifyOtp,
    setPhoneUpdateVerifyOtpLoading,
    setPhoneOtpErrormessage,
    setResendOTP,
    SetProfileCountryCode
} = profilSlice.actions;


export default profilSlice.reducer;
