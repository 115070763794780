import axios from "axios";
import { getFromLocalStorage, removeFromLocalStorage } from "../helper/Index";



export function getRequest(url, isNeedHeader, { headers } = {}) {
    const defaultHeaders = isNeedHeader ? { Authorization: `Bearer ${getFromLocalStorage("gk")}` } : {};
    const configHeaders = headers || defaultHeaders;
    return axios
        .get(url, { headers: configHeaders })
        .then((response) => response)
        .catch((error) => ({ response: error.response }));
}


export function postRequest(url, parameters, { headers } = {}) {
    const defaultHeaders = { Authorization: `Bearer ${getFromLocalStorage("gk")}` };
    const configHeaders = headers || defaultHeaders;
    return axios
        .post(url, parameters, { headers: configHeaders })
        .then((response) => response)
        .catch((error) => ({ response: error.response }));
}

export function putRequest(url, parameters, { headers } = {}) {
    const defaultHeaders = { Authorization: `Bearer ${getFromLocalStorage("gk")}` };
    const configHeaders = headers || defaultHeaders;
    return axios
        .put(url, parameters, { headers: configHeaders })
        .then((response) => response)
        .catch((error) => ({ response: error.response }));
}





// export function getRequest(url) {
//     return axios
//         .get(url)
//         .then((response) => response)
//         .catch((response) => ({
//             response: response.response,
//         }));
// }

// export function postRequest(url, paramerters) {
//     return axios
//         .post(url, paramerters)
//         .then((response) => response)
//         .catch((response) => ({
//             response: response.response,
//         }));
// }




// export function putRequest(url, parameters, isNeedConfig, manualConfig) {

//     const globalConfig = {
//         headers: {
//             Authorization: `Bearer ${getFromLocalStorage("gk")}`
//         }
//     }

//     var headers = {}
//     if (Object.keys(manualConfig).length > 0) {
//         headers = manualConfig.headers
//     } else {
//         headers = globalConfig.headers
//     }
//     if (isNeedConfig) {
//         return axios
//             .put(url, parameters, { headers })
//             .then((response) => response)
//             .catch((error) => ({ response: error.response }));
//     }
//     else {
//         return axios
//             .put(url, parameters)
//             .then((response) => response)
//             .catch((error) => ({ response: error.response }));
//     }
// }






// Request interceptors for API calls
axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
        console.log("error: ", error);
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
        if ((error.response.status === 401 && error.response.data.message === "No authorization token was found") || ( error.response.status === 401 && error.response.data === "Unauthorized") ||(error.response.status === 401 && error.response.data.message === "Invalid authorization token or expired") ) {
            window.location.href = "/";
            removeFromLocalStorage("gk")
        }
      return Promise.reject(error);
    }
  );