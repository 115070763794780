import React from "react";
import PromotionTopBanner from "./PromotionTopBanner";
import Footer from "../common/Footer";
// import welcomeBonusBannerMobile from "../../assets/images/png/promotions/promotionBanner_mobile.png";
// import welcomeBonusBannerWeb from "../../assets/images/png/promotions/promotionBanner_web.png";
import welcomeBonusBannerMobile from "../../assets/images/web/promotions/promotion-bonus-banner-mobile.webp";
import welcomeBonusBannerWeb from "../../assets/images/web/promotions/promotion-bonus-banner-desktop.webp";
import { Link } from "react-router-dom";
const WelcomeBonus = () => {
  return (
    <React.Fragment>
      <Footer />
      <div className="container">
        <PromotionTopBanner
          title={"Welcome Bonus"}
          mobileBanner={welcomeBonusBannerMobile}
          webBanner={welcomeBonusBannerWeb}
        />
        <div className="promotion_detail">
          <div className="detail_contain">
            <h4>Welcome Bonus</h4>
            {/* <p>Join to win with Playbig Get up to 150% Bonus</p> */}
            <ol className="order_list">
              <ul>
                <li>You can grab 250,000 Free Coins just by signing up!</li>
                <li>
                  Yes! These coins are totally free and can be used to play our
                  exciting games!
                </li>
                <li>Simply click on the JOIN button.</li>
              </ul>
            </ol>
            <h4>Enter your phone number or email address.</h4>
            <ol className="order_list">
              <ul>
                <li>Hit the Sign-Up button.</li>
                <li>250,000 coins will be added instantly to your account.</li>
                <li>
                  You can also gain stacks of coins in other ways - check out
                  our ‘How to Gain Coins’ section for more information{" "}
                  <Link to="/howtogaincoins">
                    <span className="pointer">HERE!</span>
                  </Link>
                </li>
              </ul>
            </ol>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomeBonus;
