import { endpoints } from "../../api/ApiEndPoints";
import { setFavouritesData, setErrorMessage, setFavouritesLoading } from "./favouritesSlice";
import { getRequest,putRequest } from "../../services/apiCaller";
import { getuserDetails } from "../home/homeApi";


export const getFavourites = () => (dispatch) => {
  let favouriteURL = endpoints.favourites;
  dispatch(setFavouritesLoading(true));
  dispatch(setErrorMessage(false));
  getRequest(favouriteURL, true)
    .then((res) => {
      if (res?.status === 200) {
        let getFavouritePlayers = res?.data?.favouritesPlayer;
        dispatch(setFavouritesData(getFavouritePlayers));
        if (getFavouritePlayers?.length < 1) {
          dispatch(setErrorMessage(true));
        }
        dispatch(setFavouritesLoading(false));

      } else {
        dispatch(setErrorMessage(true));
        dispatch(setFavouritesLoading(false));
      }
    })
    .catch((err) => {
      dispatch(setErrorMessage(true));
      dispatch(setFavouritesLoading(false));
    })
};

export const putFavourite = (id) => (dispatch) => { 
  let putFavouriteURL = endpoints.favourites;
  let body = {
    _id: id
};

  putRequest(putFavouriteURL, { ...body })
      .then((res) => {
          if (res.status === 200) {
            dispatch(getFavourites())
            dispatch(getuserDetails())
          }
      })
      .catch((err) => {
          console.log(err)
      })
}