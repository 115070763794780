import React, { useEffect, useState } from "react";
import range from '../../assets/images/png/roundRange.png';
import map from "../../assets/images/png/map.png";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from "react-redux";
import { getMatchStats, setStatisticsData } from "./cl_MatchStatsSlice";
import { DotsContentLoader } from "../common/Loader";
import { useParams } from "react-router-dom";

const MatchStats = () => {
    const params = useParams()
    const [isActiveMap, setIsActiveMap] = useState("heat");
    const [isGameActive, setIsGameActive] = useState("full")
    const { matchStatsData, isLoading, statisticsData } = useSelector((state) => state.matchStats);

    const handleMap = (type) => {
        setIsActiveMap(type)

    };
    const dispatch = useDispatch();
    const handleGame = (type) => {
        setIsGameActive(type)
    }
    const calculatePercentage = (specificNumber, total) => {
        const value1 = parseInt(specificNumber);
        const value2 = parseInt(total);
        const calculatedPercentage = (value1 / value2) * 100;
        return calculatedPercentage + "%"
    };
    useEffect(() => {
        dispatch(getMatchStats(params?.matchId, isGameActive))
        isGameActive === "full" ? 
        window.addEventListener(
            "fixture-statistics",
            (e) => {
             dispatch(setStatisticsData(e?.detail?.data))
            },
            false
          )
          : isGameActive === "first" ? 
          window.addEventListener(
            "fixture-statisticsp1",
            (e) => {
             dispatch(setStatisticsData(e?.detail?.data))
            },
            false
          )
          :
          window.addEventListener(
            "fixture-statisticsp2",
            (e) => {
             dispatch(setStatisticsData(e?.detail?.data))
            },
            false
          );
    }, [isGameActive]);
 
    const ballPossessionEvent = statisticsData.find(event => event.name === "Ball possession");
    const shotsOffTarget = statisticsData.find(event => event.name === "Shots off target");
    const shotsOnTarget = statisticsData.find(event => event.name === "Shots on target");
    const passes = statisticsData.find(event => event.name === "Passes");
    return (
        <React.Fragment>
            <div className="matchstats_wrapper">
                <h5 className="matchstats_heading">MATCH STATS</h5>
                <div className="map-Wrapper">
                    <div className="game_button">
                        <button onClick={() => handleGame("full")} className={`btn ${isGameActive !== "full" && "active_btn"}`}>Full game</button>
                        <button onClick={() => handleGame("first")} className={`btn ${isGameActive !== "first" && "active_btn"}`}>First half</button>
                        <button onClick={() => handleGame("second")} className={`btn ${isGameActive !== "second" && "active_btn"}`}>Second half</button>
                    </div>
                </div>
                <div className="possession">Possession</div>

                {isLoading ? <DotsContentLoader />
                    :
                    <React.Fragment>
                        <div className="range_block">
                            <div>{ballPossessionEvent?.home ? `${ballPossessionEvent?.home}%` : ""}</div>
                            <div style={{ width: 100, height: 100 }}>
                                <CircularProgressbar
                                    strokeWidth="16"
                                    value={ballPossessionEvent?.away ? ballPossessionEvent?.away : 0}
                                />
                            </div>
                            <div>{ballPossessionEvent?.away ? `${ballPossessionEvent?.away}%` : ""}</div>
                        </div>

                        <div className="target_block">
                            <div className="head_text">
                                <div>{shotsOffTarget?.home}</div>
                                <div>Shots off target</div>
                                <div>{shotsOffTarget?.away}</div>
                            </div>
                            <div className="white_block"></div>
                            <div className="left_block"></div>
                            <div className="right_block"></div>
                            <div className="bottom_text">
                                <div>Shots on target</div>
                                <div className="bottom_value">
                                    <div>{shotsOnTarget?.home}</div>
                                    <div>{shotsOnTarget?.away}</div>
                                </div>
                            </div>
                        </div>

                        {passes && <div className="passes_wrapper">
                            <div className="total_passes">
                                <div>{passes?.home}</div>
                                <div>TOTAL PASSES</div>
                                <div>{passes?.away}</div>
                            </div>
                            <div className="myProgress">
                                <div className="myBar" style={{ width: calculatePercentage(passes?.home, passes?.home + passes?.away) }}></div>
                            </div>
                        </div>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>

    )
}
export default MatchStats;