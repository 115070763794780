import React from 'react'
import { topStories_Data } from './Helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DotsContentLoader } from '../common/Loader';
import NoData from '../noData';
import { noNewsDataMessage } from "../../config/appConfig";

const TopStories = ({ newsSearchData }) => {
    const navigate = useNavigate();

    const {
        newsData,
        isLoading
    } = useSelector((state) => state.news);

    return (
        <React.Fragment>
            <div className='top_stories_content'>
                {isLoading ?
                    <DotsContentLoader />
                    :
                    <React.Fragment>
                        {
                            newsSearchData && newsSearchData?.length !== 0 ?
                                <React.Fragment>
                                    <div className='title'>
                                        <p>TOP STORIES</p>
                                    </div>
                                    <div className='top_stories_card'>
                                        {newsSearchData && newsSearchData?.map((data, ind) => {
                                            return (
                                                <div className='card_body pointer' key={ind} onClick={() => navigate(`article/${data.newsID}`)}>
                                                    <img src={data?.newsImages?.mainImage} alt='headline_img' />
                                                    <div className='card_footer_content'>
                                                        <p className='top_stories_txt'>{data?.newsTitle}</p>
                                                        {/* <div className='top_stories_info'>
                                                    <span className='top_stories_tags'>{topStories_Data[0]?.tags}</span>
                                                    <span className='top_stories_time'> {topStories_Data[0]?.time}</span>
                                                </div> */}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {!isLoading && newsSearchData?.length === 0 && <NoData message={noNewsDataMessage} />}
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default TopStories