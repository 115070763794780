import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staticData: "",
    homeStaticData: "",
    errorResponse: ""
};

export const staticDataSlice = createSlice({
    name: "staticData",
    initialState,
    reducers: {
        setStaticData: (state, action) => {
            state.staticData = action.payload;
        },
        setHomeStaticData: (state, action) => {
            state.homeStaticData = action.payload;
        },
        setErrorResponse: (state, action) => {
            state.errorResponse = action.payload;
        }
    }
});

export const { setStaticData, setHomeStaticData, setErrorResponse } = staticDataSlice.actions;

export default staticDataSlice.reducer;