import React, { useEffect, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import ProgressiveImage from 'react-progressive-image';

function LazyImage({ src, placeholder, alt }) {
    return (
        <LazyLoad height={"auto"}>
            <ProgressiveImage src={src} placeholder={placeholder}>
                {(currentSrc, loading, isDone) => (
                    <img
                        src={currentSrc}
                        alt={alt}
                        style={{
                            filter: loading && isDone ? 'blur(2px)' : 'none',
                        }}
                    />
                )}
            </ProgressiveImage>
        </LazyLoad>
    );
}

export default LazyImage;
