import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dummyNews from "../../assets/images/png/fixtures/placeholder-img.jpg"

const NewsBanner = ({ topStories }) => {
    const navigate = useNavigate();

    const handleNavigation = (newsId) => {
        navigate(`article/${newsId}`)
    }
    return (
        <React.Fragment>
            <div className="news_banner_wrapper">
                <Splide
                    hasTrack={false}
                    className="bannerSplider"
                    options={{
                        type: "loop",
                        autoplay: true,
                        autoWidth: true,
                        arrows: false,
                        gap: "0.5em",
                        pagination: false,
                    }}
                >
                    <SplideTrack>
                        {topStories &&
                            topStories?.[0]?.data?.length > 0 &&
                            topStories?.[0]?.data?.slice(0, 5)?.map((item, ind) => {
                                return (
                                    <SplideSlide key={ind}>
                                        <div className="homepage_banner pointer" onClick={() => handleNavigation(item.newsID)}>
                                            <div className="banner_container">
                                                <img
                                                    src={item?.newsImages?.mainImage}
                                                    alt="banner"
                                                    className="banner"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = dummyNews;
                                                      }}
                                                />
                                            </div>
                                            <div className="team_name">{item?.newsTitle}</div>
                                            <div className="news_published_date">
                                                {
                                                    moment(item?.newsPublished).format("ll") == moment(new Date).format("ll") ?
                                                        <>{moment(item?.newsPublished).startOf(new Date()).fromNow()}</>
                                                        :
                                                        <>{moment(item?.newsPublished).format("ll")}</>
                                                }
                                            </div>
                                        </div>
                                    </SplideSlide>
                                );
                            })}
                    </SplideTrack>
                </Splide>
            </div>
        </React.Fragment>
    )
};
export default NewsBanner;