import React, { useEffect, useState } from "react";
import ModalBox from "../common/ModalBox";
import { BiChevronDown, BiTrashAlt } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import {
  setPhoneNoError,
  setPhoneNumber,
  setReferSuccessToNumber,
} from "./referAndEarnSlice";
import { useDispatch, useSelector } from "react-redux";
import { referUserToNumber } from "./referAndEarnApi";
import { ButtonLoader } from "../common/Loader";
import { getFromCookie, setToCookie } from "../../services/helper/Index";
import {
  MSISDNAcceptableDigits,
  MSISDNAcceptableDigitsWithZero,
  countryIds,
  MSISDNSuffixValues,
  MSISDNZeroEnabled,
} from "../../config/appConfig";

function InviteFriendsToNumber(props) {
  const { userDetails } = useSelector((state) => state.signIn);
  const [inviteCount, setInviteCount] = useState(1);
  const [count, setCount] = useState(1);
  const [invitedlist, setInvitedlist] = useState([]);
  const [id, setId] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [msisdnFirstSuffixValues, setMSISDNFirstSuffixValues] = useState("");
  const [numberLength, setNumberLength] = useState(false);
  const dispatch = useDispatch();

  const { referSuccessToNumber, phoneNoError, phoneNoLoader } = useSelector(
    (state) => state.refer
  );

  useEffect(() => {
    if (referSuccessToNumber) {
      const tempData = [...invitedlist];
      const index = invitedlist.findIndex((ele) => ele.id === id);
      tempData[index] = {
        PhoneNumber: tempData[index]?.PhoneNumber,
        id: tempData[index]?.id,
        isInvited: true,
        country: tempData[index]?.country
      };
      setInvitedlist(tempData);
      dispatch(setReferSuccessToNumber(false));
      dispatch(setPhoneNumber(""));
      dispatch(setPhoneNoError(""));
    }
  }, [referSuccessToNumber, id]);

  useEffect(() => {
    const date = new Date().getTime();
    if (count === inviteCount) {
      setInvitedlist([
        ...invitedlist,
        { id: date, PhoneNumber: "", isInvited: false, country: countryCode || countryIds?.[0] },
      ]);
      setCount(count + 1);
      dispatch(setPhoneNoError(""));
    }
  }, [inviteCount, count, invitedlist]);

  const handleEdit = (e, id) => {
    dispatch(setPhoneNoError(""));
    const PhoneNumber = e.target.value;
    if (e.target.value?.charAt(0) == "0") {
      setNumberLength(true);
    } else {
      setNumberLength(false);
    }
    const re = /[0-9]+/g;
    if (
      re.test(e.nativeEvent.data) ||
      e.nativeEvent.inputType === "deleteContentBackward" ||
      e.nativeEvent.inputType === "deleteContentForward"
    ) {
      dispatch(setPhoneNumber(e.target.value));
      const tempData = [...invitedlist];
      const index = invitedlist.findIndex((ele) => ele.id === id);
      tempData[index] = {
        PhoneNumber,
        id: tempData[index]?.id,
        isInvited: tempData[index]?.isInvited,
        country: tempData[index]?.country
      };
      setInvitedlist(tempData);
    }
  };

  const handleInvite = (index, e) => {
    e.preventDefault();
    dispatch(setPhoneNoError(""));
    const filterData = invitedlist.filter((ele) => ele.id === index);
    if (filterData[0]?.PhoneNumber === "") {
      dispatch(setPhoneNoError("Enter Phone Number"));
    }
    // else if (filterData[0]?.PhoneNumber.length !== 10) {
    //     dispatch(setPhoneNoError("Must have 10 digit Mobile Number"));
    // }
    else {
      // let countryCode = getFromCookie("regionCode");
      let isLengthValid =
        MSISDNZeroEnabled && filterData[0]?.PhoneNumber?.charAt(0) === "0" ?
          filterData[0]?.PhoneNumber?.length === MSISDNAcceptableDigitsWithZero
          : filterData[0]?.PhoneNumber?.length === MSISDNAcceptableDigits;
      let msisdnFirstSuffixValues = MSISDNSuffixValues[countryCode];
      let phnNum =
        filterData[0]?.PhoneNumber?.charAt(0) === "0"
          ? filterData[0]?.PhoneNumber?.slice(1)
          : filterData[0]?.PhoneNumber;
      const isValidPrefix = msisdnFirstSuffixValues.some((prefix) =>
        phnNum.startsWith(prefix)
      );
      if (isLengthValid && isValidPrefix) {
        setId(index);
        dispatch(setPhoneNoError(""));
        dispatch(referUserToNumber(+phnNum, countryCode.toString()));
        dispatch(setReferSuccessToNumber(false));
      } else if (MSISDNZeroEnabled && isLengthValid && isValidPrefix) {
        setId(index);
        dispatch(setPhoneNoError(""));
        dispatch(referUserToNumber(+phnNum, countryCode.toString()));
        dispatch(setReferSuccessToNumber(false));
      } else {
        dispatch(setPhoneNoError("Invalid Phone number"));
      }
    }
  };

  useEffect(() => {
    let countryCode = getFromCookie("regionCode");
    setCountryCode(countryCode);
    getMSISDNSuffixvalues(countryCode);
    if (countryCode === "" || !countryCode || countryCode === undefined) {
      //setToCookie("regionCode", countryIds[0]);
      setCountryCode(countryIds[0]);
      getMSISDNSuffixvalues(countryIds[0]);
    }
  }, []);

  const handleDelete = (id) => {
    dispatch(setPhoneNoError(""));
    setInviteCount((prev) => (prev === 1 ? 1 : prev - 1));
    setCount((prev) => (prev === 1 ? 1 : prev - 1));
    if (invitedlist.length > 1) {
      const filteredData = invitedlist.filter((ele) => ele.id !== id);
      setInvitedlist(filteredData);
    }
  };

  const handleAddMore = () => {
    dispatch(setPhoneNumber(""));
    setCountryCode(countryIds?.[0])
    setInviteCount((prev) => prev + 1);
  };

  const handleChange = (event, countyId) => {
    //setToCookie("regionCode", event.target.value);
    setCountryCode(event.target.value);
    getMSISDNSuffixvalues(event.target.value);

    const tempData = [...invitedlist];
    const index = invitedlist.findIndex((ele) => ele.id === countyId);
    tempData[index] = {
      PhoneNumber: tempData[index]?.PhoneNumber,
      id: tempData[index]?.id,
      isInvited: tempData[index]?.isInvited,
      country: event.target.value
    };
    setInvitedlist(tempData);
    dispatch(setPhoneNoError(""));
  };

  function getMSISDNSuffixvalues(cntryId) {
    let msisdnSuffixObject = MSISDNSuffixValues[cntryId];
    setMSISDNFirstSuffixValues(msisdnSuffixObject);
  }

  return (
    <ModalBox
      dOpen={props.open !== ""}
      dClose={props.close}
      isClosable={props.closable}
      inviteFriend={true}
    >
      <div className="invite_friends_model">
        <div className="title">Invite Friends</div>
        <div className="form_group">
          {/* <div className="content">
                        {userDetails && userDetails?.username} has invited you to play Pick 6 a great new Football game where you can play and win big for free
                    </div> */}
          {invitedlist?.map((ele, ind) => {
            return (
              <form
                className="form_group_inline phone-group-dropdown"
                key={ind}
                onSubmit={(e) => handleInvite(ele.id, e)}
              >
                <div className="select_wrapper">
                  <select className="country_input" disabled={ele.isInvited} onChange={(e) => handleChange(e, ele.id)}>
                    {countryIds &&
                      countryIds?.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            selected={item[0]}
                          >
                            {item}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <input
                  disabled={ele.isInvited}
                  type={"tel"}
                  placeholder={"Enter Phone Number"}
                  onChange={(e) => handleEdit(e, ele.id)}
                  maxLength={numberLength ? 10 : 9}
                  value={ele.PhoneNumber}
                  className="form_input"
                />
                {ele.isInvited ? (
                  <button className="btn_invite invited" type="button">
                    invited
                  </button>
                ) : (
                  <button className="btn_invite invite" type="submit">
                    {phoneNoLoader && ele.id === id ? (
                      <ButtonLoader />
                    ) : (
                      "Invite"
                    )}
                  </button>
                )}
                {invitedlist.length !== 1 && (
                  <span>
                    {!ele.isInvited && (
                      <BiTrashAlt
                        className="icon_delete"
                        onClick={() => handleDelete(ele.id)}
                      />
                    )}
                  </span>
                )}
              </form>
            );
          })}
        </div>
        <div className="err_message ">{phoneNoError}</div>
        <div className="add_more">
          <span className="icon_add">
            <FaPlus onClick={() => handleAddMore()} />
          </span>
          Add more friends
        </div>
      </div>
    </ModalBox>
  );
}

export default InviteFriendsToNumber;
