import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../../services/helper/Index'
import Footer from '../common/Footer';

function PenaltyShootout() {
    const [accessToken, setAccessToken] = useState("");
    const [userId, setUserId] = useState("");
    const [userMobileNo, setUserMobileNo] = useState("");
    const { userDetails } = useSelector(state => state.signIn)


    var gameBaseUrl = process.env.REACT_APP_GAME_END_POINT;
    var platformId = process.env.REACT_APP_GAME_PLATFORM_ID;
    var operatorId = process.env.REACT_APP_GAME_OPERATOR_ID;
    var penaltyShootout = process.env.REACT_APP_PENALTY_SHOOTOUT_GAME_CODE;


    const memoizedGetFromLocalStorage = useCallback(getFromLocalStorage, []);


    useEffect(() => {
        const token = memoizedGetFromLocalStorage("gk");
        setAccessToken(token);
    }, [memoizedGetFromLocalStorage]);


    useEffect(() => {
        if (userDetails?.token) {
            setUserId(userDetails?.id);
            setUserMobileNo(userDetails?.mobile)
        } else {
            return;
        }
    }, [userDetails])


    return (
        <React.Fragment>
            <Footer title={"games"} />
            {accessToken &&
                <iframe
                    className="gameScreen_frame"
                    id="gameScreen_iframe_id"
                    title="penalty-shootout-game"
                    src={`${gameBaseUrl}?platformId=${platformId}&operatorId=${operatorId}&gameCode=${penaltyShootout}&AppSession=${accessToken}&userId=${userId}&userNumber=${userMobileNo}&userCulture=${"en-US"}&currencyCode=${"NGN"}`}>
                </iframe>
            }
        </React.Fragment>
    )
}

export default PenaltyShootout;
