import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { subscribeFixtureDetailsInfo } from "../../services/socket/scores";
import dummyImage from "../../assets/images/png/fixtures/dummyUpcoming.png"

const ScoreCard = ({ title, logo, fixturesData }) => {
  const navigate = useNavigate();

  function kickOffTime(date) {
    const utcTime = new Date(date);
    const startTime = utcTime.toLocaleTimeString("en-IN", {
      hourCycle: "h23",
      hour: "2-digit",
      minute: "2-digit",
    });
    return startTime;
  }

  function selectMatch(data) {
    navigate(`${data?.isDetailsAvailable ? "details" :
      data?.isLineupsAvailable ? "lineups" :
        data?.isPreviewAvailable ? "preview" :
          data?.isCommentaryAvailable && "commentary"}/${data?.cid}/${data?.mid}`);
    // subscribeFixtureDetailsInfo(data?.mid); //identifier, roomId
  }

  return (
    <React.Fragment>
      <div className="container_heading">
        <div className="score_heading_wrapper">
          {logo && <img src={logo} alt="" />}
          <div>{title}</div>
        </div>
        <div className="score_card_wrapper">
          {fixturesData?.map((data, ind) => {
            return (
              <React.Fragment key={ind}>
                <div
                  className="score_card_block pointer"
                  onClick={() => selectMatch(data)}
                >
                  {data?.status_str?.toLowerCase() === "cancelled" ?
                    (<div className="matchMinute_container">
                      {data?.status === "1"
                        ? kickOffTime(data?.eventKickoff)
                        : data?.status === "2" || data?.time === "90"
                          ? "FT"
                          : data?.status === "3" && data?.time === "45"
                            ? "First Half"
                            : data?.status === "3"
                              ? `${data?.time}'`
                              : data?.status === "4"
                                ? <React.Fragment>{data?.time}' <br /> <span className="matchStatus_container">{data?.status_str}</span></React.Fragment>
                                : ""}
                    </div>)
                    :
                    (<div className="time_container">
                      {data?.status === "1"
                        ? kickOffTime(data?.eventKickoff)
                        : data?.status === "2" || data?.time === "90"
                          ? "FT"
                          : data?.status === "3" && data?.time === "45"
                            ? "First Half"
                            : data?.status === "3"
                              ? `${data?.time}'`
                              : data?.status === "4"
                                ? `${data?.time}' ${data?.status_str}`
                                : ""}
                    </div>)}

                  <div className="homeTeam">
                    <img src={data?.homeTeamLogo}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImage;
                      }}
                      alt="homeTeam" />
                    <div className="homeTeamName">{data?.homeTeamName}</div>
                  </div>
                  <div className="score_line">
                    <div>{data?.status !== '1' && data?.result?.home}</div>
                    <b>-</b>
                    <div>{data?.status !== '1' && data?.result?.away}</div>
                  </div>
                  <div className="awayTeam">
                    <img src={data?.awayTeamLogo} alt="awayTeam"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImage;
                      }}
                    />
                    <div className="awayTeamName">{data?.awayTeamName}</div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScoreCard;
