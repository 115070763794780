import React from 'react';
import { ButtonLoader } from '../common/Loader';
import Timer from '../signup/Timer';
import { setResendOTP } from '../myProfile/myProfileSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function OtpInput(props) {
    const { resendOTP } = useSelector(state => state.profile)
    const dispatch = useDispatch();
    return (
        <form className='popup_content' onSubmit={(e) => props.handleSubmit(e)}>
            <div className="content">
                Enter OTP
            </div>
            <div className="form_group">
                <div className="otp_filed_box">
                    {props.value && props.value?.map((data, index) => {
                        return (
                            <input
                                // className="otp-field"
                                // type="text"
                                type='tel'
                                name="otp"
                                maxLength={1}
                                key={index}
                                value={data}
                                onChange={(e) => props.handleChange(e, index)}
                                onFocus={(e) => e.target.select()}
                                onKeyDown={props.handleKey}
                            />
                        );
                    })}
                    <div className="resend_otp">
                        {resendOTP ? <u className='pointer' onClick={(e) => props.handleResend(e, true)}>RESEND OTP</u>
                            :
                            <>
                                OTP SENT <Timer reset={() => dispatch(setResendOTP(true))} time={180} /> sec
                            </>
                        }
                    </div>
                    {
                        <div className='error_message'>{props.errorMessage}</div>
                    }
                </div>
            </div>
            <div className='btn-wrapper'>
                <button type='submit' className='btn btnMedium' >
                    {props.loading ? <ButtonLoader /> : "ENTER"}
                </button>
            </div>
        </form>
    )
}
