import React, { useEffect, useState } from "react";
import LiveTable from "./LiveTable";
import LiveScore from "./LiveScore";
import arrow from '../../assets/images/png/upDownArrow.png';
import secondYellow from "../../assets/images/png/fixtures/second yellow.png"
import MatchStats from "./MatchStats";
import TopMenu from "./TopMenu";
import { useDispatch, useSelector } from "react-redux";
import { getMatchInfo, setEventData } from "./cl_LineupsSlice";
import { DotsContentLoader } from "../common/Loader";
import { useParams } from "react-router-dom";
import NoData from "../noData";
import goalForDetails from "../../assets/images/png/fixtures/goal_Details.png";
import { subscribeFixtureDetailsInfo } from "../../services/socket/scores";

const Details = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { eventData, isLoading, teamDetails } = useSelector((state) => state?.lineups);
    const [showContent, setShowContent] = useState(false);
    useEffect(() => {
        dispatch(getMatchInfo(params?.matchId, "event"));
    }, []);
    useEffect(() => {
        window.addEventListener(
            "fixture-details",
            (e) => {
             const detailsData = {...eventData, ...eventData?.data,event : e?.detail?.data}
             dispatch(setEventData(detailsData))
            },
            false
          );
    }, []);
    const sortedData = eventData?.event?.slice().sort((a, b) => parseInt(b.time) - parseInt(a.time));
    const awayYellowCard = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowcard" && event?.team === "away");
    const homeYellowCard = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowcard" && event?.team === "home");
    const homeSubstitution = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "substitution" && event?.team === "home");
    const awaySubstitution = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "substitution" && event?.team === "away");
    const awayRedCard = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "redcard" && event?.team === "away");
    const homeRedCard = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "redcard" && event?.team === "home");
    const homeGoal = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "goal" && event?.team === "home");
    const awayGoal = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "goal" && event?.team === "away");
    const homeSecondYellow = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowredcard" && event?.team === "home");
    const awaySecondYellow = eventData?.event?.filter(event => event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowredcard" && event?.team === "away");
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowContent(true);
        }, 2000);
        // subscribeFixtureDetailsInfo(params?.matchId);
        return () => clearTimeout(timeoutId);
    }, []);
    return (
        <React.Fragment>
            <div className="container">
                {/* <LiveScore /> */}
                <div className="detail_wrapper">
                    <TopMenu />
                    {isLoading ?
                        <DotsContentLoader />
                        :
                        <React.Fragment>
                            {
                                teamDetails?.isDetailsAvailable ?
                                    <React.Fragment>
                                        <div className="event_block">
                                            <h5 className="key_heading">KEY EVENTS</h5>
                                            {sortedData?.map((event, ind) => {
                                                return (
                                                    event?.team === "away" ?
                                                        <div className="right_event_card" key={ind}>
                                                            <>
                                                                <div className="empty_box">
                                                                    {event?.type === "card" ?
                                                                        event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowredcard" ?
                                                                            <div className="secondYellowCard"><img src={secondYellow} alt="Second Yellow" /></div>
                                                                            :
                                                                            event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowcard" ?
                                                                                <div className="box"></div>
                                                                                : <div className="red_card"></div>
                                                                        :
                                                                        event?.type === "goal" ?
                                                                            <div className="football_box">
                                                                                <img src={goalForDetails} alt="" />
                                                                            </div>
                                                                            :
                                                                            <div className="football_box">
                                                                                <img src={arrow} alt="" />
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="info_box">
                                                                    <div>{event?.time}  {event?.injury > 0 ? + event?.injury : ""}</div>
                                                                    <div>{event?.pname}</div>
                                                                    <div>{event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowredcard" ? "Second Yellow" : event?.name}</div>
                                                                </div>
                                                            </>

                                                        </div>
                                                        :
                                                        <div className="left_event_card" key={ind}>

                                                            <>
                                                                <div className="info_box">
                                                                    <div>{event?.time}  {event?.injury > 0 ? + event?.injury : ""}</div>
                                                                    <div>{event?.pname}</div>
                                                                    <div>{event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowredcard" === "Yellowred card" ? "Second Yellow" : event?.name}</div>
                                                                </div>
                                                                <div className="empty_box">
                                                                    {event?.type === "card" ?
                                                                        event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowredcard" ?
                                                                            <div className="secondYellowCard"><img src={secondYellow} alt="Second Yellow" /></div>
                                                                            :
                                                                            event?.name?.toLowerCase()?.replace(/\s/g, '') === "yellowcard" ?
                                                                                <div className="box"></div>
                                                                                : <div className="red_card"></div>
                                                                        : event?.type === "goal" ?
                                                                            <div className="football_box">
                                                                                <img src={goalForDetails} alt="" />
                                                                            </div>
                                                                            :
                                                                            <div className="football_box">
                                                                                <img src={arrow} alt="" />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        </div>
                                                )

                                            }
                                            )}

                                            <div className="bottom_box_container">
                                                <div className="bottom_block">
                                                    {
                                                        homeSubstitution?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><img src={arrow} alt="" /></div>
                                                                <div className="bottom_contain"><div>{homeSubstitution?.length}</div></div>
                                                            </> : ""
                                                    }
                                                    {
                                                        homeYellowCard?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="blank_box"></div></div>
                                                                <div className="bottom_contain"><div>{homeYellowCard?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        homeRedCard?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="red_box"></div></div>
                                                                <div className="bottom_contain"><div>{homeRedCard?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        homeGoal?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="goal"><img src={goalForDetails} /></div></div>
                                                                <div className="bottom_contain"><div>{homeGoal?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        homeSecondYellow?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="secondYellowCard"><img src={secondYellow} alt="Second Yellow" /></div></div>
                                                                <div className="bottom_contain"><div>{homeSecondYellow?.length}</div></div>
                                                            </> : ""

                                                    }

                                                </div>
                                                <div className="bottom_block">
                                                    {
                                                        awaySubstitution?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><img src={arrow} alt="" /></div>
                                                                <div className="bottom_contain"><div>{awaySubstitution?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        awayYellowCard?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="blank_box"></div></div>
                                                                <div className="bottom_contain"><div>{awayYellowCard?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        awayRedCard?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="red_box"></div></div>
                                                                <div className="bottom_contain"><div>{awayRedCard?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        awayGoal?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="goal"><img src={goalForDetails} /></div></div>
                                                                <div className="bottom_contain"><div>{awayGoal?.length}</div></div>
                                                            </> : ""

                                                    }
                                                    {
                                                        awaySecondYellow?.length > 0 ?
                                                            <>
                                                                <div className="bottom_contain"><div className="secondYellowCard"><img src={secondYellow} alt="Second Yellow" /></div></div>
                                                                <div className="bottom_contain"><div>{awaySecondYellow?.length}</div></div>
                                                            </> : ""

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <MatchStats />
                                        <LiveTable />
                                    </React.Fragment>
                                    :
                                    showContent && <NoData message={"No Data Available"} />
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default Details;