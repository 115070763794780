import React from "react";
import BackButton from "../common/BackButton";

const PromotionTopBanner = ({ title, mobileBanner, webBanner }) => {
    return (
        <React.Fragment>
            <div className="back_button">
                <BackButton />
            </div>
            <div className="promotion_wrapper">
                <h4 className="promotion_heading">{title}</h4>
                <div className="promotion_banner">
                    <div className="promotion_banner_mobile">
                        <img src={mobileBanner} alt="promotion_banner" />
                    </div>
                    <div className="promotion_banner_web">
                        <img src={webBanner} alt="promotion_banner" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PromotionTopBanner;