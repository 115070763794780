import React, { useEffect, useState } from "react";
import {
  checkIsPhone,
  recoverPin,
  validateEmail,
  validateNum,
  validatePin,
} from "./recoverPinApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../common/BackButton";
import { Link } from "react-router-dom";
import LOGO from "../../assets/images/png/logo.png";
import { setRecoverPinResponseError } from "./recoverPinSlice";
import {
  MSISDNAcceptableDigits,
  MSISDNAcceptableDigitsWithZero,
  countryIds,
  MSISDNSuffixValues,
  MSISDNZeroEnabled,
} from "../../config/appConfig";
import { getFromCookie, setToCookie } from "../../services/helper/Index";
import { BiChevronDown } from "react-icons/bi";
import SelectDropdown from "../common/selectDropdown";

function UserId() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  //const [MSISDNZeroEnabled, setMSISDNZeroEnabled] = useState(false);
  const { recoverPinResponseError } = useSelector((state) => state.recoverPin);
  const [isPhoneActive, setisPhoneActive] = useState(true);
  const [msisdnFirstSuffixValues, setMSISDNFirstSuffixValues] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [numberLength, setNumberLength] = useState(false);

  const inputHandler = (e) => {
    const value = e.target.value;
    dispatch(setRecoverPinResponseError(""));
    setEmail(value);
  };

  const handleNumber = (e) => {
    // if (e.target.value.charAt(0) === "0") {
    //   setMSISDNZeroEnabled(true);
    // } else {
    //   setMSISDNZeroEnabled(false);
    // }
    dispatch(setRecoverPinResponseError(""));
    setPhoneNumber(e.target.value);
  };

  useEffect(() => {
    dispatch(setRecoverPinResponseError(""));
    if (isPhoneActive) {
      let countryCode = getFromCookie("regionCode");
      setCountryCode(countryCode);
      getMSISDNSuffixvalues(countryCode);
      if (countryCode === "" || !countryCode || countryCode === undefined) {
        //setToCookie("regionCode", countryIds[0]);
        setCountryCode(countryIds[0]);
        getMSISDNSuffixvalues(countryIds[0]);
      }
    } else {
      setCountryCode("");
    }
  }, []);

  const handleRecoverPin = () => {
    const isLengthValid = MSISDNZeroEnabled && phoneNumber?.charAt(0) === "0"
      ? phoneNumber?.length === MSISDNAcceptableDigitsWithZero
      : phoneNumber?.length === MSISDNAcceptableDigits;

    let phnNum =
      phoneNumber?.charAt(0) === "0" ? phoneNumber?.slice(1) : phoneNumber;
    const isValidPrefix = msisdnFirstSuffixValues?.some((prefix) =>
      phnNum?.startsWith(prefix)
    );

    if (!isPhoneActive) {
      if (email.trim() === "") {
        dispatch(
          setRecoverPinResponseError("Please Enter your registered Email ID")
        );
      } else if (!validateEmail(email)) {
        dispatch(setRecoverPinResponseError("Invalid Email ID"));
      } else {
        dispatch(recoverPin(email, countryCode, navigate));
      }
    } else {
      if (phoneNumber.trim() === "") {
        dispatch(
          setRecoverPinResponseError(
            "Please Enter your registered Phone Number"
          )
        );
      } else {
        if (validateNum(phoneNumber)) {
          if (isLengthValid && isValidPrefix) {
            dispatch(setRecoverPinResponseError(""));
            dispatch(recoverPin(+phoneNumber, countryCode, navigate));
          } else if (MSISDNZeroEnabled && isLengthValid && isValidPrefix) {
            dispatch(setRecoverPinResponseError(""));
            dispatch(recoverPin(+phoneNumber, countryCode, navigate));
          } else {
            dispatch(setRecoverPinResponseError("Invalid Phone Number"));
          }
        }
      }
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleRecoverPin();
    }
  };

  const handleActiveTab = (type) => {
    if (type === "email") {
      setCountryCode("");
      setisPhoneActive(false);
    } else if (type === "phone") {
      setisPhoneActive(true);
      let countryCode = getFromCookie("regionCode");
      setCountryCode(countryCode);
    }
    dispatch(setRecoverPinResponseError(""));
    setEmail("");
    setPhoneNumber("");
  };
  const handleChange = (event) => {
    //setToCookie("regionCode", event.target.value);
    setCountryCode(event.target.value);
    getMSISDNSuffixvalues(event.target.value);
    dispatch(setRecoverPinResponseError(""));
    setPhoneNumber("");
  };

  useEffect(() => {
    if (phoneNumber?.charAt(0) == "0") {
      setNumberLength(true);
    } else {
      setNumberLength(false);
    }
  }, [phoneNumber])

  useEffect(() => {
    dispatch(setRecoverPinResponseError(""));
    setEmail("");
    // setPhoneNumber("");
  }, [countryCode]);

  function avoidCharacter(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.persist();
      e.preventDefault();
    }
    if (e.key == "Enter") {
      // handleSubmitPhone(e);
    }
  }
  function getMSISDNSuffixvalues(cntryId) {
    let msisdnSuffixObject = MSISDNSuffixValues[cntryId];
    setMSISDNFirstSuffixValues(msisdnSuffixObject);
  }

  return (
    <React.Fragment>
      <div className="recover_layout" onKeyPress={handleKeypress}>
        <div className="header" id="page-layoutHeader">
          <div className="layout-header">
            <div className="mainHeader">
              <div className="container">
                <div className="mainHeader-container">
                  <div className="mainHeader-logo">
                    <Link to="/">
                      <img src={LOGO} alt="Loading" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header_recover">
          <div className="container">
            <BackButton />
            <div className="title">Recover Password</div>
          </div>
        </div>
        <div className="container">
          <div className="recover_details_body">
            <div className="text_cotaniner">
              <p>
                Enter the email address / phone number registered on your
                account to recover your PASSWORD.
              </p>
            </div>
            <div className="form_wrapper">
              <div className="form_container">
                <div className="form_group">
                  {/* <label htmlFor="pin">Email Address / Phone number</label> */}
                  <div className="signup_contant">
                    <div className="signup_option">
                      <div
                        className={
                          isPhoneActive ? "signup_phone active" : "signup_phone"
                        }
                        onClick={() => handleActiveTab("phone")}
                      >
                        Phone Number
                      </div>
                      <div
                        className={
                          !isPhoneActive
                            ? "signup_email active"
                            : "signup_email"
                        }
                        onClick={() => handleActiveTab("email")}
                      >
                        Email
                      </div>
                    </div>
                  </div>

                  {isPhoneActive ? (
                    <div className="form_group">
                      <div className="phone_group phone-group-dropdown">
                        <SelectDropdown
                          countryIds={countryIds}
                          setCountryCode={setCountryCode}
                          countryCode={countryCode}
                          getMSISDNSuffixvalues={getMSISDNSuffixvalues}
                          fromPage={"recoverpin"}
                        />
                        {/* <select
                          className="country_input"
                          onChange={handleChange}
                        >
                          {countryIds &&
                            countryIds?.map((item, index) => {
                              return (
                                <option
                                  value={item}
                                  key={index}
                                  selected={+item === +countryCode}
                                >
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                        <span className="select-dropdown-icon">
                          <BiChevronDown />
                        </span> */}
                        <input
                          type={"tel"}
                          placeholder={"Enter Phone Number"}
                          onKeyPress={(e) => avoidCharacter(e)}
                          onChange={(e) => handleNumber(e)}
                          maxLength={numberLength ? 10 : 9}
                          value={phoneNumber}
                          className="number_input"
                        />
                      </div>
                      <div className="error-msg">
                        {recoverPinResponseError && recoverPinResponseError}
                      </div>
                    </div>
                  ) : (
                    <div className="form_group email_group">
                      <input
                        type="text"
                        className="form_input"
                        placeholder="Enter Email"
                        id="pin"
                        name="email"
                        onChange={(e) => inputHandler(e)}
                        value={email}
                      />
                      <div className="error-msg">
                        {recoverPinResponseError && recoverPinResponseError}
                      </div>
                    </div>
                  )}

                  <button
                    className="btn form_btn btn-strech"
                    onClick={() => handleRecoverPin()}
                  >
                    RECOVER MY PASSWORD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserId;
