import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalBox from "../common/ModalBox";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../services/helper/Index";
import { prizeClaiming } from "./claimPrizeApi";
import { DotsContentLoader } from "../common/Loader";

function PrizeModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.coinsMeanPrizes?.loader);

  const handleClick = () => {
    dispatch(prizeClaiming(props?.coinsFormatted, props?.prizeName));
    props?.setConfirmClaim(false);
  };
  const handleClose = () => {
    props?.setPrizeModal(false);
    document?.body?.classList?.remove("SB-modal-open");
  };
  const feedBackNavigator = () => {
    navigate("/gamesfeedback");
  };
  const successPopUpStyle = {
    margin: props?.isPopUpClassName ? "25px 0px" : "",
  };

  return (
    <ModalBox
      dOpen={props.open}
      dClose={props.close}
      isClosable={false}
      isPopUpClassName={props?.isPopUpClassName} 
      inviteFriend={true}
      type={"non-scrollable"}
    >
      <div className="cmp_Modal_container">
        <div className="cmp_Modal_Wrapper">
          <div className="cmp_Modal_Content" style={successPopUpStyle}>
            <div className="cmp_Modal_ContentOne">
              {props?.confirmClaim ? (
                `Are you sure, you want to claim this prize??`
              ) : loader ? (
                <DotsContentLoader />
              ) : props?.claimedPrizeLength > 0 &&
                props?.isAddressAvailable &&
                props?.coinBal ? (
                `Congratulations, you have successfully claimed this prize and the coin value has been deducted from your balance.`
              ) : !props?.isAddressAvailable && !props?.coinBal ? (
                `Please complete your address details within the my profile section before attempting to claim your prize.`
              ) : !props?.isAddressAvailable && props?.coinBal ? (
                `Please complete your address details within the my profile section before attempting to claim your prize.`
              ) : props?.isAddressAvailable && !props?.coinBal ? (
                `You currently have an insufficient coin balance in order to claim this prize. Please try again when you have enough coins.`
              ) : (
                `Please, try again after some time`
              )}
            </div>
            <div className="cmp_Modal_ContentTwo">
              {props?.confirmClaim
                ? ``
                : props?.claimedPrizeLength > 0 &&
                  props?.isAddressAvailable &&
                  props?.coinBal
                ? `Please ensure that all of the details in your profile are correct. Our team will be in touch if they have any questions and your prize will be with you as quickly as possible`
                : ``}
            </div>
            {props?.confirmClaim && (
              <div className="cmp_Modal_Buttons">
                <button className="cmp_Modal_yesBtn" onClick={handleClick}>
                  Yes
                </button>
                <button className="cmp_Modal_noBtn" onClick={handleClose}>
                  No
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalBox>
  );
}

export default PrizeModal;
