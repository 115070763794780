import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFromLocalStorage, removeFromLocalStorage } from '../../services/helper/Index';

function MatchPredictorgame() {
    const [accessToken, setAccessToken] = useState("");
    const [gameTab, setGameTab] = useState("");
    const { userDetails } = useSelector(state => state.signIn)
    const { mpData } = useSelector(state => state.home);

    var platformId = process.env.REACT_APP_GAME_PLATFORM_ID;
    var operatorId = process.env.REACT_APP_GAME_OPERATOR_ID;
    var brand = process.env.REACT_APP_GAME_BRAND;
    const [isGameIdAvailable, setIsGameIdAvailable] = useState(true)

    const navigate = useNavigate()

    const { tab } = useParams()

    const memoizedGetFromLocalStorage = useCallback(getFromLocalStorage, []);

    useEffect(() => {
        const receiveMessage = (event) => {
            if (event.data && event.data.action === 'lobby') {
                navigate("/")
            }
            else if (event.data && event.data.action === "REDIRECT_TO_T&C") {
                navigate("/terms-and-conditions")
            }
            else if (event.data && event.data.action === "SESSION_EXPIRED") {
                removeFromLocalStorage("gk")
                window.location.href = "/"
            }
        };

        window.addEventListener('message', receiveMessage);
        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, []);


    useEffect(() => {
        const token = memoizedGetFromLocalStorage("gk");
        setAccessToken(token);
    }, [memoizedGetFromLocalStorage]);

    useEffect(() => {
        if (tab === undefined) {
            setGameTab("")
        } else {
            setGameTab(tab)
        }
    }, [tab]);

    useEffect(() => {
        if (mpData?.lastWeekGameId) {
            setIsGameIdAvailable(true)
        } else {
            setIsGameIdAvailable(false)
        }
    }, [mpData])


    if (!accessToken) return null;
    return (
        <React.Fragment>
            {gameTab.trim().length > 0 ?
                <iframe
                    className="gameScreen_frame"
                    id="gameScreen_iframe_id"
                    title="hit-six-game"
                    src={`https://dev-games-rgs.sportsit-tech.net/games/mpCric/index.html#/?token=${accessToken}&playerId=${userDetails?.id}&playerName=${userDetails?.username}&platformId=${platformId}&operatorId=${operatorId}&brand=${brand}&currencyCode=${"INR"}&languageCode=${"en"}&region=${"IN"}&launchType=${gameTab}&gameId=${mpData?.lastWeekGameId}`}>
                </iframe>
                :
                <iframe
                    className="gameScreen_frame"
                    id="gameScreen_iframe_id"
                    title="hit-six-game"
                    src={`https://dev-games-rgs.sportsit-tech.net/games/mpCric/index.html#/?token=${accessToken}&playerId=${userDetails?.id}&playerName=${userDetails?.username}&platformId=${platformId}&operatorId=${operatorId}&brand=${brand}&currencyCode=${"INR"}&languageCode=${"en"}&region=${"IN"}`}>
                </iframe>
            }
        </React.Fragment>
    )
}
export default MatchPredictorgame