import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/png/PlayBig_Logo.png";
import {
  checkIsEmail,
  checkIsPhone,
  getuserSignin,
  validateEmail,
  validateNum,
} from "./signInApi";
import { setSignErrorMessage, setSignInSuccessMessage } from "./signSlice";
import { ButtonLoader } from "../common/Loader";
import BackButton from "../common/BackButton";
import signUpBg from "../../assets/images/png/signUp.png";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  MSISDNAcceptableDigits,
  MSISDNAcceptableDigitsWithZero,
  countryIds,
  MSISDNSuffixValues,
  MSISDNZeroEnabled,
} from "../../config/appConfig";
import { getFromCookie, setToCookie } from "../../services/helper/Index";
import { BiChevronDown } from "react-icons/bi";
import SelectDropdown from "../common/selectDropdown";
import { setIsSideMenuOpened } from "../header/headerSlice";

function SignIn() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigated = location?.state?.navigatedFrom || '';
  const [logindetails, setLogindetails] = useState({
    email: "",
    number: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState(true);
  const [phonenumError, setPhoneNumError] = useState(true);
  const [iconPass, setIconPass] = useState(false);
  const { signErrorMessage } = useSelector((state) => state.signIn);
  const { signinLoading } = useSelector((state) => state.signIn);
  const [msisdnFirstSuffixValues, setMSISDNFirstSuffixValues] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isPhoneActive, setisPhoneActive] = useState(true);
  const [numberLength, setNumberLength] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setIsSideMenuOpened(false));
    setLogindetails({
      email: "",
      number: "",
      password: "",
    });
    if (isPhoneActive) {
      let countryCode = getFromCookie("regionCode");
      setCountryCode(countryCode);
      getMSISDNSuffixvalues(countryCode);
      if (countryCode === "" || !countryCode || countryCode === undefined) {
        setToCookie("regionCode", countryIds[0]);
        setCountryCode(countryIds[0]);
        getMSISDNSuffixvalues(countryIds[0]);
      }
    } else {
      setCountryCode("");
    }
    setEmailError("");
    setNumberError("");
    setPasswordError("");
    dispatch(setSignErrorMessage(""));
  }, []);

  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmailError("");
    setPasswordError("");
    setNumberError("");
    dispatch(setSignErrorMessage(""));
    setLogindetails({ ...logindetails, [name]: value });
  };

  const handleSignIn = (logindetails, e) => {
    e.preventDefault();
    if (!isPhoneActive) {
      if (
        logindetails.email.trim() === "" &&
        logindetails.password.trim() === ""
      ) {
        setEmailError("Email is Required");
        setPasswordError("Password is Required");
        setLoginError(false);
      } else if (logindetails.email.trim() === "") {
        setEmailError("Email is Required");
        setPasswordError("");
        setLoginError(false);
      } else if (!validateEmail(logindetails.email)) {
        setEmailError("Invalid Email");
        setPasswordError("");
        setLoginError(false);
      } else if (logindetails.password.trim() === "") {
        setEmailError("");
        setPasswordError("Password is Required");
        setLoginError(false);
      } else if (logindetails.password.length < 6) {
        setPasswordError("Password must have 6 alphanumeric characters");
        setLoginError(false);
      } else {
        setEmailError("");
        setPasswordError("");
        dispatch(
          getuserSignin(
            logindetails.email,
            countryCode,
            logindetails.password,
            navigate,
            navigated
          )
        );
      }
    } else {
      let isLengthValid =
        MSISDNZeroEnabled && logindetails?.number.charAt(0) === "0"
          ? logindetails?.number?.length === MSISDNAcceptableDigitsWithZero
          : logindetails?.number?.length === MSISDNAcceptableDigits;

      let phnNum =
        logindetails.number.charAt(0) === "0"
          ? logindetails.number.slice(1)
          : logindetails.number;
      const isValidPrefix = msisdnFirstSuffixValues.some((prefix) =>
        phnNum.startsWith(prefix)
      );
      if (
        logindetails.number.trim() === "" &&
        logindetails.password.trim() === ""
      ) {
        setNumberError("Mobile Number is Required");
        setPasswordError("Password is Required");
        setLoginError(false);
      } else if (logindetails.number.trim() === "") {
        setNumberError("Mobile Number is Required");
        setPasswordError("");
        setLoginError(false);
      } else if (logindetails.password.trim() === "") {
        setNumberError("");
        setPasswordError("Password is Required");
        setLoginError(false);
      } else if (logindetails.password.length < 6) {
        setPasswordError("Password must have 6 alphanumeric characters");
        setLoginError(false);
      } else {
        if (validateNum(logindetails.number)) {
          if (isLengthValid && isValidPrefix) {
            setNumberError("");
            setPasswordError("");
            dispatch(
              getuserSignin(
                +logindetails.number,
                countryCode,
                logindetails.password,
                navigate,
                navigated
              )
            );
          } else if (MSISDNZeroEnabled && isLengthValid && isValidPrefix) {
            setNumberError("");
            setPasswordError("");
            dispatch(
              getuserSignin(
                +logindetails.number,
                countryCode,
                logindetails.password,
                navigate,
                navigated
              )
            );
          } else {
            setPhoneNumError(false);
            setLoginError(true);
            setNumberError("Invalid Phone number");
          }
        }
      }
    }
  };

  const handleEyeIcon = (key) => {
    if (key === "pass") {
      setIconPass(!iconPass);
    }
  };

  useEffect(() => {
    return () => {
      setEmailError("");
      setNumberError("");
      setPasswordError("");
      dispatch(setSignErrorMessage(""));
      dispatch(setSignInSuccessMessage(""));
    };
  }, [dispatch]);

  const handleActiveTab = (type) => {
    if (type === "email") {
      setisPhoneActive(false);
      setCountryCode("");
    } else if (type === "phone") {
      setisPhoneActive(true);
      let countryCode = getFromCookie("regionCode");
      setCountryCode(countryCode);
    }
    setLogindetails({
      email: "",
      number: "",
      password: "",
    });
    setEmailError("");
    setNumberError("");
    setPasswordError("");
    dispatch(setSignErrorMessage(""));
  };

  const handleChange = (event) => {
    setToCookie("regionCode", event.target.value);
    setCountryCode(event.target.value);
    getMSISDNSuffixvalues(event.target.value);
    setLogindetails({
      email: "",
      number: "",
      password: "",
    });
    setEmailError("");
    setNumberError("");
    setPasswordError("");
    dispatch(setSignErrorMessage(""));
  };

  useEffect(() => {
    if (logindetails?.number?.charAt(0) == "0") {
      setNumberLength(true);
    } else {
      setNumberLength(false);
    }
  }, [logindetails?.number])

  useEffect(() => {
    // setLogindetails({
    //   email: "",
    //   number: "",
    //   password: "",
    // });
    setEmailError("");
    setNumberError("");
    setPasswordError("");
    dispatch(setSignErrorMessage(""));
  }, [countryCode]);

  function avoidCharacter(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.persist();
      e.preventDefault();
    }
    if (e.key == "Enter") {
      handleSignIn(logindetails, e);
    }
  }

  function getMSISDNSuffixvalues(cntryId) {
    let msisdnSuffixObject = MSISDNSuffixValues[cntryId];
    setMSISDNFirstSuffixValues(msisdnSuffixObject);
  }

  return (
    <React.Fragment>
      <BackButton />
      <div className="sign_wrapper">
        <div className="container">
          <div className="signin_content">
            <Link to={"/"}>
              <div className="sign_logo">
                <img src={LOGO} alt="logo" />
              </div>
            </Link>
            <div className="signin_title">SIGN IN</div>
            <div className="sign_box">
              <form
                onSubmit={(e) => handleSignIn(logindetails, e)}
                className="form_group"
              >
                <div className="signup_contant">
                  <div className="signup_option">
                    <div
                      className={
                        isPhoneActive ? "signup_phone active" : "signup_phone"
                      }
                      onClick={() => handleActiveTab("phone")}
                    >
                      Phone Number
                    </div>
                    <div
                      className={
                        !isPhoneActive ? "signup_email active" : "signup_email"
                      }
                      onClick={() => handleActiveTab("email")}
                    >
                      Email
                    </div>
                  </div>
                </div>

                {isPhoneActive ? (
                  <React.Fragment>
                    <div className="phone_group phone-group-dropdown">
                      <SelectDropdown
                        countryIds={countryIds}
                        setCountryCode={setCountryCode}
                        countryCode={countryCode}
                        getMSISDNSuffixvalues={getMSISDNSuffixvalues}
                        fromPage={"signin"}
                      />
                      {/* <select className="country_input" onChange={handleChange} ref={selectRef}>
                        {countryIds &&
                          countryIds?.map((item, index) => {
                            return (
                              <option
                                value={item}
                                key={index}
                                selected={+item === +countryCode}
                              >
                                {item}
                              </option>
                            );
                          })}
                      </select>
                      <span className="select-dropdown-icon"
                        onClick={() => handleIconClick()}
                      >
                        <BiChevronDown />
                      </span> */}
                      <input
                        type={"tel"}
                        placeholder={"Enter Phone Number"}
                        name="number"
                        onKeyPress={(e) => avoidCharacter(e)}
                        onChange={(e) => inputHandler(e)}
                        maxLength={numberLength ? 10 : 9}
                        value={logindetails?.number}
                        className="number_input"
                      />
                    </div>
                    <div className="error-msg error-msg-position">{numberError}</div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="form_signin_input">
                      <input
                        type="text"
                        placeholder="Enter Email address"
                        name="email"
                        value={logindetails?.email}
                        // maxLength={30}
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                    <div className="error-msg error-msg-position">{emailError}</div>
                  </React.Fragment>
                )}

                <div className="form_signin_input">
                  <input
                    type={iconPass ? "text" : "password"}
                    className="signin_pass"
                    placeholder="Password"
                    name="password"
                    value={logindetails.password}
                    onChange={(e) => inputHandler(e)}
                    maxLength={15}
                  />
                  <span
                    className="pointer signin_eye"
                    onClick={() => handleEyeIcon("pass")}
                  >
                    {iconPass ? <BsEyeFill /> : <BsEyeSlashFill />}
                  </span>
                </div>
                <div className="error-msg error-msg-position">{passwordError}</div>
                <div className="error-msg error-msg-position">{signErrorMessage}</div>
                <button className="btn btnMedium" type="submit">
                  {signinLoading ? <ButtonLoader /> : "SIGN IN"}
                </button>
              </form>
              {/* <p><span>LOGIN BY OTP</span></p> */}
              <p>
                If you do not have an account? &nbsp;
                <Link to={"/signup"}>
                  <span>Register here</span>
                </Link>
              </p>
              <p>
                Forgot Password &nbsp;
                <Link to={"/recover-details"}>
                  <span>Click here</span>
                </Link>
              </p>
            </div>
            <div className="signUpBackground">
              <img src={signUpBg} alt={"bg"} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignIn;
