import React, { useEffect } from "react";
import Accordion from "../common/Accordion";
// import coin from "../../assets/images/png/coin.png";
import coin from "../../assets/images/web/coin.webp";
// import CoinsMeanPrizesDesktop from "../../assets/images/png/store/CoinsMeanPrizesDesktop.png";
// import CoinsMeanPrizesMobile from "../../assets/images/png/store/CoinsMeanPrizesMobile.png";
import CoinsMeanPrizesDesktop from "../../assets/images/web/coinsmeansprizes/CoinsMeanPrizesDesktop.webp";
import CoinsMeanPrizesMobile from "../../assets/images/web/coinsmeansprizes/CoinsMeanPrizesMobile.webp";
import { useDispatch, connect, useSelector } from "react-redux";
import { useState } from "react";
import PrizeModal from "./PrizeModal";
import { getFromLocalStorage } from "../../services/helper/Index";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";

function CoinsMeanPrizes(props) {
  const [prizeModal, setprizeModal] = useState(false);
  const [confirmClaim, setConfirmClaim] = useState(false);
  const navigate = useNavigate();
  const [prizeName, setPrizeName] = useState("");
  const claimedPrize = useSelector((state) => state?.coinsMeanPrizes?.prizeClaimed)
  const [coinsFormatted, setCoinsFormatted] = useState(0);
  const [isAddressAvailable, setIsAddressAvailable] = useState(false);
  const [coinBal, setCoinBal] = useState(false);
  const staticDatas = useSelector((state) => state?.staticData);
  const userDetails = useSelector((state) => state?.signIn?.userDetails);
  const coinsMeanPrizesBeforeLogin =
    staticDatas?.staticData &&
    staticDatas?.staticData?.coinsMeanPrizesBeforeLogin[0];
  const coinsMeanPrizesAfterLogin =
    staticDatas?.staticData && staticDatas?.staticData?.coinsMeanPrizes[0];
  const token = getFromLocalStorage("gk");
  const { isSignInSuccess } = useSelector((state) => state.signIn);
  const dispatch = useDispatch();
  const formattedCoins = (num) => {
    const withoutCommas = num.replace(/,/g, '');
    return parseFloat(withoutCommas);
  }
  const [isShowPopup, setIsShowPopup] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isShowPopup) {
      document.body.classList.add("SB-modal-open");
    } else {
      document.body.classList.remove("SB-modal-open");
    }
  }, [isShowPopup]);

  return (
    <React.Fragment>
      <Footer title={"store"} />

      <PrizeModal
        open={prizeModal}
        close={() => {setprizeModal(false); setIsShowPopup(false)}}
        isPopUpClassName={(isAddressAvailable && coinBal && !confirmClaim && (Object.keys(claimedPrize).length > 0))}
        isAddressAvailable={isAddressAvailable}
        coinBal={coinBal}
        confirmClaim={confirmClaim}
        coinsFormatted={coinsFormatted}
        prizeName={prizeName}
        setConfirmClaim={setConfirmClaim}
        setPrizeModal={setprizeModal}
        claimedPrize={claimedPrize}
        claimedPrizeLength={Object.keys(claimedPrize)?.length}
      />
      <div className="coins_prizes_wrapper">
        <div className="container">
          <div className="coins_prizes_container">
            <div className="coins_prizes_header">
              <div className="coins_prizes_banner">
                <div className="desktop_banner hideDesktop">
                  <img
                    src={CoinsMeanPrizesDesktop}
                    alt="coins Mean Prizes Banner"
                  />
                </div>
                <div className="mobile_banner hideMobile">
                  <img
                    src={CoinsMeanPrizesMobile}
                    alt="coins Mean Prizes Banner"
                  />
                </div>
              </div>
            </div>
            <div className="coins_prizes_body">
              <div className="coins_prizes_cards">
                {token && isSignInSuccess
                  ? coinsMeanPrizesAfterLogin?.prizes?.map((ele, index) => {
                    return (
                      <div className="card" key={index}>
                        <div className="card_top">
                          <img className={`prize_images ${ele.prizeId == "6" ? "EPL_VIP_DAY_img" : ""}`}
                            // src={require(`../../assets/images/prizes/prize${ele.prizeId}.png`)}
                            src={require(`../../assets/images/web/coinsmeansprizes/prize${ele.prizeId}.webp`)}
                            alt="Match Cap"
                          />
                        </div>
                        <div className="card_content">
                          <div className="card_content_header">
                            <div className="card_header_txt">{ele.title}</div>
                            <div className="card_header_coins">
                              <div className="coins_img">
                                <img src={coin} alt="coin" />
                              </div>
                              <div className="coins">{ele?.coins}</div>
                            </div>
                          </div>
                          <ul>
                            {ele.text.map((txt, ind) => {
                              return (
                                <div className="arrow_list" key={ind}>
                                  <li>{txt} </li>
                                </div>
                              );
                            })}
                          </ul>
                          <button
                            className="cmp_button pointer  btn btnLarge"
                            onClick={() => {
                              setIsShowPopup(true);
                              if (userDetails?.address) {
                                setIsAddressAvailable(true);
                                const coinsFormatted = formattedCoins(ele?.coins)
                                if (userDetails?.coin >= coinsFormatted) {
                                  setCoinBal(true);
                                  setprizeModal(true);
                                  setConfirmClaim(true);
                                  setCoinsFormatted(coinsFormatted);
                                  setPrizeName(ele?.title);

                                } else {
                                  setCoinBal(false)
                                  setprizeModal(true);
                                }
                              } else {
                                setIsAddressAvailable(false);
                                setprizeModal(true);
                              }
                            }}
                          >
                            CLAIM PRIZE

                          </button>
                        </div>
                      </div>
                    );
                  })
                  : coinsMeanPrizesBeforeLogin?.prizes?.map((ele, index) => {
                    return (
                      <div className="card" key={index}>
                        <div className="card_top">
                          <img className={`prize_images ${ele.prizeId == "6" ? "EPL_VIP_DAY_img" : ""}`}
                            // src={require(`../../assets/images/prizes/prize${ele.prizeId}.png`)}
                            src={require(`../../assets/images/web/coinsmeansprizes/prize${ele.prizeId}.webp`)}
                            alt="Match Cap"
                          />
                        </div>
                        <div className="card_content">
                          <div className="card_content_header">
                            <div className="card_header_txt">{ele.title}</div>
                            <div className="card_header_coins">
                              <div className="coins_img">
                                <img src={coin} alt="coin" />
                              </div>
                              <div className="coins">{ele?.coins}</div>
                            </div>
                          </div>
                          <ul>
                            {ele.text.map((txt, ind) => {
                              return (
                                <div className="arrow_list" key={ind}>
                                  <li>{txt} </li>
                                </div>
                              );
                            })}
                          </ul>
                          <button
                            className="cmp_button pointer  btn btnLarge"
                            onClick={() => navigate("/signin")}
                          >
                            CLAIM PRIZE
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="container">
                {/* <div className='cmp_Accordion_footer'>
                                    *All prizes are subject to availability. In the event that a prize isn’t available, we will contact you to give you options of replacement prizes.
                                </div> */}
                <div className="cmp_accordion">
                  <div className="cmp_accordion_header">
                    <p>FREQUENTLY ASKED QUESTIONS</p>
                    <div> Learn More about redeeming points</div>
                  </div>
                  {token && isSignInSuccess
                    ? coinsMeanPrizesAfterLogin?.prizesFaqs?.map(
                      (text, ind) => {
                        return (
                          <div className="cmp_Accordion_Content" key={ind}>
                            <Accordion
                              title={text.title.toString()}
                              content={
                                <div className="accordion_content">
                                  <div className="accordion_sectionOne">
                                    <div
                                      className="content"
                                      dangerouslySetInnerHTML={{
                                        __html: text.text,
                                      }}
                                    >
                                      {/* {text.text} */}
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        );
                      }
                    )
                    : coinsMeanPrizesBeforeLogin?.prizesFaqs?.map(
                      (text, ind) => {
                        return (
                          <div className="cmp_Accordion_Content" key={ind}>
                            <Accordion
                              title={text.title.toString()}
                              content={
                                <div className="accordion_content">
                                  <div className="accordion_sectionOne">
                                    <div
                                      className="content"
                                      dangerouslySetInnerHTML={{
                                        __html: text.text,
                                      }}
                                    >
                                      {/* {text.text} */}
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        );
                      }
                    )}
                </div>
                <div className="cmp_Accordion_footer">
                  If you still have questions about redeeming points {"    "}
                  <span>
                    <a href="/faqs">read all FAQs</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CoinsMeanPrizes;
