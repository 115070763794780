import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMatchInfo } from "./cl_LineupsSlice";

const TopMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const splideRef = createRef();
    const fixtureData = useSelector((state) => state.matches);
    const lineupsData = useSelector((state) => state.lineups);
    const dispatch = useDispatch();
    const param = useParams();
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        dispatch(getMatchInfo(param.matchId, ""));
    }, []);

    const topMenu = [
        lineupsData?.teamDetails?.isDetailsAvailable ? [{ title: "Details", link: `/scores/details/${fixtureData.cidId}/${fixtureData.matchId}` }] : "",
        lineupsData?.teamDetails?.isLineupsAvailable ? [{ title: "Lineups", link: `/scores/lineups/${fixtureData.cidId}/${fixtureData.matchId}` }] : "",
        lineupsData?.teamDetails?.isPreviewAvailable ? [{ title: "Preview", link: `/scores/preview/${fixtureData.cidId}/${fixtureData.matchId}` }] : "",
        lineupsData?.teamDetails?.isCommentaryAvailable ? [{ title: "Commentary", link: `/scores/commentary/${fixtureData.cidId}/${fixtureData.matchId}` }] : "",
        // { title: "Top Player", link: "/scores/top-player" },
    ]
    const filteredArray = topMenu.filter(item => item !== "");

    const onDragging = (evt) => {
        const splidListElement = document.getElementsByClassName("splide__list")[0];
        // it will always be available and [0] will not be undefined as this dragging event fired only when the splide is available
        const xPos = splidListElement.getBoundingClientRect().x;

        let value = 0;
        if (xPos < -110) {
            value = -110;
        }
        if (value) {
            splidListElement.style.transform = `translateX(${value}px)`;
        }
    };
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowContent(true);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);
    return (
        <React.Fragment>
            <div className="score_top_menu">
                <Splide
                    onDragging={onDragging}
                    ref={splideRef}
                    options={{
                        type: "false",
                        omitEnd: true,
                        autoWidth: false,
                        pagination: false,
                        arrows: true,
                        drag: false,
                        gap: "0",
                        trimSpace: true,
                    }}
                >
                    {
                        filteredArray.length !== 0 ? filteredArray.map((item, ind) => (
                            <SplideSlide key={ind}>
                                <div className={`${location.pathname === item?.[0]?.link && "active"}`} onClick={() => navigate(item?.[0]?.link)}>{item?.[0]?.title}</div>
                            </SplideSlide>
                        ))
                            :
                            <div>{showContent && "No Data Available"}</div>
                    }
                </Splide>
            </div>
            {/* <p className="both_teams_name">{lineupsData?.topLiveScoreBanner && `${lineupsData?.topLiveScoreBanner?.homeTeamName} v ${lineupsData?.topLiveScoreBanner?.awayTeamName}`}</p> */}
        </React.Fragment>
    )
}
export default TopMenu;