import React from "react";
// import storeIcon from "../../assets/images/png/store.png";
// import storeBannerMobile from "../../assets/images/png/storeBackgroundToMobile.png";
// import storeBannerDesktop from "../../assets/images/png/storeBackgroundToWeb.png";
import storeBannerDesktop from "../../assets/images/web/homepagebanners/storeBackgroundToWeb.webp";
import storeBannerMobile from "../../assets/images/web/homepagebanners/storeBackgroundToMobile.webp";
import storeIcon from "../../assets/images/png/storeIcon.png"
import { Link } from "react-router-dom";
import LazyImage from "../common/LazyImage";

const StoreBanner = () => {
    return (
        <React.Fragment>
            <div className="container">
                <div className="store_wrapper">
                    <div className="heading_block">
                        <div className="store_heading">
                            <LazyImage src={storeIcon} placeholder={storeIcon} alt="" />
                            {/* <img src={storeIcon} alt="star" /> */}
                            Store
                        </div>
                    </div>
                    <div className="store_block">
                        <div className="store_banner">
                            <div className="store_banner_mobile">
                                <LazyImage src={storeBannerMobile} placeholder={storeBannerMobile} alt="" />
                                {/* <img src={storeBannerMobile} alt="storebanner" /> */}
                            </div>
                            <div className="store_banner_desktop">
                                <LazyImage src={storeBannerDesktop} placeholder={storeBannerDesktop} alt="" />
                                {/* <img src={storeBannerDesktop} alt="storebanner" /> */}
                            </div>
                        </div>
                        <div className="store_contain">
                            <div className="text_contain">
                                <div className="heading">Play Big Store</div>
                                <div className="text">Great Soccer Merchandise</div>
                                <div className="price_text">Incredible Prices!</div>
                                <Link to={"/coinsmeanprizes"}>
                                    <div className="bottom-section">
                                        <button className="btn btnLarge">Visit Store</button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default StoreBanner;