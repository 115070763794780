import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../../services/helper/Index";

const GamesBanner = ({ title, logo, link, height }) => {
  const navigate = useNavigate();
  const { isSignInSuccess } = useSelector((state) => state.signIn);
  const navigateToGame = (GameLink) => {
    const token = getFromLocalStorage("gk");
    if (token && isSignInSuccess) {
      navigate(GameLink);
    } else {
      navigate("/signin");
    }
  };
  return (
    <React.Fragment>
      <div
        className="game_banner_wrapper"
        onClick={() => {
          navigateToGame(link);
        }}
      >
        <div className="game_logo">
          <img className="pointer" src={logo} alt={title} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default GamesBanner;
