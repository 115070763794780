import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    commonError: "",
    hitSixData: {},
    vcttData: {},
    hitSixBannerText: {},
    vcttBannerText: {},
    socketMatchesData: [],
    completedMatchesCount: 0,
    mpData: {},
    mpBannerText: {},
    pickSixData: {},
    pickSixBannerText: {},
    penaltyShootoutData: {},
    penaltyShootoutBannerText: {},
    isDropdownOpen: false,

};

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setCommonError: (state, action) => {
            state.commonError = action.payload;
        },
        setHitSixData: (state, action) => {
            state.hitSixData = action.payload;
        },
        setHitSixBannerText: (state, action) => {
            state.hitSixBannerText = action.payload;
        },
        setVcttBannerText: (state, action) => {
            state.vcttBannerText = action.payload;
        },
        setSocketMatchesData: (state, action) => {
            state.socketMatchesData = action.payload;
        },
        setCompletedMatchesCount: (state, action) => {
            state.completedMatchesCount = action.payload;
        },
        setVcttData: (state, action) => {
            state.vcttData = action.payload;
        },
        setMpData: (state, action) => {
            state.mpData = action.payload;
        },
        setMpBannerText: (state, action) => {
            state.mpBannerText = action.payload;
        },
        setPickSixData: (state, action) => {
            state.pickSixData = action.payload;
        },
        setPickSixBannerText: (state, action) => {
            state.pickSixBannerText = action.payload;
        },
        setPenaltyShootoutData: (state, action) => {
            state.penaltyShootoutData = action.payload;
        },
        setPenaltyShootoutBannerText: (state, action) => {
            state.penaltyShootoutBannerText = action.payload;
        },
        setIsDropdownOpen: (state, action) => {
            state.isDropdownOpen = action.payload;
        },


    }
});

export const {
    setCommonError,
    setHitSixData,
    setHitSixBannerText,
    setVcttBannerText,
    setSocketMatchesData,
    setCompletedMatchesCount,
    setVcttData,
    setMpData,
    setPickSixData,
    setPenaltyShootoutData,
    setMpBannerText,
    setPickSixBannerText,
    setPenaltyShootoutBannerText,
    setIsDropdownOpen,
} = homeSlice.actions;


export default homeSlice.reducer;
