import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLiveUpcoming, setLiveData } from "./cl_LiveUpcomingSlice";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import NoData from "../noData";
import { noDataMessage } from "../../config/appConfig";
import { pageNumber, homeUpcomingLimit } from "../../config/appConfig";
import { DotsContentLoader } from "../common/Loader";
import { isMobile, setToLocalStorage } from "../../services/helper/Index";
import LazyImage from "../common/LazyImage";
import dummyUpcoming from "../../assets/images/png/fixtures/dummyUpcoming.png"
import formatDate, { formatScoresKickOffDate } from "../../helper/cl_formatDate";

function LiveUpcoming() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const upcomingData = useSelector((state) => state.liveUpcoming.upcomingData);
  const liveData = useSelector((state) => state.liveUpcoming.liveData);
  const isLoading = useSelector((state) => state.liveUpcoming.isLoading);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [liveUpcomingData, setLiveUpcomingData] = useState([]);
  const activeTabRef = useRef(activeTab);
  const handleActive = (type) => {
    setActiveTab(type);
    activeTabRef.current = type;
  };
  let kickOff = {};
  function getKickOff(fixture) {
    if (fixture?.eventKickoff) {
      kickOff = formatDate(fixture?.eventKickoff);
    }
    return kickOff;
  }
  function setHomeLiveUpcomingData(activeTab) {
    if (activeTab?.toLowerCase() === "live") {
      setLiveUpcomingData(liveData);
    } else if (activeTab?.toLowerCase() === "upcoming") {
      setLiveUpcomingData(upcomingData);
    }
  }
  useEffect(() => {
    dispatch(getLiveUpcoming("upcoming"));
    if (activeTab?.toLowerCase() === "upcoming" && upcomingData?.length === 0 && liveData?.length !== 0) {
      setActiveTab("live");
      activeTabRef.current = "live";
      setHomeLiveUpcomingData("live");
    }
  }, []);
  useEffect(() => {
    if (activeTab) {
      if (activeTab?.toLowerCase() === "live") {
        dispatch(getLiveUpcoming(activeTab));
      } else if (activeTab?.toLowerCase() === "upcoming") {
        dispatch(getLiveUpcoming(activeTab, pageNumber, homeUpcomingLimit));
      }
    }
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener(
      "live-soccer",
      (e) => {
        if (activeTabRef.current?.toLowerCase() === "live") {
          dispatch(setLiveData(e?.detail?.data));
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    setHomeLiveUpcomingData(activeTab);
  }, [liveData, upcomingData]);

  
  function goToScoresPage(leagueId, fixtureId, kickOff) {
    const matchDate = formatScoresKickOffDate(kickOff);
    setToLocalStorage("dt", matchDate);
    navigate(`${"scores/preview"}/${leagueId}/${fixtureId}`);
    //navigate(`${"scores/preview/996/228199"}`);
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="match_container">
          <div className="match_heading">
            <p>
              <span
                onClick={() => handleActive("upcoming")}
                className={`live_text ${activeTab?.toLowerCase() === "upcoming" && "isActive"
                  }`}
              >
                {" "}
                Upcoming
              </span>
              <span
                onClick={() => handleActive("live")}
                className={`live_text ${activeTab?.toLowerCase() === "live" && "isActive"
                  }`}
              >
                Live
              </span>
            </p>
          </div>
          <div className="hr_line"></div>
          <div className="trending_card_section">
            {!isLoading && liveUpcomingData && liveUpcomingData?.length > 0 && (
              <React.Fragment>
                {isMobile() ? (
                  <Splide
                    hasTrack={false}
                    className="LiveUpcomingSplider"
                    options={{
                      type: liveUpcomingData?.length > 3 ? "loop" : "slide",
                      autoplay: true,
                      autoWidth: true,
                      arrows: false,
                      gap: "0.3em",
                      pagination: false,
                      // speed: 4000,
                      lazyLoad: 'sequential'
                    }}
                  >
                    <SplideTrack>
                      {liveUpcomingData?.map((match, ind) => {
                        {
                          activeTab?.toLowerCase() === "upcoming" &&
                            getKickOff(match);
                        }
                        return (
                          <SplideSlide key={ind}>
                            <div className="team_container" onClick={() => goToScoresPage(match?.leagueId,match?.eventId, match?.eventKickoff)}>
                              {activeTab?.toLowerCase() === "upcoming" && (
                                <div className="kickOff">
                                  <div>{kickOff?.date}</div>
                                  <div>{kickOff?.time}</div>
                                </div>
                              )}
                              <div className="team_card_details">
                                <div className="team_card">
                                  <div className="team_Logo_Name">
                                    <div className="team-icontext">
                                      {/* <LazyImage
                                        src={match?.homeTeamLogo}
                                        placeholder={match?.homeTeamLogo}
                                        alt="logo"
                                      /> */}
                                      <img src={match?.homeTeamLogo}
                                        data-splide-lazy={match?.homeTeamLogo}
                                        alt=""
                                        loading="lazy"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = dummyUpcoming;
                                        }}
                                      />{" "}
                                      <div className="fixture_name">
                                        {match?.homeTeamName}
                                      </div>
                                    </div>
                                  </div>
                                  {activeTab?.toLowerCase() === "live" && (
                                    <div className="live_score">
                                      {match?.homeTeamScore}
                                    </div>
                                  )}
                                </div>
                                <div className="team_card">
                                  <div className="team_Logo_Name">
                                    <div className="team-icontext">
                                      {/* <LazyImage
                                        src={match?.awayTeamLogo}
                                        placeholder={match?.awayTeamLogo}
                                        alt="logo"
                                      /> */}
                                      <img src={match?.awayTeamLogo}
                                        data-splide-lazy={match?.awayTeamLogo}
                                        alt=""
                                        loading="lazy"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = dummyUpcoming;
                                        }}
                                      />{" "}
                                      <div className="fixture_name">
                                        {match?.awayTeamName}
                                      </div>
                                    </div>
                                  </div>
                                  {activeTab?.toLowerCase() === "live" && (
                                    <div className="live_score">
                                      {match?.awayTeamScore}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </SplideSlide>
                        );
                      })}
                    </SplideTrack>
                  </Splide>
                ) : (
                  <div className="marquee-container">
                    <div className="marquee">
                      {liveUpcomingData?.map((match, ind) => {
                        {
                          activeTab?.toLowerCase() === "upcoming" &&
                            getKickOff(match);
                        }
                        return (
                          <div className="marquee-content" key={match?.eventId} onClick={() => goToScoresPage(match?.leagueId,match?.eventId, match?.eventKickoff)}>
                            <div className="team_container">
                              {activeTab?.toLowerCase() === "upcoming" && (
                                <div className="kickOff">
                                  <div>{kickOff?.date}</div>
                                  <div>{kickOff?.time}</div>
                                </div>
                              )}
                              <div className="team_card_details">
                                <div className="team_card">
                                  <div className="team_Logo_Name">
                                    <div className="team-icontext">
                                      {/* <LazyImage
                                        src={match?.homeTeamLogo}
                                        placeholder={match?.homeTeamLogo}
                                        alt=""
                                      /> */}
                                      <img src={match?.homeTeamLogo}
                                        data-splide-lazy={match?.homeTeamLogo}
                                        alt=""
                                        loading="lazy"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = dummyUpcoming;
                                        }}
                                      />{" "}
                                      <div className="fixture_name">
                                        {match?.homeTeamName}
                                      </div>
                                    </div>
                                  </div>
                                  {activeTab?.toLowerCase() === "live" && (
                                    <div className="live_score">
                                      {match?.homeTeamScore}
                                    </div>
                                  )}
                                </div>
                                <div className="team_card">
                                  <div className="team_Logo_Name">
                                    <div className="team-icontext">
                                      {/* <LazyImage
                                        src={match?.awayTeamLogo}
                                        placeholder={match?.awayTeamLogo}
                                        alt=""
                                      /> */}
                                      <img src={match?.awayTeamLogo}
                                        data-splide-lazy={match?.awayTeamLogo}
                                        alt=""
                                        loading="lazy"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = dummyUpcoming;
                                        }}
                                      />{" "}
                                      <div className="fixture_name">
                                        {match?.awayTeamName}
                                      </div>
                                    </div>
                                  </div>
                                  {activeTab?.toLowerCase() === "live" && (
                                    <div className="live_score">
                                      {match?.awayTeamScore}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {!isLoading && liveUpcomingData?.length === 0 && (
              <NoData message={noDataMessage} />
            )}
            {isLoading && <DotsContentLoader />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LiveUpcoming;
