import React, { useEffect, useState } from "react";
// import videoIcon from "../../assets/images/svg/playbutton.svg";
import videoIcon from "../../assets/images/web/homepagebanners/playbutton.webp";
import { useDispatch, useSelector } from "react-redux";
import { getHighlights } from "../highlights/cl_HighlightsSlice";
import { homeHighlightsLimit, pageNumber } from "../../config/appConfig";
import { Link, } from "react-router-dom";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { getNewsData } from "../news/newsApi";
import LazyImage from "../common/LazyImage";

function SuperBanner() {
  const dispatch = useDispatch();
  const [isBannerData, setIsBannerData] = useState([]);
  // const bannerImages = useSelector(
  //   (state) => state.superBanner.homeBannerImages
  // );
  const bannersData = useSelector((state) => state.highlights.homeBannersData);
  const { newsBannerData } = useSelector((state) => state?.news);
  const { homeStaticData } = useSelector((state) => state?.staticData);

  useEffect(() => {

    const transformBannerData = (data, type, linkFormat) => {
      return data.map(item => ({
        image: type === "highlights" ? item.thumbnail : item.newsImages?.mainImage,
        title: type === "highlights" ? item.title : item.newsTitle,
        type,
        link: linkFormat(type === "news" ? item.newsID : item._id),
      }));
    };

    const formattedBanners = [
      ...transformBannerData(bannersData, "highlights", _id => `/video/${_id}`),
      ...transformBannerData(newsBannerData, "news", newsID => `/news/article/${newsID}`),
      ...homeStaticData,
    ];

    const data1 = formattedBanners.filter((_, index) => index % 2 === 0);
    const data2 = formattedBanners.filter((_, index) => index % 2 !== 0);

    setIsBannerData([...data1, ...data2]);
  }, [bannersData, newsBannerData, homeStaticData])
  useEffect(() => {
    dispatch(
      getHighlights("highlights", "highlights", pageNumber, homeHighlightsLimit)
    );
    dispatch(getNewsData());
    window.scrollTo(0, 0);
  }, []);

  //console.log("bannersData===", bannersData);
  return (
    <React.Fragment>
      {/* <div className="line_wrapper">
        <div className="black_line"></div>
        <div className="red_line"></div>
        <div className="green_line"></div>
      </div> */}
      <div className="container banner_container">
        <div className="banner_wrapper">
          <Splide
            hasTrack={false}
            className="bannerSplider"
            options={{
              type: "loop",
              autoplay: true,
              autoWidth: true,
              arrows: false,
              gap: "0.5em",
              pagination: false,
              lazyLoad: 'sequential'
            }}
          >
            <SplideTrack>
              {isBannerData &&
                isBannerData?.length > 0 &&
                isBannerData?.map((item, ind) => {
                  return (
                    <SplideSlide key={ind}>
                      <div className="homepage_banner">
                        <Link to={item?.link}>
                          {item?.type === "highlights" &&
                            <div className="video_icon">
                              {/* <LazyImage
                                src={videoIcon}
                                placeholder={videoIcon}
                                alt="videoIcon"
                              /> */}
                              <img src={videoIcon} data-splide-lazy={videoIcon} alt="videoIcon" />
                            </div>
                          }
                          <div className="team_name">
                            <div className={`fixture_name ${item?.type === "contentBanner" ? "content_banner" : ""}`}>
                              <div>{item?.title}</div>
                              <div>
                                {/* The Stars will be aiming at bouncing back */}
                              </div>
                            </div>
                          </div>
                          <div className="banner_container">
                            {/* <LazyImage
                              src={item?.image}
                              placeholder={item?.image}
                              alt="banner"
                              className="banner"
                            /> */}
                            <img
                              src={item?.image}
                              data-splide-lazy={item?.image}
                              alt="banner"
                              className="banner"
                            />
                          </div>
                        </Link>
                      </div>
                    </SplideSlide>
                  );
                })}
            </SplideTrack>
          </Splide>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SuperBanner;
