import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { setFavouritedData, setErrorMessage, setFavouritedLoading } from "./favouritedSlice";

export const getFavourited = () => (dispatch) => {
  let favouritedURL = endpoints.favourited;
  dispatch(setFavouritedLoading(true));
  getRequest(favouritedURL, true)
    .then((res) => {
      if (res?.status === 200) {
        let getFavouritedPlayers = res?.data?.favouritedPlayer;
        dispatch(setFavouritedData(getFavouritedPlayers));
        if (getFavouritedPlayers?.length < 1) {
          dispatch(setErrorMessage(true));
        }
        dispatch(setFavouritedLoading(false));
      } else {
        dispatch(setErrorMessage(true));
        dispatch(setFavouritedLoading(false));
      }
    })
    .catch((err) => {
      dispatch(setErrorMessage(true));
      dispatch(setFavouritedLoading(false));
    })
};