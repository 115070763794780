import { endpoints } from "../../api/ApiEndPoints";
import { putRequest } from "../../services/apiCaller";
import { unSubscribeUserBalance } from "../../services/socket";

export const logoutUser = (playerId) => () => {
    let logoutUrl = endpoints.logoutUser;

    putRequest(logoutUrl)
        .then((res) => {
            if (res.status === 200) {
                localStorage.clear();
                unSubscribeUserBalance(playerId);
                window.location.href = "/";
            }
        })
        .catch((err) => {
            
        })
};