import React from 'react';
import submitIcon from "../../assets/images/png/submitIcon.png";

export default function UserNameSuccessDialog(props) {
    return (
        <div className='myprofile_username_success_modal '>
        <div className="games_Feedback_popup">
            <div className='popup_content'>
                <img className="submit_icon" src={submitIcon} alt={"img"} />
                <div className="content">
                    {props.content}
                </div>
                <div className='btn-wrapper'>
                        <button className='btn btnMedium' onClick={() => props.close()}>
                            CLOSE
                        </button>
                </div>
            </div>
        </div>
        </div>

    )
}