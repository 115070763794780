import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    prizesData : [],
    errorMessage : false,
    prizesLoading : false
};

export const prizesSlice = createSlice({
    name: "prizes",
    initialState,
    reducers: {
        setPrizesData: (state, action) => {
            state.prizesData = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setPrizesLoading: (state, action) => {
            state.prizesLoading = action.payload;
        }
    }
});

export const {
    setPrizesData,
    setErrorMessage,
    setPrizesLoading,
} = prizesSlice.actions;


export default prizesSlice.reducer;