var beEndPoint = process.env.REACT_APP_BE_END_POINT;
var scoresEndPoint = process.env.REACT_APP_SCORES_END_POINT;
var cdnEndPoint = process.env.REACT_APP_CDN_URL;

export const endpoints = {
  login: beEndPoint + "authentication/login",
  userInfo: beEndPoint + "user/info",
  changeUserInfo: beEndPoint + "user/my/change-info",
  userNameValidate: beEndPoint + "user/validateUsername",
  favourites: beEndPoint + "user/favourites",
  playerReffered: beEndPoint + "user/refered",
  favourited: beEndPoint + "user/favourited",
  changepassword: beEndPoint + "user/my/change-password",
  register: beEndPoint + "/authentication/send-verification-code-email",
  mobileRegistration:
    beEndPoint + "authentication/send-verification-code-mobile",
  resendOtp: beEndPoint + "authentication/resend-verification-code",
  otpGenrate: beEndPoint + "authentication/confirm-code",
  finish_registration: beEndPoint + "authentication/finish-registration",
  beforeLogin_homePage_bannerImages: beEndPoint + "authentication/bannerImages",
  afterLogin_homePage_bannerImages: beEndPoint + "user/bannerImages",
  refer: beEndPoint + "user/invite",
  referToNumber: beEndPoint + "user/invite-to-mobile",
  logoutUser: beEndPoint + "user/logout",
  updateUser: beEndPoint + "user/my/change-info",
  avatarList: beEndPoint + "user/avatarImages",
  prizes: beEndPoint + "user/prizes",
  accolades: beEndPoint + "user/accolades",
  beforeLoginLeaderBoard: beEndPoint + "authentication/home",
  afterLoginLeaderBoard: beEndPoint + "user/home",
  faq: beEndPoint + "/authentication/faq",
  gameFeedback: beEndPoint + "authentication/feedBack",
  recoverPin: beEndPoint + "authentication/send-reset-code",
  checkPin: beEndPoint + "authentication/check-reset-code",
  updatePin: beEndPoint + "authentication/reset-password",
  referredByUser: beEndPoint + "user/invitedBy",
  emailUpdateGenerateOtp:
    beEndPoint + "authentication/changeEmail/sendCodeNewEmail",
  emailUpdateVerifyOtp:
    beEndPoint + "authentication/changeEmail/verifyCodeUpdateNewEmail",
  phoneUpdateGenerateOtp:
    beEndPoint + "authentication/changeMobile/sendCodeNewMobile",
  phoneUpdateVerifyOtp:
    beEndPoint + "authentication/changeMobile/verifyCodeUpdateNewMobile",
  staticData: cdnEndPoint +"/en/Static.json",
  homeStaticData: cdnEndPoint +"/en/Static_Home.json",
  homeHighlights: beEndPoint + "home/highlights",
  homeLiveUpcoming: beEndPoint + "soccer/matches",
  news: beEndPoint + "home/news",
  fixtures: scoresEndPoint + "competition/matches",
  matchInfo: scoresEndPoint + "match/info",
  matchStats: scoresEndPoint + "match/stats",
  pointsTable: scoresEndPoint + "competition-info",
  teamForm: scoresEndPoint + "teamform",
  claimPrize: beEndPoint + "transaction/prizeClaimed",
};
