import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";
import { isMobileWeb } from "../../config/appConfig";

const initialState = {
  highlightsData: [],
  homeBannersData: [],
  viewAllHighlightsData: [],
  totalRecords: "",
  isLoading: false,
};

export const highlightsSlice = createSlice({
  name: "highlights",
  initialState,
  reducers: {

    setHighlightsData: (state, action) => {
      state.highlightsData = action.payload;
    },
    setHomeBannersData: (state, action) => {
      state.homeBannersData = action.payload;
    },
    setViewAllHighlightsData: (state, action) => {
      state.viewAllHighlightsData = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setHighlightsData, setHomeBannersData, setViewAllHighlightsData, setTotalRecords, setIsLoading } =
  highlightsSlice.actions;

export const getHighlights = (pageName, type, page, limit) => (dispatch, getState) => {
  dispatch(setIsLoading(true));
  dispatch(setHomeBannersData([]));
  dispatch(setHighlightsData([]));
  const { highlights } = getState();
  let homeHighlightsUrl = endpoints.homeHighlights;
  let homeHighlights;
  let homeHighlightsBaseUrl =
    homeHighlightsUrl + `?type=${type}&page=${page}&limit=${limit}`;
  getRequest(homeHighlightsBaseUrl)
    .then((res) => {
      if (res?.status === 200) {
        dispatch(setIsLoading(false));
        homeHighlights = res?.data?.result;
        for (let highlightItem of homeHighlights) {
          highlightItem.title = highlightItem?.title?.replace(" - ", " VS ");
          let videos = highlightItem?.videos;
          if (isMobileWeb) {
            for (let videoItem of videos) {
              let embedUrl = videoItem?.embed;
              let container = document.createElement("div");
              container.innerHTML = embedUrl;

              let iframeElement = container.querySelector("iframe");

              if (iframeElement) {
                let srcValue = iframeElement.getAttribute("src");
                videoItem.embed = srcValue;
                //console.log("Found src attribute value:", srcValue);
              }
            }
          }
        }
        //console.log("homeHighlights===", homeHighlights);
        if (pageName?.toLowerCase() === "viewallhighlights") {
          if (highlights?.viewAllHighlightsData.length > 0) {
            const viewAllHighlights = [...highlights?.viewAllHighlightsData, ...homeHighlights];
            dispatch(setViewAllHighlightsData(viewAllHighlights));
          } else {
            dispatch(setViewAllHighlightsData(homeHighlights));
          }
          dispatch(setTotalRecords(res?.data?.totalRecords));
        } else if (pageName?.toLowerCase() === "highlights") {
          let homePageBannersData = homeHighlights?.slice(0, 1);
          let homePageHighlightsData = homeHighlights?.slice(1, 11);
          dispatch(setHighlightsData(homePageHighlightsData));
          dispatch(setHomeBannersData(homePageBannersData));
        }
      } else {
        dispatch(setIsLoading(false));
      }
    })
    .catch((err) => {
      console.log("err===", err);
    });
};

export default highlightsSlice.reducer;
