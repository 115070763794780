import React from 'react'
import SuperCard from './SuperCard'

function Games({ title, icon, children }) {
  return (
    <React.Fragment>
      <SuperCard title={title} icon={icon}>
        {children}

      </SuperCard>

    </React.Fragment>
  )
}

export default Games