import io from "socket.io-client";
let socket = null;
const GAMES_SOCKET_END_POINT = process.env.REACT_APP_GAMES_SOCKET_URL;
export const socketConnect = () => {
  socket = io(GAMES_SOCKET_END_POINT, {
    transports: ["websocket"],
    path: "/playbig/socket.io",
  });

  socket.on("live-soccer", function (data) {
    if (data) {
      const event = new CustomEvent("live-soccer", {
        detail: data,
      });
      //console.log("data===", data?.data);
      window.dispatchEvent(event);
    }
  });

  socket.on("balanceUpdate", function (data) {
    if (data) {
      const event = new CustomEvent("balanceUpdate", {
        detail: data,
      });
      window.dispatchEvent(event);
    }
  });

  socket.on("connect", () => {
    console.log("Connected to socket.io server");
  });

  socket.on("error", (err) => {
    console.error("Socket error:", err);
  });
};

// Function to subscribe to a room with player ID
export const subscribeUserBalance = (playerId) => {
  if (socket && playerId) {
    try {
      socket.emit(
        "subscribeRoom",
        playerId
        //JSON.stringify({ event: "balanceupdate", playerId })
      );
    } catch (err) {
      console.error(err.toString());
    }
  }
};

// Function to unSubscribe to a room with player ID
export const unSubscribeUserBalance = (playerId) => {
  if (socket && playerId) {
    try {
      socket.emit(
        "unsubscribeRoom",
        playerId
      );
    } catch (err) {
      console.error(err.toString());
    }
  }
};

socketConnect();
