import React, { useEffect, useState } from "react";
import { ButtonLoader } from "../common/Loader";
import { getFromCookie, setToCookie } from "../../services/helper/Index";
import { useDispatch, useSelector } from "react-redux";
import { MSISDNSuffixValues, countryIds } from "../../config/appConfig";
import { BiChevronDown } from "react-icons/bi";
import SelectDropdown from "../common/selectDropdown";
import { SetProfileCountryCode } from "../myProfile/myProfileSlice";

export default function InputUpdate(props) {
  const dispatch = useDispatch();
  // const [countryCode, setCountryCode] = useState("");
  const [msisdnFirstSuffixValues, setMSISDNFirstSuffixValues] = useState("");
  const {
    profileCountryCode
  } = useSelector((state) => state.profile);

  useEffect(() => {
    let countryCode = getFromCookie("regionCode");
    dispatch(SetProfileCountryCode(countryCode));
    getMSISDNSuffixvalues(countryCode);
    if (countryCode === "" || !countryCode || countryCode === undefined) {
      // setToCookie("regionCode", countryIds[0]);
      dispatch(SetProfileCountryCode(countryIds[0]));
      getMSISDNSuffixvalues(countryIds[0]);
    }
  }, []);
  const handleChange = (event) => {
    //setToCookie("regionCode", event.target.value);
    dispatch(SetProfileCountryCode(event.target.value));
    getMSISDNSuffixvalues(event.target.value);
  };
  function getMSISDNSuffixvalues(cntryId) {
    let msisdnSuffixObject = MSISDNSuffixValues[cntryId];
    setMSISDNFirstSuffixValues(msisdnSuffixObject);
  }

  return (
    <form className="popup_content" onSubmit={(e) => props.handleSubmit(e)}>
      {/* <img className="submit_icon" src={submitIcon} alt={"img"} /> */}
      <div className="content">{props.title}</div>
      <div className="form_group">
        <div className="form_control phone-group-dropdown">
          {props.type === "tel" && (
            <>
              <SelectDropdown
                countryIds={countryIds}
                setCountryCode={SetProfileCountryCode}
                countryCode={profileCountryCode}
                getMSISDNSuffixvalues={getMSISDNSuffixvalues}
                fromPage={"profile"}
              />
              {/* <select className="country_input" onChange={handleChange}>
                {countryIds &&
                  countryIds?.map((item, index) => {
                    return (
                      <option
                        value={item}
                        key={index}
                        selected={+item === +countryCode}
                      >
                        {item}
                      </option>
                    );
                  })}
              </select>
              <span className="select-dropdown-icon">
                <BiChevronDown />
              </span> */}
            </>
          )}
          <input
            type={props.type}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            id="phoneNumber"
            name="phoneNumber"
            value={props.value}
            onChange={(e) => props.handleChange(e)}
          />
        </div>
      </div>
      {props.errorMessage && <div className="error-msg">{props.errorMessage} </div>}
      <div className="btn-wrapper">
        <button type="submit" className="btn btnMedium">
          {props.loading ? <ButtonLoader /> : "ENTER"}
        </button>
      </div>
    </form>
  );
}
