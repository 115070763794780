import React, { useEffect, useState } from 'react'
import NewsPageHeader from './NewsPageHeader'
import Headlines from './Headlines'
import TopStories from './TopStories'
import Footer from '../common/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getNewsData } from './newsApi'
import NewsSearch from './NewsSearch'
import NewsBanner from './NewsBanner'
import NewsTabs from './NewsTabs'
import NewsStories from './newsStories'
import FeaturedNews from './FeaturedNews'
import { DotsContentLoader } from '../common/Loader'
import { setNewsSearch } from './newsSlice'

const News = () => {
    const [topStories, setTopStories] = useState([]);
    const dispatch = useDispatch();
    const {
        newsData,
        isLoading,
        newsSearchData,
        newsSearch
    } = useSelector((state) => state.news);

    useEffect(() => {
        dispatch(getNewsData());
        dispatch(setNewsSearch(""));
    }, []);

    useEffect(() => {
        if (newsData) {
            const storiesData = newsData?.categories?.filter((stories) => stories?.categoryName === "topStories");
            setTopStories(storiesData);
        }
    }, [newsData])

    return (
        <div className='main_News_Page'>
            <Footer title={"news"} />
            <div className='container'>
                {/* <div className='news_main_header'>
                    <NewsPageHeader />
                </div> */}
                {
                    isLoading ?
                        <DotsContentLoader />
                        :
                        <React.Fragment>
                            <NewsSearch />
                            {
                                newsSearchData?.length >= 0 && newsSearch !== "" ?
                                    <div className='news_main_body'>
                                        <div className='top_stories_section'>
                                            <TopStories newsSearchData={newsSearchData} />
                                        </div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <NewsBanner topStories={topStories} />
                                        <NewsTabs categories={newsData?.categories} />
                                        <NewsStories newsData={newsData?.categories} />
                                        <FeaturedNews FeaturedData={newsData?.featured} />
                                    </React.Fragment>
                            }
                        </React.Fragment>
                }
                <div className='news_main_body'>
                    {/* <div className='headlines_section'>
                        <Headlines />
                    </div> */}
                    <div className='top_stories_section'>
                        {/* <TopStories /> */}
                    </div>
                </div>
                {/* footer page */}
            </div>

        </div>
    )
}

export default News