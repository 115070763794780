import { endpoints } from "../../api/ApiEndPoints";
import { postRequest } from "../../services/apiCaller";
import { getFromLocalStorage } from "../../services/helper/Index";
import { setUserDetails } from "../signIn/signSlice";
import { setError, setPrizeClaimed, setLoader } from "./coinsMeanPrizesSlice";

export const prizeClaiming = (amount, prizeName) => (dispatch, getState) => {
  let state = getState();
  let userInfoObj = state?.signIn?.userDetails;
  let claimPrizeUrl = endpoints?.claimPrize;
  let body = {
    amount: amount,
    prizeType: "prize",
    prizeName: prizeName,
  };
  const headers = { Authorization: `Bearer ${getFromLocalStorage("gk")}` };
  dispatch(setPrizeClaimed({}));
  dispatch(setLoader(true));
  postRequest(claimPrizeUrl, { ...body }, { headers })
    .then((res) => {
      if (res?.status === 200) {
        dispatch(setPrizeClaimed(res?.data));
        const updatedUserObject = { ...userInfoObj };
        // Update the 'coin' property with a new value
        updatedUserObject.coin = res?.data?.balance;
        dispatch(setUserDetails(updatedUserObject));
        dispatch(setLoader(false));
      }
    })
    .catch((err) => {
      dispatch(setError(err));
    });
};
