import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { setPlayerRefferedData, setErrorMessage, setPlayerRefferedLoading } from "./playersRefferedSlice";


export const getPlayerReffered = () => (dispatch) => {
  let playerRefferedURL = endpoints.playerReffered;
  dispatch(setPlayerRefferedLoading(true));
  getRequest(playerRefferedURL, true)
    .then((res) => {
      if (res?.status === 200) {
        let getPlayerReffered = res?.data?.referredPlayer;
        dispatch(setPlayerRefferedData(getPlayerReffered));
        if (getPlayerReffered?.length < 1) {
          dispatch(setErrorMessage(true));
        }
        dispatch(setPlayerRefferedLoading(false));
      } else {
        dispatch(setErrorMessage(true));
        dispatch(setPlayerRefferedLoading(false));
      }
    })
    .catch((err) => {
      dispatch(setErrorMessage(true));
      dispatch(setPlayerRefferedLoading(false));
    })
};
