import React from 'react'
import { useSelector } from 'react-redux';
import ModalBox from '../common/ModalBox'
import { nogamesDataMessage } from '../../config/appConfig';
import NoData from '../noData';

function GainCoinsModal(props) {
    const { userDetails } = useSelector(state => state.signIn);

    const { weeklyBounus } = userDetails

    const weeklyBonusdata = weeklyBounus

    const getCurrentWeekNumber = () => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        var weekNumber = Math.ceil(days / 7);
        return weekNumber
    }
    return (
        <ModalBox dOpen={props.open} dClose={props.close} isClosable={false} >
            <div className="model_x_container">
                <div className="gaincoin_modal_wrapper">
                    <div className="gaincoin_modal_head">
                        <div className="title"> Gameweek  {getCurrentWeekNumber()}</div>
                        <div className="logged_details">
                            <div className='left'>
                                <div>Number of days logged in</div>
                                <div>Total Coins Gained</div>
                            </div>
                            <div className='right'>
                                <div className='brand_secondary'>{userDetails && userDetails?.weeklyLoginCount} of 7 days</div>
                                <div className='brand_secondary'>{0} coins</div>
                            </div>
                        </div>
                    </div>
                    <div className="gaincoin_body">
                        {weeklyBounus && weeklyBounus.length > 0 &&
                            [...weeklyBonusdata].reverse().map((ele, ind) => {
                                return (
                                    <div className="weekwise" key={ind}>
                                        <div className="title">Gameweek {ele?.week}</div>
                                        <div className="logged_details">
                                            <div className='left'>
                                                <div>Number of days logged in</div>
                                                <div>Total Coins Gained</div>
                                            </div>
                                            <div className='right'>
                                                <div className='brand_secondary'>{ele?.days} of 7 days</div>
                                                <div className='brand_secondary'>{ele?.coin} coins</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {(weeklyBounus === undefined || weeklyBounus.length === 0) &&
                             <NoData message={nogamesDataMessage} />
                            }
                    </div>
                </div>

                {/* <div className='modal_footer'>*Coins will be credited by Midday, the day afer this gameweek has finished.</div> */}
            </div>
        </ModalBox>
    )
}

export default GainCoinsModal