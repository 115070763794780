import { endpoints } from "../api/ApiEndPoints";
import { getRequest } from "../services/apiCaller";
import { getFromLocalStorage, getObject } from "../services/helper/Index";
import { setStaticData, setErrorResponse, setHomeStaticData } from "./AppSlice";
import { setHitSixBannerText, setMpBannerText, setPenaltyShootoutBannerText, setPickSixBannerText, setVcttBannerText } from "./home/homeSlice";




export const staticData = () => (dispatch) => {
    let staticDataUrl = endpoints.staticData;
    getRequest(staticDataUrl, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setStaticData(res?.data));
                const hitSixData = getObject("hit6", [res?.data?.homePageBanner[0]])
                dispatch(setHitSixBannerText(hitSixData));

                const mpData = getObject("mp", [res?.data?.homePageBanner[0]]);
                const pickSixData = getObject("pick6", [res?.data?.homePageBanner[0]]);
                const penaltyShootoutData = getObject("vps", [res?.data?.homePageBanner[0]]);

                dispatch(setMpBannerText(mpData));
                dispatch(setPickSixBannerText(pickSixData));
                dispatch(setPenaltyShootoutBannerText(penaltyShootoutData));

                const vcttData = getObject("vctt", [res?.data?.homePageBanner[0]])
                dispatch(setVcttBannerText(vcttData))
            }
        })
        .catch((err) => {
            const errorResponse = err.errorResponse;
            dispatch(setErrorResponse(errorResponse?.data?.message));
        })
}
export const homeStaticData = () => (dispatch) => {
    let homeStaticDataUrl = endpoints.homeStaticData;
    getRequest(homeStaticDataUrl, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setHomeStaticData(res?.data?.ContentBanner));
            }
        })
        .catch((err) => {
            const errorResponse = err.errorResponse;
            dispatch(setErrorResponse(errorResponse?.data?.message));
        })
}

