import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiCloseFill } from "react-icons/ri";
import { AiOutlineFullscreen } from "react-icons/ai";
import { IoChevronBackCircle } from 'react-icons/io5';
import { getHighlights } from "../highlights/cl_HighlightsSlice";
import { homeHighlightsLimit, pageNumber } from "../../config/appConfig";
import backArrow from "../../assets/images/png/back_arrow.png"

const VideoLoad = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const param = useParams();
    const [expandWin, setExpendWin] = useState(false);
    const [highlight, setHighlight] = useState([]);
    const [banner, setBanner] = useState([]);
    const [allHighlights, setAllHighlights] = useState([]);
    const bannersData = useSelector((state) => state.highlights.homeBannersData);
    const highlightsData = useSelector((state) => state.highlights.highlightsData);
    const viewAllHighlightsData = useSelector((state) => state.highlights.viewAllHighlightsData);

    useEffect(() => {
        dispatch(
            getHighlights("highlights", "highlights", pageNumber, homeHighlightsLimit)
        );
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filterNews = bannersData?.filter((news) => news?._id === param?.videoId)
        setBanner(filterNews)
    }, [bannersData]);

    useEffect(() => {
        const filterNews = highlightsData?.filter((news) => news?._id === param?.videoId)
        setHighlight(filterNews)
    }, [highlightsData]);

    useEffect(() => {
        const filterNews = viewAllHighlightsData?.filter((news) => news?._id === param?.videoId)
        setAllHighlights(filterNews)
    }, [viewAllHighlightsData]);

    const expendHandiler = () => {
        setExpendWin(!expandWin);

        let elem = document.getElementById("fullscreen");

        if (!expandWin) {
            // Request fullscreen
            try {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE11 */
                    elem.msRequestFullscreen();
                }
            } catch (error) {
                console.error("Error entering fullscreen:", error);
            }
        } else {
            // Exit fullscreen
            try {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    /* IE11 */
                    document.msExitFullscreen();
                }
            } catch (error) {
                console.error("Error exiting fullscreen:", error);
            }
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div id="fullscreen">
            <div className="container">
                <div className="video_wrapper">
                    <div className="top_header">
                        <div className="video_back_btn pointer" onClick={handleBack}>
                            <img src={backArrow} alt="" />
                            {/* <IoChevronBackCircle /> */}
                        </div>
                        <div className="icons">
                            <div className="full_screen pointer" onClick={expendHandiler}><AiOutlineFullscreen /></div>
                            <div className="close pointer" onClick={handleBack}><RiCloseFill /></div>
                        </div>
                    </div>
                    <div className="video_play_iframe">
                        {banner && banner?.length > 0 && allHighlights?.length === 0 && <iframe src={decodeURIComponent(banner?.[0]?.videos?.[0]?.embed)}></iframe>}
                        {highlight && highlight?.length > 0 && allHighlights?.length === 0 && <iframe src={decodeURIComponent(highlight?.[0]?.videos?.[0]?.embed)}></iframe>}
                        {allHighlights && allHighlights?.length > 0 && <iframe src={decodeURIComponent(allHighlights?.[0]?.videos?.[0]?.embed)}></iframe>}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VideoLoad;