export const homeHighlightsLimit = 15;
export const pageNumber = 1;
export const isMobileWeb = true;
export const viewAllHighlightsLimit = 10;
export const noDataMessage = "Currently no matches available";
export const noNewsDataMessage = "Currently no News available";
export const nogamesDataMessage ="No past week data available";
export const homeUpcomingLimit = 20;
export const MSISDNAcceptableDigits = 9;
export const MSISDNAcceptableDigitsWithZero = 10;
export const MSISDNZeroEnabled = true;
export const KE_MSISDNFirstSuffixValues = ["7", "9"];
export const TZ_MSISDNFirstSuffixValues = ["7"];
export const UG_MSISDNFirstSuffixValues = ["8", "9"];
export const KE_CountryId = 254;
export const TZ_CountryId = 255;
export const UG_CountryId = 256;
export const countryIds = [254, 255, 256];
export const Instagram_Link = "https://www.instagram.com/playbig_sports/";
export const Facebook_Link =
  "https://www.facebook.com/profile.php?id=100095108264783";
export const Twitter_Link = "https://twitter.com/playbig_offic";
export const Telegram_Link = "https://telegram.me/";
export const whatsapp_Link = "https://web.whatsapp.com/";
export const MSISDNSuffixValues = {
  254: ["7", "9"],
  255: ["7"],
  256: ["8", "9"],
};
export const coins = {
  signUp: ["250,000"],
  completeProfile: "25,000",
  referToFriend: "25,000",
  profileGainMore: ["25,000"],
  maxReferCoinsPerWeek: "20",
}