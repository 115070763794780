import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { footer_Data } from "./Helper";
import { setIsDropdownOpen } from "../home/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFromLocalStorage } from "../../services/helper/Index";
import white_arrow from "../../assets/images/png/white_arrow.png";
import yellow_arrow from "../../assets/images/png/yellow_arrow.png";
import LazyImage from "./LazyImage";

const Footer = ({ title }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const token = getFromLocalStorage("gk");
  const isDropdownOpen = useSelector(state => state?.home?.isDropdownOpen);
  const { isSignInSuccess } = useSelector((state) => state.signIn);

  const handleDropdown = (data) => {
    if (data?.title === "Games") {
      dispatch(setIsDropdownOpen(!isDropdownOpen));
    } else {
      navigate(data?.link);
      dispatch(setIsDropdownOpen(false));
    }
  }
  
  const handleClickOutside = (event) => {
    if (
      dropdownRef?.current &&
      !dropdownRef?.current?.contains(event?.target)
    ) {
      dispatch(setIsDropdownOpen(false));
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const navigateToGame = (GameLink) => {
    if (token && isSignInSuccess) {
      navigate(GameLink);
      dispatch(setIsDropdownOpen(false));
    } else {
      dispatch(setIsDropdownOpen(false));
      navigate("/signin");
    }
  };

  return (
    <div className="container">
      <div className="mobile_footer">
        <div className="footer_content">
          <ul className="footer_list" ref={dropdownRef}>
            {footer_Data?.map((item) => {
              return (
                <li
                  className={`footer_list_items pointer ${title === item?.value ? "active" : ""
                    }`}
                  onClick={() => {
                    handleDropdown(item)
                  }}
                  // onMouseEnter={() => item?.title === "Games" && dispatch(setIsDropdownOpen(true))}
                  key={item.id}
                >
                  <div className={`footer_Card ${item?.title == "Games" || item?.title == "News" ? "games_icon" : ""}`}>
                    {title === item?.value ? (
                      <LazyImage
                        src={item?.image1}
                        placeholder={item?.image1}
                        alt="" />
                      // <img
                      //   className="socialmedia_img"
                      //   src={item?.image1}
                      //   alt={item.title}
                      // />
                    ) : (
                      <LazyImage
                        src={item?.image2}
                        placeholder={item?.image2}
                        alt="" />
                      // <img
                      //   className="socialmedia_img"
                      //   src={item?.image2}
                      //   alt={item.title}
                      // />
                    )}
                  </div>
                  <div className={`dropdown-menu ${item?.title == "Games" ? " active" : ""}`}>
                    <span>{item?.title}</span>
                    {item?.title == "Games" &&
                      <span className="select-dropdown-icon">
                        {title === item?.value ?
                          <img className="yellow_arrow" src={yellow_arrow} alt="vector" />
                          :
                          <img className="white_arrow" src={white_arrow} alt="vector" />
                        }
                      </span>
                    }
                  </div>

                </li>
              );
            })}
          </ul>
          {isDropdownOpen &&
            <div className="footer-menu-dropdown"
              onMouseLeave={() => dispatch(setIsDropdownOpen(false))}
            >
              <ul>
                <a onClick={() => navigateToGame("/game/pick6")}>
                  <li>
                    <div className="text_container">
                      Pick6
                    </div>
                  </li>
                </a>
                <a onClick={() => navigateToGame("/game/penaltyshootout")}>
                  <li>
                    <div className="text_container">
                      Virtual Penalty Shootout
                    </div>
                  </li>
                </a>
                <a onClick={() => navigateToGame("/game/matchpredictor")}>
                  <li>
                    <div className="text_container">
                      Match Predictor
                    </div>
                  </li>
                </a>
              </ul>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Footer;
