import React, { useEffect, useState } from "react";
import HamBurger from "../../assets/images/png/hamburgermenu.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import profileLogo from "../../assets/images/png/sidemenuIcons/profile.png";
import Personalize from "../../assets/images/svg/sideMenuicons/personalization.svg";
import playandwin from "../../assets/images/svg/sideMenuicons/playandwin.svg";
import store from "../../assets/images/png/sidemenuIcons/store.png";
import news from "../../assets/images/png/sidemenuIcons/news.png";
import scores from "../../assets/images/png/sidemenuIcons/penalty_shootut.png";
import champion from "../../assets/images/svg/sideMenuicons/champion.svg";
import Promotions from "../../assets/images/png/sidemenuIcons/promotions.png";
import ReferEarn from "../../assets/images/png/sidemenuIcons/referandearn.png";
import setting from "../../assets/images/png/sidemenuIcons/settings_nav_img.png";
import faq from "../../assets/images/png/sidemenuIcons/faqs.png";
import TandC from "../../assets/images/png/sidemenuIcons/t&c.png";
import feedback from "../../assets/images/png/sidemenuIcons/feedback_icon.png";
import Coin from "../../assets/images/png/coin.png";
import profile from "../../assets/images/png/fixtures/profile pic_mobile.png";
import howToGainCoinsImg from "../../assets/images/png/sidemenuIcons/Howtogaincoins.png";
import settingsImg from "../../assets/images/png/sidemenuIcons/Settings.png";
import { useDispatch, useSelector } from "react-redux";
import { setIsSideMenuOpened } from "./headerSlice";
import loginIcon from "../../assets/images/png/sidemenuIcons/LoginIcon1.png";
import logout from "../../assets/images/png/sidemenuIcons/Logout.png";
import { logoutUser } from "./headerApi";
import { getFromLocalStorage } from "../../services/helper/Index";
import gameLogo from "../../assets/images/png/playbig-new-logo.png";
import SideMenuAccordion from "./SideMenuAccordion";
import scoreIcon from '../../assets/images/png/sidemenuIcons/penalty_shootut.png';
import { coins } from "../../config/appConfig";
import LazyImage from "../common/LazyImage";
import pickSixIcon from "../../assets/images/png/sidemenuIcons/pick6.png";
import MpIcon from "../../assets/images/png/sidemenuIcons/mp_icon.png";
import penality_Shootout from "../../assets/images/png/sidemenuIcons/penalty_shootut.png";
import white_arrow from "../../assets/images/png/white_arrow.png";
import yellow_arrow from "../../assets/images/png/yellow_arrow.png";
import newsIconYlw from '../../assets/images/svg/footer_icons_mobile/newsIconYlw.svg';
import newsIconWhite from '../../assets/images/svg/footer_icons_mobile/newsIconWht.svg';
import gameIconYlw from '../../assets/images/svg/footer_icons_mobile/gameIconYlw.svg';
import gameIconWhite from '../../assets/images/svg/footer_icons_mobile/gameIconWht.svg';
import scoreIconYlw from '../../assets/images/svg/footer_icons_mobile/scoreIconYlw.svg';
import scoreIconWhite from '../../assets/images/svg/footer_icons_mobile/scoresIconWht.svg';
import storeIconYlw from '../../assets/images/svg/footer_icons_mobile/storeIconYlw.svg';
import storeIconWhite from '../../assets/images/svg/footer_icons_mobile/StoreIconWht.svg';
import highlightIconYlw from '../../assets/images/svg/footer_icons_mobile/highlights_yellow.png';
import highlightIconWhite from '../../assets/images/svg/footer_icons_mobile/starIconWht.svg';

function Header() {
  const dispatch = useDispatch();
  const { isSideMenuOpened } = useSelector((state) => state.header);
  const { isSignInSuccess, userDetails } = useSelector((state) => state.signIn);
  //const remainingBal = useSelector((state) => state?.coinsMeanPrizes?.prizeClaimed?.balance)
  const location = useLocation();
  const navigate = useNavigate();

  const [activeHeaderTab, setActiveHeaderTab] = useState("");

  const toggleSideMenu = () => {
    dispatch(setIsSideMenuOpened(!isSideMenuOpened));
  };

  const feedBackNavigator = () => {
    // if (token && isSignInSuccess) {
    navigate("/gamesfeedback");
    dispatch(setIsSideMenuOpened(!isSideMenuOpened));
    // } else {
    //     navigate("/signin");
    //     dispatch(setIsSideMenuOpened(!isSideMenuOpened));
    // }
  };
  const contactUsNavigator = () => {
    if (token && isSignInSuccess) {
      navigate("/");
      dispatch(setIsSideMenuOpened(!isSideMenuOpened));
    } else {
      navigate("/signin");
      dispatch(setIsSideMenuOpened(!isSideMenuOpened));
    }
  };
  useEffect(() => {
    // if (location.pathname === "/") {
    //   setActiveHeaderTab("/");
    // } else if (location.pathname === "/howtogaincoins") {
    //   setActiveHeaderTab("howtogaincoins");
    // } else if (location.pathname.search("hit6") >= 0) {
    //   setActiveHeaderTab("hit6");
    // } else if (location.pathname.search("mpcric") >= 0) {
    //   setActiveHeaderTab("mpcric");
    // } else if (location.pathname.search("vctt") >= 0) {
    //   setActiveHeaderTab("VCTT");
    // } else {
    //   setActiveHeaderTab("");
    // }
    setActiveHeaderTab(location.pathname);
  }, [location]);

  const token = getFromLocalStorage("gk");

  const navigateToGame = (GameLink) => {
    const token = getFromLocalStorage("gk");
    if (token && isSignInSuccess) {
      navigate(GameLink);
    } else {
      navigate("/signin");
    }
  };
  return (
    <React.Fragment>
      <div className="header" id="page-layoutHeader">
        <div className="layout-header">
          <div className="mainHeader">
            <div className="container">
              <div className="mainHeader-container">
                <div className="mainHeader-leftSection">
                  <div
                    className="mainHeader-hamBurger"
                    onClick={() => toggleSideMenu()}
                  >
                    <LazyImage src={HamBurger} placeholder={HamBurger} alt="" />
                    {/* <img
                      src={HamBurger}
                      alt="Loading"
                      role="none"
                      loading="eager"
                    /> */}
                  </div>
                  <div className="mainHeader-logo">
                    <Link to="/">
                      <LazyImage src={gameLogo} placeholder={gameLogo} alt="" />
                      {/* <img
                        src={gameLogo}
                        alt="Loading"
                        role="none"
                        loading="eager"
                      /> */}
                    </Link>
                  </div>
                </div>
                <div className="mainHeader-middleSection hide-mobile show-desktop">
                  <ul>
                    {/* {token && ( */}
                    {/* <li className="gainCoins active">
                      <Link
                        className={
                          activeHeaderTab === "/howtogaincoins"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        role="none"
                        to={"/howtogaincoins"}
                      >
                        <div>How To Gain Coins</div>
                      </Link>
                    </li> */}
                    {/* )} */}
                    {/* {token && ( */}
                    {/* <li className="pick" role="none">
                      <div
                        className={
                          activeHeaderTab === "/game/pick6"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                      >
                        <div onClick={() => navigateToGame("/game/pick6")}>
                          Pick 6
                        </div>
                      </div>
                      <div className="navlink-dropdown hitSix">
                        <ul>
                          <Link to={"/game/hit6"}><li>Play</li></Link>
                          <Link to={"/game/hit6/result"}><li>Results</li></Link>
                          <Link to={"/game/hit6/ldb"}><li>leaderboard</li></Link>
                          <Link to={"/game/hit6/prizeTable"}><li>Prizes</li></Link>
                        </ul>
                      </div>
                    </li> */}
                    {/* )} */}
                    {/* {token && <li className="mp" role="none" >
                      <div className={activeHeaderTab === "mpcric" ? "mainHeader-item active" : "mainHeader-item"} role="none">
                        <div onClick={() => navigateToGame("/game/mpcric/")} >MATCH PREDICTOR</div>
                      </div> */}
                    {/* <div className="navlink-dropdown mp-dropdown">
                        <ul>
                          <Link to={"/game/mpcric/"}><li>Play</li></Link>
                          <Link to={"/game/mpcric/history"}><li>Game History</li></Link>
                          <Link to={"/game/mpcric/prizeTable"}><li>Prizes</li></Link>
                          <Link to={"/game/mpcric/gameRules"}><li>Game Rules</li></Link>
                        </ul>
                      </div> */}

                    {/* </li>} */}
                    {/* {token && <li className="vctt" role="none" >
                      <div className={activeHeaderTab === "VCTT" ? "mainHeader-item active" : "mainHeader-item"}>
                        <div onClick={() => navigateToGame("/game/vctt/")}>VCTT</div>
                      </div>
                       <div className="navlink-dropdown vctt-dropdown">
                        <ul>
                          <Link to={"/game/vctt"}><li>How to Play</li></Link>
                          <Link to={"/game/vctt"}><li>Play</li></Link>
                          <Link to={"/game/vctt"}><li>leaderboard</li></Link>
                          <Link to={"/game/vctt"}><li>Results</li></Link>
                        </ul>
                      </div> 
                    </li>} */}
                    {/* {token && ( */}
                    {/* <li className="mp" role="none">
                      <div
                        className={
                          activeHeaderTab === "/game/matchpredictor"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        role="none"
                      >
                        <div
                          onClick={() =>
                            navigateToGame("/game/matchpredictor")
                          }
                        >
                          Match Predictor
                        </div>
                      </div>
                    </li> */}
                    {/* )} */}
                    {/* {token && ( */}
                    {/* <li className="penalty" role="none">
                      <div
                        className={
                          activeHeaderTab === "/game/penaltyshootout"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                      >
                        <div
                          onClick={() =>
                            navigateToGame("/game/penaltyshootout")
                          }
                        >
                          Virtual Penalty Shootout
                        </div>
                      </div>
                    </li> */}
                    {/* )} */}

                    <li className="more" role="none">
                      <div className="mainHeader-item">
                        <div className={
                          activeHeaderTab.includes("/game/")
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        >
                          {activeHeaderTab.includes("/game/") ? <img className="header-icons" src={gameIconYlw} alt="vector" />
                            :
                            <img className="header-icons" src={gameIconWhite} alt="vector" />}
                          <span>Games</span>
                          {activeHeaderTab.includes("/game/") ? <img className="vector_arrow" src={yellow_arrow} alt="vector" />
                            :
                            <img className="vector_arrow" src={white_arrow} alt="vector" />}
                        </div>
                      </div>
                      <div className="navlink-dropdown more-dropdown">
                        <ul>
                          <li onClick={() => navigateToGame("/game/pick6")}>
                            <div className="icon_container">
                              <img alt="icon" src={pickSixIcon} />
                            </div>
                            <div className="text_container">
                              Pick 6
                            </div>
                          </li>
                          <li onClick={() => navigateToGame("/game/matchpredictor")}>
                            <div className="icon_container">
                              <img alt="icon" src={MpIcon} />
                            </div>
                            <div className="text_container">
                              Match Predictor
                            </div>
                          </li>
                          <li onClick={() => navigateToGame("/game/penaltyshootout")}>
                            <div className="icon_container">
                              <img alt="icon" src={penality_Shootout} />
                            </div>
                            <div className="text_container">
                              Virtual Penalty Shootout
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>

                    {/* {token && ( */}
                    <li className="news" role="none">
                      <Link
                        className={
                          activeHeaderTab === "/news"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        role="none"
                        to={"/news"}
                      >
                        {activeHeaderTab === "/news" ? <img className="header-icons" src={newsIconYlw} alt="vector" />
                          :
                          <img className="header-icons" src={newsIconWhite} alt="vector" />}
                        <span>News</span>
                      </Link>
                    </li>
                    <li className="news" role="none">
                      <Link
                        className={
                          activeHeaderTab === "/viewallhighlights"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        role="none"
                        to={"/viewallhighlights"}
                      >
                        {activeHeaderTab === "/viewallhighlights" ? <img className="header-icons" src={highlightIconYlw} alt="vector" />
                          :
                          <img className="header-icons" src={highlightIconWhite} alt="vector" />}
                        <span>Highlights</span>
                      </Link>
                    </li>
                    <li className="news" role="none">
                      <Link
                        className={
                          activeHeaderTab === "/scores"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        role="none"
                        to={"/scores"}
                      >
                        {activeHeaderTab === "/scores" ? <img className="header-icons" src={scoreIconYlw} alt="vector" />
                          :
                          <img className="header-icons" src={scoreIconWhite} alt="vector" />}
                        <span>Scores</span>
                      </Link>
                    </li>
                    <li className="news" role="none">
                      <Link
                        className={
                          activeHeaderTab === "/coinsmeanprizes"
                            ? "mainHeader-item active"
                            : "mainHeader-item"
                        }
                        role="none"
                        to={"/coinsmeanprizes"}
                      >
                        {activeHeaderTab === "/coinsmeanprizes" ? <img className="header-icons" src={storeIconYlw} alt="vector" />
                          :
                          <img className="header-icons" src={storeIconWhite} alt="vector" />}
                        <span>Store</span>
                      </Link>
                    </li>
                    {/* )} */}
                    {/* <li className="prize" role="none">
                      <Link className="mainHeader-item" to="/prizes">
                        <div>PRIZE</div>
                      </Link>
                    </li> */}
                    {/* {token && ( */}
                    <li className="more" role="none">
                      <div className="mainHeader-item">
                        <div>
                          <span>More</span>
                          <img className="vector_arrow" src={white_arrow} alt="vector" />
                        </div>
                      </div>
                      <div className="navlink-dropdown more-dropdown">
                        <ul>
                          {token && (
                            <Link to="/howtogaincoins">
                              <li>
                                <div className="icon_container">
                                  {/* <LazyImage src={ReferEarn} placeholder={ReferEarn} alt="" /> */}
                                  <img alt="icon" src={howToGainCoinsImg} />
                                </div>
                                <div className="text_container">
                                  How To Gain Coins
                                </div>
                              </li>
                            </Link>
                          )}
                          {token && (
                            <Link to="/referandearn">
                              <li>
                                <div className="icon_container">
                                  {/* <LazyImage src={ReferEarn} placeholder={ReferEarn} alt="" /> */}
                                  <img alt="icon" src={ReferEarn} />
                                </div>
                                <div className="text_container">
                                  Refer and Earn
                                </div>
                              </li>
                            </Link>
                          )}
                          <Link to="/promotion">
                            <li>
                              <div className="icon_container">
                                {/* <LazyImage src={Promotions} placeholder={Promotions} alt="" /> */}
                                <img alt="icon" src={Promotions} />
                              </div>
                              <div className="text_container">Promotions</div>
                            </li>
                          </Link>
                          {token && (
                            <Link to="/settings">
                              <li>
                                <div className="icon_container">
                                  {/* <LazyImage src={setting} placeholder={setting} alt="" /> */}
                                  <img alt="icon" src={setting} />
                                </div>
                                <div className="text_container">Settings</div>
                              </li>
                            </Link>
                          )}
                          <Link to="/faqs">
                            <li>
                              <div className="icon_container">
                                {/* <LazyImage src={faq} placeholder={faq} alt="" /> */}
                                <img alt="icon" src={faq} />
                              </div>
                              <div className="text_container">FAQ's</div>
                            </li>
                          </Link>
                          <Link to="/gamesfeedback">
                            <li>
                              <div className="icon_container">
                                {/* <LazyImage src={feedback} placeholder={feedback} alt="" /> */}
                                <img alt="icon" src={feedback} />
                              </div>
                              <div className="text_container">Feedback</div>
                            </li>
                          </Link>
                          <Link to="/terms-and-conditions">
                            <li>
                              <div className="icon_container">
                                {/* <LazyImage src={TandC} placeholder={TandC} alt="" /> */}
                                <img alt="icon" src={TandC} />
                              </div>
                              <div className="text_container">T&C's</div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </li>
                    {/* )} */}
                  </ul>
                </div>
                <div className="mainHeader-rightSection">
                  {isSignInSuccess ? (
                    <div className="mainHeader-afterLogin">
                      <div className="mainHeader-profile">
                        <div className="coin_section">
                          <div className="userid">
                            {userDetails?.username &&
                              `#${userDetails?.username}`}
                          </div>
                          <div className="user_coin">
                            <div className="coin_container">
                              <LazyImage src={Coin} placeholder={Coin} alt="" />
                              {/* <img src={Coin} alt="coinIcon" /> */}
                            </div>

                            <div>{userDetails?.coin}</div>
                          </div>
                        </div>
                        <div className="profileIcon">
                          <div className="show-desktop hide-mobile">
                            {userDetails?.avatarPath ? (
                              <LazyImage src={userDetails?.avatarPath} placeholder={userDetails?.avatarPath} alt="" />
                              // <img
                              //   src={userDetails?.avatarPath}
                              //   alt="coinIcon"
                              // />
                            ) : (
                              <LazyImage src={profile} placeholder={profile} alt="" />
                              // <img src={profile} alt="coinIcon" />
                            )}
                          </div>
                          <Link to={"/personal-details"}>
                            {" "}
                            <div className="show-mobile">
                              {userDetails?.avatarPath ? (
                                <LazyImage src={userDetails?.avatarPath} placeholder={userDetails?.avatarPath} alt="" />
                                // <img
                                //   src={userDetails?.avatarPath}
                                //   alt="coinIcon"
                                // />
                              ) : (
                                <LazyImage src={profile} placeholder={profile} alt="" />
                                // <img src={profile} alt="coinIcon" />
                              )}
                            </div>
                          </Link>
                          <div className="navlink-dropdown profile">
                            <ul>
                              <Link to="/personal-details">
                                <li>
                                  <div className="icon_container">
                                    {/* <LazyImage src={loginIcon} placeholder={loginIcon} alt="" /> */}
                                    <img alt="icon" src={loginIcon} />
                                  </div>
                                  <div className="text_container">
                                    My profile
                                  </div>
                                </li>
                              </Link>
                              <li onClick={() => dispatch(logoutUser(userDetails?.id))}>
                                <div className="icon_container">
                                  {/* <LazyImage src={logout} placeholder={logout} alt="" /> */}
                                  <img alt="icon" src={logout} />
                                </div>
                                <div className="text_container">Logout</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mainHeader-beforeLogin">
                      <button className="btn login_btn" id="LOGIN" onClick={() => navigate("/signin")}>
                        Login
                      </button>
                      <button className=" btn signup_btn" onClick={() => navigate("/signup")}>
                        Join
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isSideMenuOpened && <div class="SIDEMENU-mask" id="SIDEMENU-mask" onClick={() => dispatch(setIsSideMenuOpened(!isSideMenuOpened))}></div>}
        <div className={`sidemenu ${isSideMenuOpened ? " " : "hide"}`}>
          <div className="sidemenu-head">
            {!token && (
              <div className="sidemenu-head-text">
                <p>
                  Register now and <br />
                  receive upto {coins?.signUp} Free Coins immediately!
                </p>
                <div className="mainHeader-loginRegister">
                  <Link to={"/signup"}>
                    <button className="btn outline" id="SIGNUP">
                      Claim now
                    </button>
                  </Link>
                </div>
              </div>
            )}
            {token && (
              <div className="side_menu_logo" onClick={() => toggleSideMenu()}>
                <Link to="/">
                  <LazyImage src={gameLogo} placeholder={gameLogo} alt="" />
                  {/* <img
                    src={gameLogo}
                    alt="Loading"
                    role="none"
                    loading="eager"
                  /> */}
                </Link>
              </div>
            )}
            {/* <div
              className="sidemenu-closeIcon"
              onClick={() => toggleSideMenu()}
            >
              <AiOutlineCloseCircle />
            </div> */}
          </div>
          <div className="sidemenu-body">
            <ul>
              {!token && (
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <LazyImage src={profileLogo} placeholder={profileLogo} alt="" />
                      {/* <img alt="icon" src={profileLogo} /> */}
                    </div>
                    {!token && (
                      <div className="list_title">
                        <Link to={"/signin"}>Login</Link>
                      </div>
                    )}
                    {!token && (
                      <div>
                        <Link to={"/signup"}>
                          <button className="btn signup" id="SIGNUP">
                            Join
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </li>
              )}
              {token && (
                <li onClick={() => toggleSideMenu()}>
                  <Link to={"/personal-details"}>
                    <div className="left">
                      <div className="icon_container">
                        {/* <LazyImage src={profileLogo} placeholder={profileLogo} alt="" /> */}
                        <img alt="icon" src={profileLogo} loading="lazy" />
                      </div>
                      <div className="list_title">My Profile</div>
                    </div>
                  </Link>
                </li>
              )}
              {token && (
                <li onClick={() => toggleSideMenu()}>
                  <Link to={"/howtogaincoins"}>
                    <div className="left">
                      <div className="icon_container">
                        {/* <LazyImage src={howToGainCoinsImg} placeholder={howToGainCoinsImg} alt="" /> */}
                        <img alt="icon" src={howToGainCoinsImg} loading="lazy" />
                      </div>
                      <div className="list_title">How To Gain Coins</div>
                    </div>
                  </Link>
                </li>
              )}
              {token && (
                <li onClick={() => toggleSideMenu()}>
                  <Link to={"/settings"}>
                    <div className="left">
                      <div className="icon_container sidemenu_settings_icons">
                        {/* <LazyImage src={settingsImg} placeholder={settingsImg} alt="" /> */}
                        <img alt="icon" src={settingsImg} loading="lazy" />
                      </div>
                      <div className="list_title">Settings</div>
                    </div>
                  </Link>
                </li>
              )}
              {/* <li onClick={() => toggleSideMenu()}>
                <Link to={""}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={Personalize} />
                    </div>
                    <div className="list_title">Personalize</div>
                  </div>
                </Link>
              </li> */}
              <li onClick={() => toggleSideMenu()}>
                <Link to={"/news"}>
                  <div className="left">
                    <div className="icon_container news_img">
                      {/* <LazyImage src={news} placeholder={news} alt="" /> */}
                      <img className="news_img" alt="icon" src={news} loading="lazy" />
                    </div>
                    <div className="list_title">News</div>
                  </div>
                </Link>
              </li>
              <SideMenuAccordion toggleSideMenu={toggleSideMenu} />
              {/* {<Link to={"/game/pick6"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={pickSixIcon} />
                    </div>
                    <div className="list_title">Pick 6</div>
                  </div>
                </li>
              </Link>} */}
              {/* {token && <Link to={"/game/mpcric"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={MpIcon} />
                    </div>
                    <div className="list_title">Match Predictor</div>
                  </div>
                </li>
              </Link>} */}
              {/* {<Link to={"/game/matchpredictor"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={MpIcon} />
                    </div>
                    <div className="list_title">Match Predictor</div>
                  </div>
                </li>
              </Link>} */}
              {/* {<Link to={"/game/penaltyshootout"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={penaltyShootoutIcon} />
                    </div>
                    <div className="list_title">Penality Shootout</div>
                  </div>
                </li>
              </Link>} */}
              {/* {<Link to={"/game/vctt"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={VcttIcon} />
                    </div>
                    <div className="list_title">Virtual Catch The Trend</div>
                  </div>
                </li>
              </Link>} */}
              {/* <li onClick={() => toggleSideMenu()}>
                <Link to={""}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={playandwin} />
                    </div>
                    <div className="list_title">Play and Win</div>
                  </div>
                </Link>
              </li> */}
              {/* <li onClick={() => toggleSideMenu()}>
                <Link to={""}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={champion} />
                    </div>
                    <div className="list_title">Prize wall</div>
                  </div>
                </Link>
              </li> */}
              <li onClick={() => toggleSideMenu()}>
                <Link to={"/scores"}>
                  <div className="left">
                    <div className="icon_container">
                      {/* <LazyImage src={scores} placeholder={scores} alt="" /> */}
                      <img alt="icon" src={scores} loading="lazy" />
                    </div>
                    <div className="list_title">Scores</div>
                  </div>
                </Link>
              </li>
              <li onClick={() => toggleSideMenu()}>
                <Link to={"/coinsmeanprizes"}>
                  <div className="left">
                    <div className="icon_container">
                      {/* <LazyImage src={store} placeholder={store} alt="" /> */}
                      <img className="store_img" alt="icon" src={store} loading="lazy" />
                    </div>
                    <div className="list_title">Store</div>
                  </div>
                </Link>
              </li>
              {token && (
                <li onClick={() => toggleSideMenu()}>
                  <Link to={"/referandearn"}>
                    <div className="left">
                      <div className="icon_container">
                        {/* <LazyImage src={ReferEarn} placeholder={ReferEarn} alt="" /> */}
                        <img alt="icon" src={ReferEarn} loading="lazy" />
                      </div>
                      <div className="list_title">Refer and Earn</div>
                    </div>
                  </Link>
                </li>
              )}
              {/* <li>
                                <div className="left">
                                    <div className="icon_container">
                                        <img alt='icon' src={BecomeaVIP} />
                                    </div>
                                    <div className="list_title">
                                        Become A VIP
                                    </div>

                                </div>
                            </li> */}
              <li onClick={() => toggleSideMenu()}>
                <Link to={"/promotion"}>
                  <div className="left">
                    <div className="icon_container">
                      {/* <LazyImage src={Promotions} placeholder={Promotions} alt="" /> */}
                      <img alt="icon" src={Promotions} loading="lazy" />
                    </div>
                    <div className="list_title">Promotions</div>
                  </div>
                </Link>
              </li>
              {/* <li onClick={() => toggleSideMenu()}>
                <Link to={"/privacy-policy"}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={Promotions} />
                    </div>
                    <div className="list_title">PrivacyPolicy</div>
                  </div>
                </Link>
              </li> */}
              {/* {token && <Link to={"/settings"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={setting} />
                    </div>
                    <div className="list_title">Settings</div>
                  </div>
                </li>
              </Link>} */}

              {/* {token && games?.games?.map((game, index) => {
                return (
                  <SideMenuAccordion src={game.icon} title={game.category} gameData={game} key={index} />
                )
              })} */}
              {/* <Link to={"/coinsmeanprizes"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={rewards} />
                    </div>
                    <div className="list_title">Rewards</div>
                  </div>
                </li>
              </Link> */}
              {
                <Link to={"/faqs"} onClick={() => toggleSideMenu()}>
                  <li>
                    <div className="left">
                      <div className="icon_container">
                        {/* <LazyImage src={faq} placeholder={faq} alt="" /> */}
                        <img alt="icon" src={faq} loading="lazy" />
                      </div>
                      <div className="list_title">FAQ's</div>
                    </div>
                  </li>
                </Link>
              }
              {/* {<Link to={""} onClick={() => contactUsNavigator()()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={contactmail} />
                    </div>
                    <div className="list_title" >Contact Us</div>
                  </div>
                </li>
              </Link>} */}
              <a
                onClick={() => {
                  feedBackNavigator();
                  toggleSideMenu();
                }}
              >
                <li>
                  <div className="left">
                    <div className="icon_container">
                      {/* <LazyImage src={feedback} placeholder={feedback} alt="" /> */}
                      <img alt="icon" src={feedback} loading="lazy" />
                    </div>
                    <div className="list_title">Feedback/Contact Us</div>
                  </div>
                </li>
              </a>
              {
                <Link
                  to={"/terms-and-conditions"}
                  onClick={() => toggleSideMenu()}
                >
                  <li>
                    <div className="left">
                      <div className="icon_container">
                        {/* <LazyImage src={TandC} placeholder={TandC} alt="" /> */}
                        <img alt="icon" src={TandC} loading="lazy" />
                      </div>
                      <div className="list_title">T&C's</div>
                    </div>
                  </li>
                </Link>
              }
              {token && (
                <li onClick={() => dispatch(logoutUser(userDetails?.id))}>
                  <div className="left">
                    <div className="icon_container">
                      {/* <LazyImage src={logout} placeholder={logout} alt="" /> */}
                      <img alt="icon" src={logout} loading="lazy" />
                    </div>
                    <div className="list_title">Logout</div>
                  </div>
                </li>
              )}
            </ul>
            <div
              className="sidemenu-closeIcon"
              onClick={() => toggleSideMenu()}
            >
              <AiOutlineCloseCircle />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
