import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";


const initialState = {
    isLoading: false,
    lineupsData: [],
    commentaryData: [],
    eventData: [],
    headToHeadData: [],
    topLiveScoreBanner: {},
    teamDetails: {},
    matchInfoData: {}
};

export const LineupsSlice = createSlice({
    name: "lineups",
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setLineupsData(state, action) {
            state.lineupsData = action.payload;
        },
        setCommentaryData(state, action) {
            state.commentaryData = action.payload;
        },
        setEventData(state, action) {
            state.eventData = action.payload;
        },
        setHeadToHeadData(state, action) {
            state.headToHeadData = action.payload;
        },
        setTopLiveScoreBanner(state, action) {
            state.topLiveScoreBanner = action.payload;
        },
        setTeamDetails(state, action) {
            state.teamDetails = action.payload;
        },
        setMatchInfoData(state, action){
            state.matchInfoData = action.payload;
        }
    },
})

export const {
    setIsLoading,
    setLineupsData,
    setCommentaryData,
    setEventData,
    setHeadToHeadData,
    setTopLiveScoreBanner,
    setTeamDetails,
    setMatchInfoData
} = LineupsSlice.actions

export const getMatchInfo = (matchId, type) => (dispatch) => {
    dispatch(setIsLoading(true));
    // dispatch(setLineupsData([]));
    // dispatch(setCommentaryData([]));
    // dispatch(setTeamDetails([]));
    let url = endpoints.matchInfo + `?mid=${matchId}&type=${type}`
    getRequest(url, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setMatchInfoData(res?.data?.data));
                if (type === "lineup") {
                    dispatch(setLineupsData(res?.data?.data?.lineup));
                }
                if (type === "" || type === undefined) {
                    dispatch(setTeamDetails(res?.data?.data));
                }
                if (type === "commentary") {
                    dispatch(setCommentaryData(res?.data?.data?.commentary));
                }
                if (type === "event") {
                    dispatch(setEventData(res?.data?.data))
                }
                if (type === "headtohead") {
                    dispatch(setHeadToHeadData(res?.data?.data));
                }
            }
            dispatch(setIsLoading(false))
        })
        .catch(err => {
            console.log(`Error in getting News feed`)
            dispatch(setIsLoading(false))
        });
};


export default LineupsSlice.reducer
