import React from "react";
import LiveScore from "./LiveScore";
import TopMenu from "./TopMenu";
import argentinos from "../../assets/images/png/fixtures/argentinos.png"
import lineup from "../../assets/images/png/fixtures/lineup.png"

const TopPlayerAndRating = () => {
    return (
        <React.Fragment>
            <div className="container">
                <LiveScore />
                <div className="top_player_wrapper">
                    <TopMenu />
                    <h4 className="top_heading">Top Player & Rating</h4>
                    <div className="player_block">
                        <div className="player_info_head">
                            <div className="heading space-2">Shots</div>
                            <div className="space-2">Goals</div>
                            <div className="space-2">On target</div>
                            <div className="space-2">Total</div>
                        </div>
                        <div className="player_info_body">
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={lineup} alt="" />A. Russo</div>
                                <div className="space-2">0</div>
                                <div className="space-2">2</div>
                                <div className="space-2">4</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={lineup} alt="" />R. Daly</div>
                                <div className="space-2">0</div>
                                <div className="space-2">2</div>
                                <div className="space-2">3</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={argentinos} alt="" />R. Daly</div>
                                <div className="space-2">0</div>
                                <div className="space-2">1</div>
                                <div className="space-2">4</div>
                            </div>
                        </div>
                    </div>

                    <div className="player_block">
                        <div className="player_info_head">
                            <div className="heading space-2">ChancesCreated</div>
                            <div className="space-2"></div>
                            <div className="space-2">Assists</div>
                            <div className="space-2">Chances</div>
                        </div>
                        <div className="player_info_body">
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={lineup} alt="" />A.Greenwood</div>
                                <div className="space-2"></div>
                                <div className="space-2">0</div>
                                <div className="space-2">4</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={lineup} alt="" />G.Stanway</div>
                                <div className="space-2"></div>
                                <div className="space-2">0</div>
                                <div className="space-2">3</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={argentinos} alt="" />G.Stanway</div>
                                <div className="space-2"></div>
                                <div className="space-2">0</div>
                                <div className="space-2">7</div>
                            </div>
                        </div>
                    </div>

                    <div className="player_block">
                        <div className="player_info_head">
                            <div className="heading space-2">Passes</div>
                            <div className="space-2"></div>
                            <div className="space-2">Successful</div>
                            <div className="space-2">Total</div>
                        </div>
                        <div className="player_info_body">
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={lineup} alt="" />A.Greenwood</div>
                                <div className="space-2"></div>
                                <div className="space-2">65</div>
                                <div className="space-2">74</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={lineup} alt="" />K.Walsh</div>
                                <div className="space-2"></div>
                                <div className="space-2">55</div>
                                <div className="space-2">65</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={argentinos} alt="" />T.Plumptre</div>
                                <div className="space-2"></div>
                                <div className="space-2">47</div>
                                <div className="space-2">62</div>
                            </div>
                        </div>
                    </div>

                    <div className="player_block">
                        <div className="player_info_head">
                            <div className="heading space-2">PassingAccuracy(%)</div>
                            <div className="space-2"></div>
                            <div className="space-2"></div>
                            <div className="space-2"></div>
                        </div>
                        <div className="player_info_body">
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={lineup} alt="" />T.Plumptre</div>
                                <div className="space-2"></div>
                                <div className="space-2"></div>
                                <div className="space-2">82</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={lineup} alt="" />L.Bronze</div>
                                <div className="space-2"></div>
                                <div className="space-2"></div>
                                <div className="space-2">69</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={argentinos} alt="" />R.Daly</div>
                                <div className="space-2"></div>
                                <div className="space-2"></div>
                                <div className="space-2">68</div>
                            </div>
                        </div>
                    </div>

                    <div className="player_block">
                        <div className="player_info_head">
                            <div className="heading space-2">Crosses</div>
                            <div className="space-2"></div>
                            <div className="space-2">Successful</div>
                            <div className="space-2">Total</div>
                        </div>
                        <div className="player_info_body">
                            <div className="body_containt">
                                <div className="team_name space-2"><img src={lineup} alt="" />A.Greenwood</div>
                                <div className="space-2"></div>
                                <div className="space-2">6</div>
                                <div className="space-2">8</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={lineup} alt="" />G.Stanway</div>
                                <div className="space-2"></div>
                                <div className="space-2">4</div>
                                <div className="space-2">8</div>
                            </div>
                            <div className="body_containt">
                                <div className="team_name  space-2"><img src={argentinos} alt="" />T.Plumptre</div>
                                <div className="space-2"></div>
                                <div className="space-2">2</div>
                                <div className="space-2">6</div>
                            </div>
                        </div>
                    </div>

                    <div className="button">
                        <button className="btn view_button">View All</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default TopPlayerAndRating;