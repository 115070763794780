import React from "react";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { useNavigate } from "react-router";
import moment from "moment";

const FeaturedNews = ({ FeaturedData }) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className="featured_wrapper">
                <h4>Featured News</h4>
                <Splide
                    options={{
                        type: "false",
                        omitEnd: true,
                        autoWidth: false,
                        pagination: false,
                        arrows: true,
                        drag: true,
                        gap: "10",
                    }}
                >
                    {
                        FeaturedData?.map((item, ind) => (
                            <SplideSlide key={ind}>
                                <div className="news_text_containt pointer">
                                    <div
                                        onClick={() => navigate(`article/${item.newsID}`)}
                                        dangerouslySetInnerHTML={{
                                            __html: item?.newsText,
                                        }}
                                    ></div>
                                    <div className="news_published_date">
                                        {
                                            moment(item?.newsPublished).format("ll") == moment(new Date).format("ll") ?
                                                <>{moment(item?.newsPublished).startOf(new Date()).fromNow()}</>
                                                :
                                                <>{moment(item?.newsPublished).format("ll")}</>
                                        }
                                    </div>
                                </div>
                            </SplideSlide>
                        ))
                    }
                </Splide>
            </div>
        </React.Fragment >
    )
};
export default FeaturedNews;