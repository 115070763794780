import React, { useEffect, useState } from "react";
import star from "../../assets/images/png/star.png";
import videoIcon from "../../assets/images/svg/playbutton.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import LazyImage from "../common/LazyImage";
import moment from "moment";
import dummyHighlight from "../../assets/images/png/fixtures/highlightPlaceholder.jpg"

const Highlights = () => {
  //const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredHighlightsData, setFilteredHighlightsData] = useState([])
  const highlightsData = useSelector(
    (state) => state.highlights.highlightsData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (highlightsData) {
      const filterDate = new Date();
      const filteredData = highlightsData.filter(item => new Date(item.date) < filterDate);
      filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setFilteredHighlightsData(filteredData);
    }
  }, [highlightsData]);

  const dateFormat = (date) => {
    const filterDate = moment(date).format("ll");
    const updatedDate = filterDate.split(" ")
    return updatedDate;
  }

  //console.log("highlightsData===", highlightsData);
  return (
    <React.Fragment>
      <div className="container">
        <div className="highlight_main_block">
          <div className="highlight_heading">
            <div className="heading_block">
              <LazyImage src={star} placeholder={star} alt="" />
              {/* <img src={star} alt="star" />  */}
              Highlights
            </div>
            <div className="viewallhighlights_link">
              <Link to="/viewallhighlights">
                <span className="viewall_txt">View all
                  <MdOutlineKeyboardArrowRight className="viewall_btn" />
                </span>
              </Link>
            </div>
          </div>

          <div className="highlight_wrapper">
            <Splide
              hasTrack={false}
              className="HighlightSplider"
              options={{
                type: filteredHighlightsData?.length > 3 ? "loop" : "slide",
                autoplay: true,
                autoWidth: true,
                arrows: false,
                gap: "0.5em",
                pagination: false,
                lazyLoad: 'sequential'
              }}
            >
              <SplideTrack>
                {filteredHighlightsData &&
                  filteredHighlightsData?.length > 0 &&
                  filteredHighlightsData?.map((item, ind) => {
                    return (
                      <SplideSlide key={ind}>
                        <div className="highlight_block">
                          <Link to={`/video/${item?._id}`}>
                            <div className="highlights_Video">
                              <div className="video_icon">
                                {/* <LazyImage
                                  src={videoIcon}
                                  placeholder={videoIcon}
                                  alt=""
                                /> */}
                                <img src={videoIcon} data-splide-lazy={videoIcon} alt="videoIcon" />
                              </div>
                              {/* <LazyImage
                                src={item?.thumbnail}
                                placeholder={item?.thumbnail}
                                alt=""
                              /> */}
                              <img src={item?.thumbnail} 
                              data-splide-lazy={item?.thumbnail ? item?.thumbnail : ""}
                              alt="highlight"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = dummyHighlight;
                              }}
                              />
                            </div>
                          </Link>
                          <div className="team_name">{item?.title}</div>
                          <div className="date">{dateFormat(item?.date)[1].split(",")[0]} {dateFormat(item?.date)[0]} {dateFormat(item?.date)[2]}</div>
                        </div>
                      </SplideSlide>
                    );
                  })}
              </SplideTrack>
            </Splide>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Highlights;
