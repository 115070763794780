import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";

const initialState = {
    isLoading: false,
    matchStatsData: [],
    statisticsData: [],
};

export const MatchStatsSlice = createSlice({
    name: "matchstats",
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setMatchStatsData(state, action) {
            state.matchStatsData = action.payload
        },
        setStatisticsData(state, action) {
            state.statisticsData = action.payload
        },
    }
})

export const {
    setIsLoading, setMatchStatsData, setStatisticsData
} = MatchStatsSlice.actions

export const getMatchStats = (matchId, period) => (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setMatchStatsData([]));
    dispatch(setStatisticsData([]))
    let url = endpoints.matchStats + `?mid=${matchId}&period=${period}`
    getRequest(url, false)
    .then((res) => {
        if(res.status === 200) {
            dispatch(setMatchStatsData(res?.data?.data))
            if(period === "full"){
                dispatch(setStatisticsData(res?.data?.data?.statistics))
                dispatch(setIsLoading(false))
            }
            if(period === "first"){
                dispatch(setStatisticsData(res?.data?.data?.statistics_period1))
                dispatch(setIsLoading(false))
            }
            if(period === "second"){
                dispatch(setStatisticsData(res?.data?.data?.statistics_period2)) 
                dispatch(setIsLoading(false))
            }
            dispatch(setIsLoading(false))
        }
    })
    .catch(err => {
        console.log("Error in fetching Match Stats");
        dispatch(setIsLoading(false));
    })
}

export default MatchStatsSlice.reducer