import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    playerRefferedData : [],
    errorMessage : false,
    playerRefferedLoading: false,
};

export const playerRefferedSlice = createSlice({
    name: "playerReffered",
    initialState,
    reducers: {
        setPlayerRefferedData: (state, action) => {
            state.playerRefferedData = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setPlayerRefferedLoading: (state, action) => {
            state.playerRefferedLoading = action.payload;
        },
    }
});

export const {
    setPlayerRefferedData,
    setErrorMessage,
    setPlayerRefferedLoading
} = playerRefferedSlice.actions;


export default playerRefferedSlice.reducer;
