import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";


const initialState = {
    isHomeLoading: false,
    isAwayLoading: false,
    homePreviewData: [],
    awayPreviewData: [],

};

export const PreviewSlice = createSlice({
    name: "preview",
    initialState,
    reducers: {
        setIsHomeLoading(state, action) {
            state.isHomeLoading = action.payload;
        },
        setIsAwayLoading(state, action) {
            state.isAwayLoading = action.payload;
        },
        setHomePreviewData(state, action) {
            state.homePreviewData = action.payload;
        },
        setAwayPreviewData(state, action) {
            state.awayPreviewData = action.payload;
        },
    },
})

export const {
    setIsHomeLoading,
    setIsAwayLoading,
    setHomePreviewData,
    setAwayPreviewData
} = PreviewSlice.actions;

export const getHomePreviewInfo = (teamId, type) => (dispatch) => {
    dispatch(setIsHomeLoading(true));
    dispatch(setHomePreviewData([]));
    let url = endpoints.teamForm + `?tid=${teamId}&type=${type}`
    getRequest(url, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setHomePreviewData(res?.data?.data))
            }
            dispatch(setIsHomeLoading(false))
        })
        .catch(err => {
            console.log(`Error in getting News feed`)
            dispatch(setIsHomeLoading(false))
        });
};

export const getAwayPreviewInfo = (teamId, type) => (dispatch) => {
    dispatch(setIsAwayLoading(true));
    dispatch(setAwayPreviewData([]));
    let url = endpoints.teamForm + `?tid=${teamId}&type=${type}`
    getRequest(url, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setAwayPreviewData(res?.data?.data))
            }
            dispatch(setIsAwayLoading(false))
        })
        .catch(err => {
            console.log(`Error in getting News feed`)
            dispatch(setIsAwayLoading(false))
        });
};

export default PreviewSlice.reducer