import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LOGO from "../../assets/images/png/PlayBig_Logo.png";
import { ButtonLoader } from '../common/Loader';
import Timer from '../signup/Timer';
import { setErrorMessage, setOtpError } from '../signup/SignUpSlice';
import { mobileVerification, otpVerification, resendOtp } from '../signup/SignUpApi';
import { removeFromLocalStorage } from '../../services/helper/Index';
import BackButton from '../common/BackButton';

function OTPScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [disabledBtn, setDisableBtn] = useState(false);
    const [resendOTP, setResendOTP] = useState(false);
    const [backspace, setBackspace] = useState(false);

    const {
        otpSuccess,
        otpError,
        errorMessage,
        loader,
        phoneNumber,
        countryCode
    } = useSelector((state) => state.signup);

    const handleChange = (element, index) => {
        dispatch(setOtpError(false))
        if (isNaN(element.target.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.target.value : d))]);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(setOtpError(false));
        const otpvalid = otp.includes("");
        if (otpvalid) {
            dispatch(setOtpError(true));
            dispatch(setErrorMessage("Enter Valid OTP"));
        } else {
            dispatch(setOtpError(false));
            dispatch(otpVerification(otp, navigate));
        }
    };

    useEffect(() => {
        dispatch(setOtpError(false));
    }, []);

    useEffect(() => {
        const otpvalid = otp.includes("");
        if (otpvalid) {
            setDisableBtn(false)
        } else {
            setDisableBtn(true)
        };
    }, [otpSuccess, otp, navigate]);

    const handleResendOtp = () => {
        dispatch(phoneNumber ? mobileVerification(+phoneNumber, countryCode, navigate) : resendOtp())
        setResendOTP(false)
        dispatch(setOtpError(false));
        setOtp(["", "", "", ""])
    };

    const handleKeyUp = (event) => {
        if (event.nativeEvent.key === "Backspace" ||
            event.key === "Backspace" ||
            event.key === "Delete" ||
            event.keyCode === 8 ||
            event.keyCode === 46 ||
            event.nativeEvent.inputType == "deleteContentBackward") {
            if (event.target.previousSibling) {
                event.target.previousSibling.focus();
            }
        } else if (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode >= 48 && event.keyCode <= 57) {
            if (event.target.nextSibling) {
                event.target.nextSibling.focus();
            }
        }
    };

    const handleLogo = () => {
        navigate("/");
        window.location.reload();
        removeFromLocalStorage("ak")
    };

    return (
        <div className='otp_wrapper'>
            <BackButton />
            <div className="container">
                <div>
                    <img src={LOGO} alt="logo" className="sign_logo pointer" onClick={handleLogo} />
                </div>
                <form className="sign_box otp_box" onSubmit={handleSubmit}>
                    <div className="title">
                        OTP VERIFICATION
                    </div>
                    {phoneNumber ? <p>PLEASE ENTER 4 DIGIT CODE YOU RECEIVED TO YOUR PHONE NUMBER AND VERIFY</p>
                        : <p>PLEASE ENTER 4 DIGIT CODE YOU RECEIVED TO YOUR EMAIL AND VERIFY</p>
                    }

                    <div className="otp_filed_box">
                        {otp?.map((data, index) => {
                            return (
                                <input
                                    // className="otp-field"
                                    // type="text"
                                    type='tel'
                                    name="otp"
                                    maxLength={1}
                                    key={index}
                                    value={data}
                                    onChange={(e) => handleChange(e, index)}
                                    onFocus={(e) => e.target.select()}
                                    onKeyUp={handleKeyUp}
                                />
                            );
                        })}
                    </div>
                    <div className="resend_otp">
                        {resendOTP ? <u className='pointer' onClick={handleResendOtp}>RESEND OTP</u>
                            :
                            <>
                                OTP SENT <Timer reset={() => setResendOTP(true)} time={180} /> sec
                            </>
                        }
                    </div>
                    {
                        otpError && <div className='error-msg'>{errorMessage}</div>
                    }
                    <button
                        type='submit'
                        disabled={!disabledBtn}
                        className={`${disabledBtn ? "btn btn-strech otp_btn" : "btn btn-strech otp_btn disabled"}`}
                    >
                        {loader ? <ButtonLoader /> : "VERIFY"}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default OTPScreen
