import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import ScoreCard from "./FixtureCard";
import puerto from "../../assets/images/png/fixtures/puerto.png";
import curacao from "../../assets/images/png/fixtures/curacao.png";
import instituto from "../../assets/images/png/fixtures/instituto.png";
import racing from "../../assets/images/png/fixtures/racing.png";
import velez from "../../assets/images/png/fixtures/velez.png";
import argentinos from "../../assets/images/png/fixtures/argentinos.png";
import colon from "../../assets/images/png/fixtures/colon.png";
import estundiantes from "../../assets/images/png/fixtures/estundiantes.png";
import def from "../../assets/images/png/fixtures/def.png";
import bengrano from "../../assets/images/png/fixtures/bengrano.png";
import argentina from "../../assets/images/png/fixtures/argentina.png";
import { noDataMessage } from "../../config/appConfig";
import Footer from "../common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getMatches, setMatchesData } from "./cl_MatchesSlice";
import { ContentLoader, DotsContentLoader } from "../common/Loader";
import NoData from "../noData";
import { competitionEvents, subscribeCompetitionInfo, unSubscribeCompetitionInfo } from "../../services/socket/scores";

const Fixture = () => {
    const { matchesData, isLoading, searchResults } = useSelector((state) => state.matches)
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("all");

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
        // dispatch(getMatches(null, tab === "all" ? null : activeTab, searchText));
    };
    const [searchText, setSearchText] = useState("");
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    const onChangeText = (e) => {
        const newSearchText = e.target.value;
        setSearchText(newSearchText);

    };

    useEffect(() => {
        subscribeCompetitionInfo();
        competitionEvents();

        return () => unSubscribeCompetitionInfo()
    }, []);
    useEffect(() => {
        window.addEventListener(
            "competition-fixtures",
            (e) => {
                console.log("fixtures Data===", e?.detail?.data);
                const updatedScoresHome = updateCompetitionMatches(matchesData, e?.detail?.data);
                dispatch(setMatchesData(updatedScoresHome));
            },
            false
        );
    }, [matchesData]);

    function updateCompetitionMatches(existingData, newData) {
        const updatedCmpList = existingData?.map((competition) => {
            const updatedCmpMatches = competition.competition_matches.map((match) => {
                if (match.mid === newData.mid) {
                    return {
                        ...match,
                        ...newData
                    };
                }
                return match;
            });

            return {
                ...competition,
                competition_matches: updatedCmpMatches
            };
        });

        return updatedCmpList;
    }

    return (
        <React.Fragment>
            <Footer title={"scores"} />
            <SearchBar
                searchText={searchText}
                onChangeText={onChangeText}
                onSearch={() => dispatch(getMatches(null, activeTab, searchText))}
            />
            {
                isLoading ? <DotsContentLoader /> : (
                    (searchText !== "" ? searchResults : matchesData).length > 0 ?
                        (searchText !== "" ? searchResults :
                            matchesData).map((val, ind) => (
                                <React.Fragment key={ind}>
                                    <ScoreCard
                                        title={` ${val.cCategory} - ${val.cname}`}
                                        logo={""}
                                        fixturesData={val?.competition_matches}
                                    />
                                </React.Fragment>
                            ))
                        :
                        <div className="container_score">
                            <NoData message={noDataMessage} />
                        </div>
                )
            }

        </React.Fragment>
    )
}
export default Fixture;