import React, { useEffect, useState } from 'react'
import Games from "../common/HitSix"
import Winner from "../common/Winner"
import LeaderBoard from "../common/LeaderBoard"
import hit6Icon from "../../assets/images/png/hit6-logo.png"
import liveIcon from "../../assets/images/png/liveIcon.png"
import Coin from "../../assets/images/png/coin.png"
import { useNavigate } from 'react-router-dom'
import { getFromLocalStorage, getLocalString } from '../../services/helper/Index'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

function HitSixGame(props) {
    const { hitSixData, hitSixBannerText } = props
    const { isSignInSuccess } = useSelector((state) => state.signIn);

    const [dateObj, setDateObj] = useState({})
    const navigate = useNavigate()

    const navigateToGame = () => {
        const token = getFromLocalStorage("gk")
        if (token && isSignInSuccess) {
            if (hitSixData && hitSixData?.isLive) {
                navigate("/game/hit6/ldb")
            } else {
                navigate("/game/hit6")
            }
        } else {
            navigate("/signin")
        }
    }

    const getExpiryTime = () => {
        const date = new Date(hitSixData?.closeOn)
        const time = date.toLocaleTimeString();
        const dayName = date.toLocaleDateString(undefined, { weekday: 'long' });
        const dateNum = addDateSuffix(date.getDate());
        const month = date.toLocaleDateString(undefined, { month: 'long' });
        const year = date.getFullYear();
        setDateObj({ time, dayName, dateNum, month, year })


        function addDateSuffix(date) {
            if (date >= 11 && date <= 13) {
                return date + "th";
            }
            switch (date % 10) {
                case 1: return date + "st";
                case 2: return date + "nd";
                case 3: return date + "rd";
                default: return date + "th";
            }
        }

        return { time, dayName, dateNum, month }
    }


    useEffect(() => {
        getExpiryTime()
    }, [hitSixData])


    return (
        <React.Fragment>
            {/* {hitSixData && Object.keys(hitSixData).length > 0 && */}
            <div className="container">
                <Games title={"Hit 6"}>
                    <div className="hit_six_card match_predictor_card">
                        <div className="top-section">
                            <div className="liveIcon">
                                {hitSixData && hitSixData?.isLive ? <img className='liveIcon' src={liveIcon} alt="lice ogo" /> : null}
                            </div>
                            {hitSixData && hitSixData?.betAmount &&
                                <div className='entry_button'><button>ENTRY =  {hitSixData?.betAmount}<img src={Coin} alt='coin' /></button></div>
                            }
                        </div>
                        <div className='game_title hitsix'>
                            <img src={hit6Icon} alt="hit6 logo" />
                        </div>
                        <div className="middle-section">
                            <div className="left">
                                <div dangerouslySetInnerHTML={{ __html: hitSixBannerText?.bannerText }}></div>
                                {/* <p dangerouslySetInnerHTML={{ __html: hitSixBannerText?.bannerExpiryTime }}></p> */}
                                {hitSixData && !hitSixData?.isLive && <p>{`Entries by ${dateObj.time} on ${dateObj.dayName} ${dateObj.dateNum} ${dateObj.month} ${dateObj.year} and terms T&Cs apply`}</p>}
                            </div>
                            <div className="right">
                                {hitSixData && hitSixData?.jacpotPrize &&
                                    <p><span className='prize_detail'>WIN</span><span className='prize_amount'><img src={Coin} alt='coin' /> {getLocalString(hitSixData?.jacpotPrize) || 0}</span></p>
                                }
                            </div>
                        </div>
                        <div className="bottom-section">
                            <button className="btn btnLarge" onClick={() => navigateToGame()}>{hitSixData?.isLive ? "View" : "Play Now"}</button>
                            <p><Link to="/coinsmeanprizes">Coins Mean Prizes </Link></p>
                        </div>
                    </div>
                </Games>
                {hitSixData && hitSixData?.players && hitSixData?.players.length > 0 && <LeaderBoard leaderBoardData={hitSixData?.players} navigateToLDB={"/game/hit6/ldb"} />}
                {hitSixData && hitSixData?.prevGameWinners && hitSixData?.prevGameWinners.length > 0 && <Winner WinnersData={hitSixData?.prevGameWinners} navigateToResult={"/game/hit6/result"} />}

            </div>
            {/* } */}
        </React.Fragment>
    )
}

export default HitSixGame
