import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    newsData: [],
    newsBannerData: [],
    isLoading: false,
    isActiveTab: "",
    isViewAllNews: false,
    newsSearchData: [],
    newsSearch: ""
};

export const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        setNewsData: (state, action) => {
            state.newsData = action.payload;
        },
        setNewsBannerData: (state, action) => {
            state.newsBannerData = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsActiveTab: (state, action) => {
            state.isActiveTab = action.payload;
        },
        setIsViewAllNews: (state, action) => {
            state.isViewAllNews = action.payload;
        },
        setNewsSearchData: (state, action) => {
            state.newsSearchData = action.payload;
        },
        setNewsSearch: (state, action) => {
            state.newsSearch = action.payload;
        }
    }
});

export const {
    setNewsData,
    setNewsBannerData,
    setIsLoading,
    setIsActiveTab,
    setIsViewAllNews,
    setNewsSearchData,
    setNewsSearch
} = newsSlice.actions;


export default newsSlice.reducer;
