import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";
import { filterCompetitionsByTeamLetter, getUTCTimeDiff, groupByCompetition } from "../../helper/cl_formatDate";
import moment from "moment";

const initialState = {
    isLoading: false,
    matchesData: [],
    selectedDate: {
        fd: '',
        index: '',
    },
    matchId: "",
    searchResults: [],
    cidId: "",
};

export const MatchesSlice = createSlice({
    name: "matchesList",
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setMatchesData(state, action) {
            state.matchesData = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload
        },
        setMatchId(state, action) {
            state.matchId = action.payload
        },
        // setLiveMatches(state, action) {
        //     state.matchesData = action.payload;
        // },
        setSearchResults(state, action) {
            state.searchResults = action.payload;
        },
        setCidId(state, action) {
            state.cidId = action.payload;
        },
    },
})

export const { setIsLoading, setMatchesData, setSelectedDate, setMatchId, setSearchResults, setCidId } = MatchesSlice.actions

export const getMatches = (date, showSearchResults) => (dispatch) => {
    dispatch(setIsLoading(true))
    dispatch(setMatchesData([]))
    const offsetTime = getUTCTimeDiff(moment(date));
    let url = endpoints.fixtures + `?date=${date}&offset=${offsetTime}`;
    getRequest(url)
        .then((res) => {
            if (res.status === 200) {
                const filteredData = groupByCompetition(res?.data?.data);
                dispatch(setMatchesData(filteredData))
                dispatch(setIsLoading(false))
                if (showSearchResults) {
                    dispatch(getSearchResults(filteredData, showSearchResults))
                    // dispatch(setIsLoading(false))
                }
            }
        })
        .catch(err => {
            console.log(`Error in fetching Matches`)
            dispatch(setIsLoading(false))
        });
};

export const getSearchResults = (data, key) => (dispatch) => {
    const data1 = filterCompetitionsByTeamLetter(data, key)
    dispatch(setSearchResults(data1));

}

export default MatchesSlice.reducer
