import { endpoints } from "../../api/ApiEndPoints"
import { getRequest } from "../../services/apiCaller";
import { setNewsData, setIsLoading, setNewsBannerData } from "./newsSlice";

export const getNewsData = () => (dispatch) => {
    const newsUrl = endpoints.news;
    dispatch(setIsLoading(true));
    getRequest(newsUrl, false)
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setNewsData(res?.data?.data));
                const data = res?.data?.data?.categories?.[0]?.data?.slice(0, 2);
                dispatch(setNewsBannerData(data))
                dispatch(setIsLoading(false));
            } else {
                dispatch(setIsLoading(false));
            }

        })
        .catch((err) => {
            console.log(err);
            dispatch(setIsLoading(false));
        })
}

