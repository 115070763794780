function SuperCard({ title, icon, children }) {
  return (
    <div className="super_card_wrapper">

      <div className="super_card">
        {children}
      </div>
    </div>
  )
}

export default SuperCard