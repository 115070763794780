import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    commonError: "",
    termsAndConditionData: "",
};

export const termsconditionSlice = createSlice({
    name: "termscondition",
    initialState,
    reducers: {
        setCommonError: (state, action) => {
            state.commonError = action.payload;
        },
        setTermsAndConditionData: (state, action) => {
            state.termsAndConditionData = action.payload;
        },
    }
});

export const {
    setCommonError,
    setTermsAndConditionData
} = termsconditionSlice.actions;


export default termsconditionSlice.reducer;