import React, { useState, useEffect } from "react";
import ModalBox from "../common/ModalBox";
import { useSelector, useDispatch } from "react-redux";
import { setResendOTP } from "./myProfileSlice";
import {
  setEmailErrorMessage,
  setEmailOtpErrorMessage,
  setPhoneErrorMessage,
  setPhoneOtpErrormessage,
} from "./myProfileSlice";
import {
  emailUpdateGenerateOtp,
  emailUpdateVerifyOtp,
  phoneUpdateGenerateOtp,
  phoneUpdateVerifyOtp,
} from "./myProfileApi";
import UserNameSuccessDialog from "./UserNameSuccessDialog";
import InputUpdate from "../verificationForms/InputUpdate";
import OtpInput from "../verificationForms/OtpInput";
import {
  MSISDNAcceptableDigits,
  MSISDNAcceptableDigitsWithZero,
  MSISDNZeroEnabled,
  MSISDNSuffixValues,
} from "../../config/appConfig";
import { getFromCookie } from "../../services/helper/Index";
import { coins } from "../../config/appConfig";

export default function VerificationModal(props) {
  const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [backspace, setBackspace] = useState(false);
  const [numberLength, setNumberLength] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const {
    profileCountryCode
  } = useSelector((state) => state.profile);

  const {
    formToShowInModal,
    emailErrorMessage,
    emailOtpErrorMessage,
    emailUpdateOtpVerifyLoading,
    emailUpdateOtpSentLoading,
    phoneErrorMessage,
    phoneUpdateGenerateOtpLoading,
    phoneOtpErrormessage,
    phoneUpdateVerifyOtpLoading,
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setEmail("");
      setPhoneNumber("");
      setOtp(new Array(4).fill(""));
      dispatch(setEmailErrorMessage(""));
      dispatch(setEmailOtpErrorMessage(""));
      dispatch(setPhoneErrorMessage(""));
      dispatch(setPhoneOtpErrormessage(""));
    };
  }, [props.open]);
  const handleEmailChange = (e) => {
    const value = e.target.value;
    dispatch(setEmailErrorMessage(""));
    setEmail(e.target.value);
  };
  const handleEmailSave = (e, resend) => {
    e.preventDefault();
    dispatch(setResendOTP(false));
    dispatch(setEmailErrorMessage(""));
    if (resend) {
      setOtp(new Array(4).fill(""));
    }
    dispatch(setEmailOtpErrorMessage(""));
    if (email === "") {
      // setEmailErrorMessage("Enter Email")
      dispatch(setEmailErrorMessage("Enter Email"));
    } else if (!regExp.test(email)) {
      // setEmailErrorMessage("Invalid Email")
      dispatch(setEmailErrorMessage("Invalid Email"));
    } else {
      console.log("api");
      dispatch(emailUpdateGenerateOtp(email));
      // dispatch(setFormToShowInModal("emailOtp"));
    }
  };

  const handleOtpSumbit = (e) => {
    e.preventDefault();
    dispatch(setEmailOtpErrorMessage(""));
    const otpvalid = otp.includes("");
    if (otpvalid) {
      dispatch(setEmailOtpErrorMessage("Enter Valid OTP"));
    } else {
      console.log("verifyotpcall");
      dispatch(emailUpdateVerifyOtp(otp));
    }
  };

  const handleOtpChange = (element, index) => {
    dispatch(setEmailOtpErrorMessage(""));
    dispatch(setPhoneOtpErrormessage(""));
    if (isNaN(element.target.value)) return false;

    setOtp([
      ...otp.map((d, idx) => (idx === index ? element.target.value : d)),
    ]);

    if (backspace || element.nativeEvent.inputType == "deleteContentBackward") {
      if (element.target.previousSibling) {
        element.target.previousSibling.focus();
      }
    } else {
      if (element.target.nextSibling) {
        element.target.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.keyCode === 8 ||
      event.keyCode === 46
    ) {
      setBackspace(true);
    } else {
      setBackspace(false);
    }
  };

  // const handleNumberChange = (e) => {
  //     const value = e.target.value
  //     dispatch(setPhoneErrorMessage(""))
  //     setPhoneNumber(value)
  // }

  const handleNumberChange = (e) => {
    dispatch(setPhoneErrorMessage(false));
    // setCountryCodeError(false)
    const re = /[0-9]+/g;
    if (
      re.test(e.nativeEvent.data) ||
      e.nativeEvent.inputType == "deleteContentBackward" ||
      e.nativeEvent.inputType === "deleteContentForward"
    ) {
      setPhoneNumber(e.target.value);
    } else if (e?.target?.name?.toLowerCase() === "phonenumber") {
      setPhoneNumber(e.target.value);
    }
  };
  const handlePhoneOtpSumbit = (e) => {
    e.preventDefault();
    dispatch(setPhoneOtpErrormessage(""));
    const otpvalid = otp.includes("");
    if (otpvalid) {
      dispatch(setPhoneOtpErrormessage("Enter Valid OTP"));
    } else {
      console.log("verifyotpcall");
      dispatch(phoneUpdateVerifyOtp(otp));
    }
  };

  useEffect(() => {
    if (phoneNumber?.charAt(0) == "0") {
      setNumberLength(true);
    } else {
      setNumberLength(false);
    }
  }, [phoneNumber]);

  const handleSubmitPhone = (e, resend) => {
    e.preventDefault();
    dispatch(setResendOTP(false));
    dispatch(setPhoneOtpErrormessage(""));
    if (resend) {
      setOtp(new Array(4).fill(""));
    }
    if (phoneNumber === undefined || phoneNumber === "") {
      dispatch(setPhoneErrorMessage("Enter Phone Number"));
    }
    // else if (phoneNumber.length !== 10) {
    //   dispatch(setPhoneErrorMessage("Must have 10 digit Mobile Number"));
    // } 
    else {
      let isLengthValid =
        MSISDNZeroEnabled && phoneNumber?.charAt(0) === "0"
          ? phoneNumber?.length === MSISDNAcceptableDigitsWithZero
          : phoneNumber?.length === MSISDNAcceptableDigits;
      // let countryCode = getFromCookie("regionCode");
      let msisdnFirstSuffixValues = MSISDNSuffixValues[profileCountryCode];
      let phnNum =
        phoneNumber?.charAt(0) === "0" ? phoneNumber?.slice(1) : phoneNumber;
      const isValidPrefix = msisdnFirstSuffixValues.some((prefix) =>
        phnNum.startsWith(prefix)
      );
      if (isLengthValid && isValidPrefix) {
        dispatch(phoneUpdateGenerateOtp(phnNum, profileCountryCode));
      } else {
        dispatch(setPhoneErrorMessage("Invalid Phone number"));
      }
    }
  };
  let formToShow =
    formToShowInModal === "emailInput" ? (
      <InputUpdate
        title={"Enter Email"}
        placeholder={"Email"}
        type={"text"}
        maxlength={""}
        value={email}
        handleChange={handleEmailChange}
        handleSubmit={handleEmailSave}
        errorMessage={emailErrorMessage}
        loading={emailUpdateOtpSentLoading}
      />
    ) : formToShowInModal === "emailOtp" ? (
      <OtpInput
        value={otp}
        handleKey={handleKeyDown}
        handleChange={handleOtpChange}
        handleSubmit={handleOtpSumbit}
        handleResend={handleEmailSave}
        errorMessage={emailOtpErrorMessage}
        loading={emailUpdateOtpVerifyLoading}
      />
    ) : formToShowInModal === "phoneInput" ? (
      <InputUpdate
        title={"Enter Phone Number"}
        placeholder={"Phone Number"}
        type={"tel"}
        maxLength={numberLength ? 10 : 9}
        value={phoneNumber}
        handleChange={handleNumberChange}
        handleSubmit={handleSubmitPhone}
        errorMessage={phoneErrorMessage}
        loading={phoneUpdateGenerateOtpLoading}
        // setCountryCode={setCountryCode}
        // countryCode={countryCode}
      />
    ) : formToShowInModal === "coingainmessage" ? (
      <div className="success-content">
        <UserNameSuccessDialog
          close={props.close}
          content={`Thank you for updating your details. ${coins?.completeProfile} coins have been credited to your account`}
        />
      </div>
    ) : formToShowInModal === "phoneOtp" ? (
      <OtpInput
        value={otp}
        handleKey={handleKeyDown}
        handleChange={handleOtpChange}
        handleSubmit={handlePhoneOtpSumbit}
        handleResend={handleSubmitPhone}
        errorMessage={phoneOtpErrormessage}
        loading={phoneUpdateVerifyOtpLoading}
      />
    ) : null;

  return (
    <ModalBox
      dOpen={props.open}
      dClose={props.close}
      isClosable={true}
      small={true}
    >
      <div className="verifiction_modal_wrapper">
        <div className="myprofile_username_success_modal ">
          <div className="games_Feedback_popup">{formToShow}</div>
        </div>
      </div>
    </ModalBox>
  );
}
